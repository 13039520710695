import { useEffect, useState } from 'react';
import axios, { AxiosError } from 'axios';
import AuthenticationService from 'authentication/AuthenticationService';
import { TenantReferenceData } from './types';

type Params = {
  uuid: string;
};

type Return = {
  data: TenantReferenceData | null;
  loading: boolean;
  error: string;
};

export const useGetRentalReferenceData = ({ uuid }: Params): Return => {
  const [data, setData] = useState<TenantReferenceData | null>(null);
  const [loading, setLoading] = useState(!data);
  const [error, setError] = useState<string>('');

  const token = AuthenticationService.accessToken;

  const fetchData = async () => {
    try {
      setLoading(true);
      setError('');

      const newData = await axios
        .get<TenantReferenceData>(
          `${process.env.REACT_APP_BASE_API_URI}api/tenant-review/${uuid}/`,
          {
            withCredentials: true,
            headers: { Authorization: `Bearer ${token}` },
          },
        )
        .then(({ data: d }) => d);

      setLoading(false);
      setData(newData);
    } catch (e) {
      console.error(e);

      setLoading(false);
      setError((e as AxiosError)?.message || 'Unexpected error');
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid]);

  return {
    data,
    loading,
    error,
  };
};

import { useMemo } from 'react';
import { useTranslation, Trans, TFunction } from 'react-i18next';
import { Options } from 'components/atoms/RadioGroup/RadioGroup';
import RadioQuestion from 'questionFlow/genericQuestions/RadioQuestion';
import { getRTRDocumentOptionsByNationalityAndStatus } from 'questionFlow/flows/rightToRentIdentity/data/right-to-rent-docs-list';
import {
  RTRIdentityQuestionComponentProps,
  RTRIdentityValuesType,
  StepNamesRTRIdentity,
} from 'questionFlow/flows/rightToRentIdentity/types';

const cmsLocation = '/questions/identity/documents';

const useContent = (t: TFunction<'translation'>) => ({
  title: t(`/questions/identity/secondaryDocuments.title`),
  prompt: t(`${cmsLocation}.prompt`),
  none: t(`${cmsLocation}.none`),
});

const getOptions = (
  { nationality, secondaryDocumentOne, primaryDocument, status }: RTRIdentityValuesType,
  t: TFunction,
  isSecondSelection?: boolean,
) => {
  const docDefinition = getRTRDocumentOptionsByNationalityAndStatus(
    nationality || '',
    status ? status : primaryDocument === 'none' ? undefined : primaryDocument,
  );

  if (!docDefinition || !docDefinition?.documents?.secondary) {
    throw new Error(
      `Could not locate secondary document definition for nationality ${nationality} and status ${primaryDocument}`,
    );
  }
  if (isSecondSelection) {
    if (docDefinition.documents.secondary[0].quantity > 1) {
      return [
        ...docDefinition.documents.secondary[0].options
          .filter(option => option !== secondaryDocumentOne)
          .map(doc => ({
            value: doc,
            label: t(`${cmsLocation}.${doc}` as any),
          })),
        { value: 'none', label: t(`${cmsLocation}.none` as any), name: 'none' },
      ];
    }

    return [
      ...docDefinition.documents.secondary[1].options.map(doc => ({
        value: doc,
        label: t(`${cmsLocation}.${doc}` as any),
      })),
      { value: 'none', label: t(`${cmsLocation}.none` as any), name: 'none' },
    ];
  }

  return [
    ...docDefinition.documents.secondary[0].options.map(doc => ({
      value: doc,
      label: t(`${cmsLocation}.${doc}` as any),
    })),
    { value: 'none', label: t(`${cmsLocation}.none` as any), name: 'none' },
  ];
};

interface Props extends RTRIdentityQuestionComponentProps {
  isSecondSelection?: boolean;
}

const SecondaryDocuments = ({ values, isSecondSelection, ...props }: Props) => {
  const name = isSecondSelection
    ? StepNamesRTRIdentity.secondaryDocumentTwo
    : StepNamesRTRIdentity.secondaryDocumentOne;
  const { t } = useTranslation();
  const content = useContent(t);

  const options: Options = useMemo(() => {
    return getOptions(values, t, isSecondSelection);
  }, [values, t, isSecondSelection]);

  return (
    <RadioQuestion
      content={{
        prompt: content.prompt,
        title: content.title,
        subtitle: (
          <Trans
            i18nKey={`${cmsLocation}.subtitle` as any}
            components={{ bold: <span className="font-semibold" /> }}
          />
        ),
      }}
      initialValue={values[name] || ''}
      name={name}
      options={options}
      large
      {...props}
    />
  );
};

export default SecondaryDocuments;

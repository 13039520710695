import { useCallback, useMemo, useState } from 'react';
import {
  WizardSectionType,
  WizardSectionStepDefinition,
  useUpdateConsentsMutation,
} from 'generated/graphql';
import { useAnalyticsEvent } from 'monitoring/analyticsHooks';
import useInitialData from 'api/data/initial-data/useInitialData';

import Modal from 'react-modal';
import OnboardingSteps from '../OnboardingSteps';

import WelcomePage from '../WelcomePage';
import IdentityPage from '../IdentityPage';
import IncomePage from '../IncomePage';
import ResidencePage from '../ResidencePage';
import AllSetPage from '../AllSetPage';
import ConsentPage from '../ConsentPage';
import { SubmitPayload } from '../ConsentPage/ConsentPage';

export enum OnboardingSectionEnum {
  Welcome = 'WELCOME',
  AllSet = 'ALL_SET',
  Consent = 'CONSENT',
}

const OnboardingOverlay = () => {
  const [acceptedConsents, setAcceptedConsents] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);

  const initialData = useInitialData();
  const [updateConsents, { loading, error }] = useUpdateConsentsMutation();
  const { broadcastEvent } = useAnalyticsEvent();

  const stepsArray = useMemo(() => {
    const sections = initialData.me.latestValidation?.sections?.map(section => section.name) || [];
    return [
      OnboardingSectionEnum.Welcome,
      ...sections,
      OnboardingSectionEnum.AllSet,
      OnboardingSectionEnum.Consent,
    ];
  }, [initialData]);

  const stepsComponent = useMemo(
    () => <OnboardingSteps stepsCount={stepsArray.length - 1} crtStep={currentStep} />,
    [currentStep, stepsArray],
  );

  const renderSteps = () => {
    const goToNextStep = () => {
      setCurrentStep(currentStep + 1);
      broadcastEvent(`Cicked submit on onboarding step ${stepsArray[currentStep]}`);
    };

    const props = { stepsComponent, onClick: goToNextStep, index: currentStep };

    switch (stepsArray[currentStep]) {
      case OnboardingSectionEnum.Welcome: {
        const name = initialData.me.name?.firstName || '';
        return <WelcomePage name={name} {...props} />;
      }
      case WizardSectionType.Identity: {
        const hasIdentityRTR = !!initialData.me.latestValidation?.sections?.find(
          section => section?.steps?.[0] === WizardSectionStepDefinition.IdentityRtr,
        );

        return <IdentityPage RTR={hasIdentityRTR} {...props} />;
      }
      case WizardSectionType.Income: {
        return <IncomePage {...props} />;
      }
      case WizardSectionType.Residence: {
        return <ResidencePage {...props} />;
      }
      case OnboardingSectionEnum.AllSet: {
        return <AllSetPage {...props} />;
      }
      case OnboardingSectionEnum.Consent: {
        return <ConsentPage error={!!error} loading={loading} onSubmitConsents={submitConsents} />;
      }
    }
  };

  const hasConsentedInitially = () => {
    const consents = initialData.me.latestValidation?.consents;
    return (
      consents && consents.backgroundChecks && consents.dataAccuracy && consents.termsAndConditions
    );
  };

  const submitConsents = useCallback(
    (payload: SubmitPayload) => {
      updateConsents({
        variables: {
          backgroundChecks: payload.background,
          dataAccuracy: payload.data,
          marketing: payload.marketing,
          termsAndConditions: payload.terms,
        },
      })
        .then(() => {
          setAcceptedConsents(true);
        })
        .catch(() => {
          // An error was thrown, we only need to catch it, the error object no longer being 'undefind' is how we provide feedback
        });
    },
    [updateConsents],
  );

  return (
    <Modal
      id="onboarding-overlay"
      appElement={document.getElementById('root') || undefined}
      isOpen={!hasConsentedInitially() && !acceptedConsents}
      contentLabel="Onboarding Modal"
      className="outline-none p-0 md:w-[480px] min-h-full md:min-h-[600px] absolute md:static left-0 right-0 top-0 bottom-0 md:flex overflow-scroll md:overflow-hidden shadow-xl"
      overlayClassName="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-white bg-opacity-90 z-50"
    >
      {renderSteps()}
    </Modal>
  );
};

export default OnboardingOverlay;

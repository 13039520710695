import AmountsQuestion from '../genericQuestions/AmountsQuestion';
import {
  DefaultIncomeQuestionComponentProps,
  StepNamesDefaultIncome,
} from 'questionFlow/flows/defaultIncome/types';

import { TFunction, useTranslation } from 'react-i18next';

const cmsLocation = '/questions/income/yourLoan';

const useContent = (t: TFunction<'translation'>) => ({
  title: t(`${cmsLocation}.title`),
  subtitle: t(`${cmsLocation}.subtitle`),

  annualValue: {
    label: t(`${cmsLocation}.annualValue.label`),
    placeholder: t(`${cmsLocation}.annualValue.placeholder`),
  },
  instalmentAmount: {
    label: t(`${cmsLocation}.instalmentAmount.label`),
    placeholder: t(`${cmsLocation}.instalmentAmount.placeholder`),
  },
  recentPaymentDate: {
    label: t(`${cmsLocation}.recentPaymentDate.label`),
  },
});

const name = StepNamesDefaultIncome.yourLoan;

const YourLoan = ({
  data,
  onSubmit,
  values,
  validationRules,
  ...props
}: DefaultIncomeQuestionComponentProps) => {
  const { t } = useTranslation();
  const content = useContent(t);

  return (
    <AmountsQuestion
      onSubmit={onSubmit}
      validationRules={validationRules}
      data={data}
      values={values}
      annualLabel={content.annualValue.label}
      annualPlaceholderText={content.annualValue.placeholder}
      paymentLabel={content.instalmentAmount.label}
      paymentPlaceholderText={content.instalmentAmount.placeholder}
      paymentDateLabel={content.recentPaymentDate.label}
      title={content.title}
      subtitle={content.subtitle}
      name={name}
      {...props}
    />
  );
};
export default YourLoan;

import { ApolloLink, RequestHandler } from '@apollo/client';

import AuthenticationService from 'authentication/AuthenticationService';

export const requestHandler: RequestHandler = (operation, forward) => {
  const accessToken = AuthenticationService.accessToken;

  if (accessToken) {
    operation.setContext({
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    });
  }

  return forward(operation);
};

const createRequestLink = () => new ApolloLink(requestHandler);

export default createRequestLink;

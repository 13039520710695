import { useMemo } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { Options } from 'components/molecules/DropDown/types';
import { GqlPaymentFrequencyEnum } from 'generated/graphql';
import { YesNoEnum } from 'questionFlow/flows/defaultIncome/types';

export const cmsLocation = '/questions/income/yourSalary';

export const useContent = (t: TFunction<'translation'>) => ({
  title: t(`${cmsLocation}.title`),
  subtitle: t(`${cmsLocation}.subtitle`),

  annualBaseSalary: {
    label: t(`${cmsLocation}.annualBaseSalary.label`),
    placeholder: t(`${cmsLocation}.annualBaseSalary.placeholder`),
  },
  currency: {
    label: t(`${cmsLocation}.currency.label`),
    placeholder: t(`${cmsLocation}.currency.placeholder`),
  },
  netSalary: {
    label: t(`${cmsLocation}.netSalary.label`),
    label_optional: t(`${cmsLocation}.netSalary.label_optional`),
    placeholder: t(`${cmsLocation}.netSalary.placeholder`),
  },
  frequency: {
    label: t(`${cmsLocation}.frequency.label`),
    placeholder: t(`${cmsLocation}.frequency.placeholder`),
    option_daily: t(`${cmsLocation}.frequency.option_daily`),
    option_weekly: t(`${cmsLocation}.frequency.option_weekly`),
    option_fortnightly: t(`${cmsLocation}.frequency.option_fortnightly`),
    option_monthly: t(`${cmsLocation}.frequency.option_monthly`),
  },
  lastPaymentDate: {
    label: t(`${cmsLocation}.lastPaymentDate.label`),
    label_optional: t(`${cmsLocation}.lastPaymentDate.label_optional`),
    placeholder: t(`${cmsLocation}.lastPaymentDate.placeholder`),
    dayInputLabel: t(`${cmsLocation}.lastPaymentDate.dayInputLabel`),
    monthInputLabel: t(`${cmsLocation}.lastPaymentDate.monthInputLabel`),
    yearInputLabel: t(`${cmsLocation}.lastPaymentDate.yearInputLabel`),
  },
  bonusStatus: {
    label: t(`${cmsLocation}.bonusStatus.label`),
    label_optional: t(`${cmsLocation}.bonusStatus.label_optional`),
    placeholder: t(`${cmsLocation}.bonusStatus.placeholder`),
    option_yes: t(`${cmsLocation}.bonusStatus.option_yes`),
    option_no: t(`${cmsLocation}.bonusStatus.option_no`),
  },
});

export const useBonusStatusOptions = () => {
  const { t } = useTranslation();
  const content = useContent(t);

  const { option_no, option_yes } = content.bonusStatus;
  const yesNoOptions: Options = useMemo(
    () => [
      { value: YesNoEnum.YES, label: option_yes },
      { value: YesNoEnum.NO, label: option_no },
    ],
    [option_no, option_yes],
  );

  return yesNoOptions;
};

export const useFrequencyOptions = () => {
  const { t } = useTranslation();
  const content = useContent(t);

  const { option_daily, option_weekly, option_fortnightly, option_monthly } = content.frequency;

  const frequencyOptions: Options = useMemo(
    () => [
      { value: GqlPaymentFrequencyEnum.Daily, label: option_daily },
      { value: GqlPaymentFrequencyEnum.Weekly, label: option_weekly },
      { value: GqlPaymentFrequencyEnum.Weekly_2, label: option_fortnightly },
      { value: GqlPaymentFrequencyEnum.Monthly, label: option_monthly },
    ],
    [option_daily, option_weekly, option_fortnightly, option_monthly],
  );

  return frequencyOptions;
};

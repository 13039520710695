import axios from 'axios';
import AuthenticationService from 'authentication/AuthenticationService';

const AxiosInstance = axios.create({
  withCredentials: true,
});

AxiosInstance.interceptors.request.use(async config => {
  const newToken = await AuthenticationService.refreshToken();
  if (newToken && config.headers.Authorization) {
    config.headers.Authorization = `Bearer ${newToken}`;
  }
  return config;
});

export default AxiosInstance;

import { Route, RouteProps } from 'react-router-dom';
import useAuthentication from 'authentication/useAuthentication';
import GotoOtl from 'pages/otl/GotoOtl';
import { useState } from 'react';
import GotoSessionExpired from 'pages/otl/GotoSessionExpired';
import { InternalRouteLayout, NavigationHeader } from 'components';

const ProtectedRoute = (props: RouteProps) => {
  const authenticationState = useAuthentication();
  const [hasLoggedIn, setHasLoggedIn] = useState(authenticationState === 'logged-in');
  if (authenticationState === 'logged-in') {
    if (!hasLoggedIn) {
      setHasLoggedIn(true);
    }

    return (
      <InternalRouteLayout>
        <InternalRouteLayout.Header>
          <NavigationHeader />
        </InternalRouteLayout.Header>
        <Route {...props} />
      </InternalRouteLayout>
    );
  }

  if (authenticationState === 'logged-out') {
    return hasLoggedIn ? <GotoSessionExpired /> : <GotoOtl />;
  }

  return (
    <div>{`Invalid state: Routes should not be rendered while authentication state is ${authenticationState}`}</div>
  );
};

export default ProtectedRoute;

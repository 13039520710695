import { useMemo } from 'react';
import {
  incomeConditionTypesEnum,
  DefaultIncomeQuestionComponentProps,
  someoneElseEnum,
  StepNamesDefaultIncome,
} from '../flows/defaultIncome/types';

import { TFunction, useTranslation } from 'react-i18next';
import RadioQuestion from 'questionFlow/genericQuestions/RadioQuestion';
import { Options } from 'components/atoms/RadioGroup/RadioGroup';
import { getIncomeCondition } from './utilities';
const cmsLocation = '/questions/income/someoneElse';

const useContent = (t: TFunction<'translation'>) => ({
  title: t(`${cmsLocation}.title`),
  subtitle: t(`${cmsLocation}.subtitle`),
  zeroPercentShare: t(`${cmsLocation}.zeroPercentShare`),
  guarantor: t(`${cmsLocation}.guarantor`),
});

const getOtherPayersList = (data: DefaultIncomeQuestionComponentProps['data']): string[] | null => {
  const allowedOtherPayers = getIncomeCondition(
    { incomeSectionMetaData: data },
    incomeConditionTypesEnum.ALLOWED_OTHER_PAYERS,
  );
  const allowedOtherPayersList =
    allowedOtherPayers?.__typename === 'WizardListOfStringsConditionType'
      ? ((allowedOtherPayers as any).listStrValues as string[])
      : null;
  return (
    allowedOtherPayersList &&
    allowedOtherPayersList.map(option => (someoneElseEnum as any)[option]).filter(option => option)
  );
};

const name = StepNamesDefaultIncome.someoneElse;

const SomeoneElse = ({ data, values, ...props }: DefaultIncomeQuestionComponentProps) => {
  const allowedOtherPayersValues = getOtherPayersList(data);
  const { t } = useTranslation();
  const content = useContent(t);

  const options: Options = useMemo(
    () =>
      [
        { value: someoneElseEnum.ZEROPERCENTSHARE, label: content.zeroPercentShare },
        { value: someoneElseEnum.GUARANTOR, label: content.guarantor },
      ].filter(option =>
        allowedOtherPayersValues ? allowedOtherPayersValues.includes(option.value) : true,
      ),
    [content.zeroPercentShare, content.guarantor, allowedOtherPayersValues],
  );
  return (
    <RadioQuestion
      content={content}
      initialValue={values[name]}
      name={name}
      options={options}
      {...props}
    />
  );
};

export default SomeoneElse;

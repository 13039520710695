import React from 'react';
import clsx from 'clsx';

interface Props {
  className?: string;
  [key: string]: any;
}

const ValidationError: React.FC<Props> = ({ className, ...props }) => (
  <span
    aria-atomic="true"
    aria-live="assertive"
    className={clsx('block text-red-5 text-xs leading-4 min-h-[16px] whitespace-normal', className)}
    {...props}
  />
);

export default ValidationError;

export enum SupportedLanguageCode {
  de = 'de',
  en = 'en',
  es = 'es',
  fr = 'fr',
  it = 'it',
  pt = 'pt',
}

export type LanguageMeta = {
  code: SupportedLanguageCode;
  displayNameShort: string;
  displayNameLong: string;
};

const createLanguageMeta = (code: SupportedLanguageCode, name: string) =>
  Object.freeze<LanguageMeta>({
    code,
    displayNameShort: code.toUpperCase(),
    displayNameLong: name,
  });

const SupportedLanguages = Object.freeze([
  createLanguageMeta(SupportedLanguageCode.de, 'Deutsch'),
  createLanguageMeta(SupportedLanguageCode.en, 'English'),
  createLanguageMeta(SupportedLanguageCode.es, 'Español'),
  createLanguageMeta(SupportedLanguageCode.fr, 'Français'),
  createLanguageMeta(SupportedLanguageCode.it, 'Italiano'),
  createLanguageMeta(SupportedLanguageCode.pt, 'Português'),
]);

export default SupportedLanguages;

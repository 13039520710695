import { useAnalyticsErrors, useAnalyticsValues } from 'monitoring/analyticsHooks';
import { DefaultIncomeValuesType } from 'questionFlow/flows/defaultIncome/types';
import { YourSalaryQuestion, YourSalaryValues, YourSalaryQuestionValues } from './types';

export const adaptToExternalFormat = ({
  lastPaymentDate,
  ...input
}: YourSalaryValues): YourSalaryQuestionValues => ({
  ...input,
  lastPaymentDate: lastPaymentDate.value,
});

export const useValues = (initialValues?: DefaultIncomeValuesType) => {
  const yourSalary = initialValues?.yourSalary as YourSalaryQuestionValues | undefined;

  return useAnalyticsValues<YourSalaryValues>({
    annualBaseSalary: yourSalary?.annualBaseSalary || '',
    currency: yourSalary?.currency || '',
    netSalary: yourSalary?.netSalary || '',
    frequency: yourSalary?.frequency || '',
    lastPaymentDate: {
      valid: true,
      value: yourSalary?.lastPaymentDate || '',
    },
    bonusStatus: yourSalary?.bonusStatus || '',
  });
};

export const useErrors = () =>
  useAnalyticsErrors<Record<YourSalaryQuestion, string>>({
    annualBaseSalary: '',
    currency: '',
    netSalary: '',
    frequency: '',
    lastPaymentDate: '',
    bonusStatus: '',
  });

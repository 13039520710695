import { InternalRouteLayout, Button, Spinner } from 'components';
import PublicNavigationHeader from 'components/organisms/PublicNavigationHeader';
import ReferenceDetails from './ReferenceDetails';
import ReferenceForm from './ReferenceForm';
import { useParams } from 'react-router-dom';
import { useGetRentalReferenceData } from './useGetRentalReferenceData';
import { Trans } from 'react-i18next';
import { useSaveRentalReferenceData } from './useSaveRentalReferenceData';
import Server500 from 'pages/Error';

const RentalReferencePage = () => {
  const { uuid } = useParams<{ uuid: string }>();
  const { data, loading, error } = useGetRentalReferenceData({ uuid });
  const { saveData, loading: loadingSave, error: errorSave } = useSaveRentalReferenceData({ uuid });

  if (error || errorSave) {
    return <Server500 />;
  }

  if (!data || loading) {
    return <Spinner className="mx-5" strokeClass="text-white" fillClass="text-white" />;
  }

  return (
    <InternalRouteLayout>
      <InternalRouteLayout.Header>
        <PublicNavigationHeader showLanguage={false} />
      </InternalRouteLayout.Header>
      <InternalRouteLayout.Body data-testid="rental-reference">
        <div className="max-w-[500px] ">
          <ReferenceDetails userData={data} />
          <ReferenceForm tenantName={data.first_name} onSubmit={saveData} />
        </div>
      </InternalRouteLayout.Body>
      <InternalRouteLayout.Footer aria-label="question-submit-banner">
        <Button
          className="md:w-auto"
          id="question-submit"
          type="submit"
          form="refrence-form"
          data-testid="question-submit"
        >
          {loadingSave ? (
            <Spinner small className="mx-5" strokeClass="text-white" fillClass="text-white" />
          ) : (
            <Trans i18nKey={'/rentalReference.submit'} />
          )}
        </Button>
      </InternalRouteLayout.Footer>
    </InternalRouteLayout>
  );
};

export default RentalReferencePage;

import { ValidationRule } from '../../types';
import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';
import QuestionOrganism from '../../QuestionOrganism';
import { DropDown, Field, Input } from 'components';
import DateInput, { OnDateChange } from 'components/molecules/DateInput/DateInput';
import { useContent, useBonusStatusOptions, useFrequencyOptions } from './content';
import {
  assertIsYourSalaryStringQuestion,
  assertYourSalaryDateQuestion,
  YourSalaryQuestion,
  DateQuestion,
  DateValue,
  StringQuestion,
  isDateValue,
} from './types';
import { adaptToExternalFormat, useErrors, useValues } from './state';
import { isRecentStarter } from '../utilities';
import {
  DefaultIncomeQuestionComponentProps,
  StepNamesDefaultIncome,
} from 'questionFlow/flows/defaultIncome/types';
import useCurrencyCodeOptions from 'questionFlow/genericQuestions/useCurrencyCodeOptions';

const validate = (v: string, rules: ValidationRule[], valid?: boolean) =>
  rules.find(({ rule }) => !rule(v, valid));

const isEmptyValue = (stringOrDateValue: string | DateValue) => {
  return isDateValue(stringOrDateValue)
    ? stringOrDateValue.value.length === 0
    : stringOrDateValue.length === 0;
};

const name = StepNamesDefaultIncome.yourSalary;

const OPTIONAL_FOR_NEW_STARTERS = new Set<YourSalaryQuestion>([
  'netSalary',
  'bonusStatus',
  'lastPaymentDate',
]);

const YourSalary = ({
  data,
  values: initialValues,
  onSubmit,
  validationRules,
  ...props
}: DefaultIncomeQuestionComponentProps) => {
  const { t } = useTranslation();
  const content = useContent(t);
  const [values, setValues] = useValues(initialValues);
  const [error, setError] = useErrors();

  const currencyOptions = useCurrencyCodeOptions();
  const frequencyOptions = useFrequencyOptions();
  const yesNoOptions = useBonusStatusOptions(content);

  const recentlyStartedJob = useMemo(
    () => isRecentStarter(initialValues?.currentEmployer.startDate),
    [initialValues.currentEmployer.startDate],
  );

  const canSkipValidation = useCallback(
    (questionName: YourSalaryQuestion, stringOrDateValue: string | DateValue) => {
      const isOptional = recentlyStartedJob ? OPTIONAL_FOR_NEW_STARTERS.has(questionName) : false;

      return isOptional && isEmptyValue(stringOrDateValue);
    },
    [recentlyStartedJob],
  );

  const NET_SALARY_LIMIT_RULE = useMemo(
    () =>
      Object.freeze<ValidationRule>({
        name: 'net-salary-limit',
        rule: v => {
          const annualBaseSalary = parseFloat(values.annualBaseSalary);
          const netSalary = parseFloat(v);

          return isFinite(annualBaseSalary) && isFinite(netSalary) && netSalary <= annualBaseSalary;
        },
        error: '/questions/income/yourSalary.validation.net_range',
      }),
    [values.annualBaseSalary],
  );

  const getValidationResult = (name: YourSalaryQuestion, givenValue?: string | DateValue) => {
    const stringOrDateValue = givenValue === undefined ? values[name] : givenValue;

    if (canSkipValidation(name, stringOrDateValue)) {
      return undefined;
    }

    if (isDateValue(stringOrDateValue)) {
      return validate(stringOrDateValue.value, validationRules[name], stringOrDateValue.valid);
    }

    const validationRulesResult = validate(stringOrDateValue, validationRules[name]);
    if (!validationRulesResult && name === 'netSalary') {
      return validate(stringOrDateValue, [NET_SALARY_LIMIT_RULE]);
    }

    return validationRulesResult;
  };

  const handleSubmit = () => {
    const errors = (Object.keys(values) as YourSalaryQuestion[]).reduce((e, inputName) => {
      const result = getValidationResult(inputName);

      return { ...e, [inputName]: result ? t(result.error as any) : '' };
    }, error);

    if (Object.values(errors).filter(e => e !== '').length === 0) {
      onSubmit({ key: name, value: adaptToExternalFormat(values) });
    } else {
      setError(errors);
    }
  };

  const validateAndSetError = (name: YourSalaryQuestion, newValue: string) => {
    const result = getValidationResult(name, newValue);
    setError({ ...error, [name]: result ? t(result.error as any) : '' });
  };

  const onStringValueChange = (name: YourSalaryQuestion, value: string) => {
    if (error[name]) {
      validateAndSetError(name, value);
    }

    setValues({ ...values, [name]: value });
  };

  const handleInputOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    assertIsYourSalaryStringQuestion(e.target.name);
    onStringValueChange(e.target.name, e.target.value);
  };

  const handleInputOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    assertIsYourSalaryStringQuestion(e.target.name);
    validateAndSetError(e.target.name, e.target.value);
  };

  const onDropdownChange = (name: StringQuestion, optionValue: string) =>
    onStringValueChange(name, optionValue);

  const handleDateChange: OnDateChange = ({ name, value, valid }) => {
    assertYourSalaryDateQuestion(name);

    setValues({
      ...values,
      [name]: {
        value,
        valid,
      },
    });

    if (valid && error[name]) {
      setError({ ...error, [name]: '' });
    }
  };

  const handleDateOnBlur = (name: DateQuestion) => {
    const result = getValidationResult(name);
    setError({ ...error, [name]: result ? t(result.error as any) : '' });
  };

  return (
    <QuestionOrganism
      id={name}
      data-testid={name}
      onSubmit={handleSubmit}
      title={content.title}
      subtitle={content.subtitle}
      {...props}
    >
      <div className="grid w-max md:grid-cols-2 gap-x-10 gap-y-4 pb-60">
        <Field
          className="md:order-1"
          data-testid={`${name}-currency`}
          label={content.currency.label}
          validationError={error.currency}
        >
          <DropDown
            size="sm"
            placeholder={content.currency.placeholder}
            options={currencyOptions}
            onSelectValue={value => onDropdownChange('currency', value)}
            value={values.currency}
            error={!!error.currency}
            label={content.currency.label}
          />
        </Field>

        <Field
          data-testid={`${name}-annualBaseSalary`}
          label={content.annualBaseSalary.label}
          validationError={error.annualBaseSalary}
        >
          <Input
            id={`${name}-annualBaseSalary`}
            name={'annualBaseSalary'}
            defaultValue={values.annualBaseSalary}
            onChange={handleInputOnChange}
            aria-invalid={!!error.annualBaseSalary}
            onBlur={handleInputOnBlur}
            placeholder={content.annualBaseSalary.placeholder}
            type="number"
          />
        </Field>

        <Field
          className="order-2"
          data-testid={`${name}-netSalary`}
          label={recentlyStartedJob ? content.netSalary.label_optional : content.netSalary.label}
          validationError={error.netSalary}
        >
          <Input
            id={`${name}-netSalary`}
            name={'netSalary'}
            defaultValue={values.netSalary}
            onChange={handleInputOnChange}
            aria-invalid={!!error.netSalary}
            onBlur={handleInputOnBlur}
            placeholder={content.netSalary.placeholder}
            type="number"
          />
        </Field>

        <Field
          className="order-3"
          data-testid={`${name}-frequency`}
          label={content.frequency.label}
          validationError={error.frequency}
        >
          <DropDown
            placeholder={content.frequency.placeholder}
            options={frequencyOptions}
            onSelectValue={value => onDropdownChange('frequency', value)}
            value={values.frequency}
            error={!!error.frequency}
            label={content.frequency.label}
          />
        </Field>

        <Field
          className="order-4"
          data-testid={`${name}-lastPaymentDate`}
          label={
            recentlyStartedJob
              ? content.lastPaymentDate.label_optional
              : content.lastPaymentDate.label
          }
          validationError={error.lastPaymentDate}
        >
          <DateInput
            data-testid="lastPaymentDate-date-input"
            onChange={handleDateChange}
            onBlur={() => handleDateOnBlur('lastPaymentDate')}
            defaultValue={values.lastPaymentDate.value}
            error={!!error.lastPaymentDate}
            id="lastPaymentDate"
            dayLabel={content.lastPaymentDate.dayInputLabel}
            monthLabel={content.lastPaymentDate.monthInputLabel}
            yearLabel={content.lastPaymentDate.yearInputLabel}
          />
        </Field>

        <div className="hidden md:block order-5" />

        <Field
          className="order-6"
          data-testid={`${name}-bonusStatus`}
          label={
            recentlyStartedJob ? content.bonusStatus.label_optional : content.bonusStatus.label
          }
          validationError={error.bonusStatus}
        >
          <DropDown
            placeholder={content.bonusStatus.placeholder}
            options={yesNoOptions}
            onSelectValue={value => onDropdownChange('bonusStatus', value)}
            value={values.bonusStatus}
            error={!!error.bonusStatus}
            label={
              recentlyStartedJob ? content.bonusStatus.label_optional : content.bonusStatus.label
            }
          />
        </Field>
      </div>
    </QuestionOrganism>
  );
};

export default YourSalary;

import { useMemo, useState, useRef, useCallback, DetailedHTMLProps } from 'react';
import clsx from 'clsx';
import OptionsList from './OptionsList';
import { Option, Options, OptionComponentProps } from './types';
import DownChevron from './DownChevron.svg';
import { ButtonHTMLAttributes } from 'react';

export interface ButtonContentsProps
  extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  placeholder?: string;
  IconComponent?: React.ElementType;
  option?: Option;
  error?: boolean;
}

export interface DropdownProps {
  label: string;
  ButtonContentsComponent?: React.ComponentType<ButtonContentsProps>;
  className?: string;
  disabled?: boolean;
  'data-testid'?: string;
  onSelectValue: (newValue: string) => void;
  options: Options;
  OptionComponent?: React.ComponentType<OptionComponentProps>;
  value?: string;
  selectStyle?: { [key: string]: string };
  error?: boolean;
  IconComponent?: React.ElementType;
  placeholder?: string;
  below?: boolean;
  onClose?: (value?: string) => void;
  size?: 'sm' | 'lg';
}

const FallbackIcon = () => <img src={DownChevron} alt="" role="presentation" />;
const defaultSelectStyle = {
  backgroundPosition: 'right',
  backgroundImage: `url("${DownChevron}")`,
};

const ButtonContents = ({
  IconComponent = FallbackIcon,
  placeholder,
  option,
  error,
  ...props
}: ButtonContentsProps) => (
  <button
    {...props}
    className={clsx(
      'w-full h-full',
      error ? 'border-red-3 text-red-5' : 'border-grey-3 text-black disabled:border-grey-3',
      'border rounded',
      'disabled:opacity-50',
      'hover:border-blue-4',
    )}
  >
    <div
      className={clsx(
        'absolute left-0 right-0 top-1 bottom-1 p-4',
        'flex flex-row-reverse flex-no-wrap justify-between items-center',
        !option && 'text-grey-2',
      )}
    >
      <IconComponent />
      <div>{option ? option.label : placeholder}</div>
    </div>
  </button>
);

const DropDown = ({
  ButtonContentsComponent = ButtonContents,
  className,
  disabled,
  IconComponent,
  label,
  onSelectValue,
  options,
  OptionComponent,
  placeholder,
  value = '',
  selectStyle = defaultSelectStyle,
  error,
  below,
  onClose,
  size,
  ...props
}: DropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const listRef = useRef<HTMLUListElement>(null);

  const selectedIndex = useMemo(() => options.findIndex(option => option.value === value), [
    value,
    options,
  ]);

  const keyboardSelectNext = () => {
    if (selectedIndex < options.length - 1) {
      onSelectValue(options[selectedIndex + 1].value);
    }
  };

  const keyboardSelectPrev = () => {
    if (selectedIndex > 0) {
      onSelectValue(options[selectedIndex - 1].value);
    }
  };

  const toggleModal = (e: React.MouseEvent) => {
    e.preventDefault();

    setIsOpen(!isOpen);
  };

  const closeModal = useCallback(() => {
    setIsOpen(false);
    if (onClose) {
      onClose(value);
    }
  }, [onClose, value]);

  const onSelectAndClose = useCallback(
    (newValue: string) => {
      onSelectValue(newValue);
      setIsOpen(false);
      if (onClose) {
        onClose(newValue);
      }
    },
    [onSelectValue, onClose],
  );

  const handleNativeChange = (e: React.ChangeEvent) => {
    const target = e.target as HTMLInputElement;
    onSelectValue(target.value);
  };

  return (
    <>
      <div
        className={clsx(
          error ? 'border-red-3 text-red-5' : 'border-grey-3 text-black',
          'md:hidden',
          'border rounded p-1 pr-4',
          'h-14',
          size === 'sm' ? 'w-28' : 'w-64',
          'disabled:opacity-50',
          className,
        )}
        data-testid={`${props['data-testid']}-native`}
      >
        <select
          aria-invalid={error}
          aria-label={label}
          onChange={handleNativeChange}
          value={value || ''}
          disabled={disabled}
          className={clsx(
            'w-full h-full focus:outline-none px-4 truncate',
            'appearance-none relative',
            'bg-white bg-no-repeat',
            value === '' ? 'text-gray-400' : 'text-black',
          )}
          style={selectStyle}
        >
          <option value="" disabled className={clsx(value === '' ? 'text-gray-400' : 'text-black')}>
            {placeholder}
          </option>
          {options.map(o => (
            <option key={o.value} value={o.value} className="text-black">
              {o.label}
            </option>
          ))}
        </select>
      </div>

      <div
        className={clsx(
          'hidden md:block',
          'relative box-border',
          'h-14 w-64',
          size === 'sm' ? 'w-28' : '',
          size === 'lg' ? 'w-72' : '',
          className,
        )}
        data-testid="desktop-dropdown"
        {...props}
      >
        <ButtonContentsComponent
          aria-expanded={isOpen ? true : undefined}
          aria-label={label}
          aria-invalid={error}
          role="listbox"
          disabled={disabled}
          onClick={toggleModal}
          IconComponent={IconComponent}
          placeholder={placeholder}
          option={options[selectedIndex]}
          error={error}
        />

        {isOpen && (
          <OptionsList
            aria-label={label}
            className={clsx(below ? 'absolute top-11' : 'absolute top-2 left-2', 'z-10')}
            onClose={closeModal}
            onSelectValue={onSelectAndClose}
            onKeyboardNext={keyboardSelectNext}
            onKeyboardPrev={keyboardSelectPrev}
            OptionComponent={OptionComponent}
            options={options}
            value={value}
            ref={listRef}
            size={size}
          />
        )}
      </div>
    </>
  );
};

export default DropDown;

import { useMemo } from 'react';
import QuestionOrganism from '../QuestionOrganism';
import { Field, RadioGroup } from 'components';
import { OnSubmitType, ValidationRule, ValueType } from '../types';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { Options } from 'components/atoms/RadioGroup/RadioGroup';
import { useAnalyticsError, useAnalyticsValue } from 'monitoring/analyticsHooks';

export interface RadioQuestionProps {
  content: {
    title: string | React.ReactNode;
    subtitle: string | React.ReactNode;
    prompt?: string | React.ReactNode;
  };
  index: number;
  initialValue?: ValueType;
  name: string;
  onBack: () => void;
  onSubmit: OnSubmitType;
  options: Options;
  sectionName: string;
  validationRules: { [key: string]: ValidationRule[] };
  large?: boolean;
}

const RadioQuestion = ({
  content,
  initialValue = '',
  name,
  onSubmit,
  options,
  validationRules,
  large,
  ...props
}: RadioQuestionProps) => {
  const { t } = useTranslation();

  const allowedOptionValues = useMemo(() => options.map(({ value }) => value), [options]);

  const [value, setValue] = useAnalyticsValue(
    name,
    typeof initialValue === 'string' ? initialValue : '',
  );
  const [error, setError] = useAnalyticsError(name, '');

  const validate = useCallback(
    (v: string) => validationRules[name].find(({ rule }) => !rule(v, allowedOptionValues)),
    [allowedOptionValues, name, validationRules],
  );

  const handleSubmit = useCallback(() => {
    const err = validate(value);

    if (err) {
      setError(t(err.error as any));
    } else {
      onSubmit({ key: name, value });
    }
  }, [t, name, onSubmit, validate, value, setError]);

  const handleOnChange = useCallback(
    (e: React.ChangeEvent) => {
      const target = e.target as HTMLInputElement;
      const err = validate(target.value);
      if (error && !err) {
        setError('');
      }
      setValue(target.value);
    },
    [error, validate, setError, setValue],
  );

  return (
    <QuestionOrganism id={name} data-testid={name} onSubmit={handleSubmit} {...content} {...props}>
      <Field validationError={error} data-testid={name}>
        <RadioGroup
          large={large}
          defaultValue={value}
          onChange={handleOnChange}
          id={name}
          options={options}
        />
      </Field>
    </QuestionOrganism>
  );
};

export default RadioQuestion;

import { useMemo } from 'react';
import {
  DefaultIncomeQuestionComponentProps,
  StepNamesDefaultIncome,
} from 'questionFlow/flows/defaultIncome/types';

import { useTranslation, TFunction } from 'react-i18next';
import {
  useDocumentOptionsContent,
  DocumentTypes,
  mapDocumentTypeToAccountDocument,
} from './utilities/documentOptions';
import Upload from '../genericQuestions/Upload';
import { getFileFormatRuleForDocumentKey } from 'questionFlow/genericQuestions/Upload/uploadHelpers';

const cmsLocation = '/questions/income/upload';

const useContent = (t: TFunction<'translation'>, fileName: DocumentTypes) => {
  const translationLocation = {
    [DocumentTypes.PAYSLIP]: `${cmsLocation}.PAYSLIP`,
    [DocumentTypes.TAXRETURN]: `${cmsLocation}.TAX_RETURN`,
    [DocumentTypes.CONTRACT]: `${cmsLocation}.EMPLOYMENT_CONTRACT`,
    [DocumentTypes.INVESTMENT]: `${cmsLocation}.INVESTMENT_PORTFOLIO`,
    [DocumentTypes.STUDENT_LETTER]: `${cmsLocation}.STUDENT_STATUS`,
    [DocumentTypes.PENSION_STATEMENT]: `${cmsLocation}.PENSION_STATEMENT`,
    [DocumentTypes.BENEFITS_PAPERWORK]: `${cmsLocation}.BENEFITS_PAPERWORK`,
    [DocumentTypes.COMPANY_PROOF]: `${cmsLocation}.COMPANY_PROOF`,
    [DocumentTypes.BANKSTATEMENT]: `${cmsLocation}.BANK_STATEMENT3`,
    [DocumentTypes.OTHER]: `${cmsLocation}.OTHER`,
  };

  const doesTranslationExist = (translateItem: String) =>
    t(`${translationLocation[fileName]}.${translateItem}` as any).includes(cmsLocation)
      ? t(`${cmsLocation}.${translateItem}` as any)
      : t(`${translationLocation[fileName]}.${translateItem}` as any);
  return {
    do: doesTranslationExist('do'),
    dont: doesTranslationExist('dont'),
    doItems: doesTranslationExist('doItems'),
    dontItems: doesTranslationExist('dontItems'),
    dontHaveTitle: doesTranslationExist('dontHave.title'),
    dontHaveSubtitle: doesTranslationExist('dontHave.subtitle'),
    dontHaveOption: doesTranslationExist('dontHave.option'),
    required: t('validation.required'),
    inProgress: t('validation.inProgress'),
    fileTooLarge: t('validation.fileTooLarge'),
    wrongFileFormat: t('validation.wrongFileFormat'),
    uploadDoc: t('/questions/upload.uploadDoc'),
    multipleFiles: t('/questions/upload.multipleFiles'),
    minFiles: t('/questions/upload.minFiles'),
    ...useDocumentOptionsContent(t),
  };
};

const name = StepNamesDefaultIncome.upload;

const UploadSingle = ({ values, ...props }: DefaultIncomeQuestionComponentProps) => {
  const { t } = useTranslation();
  const translatedContent = useContent(t, values.documents as DocumentTypes);

  const content = useMemo(() => {
    return {
      ...translatedContent,
      title: `${cmsLocation}.${values.documents}.title`,
      subtitle: `${cmsLocation}.${values.documents}.subtitle`,
      fileName: translatedContent[values.documents as DocumentTypes] as string,
      formattedFileName: translatedContent[values.documents as DocumentTypes] as string,
    };
  }, [translatedContent, values.documents]);

  const fileFormat = getFileFormatRuleForDocumentKey(
    mapDocumentTypeToAccountDocument(values.documents as DocumentTypes),
  );

  return (
    <Upload
      values={values}
      {...props}
      fileFormat={fileFormat}
      content={content}
      name={name}
      sides={values.documents === DocumentTypes.OTHER ? 6 : 1}
      additionalFilePath={`income_sources/${values.uuid}/`}
      modalTransValues={{ role: values.currentEmployer.role }}
    />
  );
};

export default UploadSingle;

import { Field } from 'components';
import QuestionOrganism from 'questionFlow/QuestionOrganism';
import { useCallback, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { ValidationRule } from '../types';
import {
  DefaultIncomeQuestionComponentProps,
  DefaultIncomeValuesType,
  StepNamesDefaultIncome,
} from '../flows/defaultIncome/types';
import DateInput, { OnDateChange } from 'components/molecules/DateInput/DateInput';
import { DateValue, isDateValue } from './YourSalary/types';
import { useAnalyticsErrors } from 'monitoring/analyticsHooks';
const cmsLocation = '/questions/income/benefitDetails';

const useContent = (t: TFunction<'translation'>) => ({
  title: t(`${cmsLocation}.title`),
  subtitle: t(`${cmsLocation}.subtitle`),
  startDate: {
    label: t(`${cmsLocation}.startDate.label`),
    dayInputLabel: t(`${cmsLocation}.startDate.dayInputLabel`),
    monthInputLabel: t(`${cmsLocation}.startDate.monthInputLabel`),
    yearInputLabel: t(`${cmsLocation}.startDate.yearInputLabel`),
  },
  reviewDate: {
    label: t(`${cmsLocation}.reviewDate.label`),
    dayInputLabel: t(`${cmsLocation}.reviewDate.dayInputLabel`),
    monthInputLabel: t(`${cmsLocation}.reviewDate.monthInputLabel`),
    yearInputLabel: t(`${cmsLocation}.reviewDate.yearInputLabel`),
  },
});

export type BenefitDetailsQuestionName = 'startDate' | 'reviewDate';
export type BenefitDetailsValues = {
  startDate: DateValue;
  reviewDate: DateValue;
};

export const useValues = (initialValues?: DefaultIncomeValuesType) => {
  const currentDetails = initialValues?.benefitDetails as
    | {
        startDate: string;
        reviewDate: string;
      }
    | undefined;

  return useState<BenefitDetailsValues>({
    startDate: currentDetails?.startDate
      ? {
          value: currentDetails.startDate,
          valid: true,
        }
      : {
          value: '',
          valid: false,
        },
    reviewDate: currentDetails?.reviewDate
      ? {
          value: currentDetails.reviewDate,
          valid: true,
        }
      : {
          value: '',
          valid: false,
        },
  });
};

export const useErrors = () =>
  useAnalyticsErrors<Record<BenefitDetailsQuestionName, string>>({
    startDate: '',
    reviewDate: '',
  });

const validate = (v: string, rules: ValidationRule[], valid?: boolean) =>
  rules.find(({ rule }) => !rule(v, valid));

const stepName = StepNamesDefaultIncome.benefitDetails;

const BenefitDetails = ({
  data,
  onSubmit,
  values: initialValues,
  validationRules: validationRulesGeneric,
  ...props
}: DefaultIncomeQuestionComponentProps) => {
  const validationRules = (validationRulesGeneric as any) as Record<
    BenefitDetailsQuestionName,
    ValidationRule[]
  >;
  const { t } = useTranslation();
  const content = useContent(t);
  const [values, setValues] = useValues(initialValues);
  const [error, setError] = useErrors();

  const getValidationResult = useCallback(
    (name: BenefitDetailsQuestionName, givenValue?: string) => {
      const actualValue = givenValue === undefined ? values[name] : givenValue;
      return isDateValue(actualValue)
        ? validate(actualValue.value, validationRules[name], actualValue.valid)
        : validate(actualValue, validationRules[name]);
    },
    [validationRules, values],
  );

  const adaptToExternalFormat = ({
    reviewDate,
    startDate,
    ...input
  }: BenefitDetailsValues): Record<BenefitDetailsQuestionName, string> => ({
    ...input,
    startDate: startDate.value,
    reviewDate: reviewDate.value,
  });

  const handleSubmit = useCallback(() => {
    const errors = (Object.keys(values) as BenefitDetailsQuestionName[]).reduce((e, inputName) => {
      const result = getValidationResult(inputName);

      return { ...e, [inputName]: result ? t(result.error as any) : '' };
    }, error);

    if (Object.values(errors).filter(e => e !== '').length === 0) {
      onSubmit({ key: stepName, value: adaptToExternalFormat(values) });
    } else {
      setError(errors);
    }
  }, [error, getValidationResult, onSubmit, setError, t, values]);

  const handleDateOnBlur = (name: BenefitDetailsQuestionName) => {
    const result = getValidationResult(name);
    setError({ ...error, [name]: result ? t(result.error as any) : '' });
  };

  const handleDateChange: OnDateChange = ({ name, value, valid }) => {
    setValues({
      ...values,
      [name]: {
        value,
        valid,
      },
    });

    if (valid && error[name as BenefitDetailsQuestionName]) {
      setError({ ...error, [name]: '' });
    }
  };

  return (
    <QuestionOrganism
      id={stepName}
      data-testid={stepName}
      onSubmit={handleSubmit}
      title={content.title}
      subtitle={content.subtitle}
      {...props}
    >
      <div className="grid w-max md:grid-cols-2 gap-x-10 gap-y-4 pb-60">
        <Field
          data-testid={`${stepName}-startDate`}
          label={content.startDate.label}
          validationError={error.startDate}
        >
          <DateInput
            data-testid="start-date-input"
            onChange={handleDateChange}
            onBlur={() => handleDateOnBlur('startDate')}
            defaultValue={values.startDate.value}
            error={!!error.startDate}
            id="startDate"
            dayLabel={content.startDate.dayInputLabel}
            monthLabel={content.startDate.monthInputLabel}
            yearLabel={content.startDate.yearInputLabel}
          />
        </Field>
        <Field
          data-testid={`${stepName}-reviewDate`}
          label={content.reviewDate.label}
          validationError={error.reviewDate}
        >
          <DateInput
            data-testid="review-date-input"
            onChange={handleDateChange}
            onBlur={() => handleDateOnBlur('reviewDate')}
            defaultValue={values.reviewDate.value}
            error={!!error.reviewDate}
            id="reviewDate"
            dayLabel={content.reviewDate.dayInputLabel}
            monthLabel={content.reviewDate.monthInputLabel}
            yearLabel={content.reviewDate.yearInputLabel}
          />
        </Field>
      </div>
    </QuestionOrganism>
  );
};

export default BenefitDetails;

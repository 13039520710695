import { useMemo, useState } from 'react';
import { useTranslation, TFunction } from 'react-i18next';
import { AccountDocumentType } from 'generated/graphql';
import DocumentSignposting from 'questionFlow/genericQuestions/DocumentSignposting';
import {
  IdentityQuestionComponentProps,
  StepNamesDefaultIdentity,
} from 'questionFlow/flows/defaultIdentity/types';
import { get } from 'lodash';
import { DateInput, Field } from 'components';
import { OnDateChange } from 'components/molecules/DateInput/DateInput';
import { SubmitValue, ValidationRule } from 'questionFlow/types';
import { getDocTypeForCopy } from 'questionFlow/genericQuestions/Upload/utils';

const validate = (v: string, rules: ValidationRule[]) => rules.find(({ rule }) => !rule(v));

const cmsDocumentLocation = '/questions/identity/documents';
const cmsSignpostingLocation = '/questions/identity/documentSignposting';

const useContent = (t: TFunction<'translation'>, fileName: AccountDocumentType) => {
  const translationLocation: Partial<Record<AccountDocumentType | string, string>> = {
    [AccountDocumentType.UkDrivingLicence]: t(`${cmsDocumentLocation}.UK_DRIVING_LICENCE`),
    [AccountDocumentType.DrivingLicence]: t(`${cmsDocumentLocation}.DRIVING_LICENCE`),
    [AccountDocumentType.Passport]: t(`${cmsDocumentLocation}.PASSPORT`),
    [AccountDocumentType.UkPassport]: t(`${cmsDocumentLocation}.UK_PASSPORT`),
    [AccountDocumentType.TimeLimitedPassport]: t(`${cmsDocumentLocation}.TIME_LIMITED_PASSPORT`),
    NATIONAL_ID: t(`${cmsDocumentLocation}.NATIONAL_ID`),
    VISA: t(`${cmsDocumentLocation}.VISA`),
  };

  const doesTranslationExist = (translateItem: string) => {
    return t(`${cmsSignpostingLocation}.${fileName}.${translateItem}` as any).includes(
      cmsSignpostingLocation,
    )
      ? {}
      : {
          [translateItem]: t(`${cmsSignpostingLocation}.${fileName}.${translateItem}` as any, {
            doc: translationLocation[fileName] || '{{doc}}',
          }),
        };
  };

  return {
    ...doesTranslationExist('title'),
    ...doesTranslationExist('subtitle'),
    ...doesTranslationExist('doItems'),
    ...doesTranslationExist('dontItems'),
    ...translationLocation,
    expiryDateTitle: t(`${cmsSignpostingLocation}.expiryDateTitle`),
    expiryDateSubtitle: t(`${cmsSignpostingLocation}.expiryDateSubtitle`),
  };
};

const IdentityDocSignposting = ({
  documentKey = StepNamesDefaultIdentity.documents,
  onSubmit,
  validationRules,
  side,
  ...props
}: IdentityQuestionComponentProps & { documentKey?: string; side?: 'front' | 'back' }) => {
  const { t } = useTranslation();

  const [error, setError] = useState('');
  const [expiryDate, setExpiryDate] = useState('');
  const docType = useMemo(
    () => get({ ...props.values, livePhotoSignposting: 'LIVE_PHOTO' }, documentKey),
    [documentKey, props.values],
  );

  const translatedContent = useContent(t, docType as AccountDocumentType);

  const handleDateChange: OnDateChange = ({ value, valid }) => {
    setExpiryDate(value);
    if (valid && error) {
      setError('');
    }
  };

  const validateDateInput = () => {
    const result = validate(expiryDate, validationRules.expiryDate);
    setError(result ? t(result.error as any) : '');
  };

  const handleSubmit = (params: SubmitValue) => {
    if (error) {
      return;
    }

    if (docType === AccountDocumentType.TimeLimitedPassport && side === 'back') {
      if (!expiryDate) {
        validateDateInput();
        return;
      }
      var now = new Date();
      var expiry = new Date(expiryDate);
      onSubmit({ key: 'docSignposting', value: now < expiry });
    } else {
      onSubmit(params);
    }
  };

  const content = useMemo(() => {
    const type = getDocTypeForCopy(docType, side);

    return {
      ...translatedContent,
      fileName: translatedContent[type as keyof typeof translatedContent] as string,
      formattedFileName: translatedContent[type as keyof typeof translatedContent] as string,
    };
  }, [translatedContent, docType, side]);

  return (
    <DocumentSignposting
      {...props}
      showNumericSides={docType === AccountDocumentType.TimeLimitedPassport}
      documentKey={documentKey}
      content={content}
      validationRules={validationRules}
      onSubmit={handleSubmit}
      side={side}
    >
      {docType === AccountDocumentType.TimeLimitedPassport && side === 'back' ? (
        <div className="pt-8 border-t border-gray-200">
          <h2 className="text-2xl font-semibold">{content.expiryDateTitle}</h2>
          <p className="mt-1 mb-4">{content.expiryDateSubtitle}</p>
          <Field data-testid="visa-expiry" validationError={error}>
            <DateInput
              defaultValue={expiryDate}
              data-testid="expity-date-input"
              onChange={handleDateChange}
              onBlur={validateDateInput}
              error={!!error}
              id="startDate"
              dayLabel=""
              monthLabel=""
              yearLabel=""
            />
          </Field>
        </div>
      ) : null}
    </DocumentSignposting>
  );
};

export default IdentityDocSignposting;

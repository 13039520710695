interface Props {
  className?: string;
}

const ImageFileIcon = ({ className }: Props) => (
  <svg
    className={className}
    width="33"
    height="33"
    viewBox="0 0 33 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.3334 4.67928H5.00002C4.11597 4.67928 3.26812 5.03047 2.643 5.65559C2.01788 6.28071 1.66669 7.12856 1.66669 8.01261V24.6793M1.66669 24.6793V28.0126C1.66669 28.8967 2.01788 29.7445 2.643 30.3696C3.26812 30.9948 4.11597 31.3459 5.00002 31.3459H25C25.8841 31.3459 26.7319 30.9948 27.357 30.3696C27.9822 29.7445 28.3334 28.8967 28.3334 28.0126V21.3459M1.66669 24.6793L9.31002 17.0359C9.93511 16.411 10.7828 16.06 11.6667 16.06C12.5506 16.06 13.3983 16.411 14.0234 17.0359L18.3334 21.3459M28.3334 14.6793V21.3459M28.3334 21.3459L25.69 18.7026C25.0649 18.0777 24.2172 17.7267 23.3334 17.7267C22.4495 17.7267 21.6018 18.0777 20.9767 18.7026L18.3334 21.3459M18.3334 21.3459L21.6667 24.6793M25 4.67928H31.6667M28.3334 1.34595V8.01261M18.3334 11.3459H18.35"
      stroke="#9CA3AF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ImageFileIcon;

import React from 'react';
import Radio from './Radio';
import clsx from 'clsx';

export interface Option<T extends string> {
  label: string;
  value: T;
}

export type Options<T extends string = string> = Option<T>[];

interface Props<T extends string>
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLFieldSetElement>,
    HTMLFieldSetElement
  > {
  options: Options<T>;
  radioClassName?: string;
  large?: boolean;
}

const RadioGroup = <T extends string>({
  options,
  className,
  radioClassName,
  large,
  id = 'radio-group',
  ...props
}: Props<T>) => (
  <fieldset data-testid="radio-group" role="radiogroup" className={className} id={id} {...props}>
    {options.map(o => (
      <Radio
        {...o}
        key={o.value}
        className={clsx('mb-4', radioClassName)}
        name={id}
        defaultChecked={props.defaultValue === o.value}
        large={large}
      />
    ))}
  </fieldset>
);

export default RadioGroup;

import LanguagePicker from 'i18n/LanguagePicker';
import CenteredCard from 'components/templates/CenteredCard';
import Logo from 'assets/images/HomepplLogo-full.svg';

const BaseOtlPage: React.FC = ({ children }) => {
  return (
    <CenteredCard>
      <CenteredCard.Header className="flex h-[75px] border md:border-0 border-grey-1 items-center justify-between px-6 md:px-[34px] md:py-12">
        <img
          className="max-w-[99px] max-h-[21px] md:min-w-[152px] md:min-h-8 md:max-w-[152px] md:max-h-8 md:relative md:translate-x-[-50%] md:left-[50%]"
          src={Logo}
          alt="Homeppl"
        />
        <LanguagePicker />
      </CenteredCard.Header>
      {children}
      {/* Footer might be empty on this page but it is still important to filling space */}
      <CenteredCard.Footer className="flex justify-center md:flex-auto" />
    </CenteredCard>
  );
};

export default BaseOtlPage;

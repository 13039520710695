import { WizardSectionType } from 'generated/graphql';

export const QuestionRoutes = {
  [WizardSectionType.Identity]: '/questions/identity',
  [WizardSectionType.Income]: '/questions/income',
  [WizardSectionType.Residence]: '/questions/residence',
};

enum TopLevelRoutes {
  DASHBOARD = '/dashboard',
  LOGOUT = '/logout',
  OTL = '/otl',
  QUESTIONS = '/questions',
  OPEN_BANKING = '/open-banking',
  RENTAL_REFERENCE = '/rental-reference/:uuid',
  REFERENCE_THANK_YOU = '/reference-thank-you',
}

const allRoutes = { ...TopLevelRoutes, ...QuestionRoutes };
export type RoutesType = typeof allRoutes;

export default TopLevelRoutes;

import { useMemo } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import RadioQuestion from 'questionFlow/genericQuestions/RadioQuestion';
import { Options } from 'components/atoms/RadioGroup/RadioGroup';
import {
  DefaultResidenceQuestionComponentProps,
  StepNamesDefaultResidence,
} from 'questionFlow/flows/defaultResidence/types';
import { YesNoEnum } from 'questionFlow/types';

const cmsLocation = '/questions/residence/haveYouEver';

const useContent = (t: TFunction<'translation'>) => ({
  title: t(`${cmsLocation}.title`),
  subtitle: t(`${cmsLocation}.subtitle`),
  yes: t('utility.yes') as string,
  no: t('utility.no') as string,
});

const name = StepNamesDefaultResidence.haveYouEver;

const HaveYouEver = ({ data, values, ...props }: DefaultResidenceQuestionComponentProps) => {
  const { t } = useTranslation();
  const content = useContent(t);

  const options: Options = useMemo(
    () => [
      { value: YesNoEnum.YES, label: content.yes },
      { value: YesNoEnum.NO, label: content.no },
    ],
    [content],
  );

  return (
    <RadioQuestion
      content={content}
      initialValue={values[name]}
      name={name}
      options={options}
      {...props}
    />
  );
};

export default HaveYouEver;

import { useMemo } from 'react';
import {
  DefaultResidenceQuestionComponentProps,
  StepNamesDefaultResidence,
} from 'questionFlow/flows/defaultResidence/types';
import { useTranslation, TFunction } from 'react-i18next';
import { AccountDocumentType } from 'generated/graphql';
import DocumentSignposting from 'questionFlow/genericQuestions/DocumentSignposting';
import { getCurrentDocType } from 'questionFlow/flows/defaultResidence/DefaultResidence';
import { OnSubmitType } from 'questionFlow/types';

const cmsDocumentLocation = '/questions/residence/documents';
const cmsResidenceSignpostingLocation = '/questions/residence/documentSignposting';

const useContent = (t: TFunction<'translation'>, fileName: AccountDocumentType) => {
  const translationLocation: Partial<Record<AccountDocumentType, string>> = {
    [AccountDocumentType.UtilityBill]: t(`${cmsDocumentLocation}.UTILITY_BILL`),
    [AccountDocumentType.BankStatement]: t(`${cmsDocumentLocation}.BANK_STATEMENT`),
    [AccountDocumentType.DrivingLicence]: t(`${cmsDocumentLocation}.DRIVING_LICENCE`),
    [AccountDocumentType.TenancyAgreement]: t(`${cmsDocumentLocation}.TENANCY_AGREEMENT`),
  };

  const doesTranslationExist = (translateItem: string) => {
    return t(`${cmsResidenceSignpostingLocation}.${fileName}.${translateItem}` as any).includes(
      cmsResidenceSignpostingLocation,
    )
      ? {}
      : {
          [translateItem]: t(
            `${cmsResidenceSignpostingLocation}.${fileName}.${translateItem}` as any,
            {
              doc: translationLocation[fileName] || '{{doc}}',
            },
          ),
        };
  };

  return {
    ...doesTranslationExist('title'),
    ...doesTranslationExist('subtitle'),
    ...doesTranslationExist('doItems'),
    ...doesTranslationExist('dontItems'),
    ...translationLocation,
  };
};

const ResidenceDocSignposting = ({
  values,
  data,
  onSubmit,
  ...props
}: DefaultResidenceQuestionComponentProps & { type?: string }) => {
  const { t } = useTranslation();
  const docType = getCurrentDocType(values, props.type);
  const translatedContent = useContent(t, docType as AccountDocumentType);

  const content = useMemo(() => {
    return {
      ...translatedContent,
      fileName: translatedContent[docType as keyof typeof translatedContent] as string,
      formattedFileName: translatedContent[docType as keyof typeof translatedContent] as string,
    };
  }, [translatedContent, docType]);

  let selectAddress = values?.[StepNamesDefaultResidence.selectAddress];

  if (!selectAddress && data.remedialSection) {
    selectAddress = values[StepNamesDefaultResidence.residentialHistory]?.find(
      ({ uuid }) => uuid === data.remedialSection.relatedObjectUuid,
    );
  }

  const handleSubmit: OnSubmitType = () => {
    const oldDocType = selectAddress?.documents?.[0]?.type;

    onSubmit({
      key: StepNamesDefaultResidence.selectAddress,
      value: {
        ...selectAddress,
        documents:
          oldDocType === docType ? selectAddress?.documents : [{ type: docType, path: null }],
      },
    });
  };

  return (
    <DocumentSignposting
      {...props}
      values={{
        documents: docType,
        upload: values['select-address']?.documents,
      }}
      data={data}
      onSubmit={handleSubmit}
      content={content}
      translationValues={{ address: values['select-address']?.address?.formattedAddress || '' }}
    />
  );
};

export default ResidenceDocSignposting;

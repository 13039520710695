import React, { useMemo, useState } from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import HomepplLogo from 'assets/images/HomepplLogo.svg';
import LanguagePicker from '../../../i18n/LanguagePicker';
import useInitialData from '../../../api/data/initial-data/useInitialData';
import { Button, DropDown } from 'components';
import { ButtonContentsProps } from 'components/molecules/DropDown/DropDown';

export interface Props {
  className?: string;
  options: { label: string; value: string }[];
  content: { [key: string]: string };
}

const DropdownButtonComponent = (userInitials: string) => ({
  IconComponent,
  ...props
}: ButtonContentsProps) => (
  <Button
    className=" self-center rounded-full px-3 py-1.5 font-semibold text-sm leading-5"
    {...props}
  >
    {userInitials}
  </Button>
);

const NavigationHeader = ({ className, options, content, ...props }: Props) => {
  const initialData = useInitialData();
  const [selectValue, setSelectValue] = useState('');
  const history = useHistory();

  const userInitials = useMemo(() => {
    return initialData.me.name.firstName?.charAt(0).toUpperCase() || '';
  }, [initialData]);

  //for keyboard usage it needs the value storing
  const handleSelectValue = (value: string) => {
    setSelectValue(value);
  };

  const handleSelectClose = (newValue?: string) => {
    let value = newValue || selectValue;
    if (value) {
      history.push(value);
    }
  };

  return (
    <div
      {...props}
      data-testid="navigation-header"
      className={clsx('hidden md:flex flex-row justify-between', className)}
    >
      <div className="flex flex-row items-center text-xs">
        {content.poweredBy} <img className="ml-2.5" src={HomepplLogo} alt="Homeppl" />
      </div>
      <div className="flex flex-row items-center">
        <LanguagePicker />

        <DropDown
          data-testid="user-menu"
          label={content.menu as string}
          className="w-auto h-auto ml-12"
          value={selectValue}
          options={options}
          onSelectValue={handleSelectValue}
          onClose={handleSelectClose}
          ButtonContentsComponent={DropdownButtonComponent(userInitials)}
          below
        />
      </div>
    </div>
  );
};

export default NavigationHeader;

import SignInPage from './SignInPage';
import useLoginRequest from './useLoginRequest';
import useDocumentTitle from '@tanem/use-document-title';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { useCallback, useMemo } from 'react';
import SentMailPage from './SentMailPage';
import useAuthentication from 'authentication/useAuthentication';
import TopLevelRoutes from '../../routing/routes';

const OtlRoute = () => {
  const location = useLocation<{ email?: string }>();
  const history = useHistory();
  const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const onLoginSuccess = useCallback(
    (email: string) => {
      history.push(TopLevelRoutes.OTL, {
        email,
      });
    },
    [history],
  );

  const [requestState, doLogin] = useLoginRequest(onLoginSuccess);
  const { t } = useTranslation();

  useDocumentTitle(
    t(location.state && location.state.email ? 'sentmail.page_title' : 'signup.page_title'),
  );

  const authenticationState = useAuthentication();

  if (authenticationState === 'logged-in') {
    return <Redirect to={TopLevelRoutes.DASHBOARD} />;
  }

  return location.state && location.state.email ? (
    <SentMailPage email={location.state.email} />
  ) : (
    <SignInPage
      disabled={requestState === 'working'}
      error={requestState === 'error'}
      isSessionExpired={queryParams.has('expired')}
      onLogin={doLogin}
    />
  );
};

export default OtlRoute;

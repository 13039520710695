import { isEqual } from 'lodash';
import { useContext, useState, useEffect } from 'react';
import { AnalyticsContext } from './analytics';

export const useAnalyticsEvent = () => {
  const [inputEventsMap, setInputEventsMap] = useState<Record<string, boolean>>({});
  const { analytics } = useContext(AnalyticsContext);
  const broadcastEvent = async (name: string) => {
    if (analytics && !inputEventsMap[name]) {
      analytics.track(name);
      setInputEventsMap({ ...inputEventsMap, [name]: true });
    }
  };
  const broadcastUniqueEvent = async (name: string) => {
    if (analytics) {
      analytics.track(name);
    }
  };
  return { broadcastEvent, broadcastUniqueEvent };
};

type ErrorsByFieldMap = { [x: string]: string[] };
export const useAnalyticsErrors = <T extends Record<string, string>>(initial: T) => {
  const [errors, setErrors] = useState<T>(initial);
  const [reportedErrorsMap, setReportedErrorsMap] = useState<ErrorsByFieldMap>({});
  const { broadcastEvent } = useAnalyticsEvent();

  useEffect(() => {
    const errorKeys = Object.keys(errors);
    if (errorKeys.length) {
      errorKeys.forEach(async k => {
        const errorAlreadyExists =
          !errors[k] || (k in reportedErrorsMap && reportedErrorsMap[k].includes(errors[k]));
        if (errorAlreadyExists) return;

        await broadcastEvent(`Validation error in ${k}, error: ${errors[k]}`);
        setReportedErrorsMap(previous => ({
          ...previous,
          [k]: [...(previous[k] || []), errors[k]],
        }));
      });
    }
  }, [errors, broadcastEvent, reportedErrorsMap]);

  return [errors, setErrors] as const;
};

export const useAnalyticsError = (fieldName: string, initial: string) => {
  const [error, setError] = useState<string>(initial);
  const [reportedErrors, setReportedErrors] = useState<string[]>([]);
  const { broadcastEvent } = useAnalyticsEvent();

  useEffect(() => {
    if (error) {
      const errorAlreadyExists = reportedErrors.includes(error);
      if (errorAlreadyExists) return;

      broadcastEvent(`Validation error in ${fieldName}, error: ${error}`);
      setReportedErrors(v => [...v, error]);
    }
  }, [error, broadcastEvent, reportedErrors, setReportedErrors, fieldName]);

  return [error, setError] as const;
};

type ValueChangedByFieldMap = { [x: string]: boolean };
export const useAnalyticsValues = <T extends Record<string, any>>(initial: T) => {
  const [values, setValues] = useState<T>(initial);
  const [reportedValuesMap, setReportedErrorsMap] = useState<ValueChangedByFieldMap>({});
  const { broadcastEvent } = useAnalyticsEvent();

  useEffect(() => {
    const keys = Object.keys(values);
    if (keys.length) {
      keys.forEach(async k => {
        const valueAlreadyReported = k in reportedValuesMap && reportedValuesMap[k];
        const valueNotChanged = !values[k] || (values[k] && isEqual(values[k], initial[k]));
        if (valueAlreadyReported || valueNotChanged) return;
        await broadcastEvent(`Changed field ${k}`);
        setReportedErrorsMap(previous => ({
          ...previous,
          [k]: true,
        }));
      });
    }
  }, [values, broadcastEvent, reportedValuesMap, initial]);

  return [values, setValues] as const;
};

export const useAnalyticsValue = <T = any>(fieldName: string, initial: T) => {
  const [values, setValues] = useAnalyticsValues({
    [fieldName]: initial,
  });
  return [values[fieldName], (val: T) => setValues({ [fieldName]: val })] as const;
};

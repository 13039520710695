import { useContext, createContext } from 'react';
import { GetInitialDataQuery } from 'generated/graphql';
// Inside this file we know the context may be undefined:
//  - while loading
//  - while not logged in
//  - after error loading
// Internally we will handle the exceptions and make available in `useInitialDataContext` a real instance only
export const InitialDataContext = createContext<GetInitialDataQuery | undefined>(undefined);

// No component that depends on this should be rendered by this file if the data is not good to use
// Therefore hard stop if used unexpectedly.
const useInitialData = () => {
  const initialDataMaybe = useContext(InitialDataContext);
  // if(process.env.NODE_ENV==="test"){
  //   return mockMeStartData();
  // }

  if (initialDataMaybe === undefined) {
    // If you are seeing this error:
    // - You are calling this hook in a component that is not contained within a protected route
    // - You are calling this function from within this file and bypassing the existing logic
    throw new Error('Invalid State - This screen cannot be rendered without initial data');
  }

  return initialDataMaybe;
};

export default useInitialData;

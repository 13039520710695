import { useTranslatedValues } from 'hooks/useTranslatedValues';
import React, { useMemo, useState } from 'react';
import { Trans } from 'react-i18next';
import ReferenceQuestion from './ReferenceQuestion';

const translations = {
  checkDetails: '/rentalReference.checkDetails',
  checkDetailsNote: '/rentalReference.checkDetailsNote',
  questionOne: '/rentalReference.questionOne',
  questionOneNotes: '/rentalReference.questionOneNotes',
  questionTwo: '/rentalReference.questionTwo',
  questionTwoNotes: '/rentalReference.questionTwoNotes',
  questionThree: '/rentalReference.questionThree',
  questionThreeNotes: '/rentalReference.questionThreeNotes',
  questionFour: '/rentalReference.questionFour',
  questionFourNotes: '/rentalReference.questionFourNotes',
  agreement: '/rentalReference.agreement',
  note1: '/rentalReference.note1',
  note2: '/rentalReference.note2',
  yes: '/rentalReference.yes',
  no: '/rentalReference.no',
  required: 'validation.required',
} as const;

type Props = {
  onSubmit: (value: any) => void;
  tenantName: string;
};

const ReferenceForm: React.FC<Props> = ({ tenantName, onSubmit }) => {
  const content = useTranslatedValues(translations);

  const questionsList = useMemo(
    () => [
      {
        question: content.checkDetails,
        note: content.checkDetailsNote,
        defaultValue: content.yes,
      },
      {
        question: content.questionOne,
        note: content.questionOneNotes,
        defaultValue: content.no,
      },
      {
        question: content.questionTwo,
        note: content.questionTwoNotes,
        defaultValue: content.no,
      },
      {
        question: content.questionThree,
        note: content.questionThreeNotes,
        defaultValue: content.no,
      },
      {
        question: content.questionFour,
        note: content.questionFourNotes,
        defaultValue: content.no,
      },
    ],
    [content],
  );

  const [answers, setAnswers] = useState<Record<number, string>>({});
  const [errors, setErrors] = useState<Record<number, boolean>>({});
  // const [checked, setChecked] = useState(false);

  // const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   setChecked(e.currentTarget.checked);
  // };

  const saveNewAnswer = (index: number, newAnswer: string) => {
    setAnswers({ ...answers, [index]: newAnswer });
  };

  const setError = (index: number, value: boolean) => {
    setErrors({ ...errors, [index]: value });
  };

  const validAnswers = () => {
    let isValid = true;
    let crtErrors = { ...errors };

    questionsList.forEach((_, index) => {
      if (errors[index]) {
        isValid = false;
      }
      if (!answers[index]) {
        crtErrors = { ...crtErrors, [index]: true };
        isValid = false;
      }
    });
    setErrors(crtErrors);

    return isValid;
  };

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!validAnswers()) {
      return;
    }

    const review = questionsList.reduce((all, question, index) => {
      const answer = answers[index];
      if (answer === question.defaultValue) {
        return all;
      }
      return `${all}${question.question} ${answer}${
        index === questionsList.length - 1 ? '' : ', '
      }`;
    }, '');
    onSubmit({
      tenancy_details_dates_confirmed: answers[0] === questionsList[0].defaultValue,
      missed_or_incomplete_payments: answers[1] !== questionsList[1].defaultValue,
      damage_to_the_property: answers[2] !== questionsList[2].defaultValue,
      any_complaints: answers[3] !== questionsList[3].defaultValue,
      any_reason_not_to_let_again: answers[4] !== questionsList[4].defaultValue,
      review,
    });
  };

  return (
    <form
      id="refrence-form"
      data-testid="refrence-form"
      onSubmit={handleFormSubmit}
      className="space-y-10 my-10"
    >
      {questionsList.map((question, index) => (
        <React.Fragment key={index}>
          <ReferenceQuestion
            index={index}
            validationError={content.required}
            options={[content.yes, content.no] as [string, string]}
            hasError={errors[index]}
            question={question.question}
            note={question.note}
            defaultValue={question.defaultValue}
            saveAnswer={value => saveNewAnswer(index, value)}
            setError={value => setError(index, value)}
          />
          {index === 0 && (
            <>
              <hr />

              <p className="my-6 font-semibold text-lg text-gray-700">
                <Trans i18nKey="/rentalReference.confirm" values={{ name: tenantName }} />
              </p>
            </>
          )}
        </React.Fragment>
      ))}

      {/* <Field data-testid="reference-checkbox" validationError={checkboxError} className="ml-9">
        <label className="text-base flex items-center">
          <Checkbox
            className="-ml-9"
            checked={checked}
            onChange={handleCheckboxChange}
            color="blue"
          />

          <span className="ml-4">{content.agreement}</span>
        </label>
      </Field> */}

      <div className="text-sm space-y-6 text-gray-800 md:ml-4">
        <p>{content.note1}</p>
        <p>
          <Trans
            i18nKey="/rentalReference.note2"
            components={{
              r: <span className="text-red-600" />,
            }}
          />
        </p>
      </div>
    </form>
  );
};

export default ReferenceForm;

import { useMemo } from 'react';
import {
  DefaultIncomeQuestionComponentProps,
  StepNamesDefaultIncome,
} from 'questionFlow/flows/defaultIncome/types';
import { useTranslation, Trans, TFunction } from 'react-i18next';
import { Options } from 'components/atoms/RadioGroup/RadioGroup';
import RadioQuestion from 'questionFlow/genericQuestions/RadioQuestion';
import selectDocumentOptions, { useDocumentOptionsContent } from './utilities/documentOptions';

const cmsLocation = '/questions/income/documents';

const useContent = (t: TFunction<'translation'>) => ({
  title: t(`${cmsLocation}.title`),
  none: t(`${cmsLocation}.none`),
  ...useDocumentOptionsContent(t),
});

const name = StepNamesDefaultIncome.documents;

const Documents = ({ data, values, ...props }: DefaultIncomeQuestionComponentProps) => {
  const { t } = useTranslation();
  const content = useContent(t);

  const options: Options = useMemo(
    () => [
      ...selectDocumentOptions(values).map(doc => ({
        value: doc,
        label: content[doc] as string,
      })),
      { value: 'none', label: content.none },
    ],
    [values, content],
  );

  return (
    <RadioQuestion
      content={{
        title: content.title,
        subtitle: (
          <Trans
            i18nKey={`${cmsLocation}.subtitle`}
            components={{
              bold: <b />,
            }}
          />
        ),
      }}
      initialValue={values[name]}
      name={name}
      options={options}
      {...props}
    />
  );
};

export default Documents;

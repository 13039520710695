import { STATUS_OPTIONS } from '../flows/rightToRentIdentity/data/right-to-rent-docs-list';
import { useTranslation, TFunction } from 'react-i18next';
import { Options } from 'components/atoms/RadioGroup/RadioGroup';
import RadioQuestion from 'questionFlow/genericQuestions/RadioQuestion';
import {
  RTRIdentityQuestionComponentProps,
  StepNamesRTRIdentity,
} from 'questionFlow/flows/rightToRentIdentity/types';
import { useMemo } from 'react';

const cmsLocation = '/questions/identity/status';

const useContent = (t: TFunction<'translation'>) => ({
  title: t(`${cmsLocation}.title`),
  prompt: t(`${cmsLocation}.prompt`),
});

const name = StepNamesRTRIdentity.status;

const StatusQuestion = ({ values, ...props }: RTRIdentityQuestionComponentProps) => {
  const { t } = useTranslation();
  const content = useContent(t);

  const options: Options = useMemo(() => {
    return [
      { value: STATUS_OPTIONS.NO_APPLICATIONS, label: t(`${cmsLocation}.noApplication`) as string },
      {
        value: STATUS_OPTIONS.ONGOING_VISA_APPLICATION,
        label: t(`${cmsLocation}.ongoingVisaApplication`) as string,
      },
    ];
  }, [t]);

  return (
    <RadioQuestion
      content={{
        prompt: content.prompt,
        title: content.title,
      }}
      initialValue={values[name] || ''}
      name={name}
      options={options}
      large
      {...props}
    />
  );
};

export default StatusQuestion;

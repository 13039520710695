import { DefaultIdentityValuesType, StepNamesDefaultIdentity } from './types';
import { Flow, GeneralSteps } from '../../types';
import validationRules from '../../validations/validationRules';
import { Name, Nationality, Documents, Upload, Birthdate } from '../../identity';
import { gql } from '@apollo/client';
import {
  AccountDocumentType,
  GqlDocTypeEnum,
  IdentitySectionMetaDataType,
  Maybe,
  MutationMeArgs,
  SectionMetaDataType,
} from 'generated/graphql';
import NoDocuments from 'questionFlow/genericQuestions/NoDocuments';
import { includes } from 'lodash';
import {
  OPTIONAL_NAME_LENGTH,
  OPTIONAL_NAME_PATTERN,
  UNDER_18_INVALID,
  FIELD_REQUIRED,
  NAME_LENGTH,
  NAME_PATTERN,
} from '../../validations';
import MissingInformation from 'questionFlow/genericQuestions/MissingInformation';
import { hasDocumentSignposting } from 'questionFlow/genericQuestions/Upload/utils';
import { docHasMultipleSides } from '../rightToRentIdentity/utils';
import IdentityDocSignposting from 'questionFlow/identity/IdentityDocSignposting';
import GenericIdentityDocSignposting from 'questionFlow/identity/GenericIdentityDocSignposting';
import Camera from 'questionFlow/identity/Camera';
import Permissions from 'questionFlow/identity/Permissions';
import PhotoOptOut from 'questionFlow/identity/PhotoOptOut';

const gqlQueryFragment = gql`
  fragment defaultIdentity on Query {
    me {
      uuid
      name {
        firstName
        lastName
        middleName
      }
      profile {
        nationality
        birthdate
      }
      identityDocuments(remedialSectionUuid: $remedialSectionUuid) {
        id
        type
        path
      }
    }
    sectionMetaData {
      identitySectionMetaData {
        documentUploadDefinitions {
          docType
          docName
          acceptedFileTypes
          sides
          sideNames
        }
        countriesDocsMap {
          countryCode
          allowedIdentityDocs
        }
        enableIdvt
      }
    }
  }
`;

const mutation = gql`
  mutation MutateMe(
    $firstName: String
    $middleName: String
    $lastName: String
    $birthdate: Date
    $nationality: String
    $identityDocuments: [DocumentInputType]
    $remedialSectionUuid: UUID
  ) {
    me(
      firstName: $firstName
      middleName: $middleName
      lastName: $lastName
      birthdate: $birthdate
      nationality: $nationality
      identityDocuments: $identityDocuments
      remedialSectionUuid: $remedialSectionUuid
    ) {
      name {
        firstName
      }
    }
  }
`;

const matchUploadToDocType = (uploads?: Maybe<AccountDocumentType[]>) => {
  const type =
    uploads && uploads.length > 0 ? uploads.filter(doc => doc.type !== 'LIVE_PHOTO')[0].type : null;
  if (type && includes(GqlDocTypeEnum, type)) {
    return type;
  }
};

const defaultIdentityFlow: Flow<
  IdentitySectionMetaDataType,
  DefaultIdentityValuesType,
  StepNamesDefaultIdentity,
  void,
  MutationMeArgs
> = {
  start: () => StepNamesDefaultIdentity.name,
  getQueryParams: () => {},
  queryFragment: gqlQueryFragment,
  mutation,
  handleMutationResponse: () => ({}),
  getData: ({ identitySectionMetaData }: SectionMetaDataType) => identitySectionMetaData,
  getDefaultValues: values => {
    return {
      name: {
        first_name: values.name.firstName || '',
        middle_name: values.name.middleName || '',
        last_name: values.name.lastName || '',
      },
      birthdate: values.profile.birthdate || '',
      nationality: values.profile.nationality || '',
      documents: matchUploadToDocType(values.identityDocuments),
      upload: values.identityDocuments || [],
      uuid: null,
    };
  },
  steps: {
    [StepNamesDefaultIdentity.name]: {
      component: Name,
      validationRules: {
        first_name: [FIELD_REQUIRED, NAME_LENGTH, NAME_PATTERN],
        middle_name: [OPTIONAL_NAME_LENGTH, OPTIONAL_NAME_PATTERN],
        last_name: [FIELD_REQUIRED, NAME_LENGTH, NAME_PATTERN],
      },
      getMutationValues: ({ name }: DefaultIdentityValuesType) => ({
        firstName: name?.first_name || '',
        middleName: name?.middle_name || null,
        lastName: name?.last_name || '',
      }),
      chooseSuccessor: () => StepNamesDefaultIdentity.birthdate,
    },
    [StepNamesDefaultIdentity.birthdate]: {
      component: Birthdate,
      getMutationValues: ({ birthdate }: Partial<DefaultIdentityValuesType>) => ({
        birthdate,
      }),
      validationRules: {
        birthdate: [
          {
            name: 'required',
            rule: v => validationRules.required(v.replace(/-/g, '')),
            error: 'validation.required',
          },
          {
            name: 'pattern',
            rule: v => /^[1-9]{1}[0-9]{3}-[0-9]{2}-[0-9]{2}$/.test(v),
            error: 'validation.birthdate.invalid',
          },
          UNDER_18_INVALID,
          {
            name: 'valid',
            rule: (_, valid) => !!valid,
            error: 'validation.birthdate.invalid',
          },
        ],
        day: [],
        month: [],
        year: [],
      },
      chooseSuccessor: () => StepNamesDefaultIdentity.nationality,
    },
    [StepNamesDefaultIdentity.nationality]: {
      component: Nationality,
      getMutationValues: ({ nationality }: Partial<DefaultIdentityValuesType>) => ({
        nationality,
      }),
      validationRules: {
        nationality: [
          FIELD_REQUIRED,
          {
            name: 'oneOf',
            rule: (value, options) =>
              !!options && typeof options === 'object' && options.includes(value as string),
            error: 'The value selected must be a valid option',
          },
        ],
      },
      chooseSuccessor: () => StepNamesDefaultIdentity.documents,
    },
    [StepNamesDefaultIdentity.documents]: {
      component: Documents,
      validationRules: {
        documents: [FIELD_REQUIRED],
      },
      chooseSuccessor: ({ value, values }) => {
        if (value === 'none') {
          return StepNamesDefaultIdentity.none;
        }
        if (typeof value === 'string' && hasDocumentSignposting(value)) {
          return StepNamesDefaultIdentity.documentSignposting;
        }
        return StepNamesDefaultIdentity.upload;
      },
    },
    [GeneralSteps.genericUpload]: {
      component: GenericIdentityDocSignposting,
      validationRules: {
        agreement: [FIELD_REQUIRED],
      },
      chooseSuccessor: () => StepNamesDefaultIdentity.upload,
    },
    [StepNamesDefaultIdentity.documentSignposting]: {
      component: props => {
        const hasSides = docHasMultipleSides(props.values.documents);
        return <IdentityDocSignposting side={hasSides ? 'front' : ''} {...props} />;
      },
      validationRules: {
        agreement: [FIELD_REQUIRED],
      },
      chooseSuccessor: () => StepNamesDefaultIdentity.upload,
    },
    [StepNamesDefaultIdentity.documentSignpostingBack]: {
      component: props => <IdentityDocSignposting {...props} side="back" />,
      validationRules: {
        agreement: [FIELD_REQUIRED],
      },
      chooseSuccessor: () => StepNamesDefaultIdentity.uploadBack,
    },
    [StepNamesDefaultIdentity.none]: {
      component: NoDocuments,
      validationRules: {},
    },
    [StepNamesDefaultIdentity.upload]: {
      component: props => {
        const hasSides = docHasMultipleSides(props.values.documents);
        return <Upload side={hasSides ? 'front' : ''} {...props} />;
      },
      validationRules: {
        upload: [FIELD_REQUIRED],
      },
      getMutationValues: ({ upload }: Partial<DefaultIdentityValuesType>) => ({
        identityDocuments: upload?.map(({ __typename, ...doc }) => doc) || [],
      }),
      chooseSuccessor: ({ values, data }) => {
        if (
          ((data as any) || {}).enableIdvt &&
          values.documents === 'PASSPORT' &&
          values.nationality === 'GB'
        ) {
          return StepNamesDefaultIdentity.livePhotoSignposting;
        }

        if (docHasMultipleSides(values.documents)) {
          return StepNamesDefaultIdentity.documentSignpostingBack;
        }
      },
    },
    [StepNamesDefaultIdentity.uploadBack]: {
      component: props => <Upload {...props} name={StepNamesDefaultIdentity.upload} side="back" />,
      validationRules: {
        upload: [FIELD_REQUIRED],
      },
      getMutationValues: ({ upload }: Partial<DefaultIdentityValuesType>) => {
        return {
          identityDocuments: upload?.map(({ __typename, ...doc }) => doc) || [],
        };
      },
    },
    [GeneralSteps.missingInformation]: {
      component: MissingInformation,
      validationRules: {},
      chooseSuccessor: ({ data }) => data?.remedialSection?.start as StepNamesDefaultIdentity,
    },
    [StepNamesDefaultIdentity.livePhotoSignposting]: {
      component: props => (
        <IdentityDocSignposting
          {...props}
          values={{ ...props.values, livePhotoSignposting: 'LIVE_PHOTO' }}
          documentKey={StepNamesDefaultIdentity.livePhotoSignposting}
        />
      ),
      validationRules: { agreement: [FIELD_REQUIRED] },
      chooseSuccessor: () => StepNamesDefaultIdentity.livePhotoPermissions,
    },
    [StepNamesDefaultIdentity.livePhotoPermissions]: {
      component: props => <Permissions {...props} />,
      validationRules: { permissionGranted: [FIELD_REQUIRED] },
      chooseSuccessor: ({ value }) => {
        if (value === 'skip') return StepNamesDefaultIdentity.livePhotoOptOut;
        return StepNamesDefaultIdentity.livePhotoCamera;
      },
    },
    [StepNamesDefaultIdentity.livePhotoOptOut]: {
      component: props => <PhotoOptOut {...props} />,
      validationRules: { permissionGranted: [FIELD_REQUIRED] },
      chooseSuccessor: ({ value }) => {
        if (value === 'takePhoto') return StepNamesDefaultIdentity.livePhotoCamera;
        return;
      },
    },
    [StepNamesDefaultIdentity.livePhotoCamera]: {
      component: props => <Camera {...props} />,
      validationRules: {
        [StepNamesDefaultIdentity.upload]: [FIELD_REQUIRED],
      },
      getMutationValues: ({ upload }: Partial<DefaultIdentityValuesType>) => {
        return {
          identityDocuments: upload?.map(({ __typename, ...doc }) => doc) || [],
        };
      },
      chooseSuccessor: ({ value }) => {
        if (value === 'skip') return StepNamesDefaultIdentity.livePhotoOptOut;
        return undefined;
      },
    },
  },
};

export default defaultIdentityFlow;

import {
  AccountDocumentGqlType,
  AccountDocumentType,
  IdentitySectionMetaDataType,
  Maybe,
} from 'generated/graphql';
import { QuestionComponentProps } from 'questionFlow/types';

export enum StepNamesDefaultIdentity {
  name = 'name',
  birthdate = 'birthdate',
  nationality = 'nationality',
  documents = 'documents',
  none = 'none',
  upload = 'upload',
  uploadBack = 'uploadBack',
  documentSignposting = 'documentSignposting',
  documentSignpostingBack = 'documentSignpostingBack',
  livePhotoSignposting = 'livePhotoSignposting',
  livePhotoPermissions = 'livePhotoPermissions',
  livePhotoCamera = 'livePhotoCamera',
  livePhotoOptOut = 'livePhotoOptOut',
}

export interface DefaultIdentityValuesType {
  uuid?: Maybe<string>;
  [StepNamesDefaultIdentity.name]?: { first_name: string; middle_name: string; last_name: string };
  [StepNamesDefaultIdentity.birthdate]?: string;
  [StepNamesDefaultIdentity.nationality]?: string;
  [StepNamesDefaultIdentity.documents]?: AccountDocumentType;
  [StepNamesDefaultIdentity.upload]?: AccountDocumentGqlType[];
}

export interface IdentityQuestionComponentProps
  extends QuestionComponentProps<IdentitySectionMetaDataType, DefaultIdentityValuesType> {}

import React, { useMemo, createRef, useState } from 'react';
import clsx from 'clsx';
import { Input } from 'components';
import { useTranslation } from 'react-i18next';
import isExists from 'date-fns/isExists';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';

export type OnDateChange = (newDateValues: { name: string; valid: boolean; value: string }) => void;

export interface Props
  extends Omit<
    React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
    'onChange'
  > {
  className?: string;
  children?: React.ReactNode;
  defaultValue: string;
  error?: boolean;
  onChange: OnDateChange;
}

const cmsLocation = 'date_of_birth_input';
const useContent = () => {
  const { t } = useTranslation();
  return {
    day: {
      placeholder: t(`${cmsLocation}.day.placeholder`),
      'aria-label': t(`${cmsLocation}.day.aria-label`),
    },
    month: {
      placeholder: t(`${cmsLocation}.month.placeholder`),
      'aria-label': t(`${cmsLocation}.month.aria-label`),
    },
    year: {
      placeholder: t(`${cmsLocation}.year.placeholder`),
      'aria-label': t(`${cmsLocation}.year.aria-label`),
    },
  };
};

const maxAge = 120;

const isValidDateOfBirth = ({ year, month, day }: { year: string; month: string; day: string }) => {
  const dayNumber = parseInt(day, 10);
  const monthNumber = parseInt(month, 10) - 1;
  const yearNumber = parseInt(year, 10);
  const currentDate = new Date();
  const oldestDate = new Date();
  const currentYear = currentDate.getFullYear();
  oldestDate.setFullYear(currentYear - maxAge);

  return (
    isExists(yearNumber, monthNumber, dayNumber) &&
    isBefore(new Date(yearNumber, monthNumber, dayNumber), currentDate) &&
    isAfter(new Date(yearNumber, monthNumber, dayNumber), oldestDate)
  );
};

const getInitialState = (birthdateString: string) => {
  if (birthdateString) {
    let birthdate = new Date(birthdateString);
    if (!isNaN((birthdate as unknown) as number)) {
      return {
        day: `${birthdate.getDate()}`.padStart(2, '0'),
        month: `${birthdate.getMonth() + 1}`.padStart(2, '0'),
        year: `${birthdate.getFullYear()}`,
      };
    }
  }
  return { year: '', month: '', day: '' };
};

const shouldFocusOntoNext = (value: string, max: number) => {
  const parsedValue = parseInt(value, 10);

  return value.length >= max.toString().length || parsedValue >= max || parsedValue * 10 > max;
};

const isPermittedNumber = (value: string, max: number) =>
  !value || (/[0-9]*$/.test(value) && parseInt(value, 10) <= max);

const DateOfBirthInput = ({
  className,
  children,
  onChange,
  id = 'birthdate',
  defaultValue,
  error,
  onBlur,
  ...props
}: Props) => {
  const [{ year, month, day }, setDate] = useState(getInitialState(defaultValue));

  const content = useContent();

  const currentYear = useMemo(() => {
    return new Date().getFullYear();
  }, []);

  const dayRef = createRef() as React.RefObject<HTMLInputElement>;
  const monthRef = createRef() as React.RefObject<HTMLInputElement>;
  const yearRef = createRef() as React.RefObject<HTMLInputElement>;

  const handleChange = (key: string, max: number, nextRef?: React.RefObject<HTMLInputElement>) => (
    e: React.ChangeEvent,
  ) => {
    const target = e.target as HTMLInputElement;

    if (!isPermittedNumber(target.value, max)) {
      return;
    }

    const shouldFocusNext = shouldFocusOntoNext(target.value, max);
    const nextValue = {
      year,
      month,
      day,
      [key]: shouldFocusNext ? target.value.padStart(2, '0') : target.value,
    };

    if (nextRef?.current && shouldFocusNext) {
      nextRef.current.focus();
    }

    const isValid = isValidDateOfBirth(nextValue);
    const dateOfBirth = `${nextValue.year}-${nextValue.month}-${nextValue.day}`;

    setDate(nextValue);
    onChange({
      name: id,
      value: dateOfBirth,
      valid: isValid,
    });
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (
      e.relatedTarget !== dayRef.current &&
      e.relatedTarget !== monthRef.current &&
      e.relatedTarget !== yearRef.current
    ) {
      onBlur && onBlur(e);
    }
  };

  const handleInputBlur = (
    e: React.FocusEvent<HTMLInputElement>,
    key: string,
    prependBelow: Number,
  ) => {
    const parsed = parseInt(e.target.value);
    if (parsed < prependBelow) {
      e.target.value = `0${parsed}`;
      setDate({ day, month, year, [key]: e.target.value });
    }
  };

  return (
    <div
      {...props}
      data-testid="date-of-birth-input"
      className={clsx('flex flex-row space-x-6', className)}
      onBlur={handleBlur}
    >
      <Input
        autoComplete="bday-day"
        inputMode="numeric"
        name="day"
        ref={dayRef}
        onChange={handleChange('day', 31, monthRef)}
        onBlur={e => handleInputBlur(e, 'day', 4)}
        maxLength={2}
        className="w-16 text-center"
        value={day}
        aria-invalid={error}
        data-testid="date-of-birth-input-day"
        {...content.day}
      />
      <Input
        autoComplete="bday-month"
        inputMode="numeric"
        name="month"
        ref={monthRef}
        maxLength={2}
        onChange={handleChange('month', 12, yearRef)}
        onBlur={e => handleInputBlur(e, 'month', 2)}
        className="w-16 text-center"
        value={month}
        aria-invalid={error}
        data-testid="date-of-birth-input-month"
        {...content.month}
      />
      <Input
        autoComplete="bday-year"
        inputMode="numeric"
        name="year"
        ref={yearRef}
        value={year}
        maxLength={4}
        className="w-20 text-center"
        onChange={handleChange('year', currentYear)}
        data-testid="date-of-birth-input-year"
        aria-invalid={error}
        {...content.year}
      />
    </div>
  );
};

export default DateOfBirthInput;

import { useTranslation } from 'react-i18next';
import { ValidationRule } from '../../types';
import { DropDown, Field, Input } from 'components';
import DateInput, { OnDateChange } from 'components/molecules/DateInput/DateInput';
import QuestionOrganism from '../../QuestionOrganism';
import {
  YourAmountsQuestion,
  DateQuestion,
  DateValue,
  StringQuestion,
  YourAmountsQuestionValues,
} from './types';
import { useContent, useFrequencyOptions } from './content';
import {
  DefaultIncomeQuestionComponentProps,
  StepNamesDefaultIncome,
} from 'questionFlow/flows/defaultIncome/types';
import { useState } from 'react';
import useCurrencyCodeOptions from '../useCurrencyCodeOptions';
import { useAnalyticsErrors, useAnalyticsValues } from 'monitoring/analyticsHooks';
export interface AmountsQuestionProps extends DefaultIncomeQuestionComponentProps {
  annualLabel: string;
  annualPlaceholderText: string;
  paymentLabel: string;
  paymentDateLabel: string;
  paymentPlaceholderText: string;
  title: string;
  subtitle: string;
  name: StepNamesDefaultIncome;
}

interface InputEventTarget {
  name: keyof YourAmountsQuestionValues;
  value: string;
}

const validate = (v: string, rules: ValidationRule[], valid?: boolean) =>
  rules.find(({ rule }) => !rule(v, valid));

const initialState = {
  annualValue: '',
  currency: '',
  paymentAmount: '',
  frequency: '',
  paymentDate: '',
};
const YourAmount = ({
  data,
  values: initialValues,
  onSubmit,
  validationRules,
  title,
  subtitle,
  annualLabel,
  annualPlaceholderText,
  paymentLabel,
  paymentPlaceholderText,
  paymentDateLabel,
  name,
  ...props
}: AmountsQuestionProps) => {
  const { t } = useTranslation();
  const content = useContent(t);
  const [values, setValues] = useAnalyticsValues(
    initialValues ? { ...initialState, ...initialValues[name] } : initialState,
  );
  const [dateValid, setDateValid] = useState(true);
  const [error, setError] = useAnalyticsErrors<Record<YourAmountsQuestion, string>>(initialState);
  const currencyOptions = useCurrencyCodeOptions();
  const frequencyOptions = useFrequencyOptions(content);

  const getValidationResult = (name: YourAmountsQuestion, givenValue?: string | DateValue) => {
    const value = givenValue === undefined ? values[name] : givenValue;
    return validate(value, validationRules[name], dateValid);
  };

  const handleSubmit = () => {
    const errors = (Object.keys(values) as YourAmountsQuestion[]).reduce((e, inputName) => {
      const result = getValidationResult(inputName);

      return { ...e, [inputName]: result ? t(result.error as any) : '' };
    }, error);

    if (Object.values(errors).filter(e => e !== '').length === 0) {
      onSubmit({ key: name, value: values });
    } else {
      setError(errors);
    }
  };

  const validateAndSetError = (name: YourAmountsQuestion, newValue: string) => {
    const result = getValidationResult(name, newValue);
    setError({ ...error, [name]: result ? t(result.error as any) : '' });
  };

  const onStringValueChange = (name: YourAmountsQuestion, value: string) => {
    if (error[name]) {
      validateAndSetError(name, value);
    }

    setValues({ ...values, [name]: value });
  };

  const handleInputOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target as InputEventTarget;
    onStringValueChange(name, value);
  };

  const handleInputOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = e.target as InputEventTarget;

    validateAndSetError(name, value);
  };

  const onDropdownChange = (name: StringQuestion, optionValue: string) =>
    onStringValueChange(name, optionValue);

  const handleDateChange: OnDateChange = ({ name: fieldName, value, valid }) => {
    setValues({
      ...values,
      [fieldName]: value,
    });
    setDateValid(valid);

    if (valid && error[fieldName as DateQuestion]) {
      setError({ ...error, [fieldName]: '' });
    }
  };

  const handleDateOnBlur = (fieldName: DateQuestion) => {
    const result = getValidationResult(fieldName);
    setError({ ...error, [fieldName]: result ? t(result.error as any) : '' });
  };

  return (
    <QuestionOrganism
      id={name}
      data-testid={name}
      onSubmit={handleSubmit}
      title={title}
      subtitle={subtitle}
      {...props}
    >
      <div className="grid w-max md:grid-cols-2 gap-x-10 gap-y-4 pb-60">
        <Field
          className="md:order-1"
          data-testid={`${name}-currency`}
          label={content.currency.label}
          validationError={error.currency}
        >
          <DropDown
            size="sm"
            placeholder={content.currency.placeholder}
            options={currencyOptions}
            onSelectValue={value => onDropdownChange('currency', value)}
            value={values.currency}
            error={!!error.currency}
            label={content.currency.label}
          />
        </Field>

        <Field
          data-testid={`${name}-annualValue`}
          label={annualLabel}
          validationError={error.annualValue}
        >
          <Input
            id={`${name}-annualValue`}
            name={'annualValue'}
            defaultValue={values.annualValue}
            onChange={handleInputOnChange}
            aria-invalid={!!error.annualValue}
            onBlur={handleInputOnBlur}
            placeholder={annualPlaceholderText}
            type="number"
          />
        </Field>

        <Field
          className="order-2"
          data-testid={`${name}-paymentAmount`}
          label={paymentLabel}
          validationError={error.paymentAmount}
        >
          <Input
            id={`${name}-paymentAmount`}
            name={'paymentAmount'}
            defaultValue={values.paymentAmount}
            onChange={handleInputOnChange}
            aria-invalid={!!error.paymentAmount}
            onBlur={handleInputOnBlur}
            placeholder={paymentPlaceholderText}
            type="number"
          />
        </Field>

        <Field
          className="order-3"
          data-testid={`${name}-frequency`}
          label={content.frequency.label}
          validationError={error.frequency}
        >
          <DropDown
            placeholder={content.frequency.placeholder}
            options={frequencyOptions}
            onSelectValue={value => onDropdownChange('frequency', value)}
            value={values.frequency}
            error={!!error.frequency}
            label={content.frequency.label}
          />
        </Field>

        <Field
          className="order-4"
          data-testid={`${name}-paymentDate`}
          label={paymentDateLabel}
          validationError={error.paymentDate}
        >
          <DateInput
            data-testid="paymentDate-date-input"
            onChange={handleDateChange}
            onBlur={() => handleDateOnBlur('paymentDate')}
            defaultValue={values.paymentDate}
            error={!!error.paymentDate}
            id="paymentDate"
            dayLabel={content.paymentDate.dayInputLabel}
            monthLabel={content.paymentDate.monthInputLabel}
            yearLabel={content.paymentDate.yearInputLabel}
          />
        </Field>
      </div>
    </QuestionOrganism>
  );
};

export default YourAmount;

import { gql, useMutation } from '@apollo/client';
import { WizardSectionType, Maybe, useProgressQuery, WizardProgressType } from 'generated/graphql';
import * as Sentry from '@sentry/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { isEmpty } from 'lodash';
import { History } from 'history';

const validateProgressId = (id: string) => !isNaN(parseInt(id, 10));

const getProgressQueryParam = (search: string) => {
  let params = new URLSearchParams(search);
  const wizardProgressId = params.get('progress_id') || '';
  return validateProgressId(wizardProgressId) ? wizardProgressId : null;
};

const SectionsThatOnlyAllowOneProgressRecord = [WizardSectionType.Identity];

export const getProgressObject = (
  id: Maybe<string>,
  wizardProgress: Partial<WizardProgressType>[],
  uuid: Maybe<string>,
  sectionName: string,
) => {
  if (id && uuid) {
    return wizardProgress.find(wp => wp.id === id && wp.relatedObjectUuid === uuid);
  } else if (uuid) {
    return wizardProgress.find(wp => wp.relatedObjectUuid === uuid && wp.name === sectionName);
  } else if (id) {
    return wizardProgress.find(wp => wp.id === id);
  } else if (SectionsThatOnlyAllowOneProgressRecord.includes(sectionName as WizardSectionType)) {
    return wizardProgress.find(wp => wp.name === sectionName);
  }
};

export const getProgressMutationVariables = (
  completedSteps: string[],
  isLastQuestion: boolean,
  sectionName: WizardSectionType,
  currentStep: string,
  uuid: Maybe<string>,
  wizardProgressObject?: Partial<WizardProgressType>,
) => {
  if (wizardProgressObject) {
    if (
      wizardProgressObject.completedDtm &&
      wizardProgressObject.completedSteps?.includes(currentStep)
    ) {
      //If previously completed the wizard and on flow already answered don't change anything
      return {};
    }
    return {
      id: wizardProgressObject.id,
      completedSteps: wizardProgressObject.completedSteps?.includes(currentStep)
        ? wizardProgressObject.completedSteps
        : completedSteps,
      completed: isLastQuestion,
      relatedObjectUuid: uuid,
    };
  }
  return {
    name: sectionName,
    completedSteps,
    completed: isLastQuestion,
    relatedObjectUuid: uuid,
  };
};

export const PROGRESS_FRAGMENT = gql`
  fragment ProgressFragment on WizardProgressType {
    id
    name
    completedDtm
    completedSteps
    relatedObjectUuid
  }
`;
export const PROGRESS_QUERY = gql`
  query Progress {
    me {
      uuid
      latestValidation {
        progress {
          ...ProgressFragment
        }
      }
    }
  }
`;

const PROGRESS_MUTATION = gql`
  mutation MutateProgress(
    $completedSteps: [String]
    $id: Int
    $completed: Boolean
    $name: String
    $relatedObjectUuid: UUID
  ) {
    updateWizardProgress(
      completedSteps: $completedSteps
      name: $name
      id: $id
      completed: $completed
      relatedObjectUuid: $relatedObjectUuid
    ) {
      wizardProgress {
        ...ProgressFragment
      }
    }
  }
  ${PROGRESS_FRAGMENT}
`;

const progressIdUrlKey = 'progress_id';

const updateUrlSearchParams = (history: History<unknown>, key: string, value: string) => {
  const newQueryParams = new URLSearchParams(history.location.search);
  newQueryParams.set(key, value);
  history.replace(`${history.location.pathname}?${newQueryParams.toString()}`);
};

interface Params {
  uuid: Maybe<string>;
  sectionName: WizardSectionType;
  setRelatedObjectUuid: (uuid: string) => void;
}

export const useProgressTracking = ({ uuid = null, sectionName, setRelatedObjectUuid }: Params) => {
  const history = useHistory();
  const { data } = useProgressQuery({
    onError: error => {
      Sentry.captureException(error, {
        tags: {
          category: 'graphql',
        },
      });
    },
  });

  const queriedProgressId = useMemo(() => getProgressQueryParam(history.location.search) || '', [
    history.location.search,
  ]);

  const [wizardProgress, setWizardProgress] = useState<Partial<WizardProgressType>>();

  useEffect(() => {
    if (wizardProgress?.id && !queriedProgressId) {
      updateUrlSearchParams(history, progressIdUrlKey, wizardProgress?.id || '');
    }
  }, [queriedProgressId, history, wizardProgress?.id]);

  const [runMutation] = useMutation(PROGRESS_MUTATION, {
    onError: err => {
      Sentry.captureException(err, {
        tags: {
          category: 'graphql',
        },
      });
    },
    onCompleted: data => {
      let id = data?.updateWizardProgress?.wizardProgress?.id;
      if (!wizardProgress && id) {
        setWizardProgress({ id });
        if (id !== queriedProgressId) {
          updateUrlSearchParams(history, progressIdUrlKey, id);
        }
      }
    },
  });

  useEffect(() => {
    //Match initial queried progress to a provided progress object if we haven't already
    const gqlProgress = data?.me?.latestValidation?.progress as WizardProgressType[];

    if (!wizardProgress && gqlProgress && gqlProgress.length > 0) {
      const matchedObject = getProgressObject(queriedProgressId, gqlProgress, uuid, sectionName);

      if (matchedObject && matchedObject.id) {
        setWizardProgress(matchedObject);

        if (matchedObject.id !== queriedProgressId) {
          //If matched only by uuid throw progress id in url
          updateUrlSearchParams(history, progressIdUrlKey, matchedObject.id);
        }
        if (matchedObject.relatedObjectUuid) {
          //If didn't specify uuid but matched a progress object that had one
          setRelatedObjectUuid(matchedObject.relatedObjectUuid);
        }
      }
    }
  }, [data, wizardProgress, uuid, queriedProgressId, history, sectionName, setRelatedObjectUuid]);

  useEffect(() => {
    //The first time we get a uuid tie it to the progress immediately
    if (uuid && wizardProgress?.id && uuid !== wizardProgress?.relatedObjectUuid) {
      runMutation({
        variables: {
          id: wizardProgress.id,
          relatedObjectUuid: uuid,
          name: sectionName,
        },
      });
      setWizardProgress({ ...wizardProgress, relatedObjectUuid: uuid });
    }
  }, [uuid, wizardProgress, runMutation, sectionName]);

  const updateProgress = useCallback(
    async (
      completedSteps: string[],
      isLastQuestion: boolean,
      currentStep: string,
    ): Promise<void> => {
      const variables = getProgressMutationVariables(
        completedSteps,
        isLastQuestion,
        sectionName,
        currentStep,
        uuid,
        wizardProgress,
      );

      if (!isEmpty(variables)) {
        await runMutation({ variables });
      }
    },
    [runMutation, sectionName, uuid, wizardProgress],
  );

  return updateProgress;
};

import React from 'react';
import QuestionOrganism from '../QuestionOrganism';
import { ValidationRule } from '../types';
import { Field, Input } from 'components';
import { Trans, useTranslation } from 'react-i18next';
import {
  RTRIdentityQuestionComponentProps,
  StepNamesRTRIdentity,
} from 'questionFlow/flows/rightToRentIdentity/types';
import { useAnalyticsError, useAnalyticsValue } from 'monitoring/analyticsHooks';
import { useTranslatedValues } from 'hooks/useTranslatedValues';

const cmsLocation = '/questions/identity/shareCode';

const translations = {
  title: `${cmsLocation}.title`,
  subtitle: `${cmsLocation}.subtitle`,
  prompt: `${cmsLocation}.prompt`,
  placeholder: `${cmsLocation}.placeholder`,
  label: `${cmsLocation}.label`,
} as const;

const name = StepNamesRTRIdentity.shareCode;

const ShareCodeQuestion = ({
  values,
  onSubmit,
  validationRules,
  ...props
}: RTRIdentityQuestionComponentProps) => {
  const [value, setValue] = useAnalyticsValue(name, values[name] || '');
  const [error, setError] = useAnalyticsError(name, '');
  const { t } = useTranslation();
  const content = useTranslatedValues(translations);

  const validate = (v: string, rules: ValidationRule[]) => rules.find(({ rule }) => !rule(v));

  const handleSubmit = () => {
    const result = validate(value, validationRules[name]);

    if (!result) {
      onSubmit({ key: name, value });
    } else {
      setError(t(result.error as any));
    }
  };

  const handleOnChange = (e: React.ChangeEvent) => {
    const target = e.target as HTMLInputElement;

    if (error) {
      const result = validate(target.value, validationRules[name]);
      if (result) {
        setError(t(result.error as any));
      } else {
        setError('');
      }
    }
    setValue(target.value);
  };

  const handleOnBlur = (e: React.FocusEvent) => {
    const target = e.target as HTMLInputElement;
    const result = validate(target.value, validationRules[name]);
    if (result) {
      setError(t(result.error as any));
    } else {
      setError('');
    }
  };

  return (
    <QuestionOrganism
      id={name}
      data-testid={name}
      onSubmit={handleSubmit}
      title={content.title}
      subtitle={
        <Trans
          i18nKey={`${cmsLocation}.subtitle` as any}
          components={{
            br: <br></br>,
            p: <p />,
            a: (
              // eslint-disable-next-line jsx-a11y/anchor-has-content
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.gov.uk/prove-right-to-rent"
                className="text-blue-3"
              />
            ),
          }}
        />
      }
      prompt={content.prompt}
      {...props}
    >
      <Field
        className="mb-4"
        data-testid="share-code"
        label={content.label as string}
        validationError={error}
      >
        <Input
          id={`${name}-share-code`}
          name="share-code"
          defaultValue={value}
          onChange={handleOnChange}
          aria-invalid={!!error}
          onBlur={handleOnBlur}
          placeholder={content.placeholder as string}
          autoComplete="off"
        />
      </Field>
    </QuestionOrganism>
  );
};

export default ShareCodeQuestion;

import { ValidationRule } from '../../types';
import { useTranslation } from 'react-i18next';
import QuestionOrganism from '../../QuestionOrganism';
import { DateInput, Field, Input } from 'components';
import { OnDateChange } from 'components/molecules/DateInput/DateInput';
import { useContent } from './content';
import {
  assertIsLoanDetailsStringQuestion,
  assertIsLoanDetailsDateQuestion,
  LoanDetailsQuestion,
  DateQuestion,
  DateValue,
  isDateValue,
} from './types';
import { adaptToExternalFormat, useErrors, useValues } from './state';
import {
  DefaultIncomeQuestionComponentProps,
  StepNamesDefaultIncome,
} from 'questionFlow/flows/defaultIncome/types';

const validate = (v: string, rules: ValidationRule[], valid?: boolean) =>
  rules.find(({ rule }) => !rule(v, valid));

const name = StepNamesDefaultIncome.loanDetails;

const LoanDetails = ({
  data,
  values: initialValues,
  onSubmit,
  validationRules,
  ...props
}: DefaultIncomeQuestionComponentProps) => {
  const { t } = useTranslation();
  const content = useContent(t);
  const [values, setValues] = useValues(initialValues);
  const [error, setError] = useErrors();

  const getValidationResult = (name: LoanDetailsQuestion, givenValue?: string | DateValue) => {
    const stringOrDateValue = givenValue === undefined ? values[name] : givenValue;

    return isDateValue(stringOrDateValue)
      ? validate(stringOrDateValue.value, validationRules[name], stringOrDateValue.valid)
      : validate(stringOrDateValue, validationRules[name]);
  };

  const handleSubmit = () => {
    const errors = (Object.keys(values) as LoanDetailsQuestion[]).reduce((e, inputName) => {
      const result = getValidationResult(inputName);

      return { ...e, [inputName]: result ? t(result.error as any) : '' };
    }, error);

    if (Object.values(errors).filter(e => e !== '').length === 0) {
      onSubmit({ key: name, value: adaptToExternalFormat(values) });
    } else {
      setError(errors);
    }
  };

  const validateAndSetError = (name: LoanDetailsQuestion, newValue: string) => {
    const result = getValidationResult(name, newValue);
    setError({ ...error, [name]: result ? t(result.error as any) : '' });
  };

  const onStringValueChange = (name: LoanDetailsQuestion, value: string) => {
    if (error[name]) {
      validateAndSetError(name, value);
    }

    setValues({ ...values, [name]: value });
  };

  const handleInputOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    assertIsLoanDetailsStringQuestion(e.target.name);
    onStringValueChange(e.target.name, e.target.value.trim());
  };

  const handleInputOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    assertIsLoanDetailsStringQuestion(e.target.name);
    validateAndSetError(e.target.name, e.target.value.trim());
  };

  const handleDateChange: OnDateChange = ({ name, value, valid }) => {
    assertIsLoanDetailsDateQuestion(name);

    setValues({
      ...values,
      [name]: {
        value,
        valid,
      },
    });

    if (valid && error[name]) {
      setError({ ...error, [name]: '' });
    }
  };

  const handleDateOnBlur = (name: DateQuestion) => {
    const result = getValidationResult(name);
    setError({ ...error, [name]: result ? t(result.error as any) : '' });
  };

  return (
    <QuestionOrganism
      id={name}
      data-testid={name}
      onSubmit={handleSubmit}
      title={content.title}
      subtitle={content.subtitle}
      {...props}
    >
      <div className="grid w-max md:grid-cols-2 gap-x-10 gap-y-4 pb-60">
        <Field
          data-testid={`${name}-bodyName`}
          label={content.bodyName.label}
          validationError={error.bodyName}
        >
          <Input
            id={`${name}-bodyName`}
            name={'bodyName'}
            defaultValue={values.bodyName}
            onChange={handleInputOnChange}
            aria-invalid={!!error.bodyName}
            onBlur={handleInputOnBlur}
            placeholder={content.bodyName.placeholder}
          />
        </Field>

        <div className="hidden md:block" />

        <Field
          data-testid={`${name}-startDate`}
          label={content.startDate.label}
          validationError={error.startDate}
        >
          <DateInput
            data-testid="start-date-input"
            onChange={handleDateChange}
            onBlur={() => handleDateOnBlur('startDate')}
            defaultValue={values.startDate.value}
            error={!!error.startDate}
            id="startDate"
            dayLabel={content.startDate.dayInputLabel}
            monthLabel={content.startDate.monthInputLabel}
            yearLabel={content.startDate.yearInputLabel}
          />
        </Field>

        <Field
          data-testid={`${name}-endDate`}
          label={content.endDate.label}
          validationError={error.endDate}
        >
          <DateInput
            data-testid="end-date-input"
            onChange={handleDateChange}
            onBlur={() => handleDateOnBlur('endDate')}
            defaultValue={values.endDate.value}
            error={!!error.endDate}
            id="endDate"
            dayLabel={content.endDate.dayInputLabel}
            monthLabel={content.endDate.monthInputLabel}
            yearLabel={content.endDate.yearInputLabel}
          />
        </Field>
      </div>
    </QuestionOrganism>
  );
};

export default LoanDetails;

import AxiosProvider from 'api/axios/AxiosProvider';
import { getEnv } from 'services/environmentVariables';
import useIsMounted from 'hooks/useIsMounted';
import { RefObject, useMemo, useState } from 'react';

type State = 'initial' | 'working' | 'done' | 'error';

export interface OtlRequestBody {
  email: string;
}

export const SubmitFunctionFactory = {
  createSubmitFunction(
    currentState: State,
    setState: (newState: State) => void,
    isMounted: RefObject<boolean>,
    onLoginSuccess: (email: string) => void,
  ) {
    return (email: string) => {
      const uri = getEnv('OTL_REQUEST_URI');

      if (!uri) {
        setState('error');
        throw new Error('OTL_REQUEST_URI is not set');
      }

      if (currentState === 'working') {
        throw new Error(
          'Invalid state, called for a second time with ' +
            email +
            ' while a request is in progress',
        );
      }

      const body: OtlRequestBody = { email };

      setState('working');

      AxiosProvider.post(uri, body)
        .then(response => {
          if (isMounted.current) {
            setState(response.status === 200 ? 'done' : 'error');
            onLoginSuccess(email);
          }
        })
        .catch(() => {
          if (isMounted.current) {
            setState('error');
          }
        });
    };
  },
};

const useLoginRequest = (
  onLoginSuccess: (email: string) => void,
): [State, (email: string) => void] => {
  const isMounted = useIsMounted();
  const [state, setState] = useState<State>('initial');

  const onSubmit = useMemo(
    () => SubmitFunctionFactory.createSubmitFunction(state, setState, isMounted, onLoginSuccess),
    [isMounted, state, onLoginSuccess],
  );

  return [state, onSubmit];
};

export default useLoginRequest;

import React from 'react';
import clsx from 'clsx';

export interface Props {
  className?: string;
  children?: React.ReactNode;
}

const InternalRouteLayout = ({ className, children, ...props }: Props) => {
  return (
    <div
      {...props}
      data-testid="internal-route-layout"
      className={clsx('w-full h-screen', className)}
    >
      {children}
    </div>
  );
};

const Header = ({ className, children, ...props }: Props) => {
  return (
    <header
      data-testid="header"
      className={clsx(
        'w-full sticky top-0 border border-grey-1 bg-white px-6 pt-8 pb-4 m-0 z-40',
        className,
      )}
      {...props}
    >
      <div className="md:max-w-desktop m-auto">{children}</div>
    </header>
  );
};

const Body = ({ className, children, ...props }: Props) => {
  return (
    <main
      data-testid="body"
      className={clsx('bg-white px-6 pb-20 overflow-y-visible', className)}
      {...props}
    >
      <div className="md:max-w-desktop m-auto">{children}</div>
    </main>
  );
};

const Footer = ({ className, children, ...props }: Props) => {
  return (
    <section
      data-testid="footer"
      className={clsx('max-h-20 fixed bottom-0 w-full bg-light-blue-1 py-4 px-6 z-40', className)}
      {...props}
    >
      <div className="md:max-w-desktop m-auto flex justify-center md:justify-end">{children}</div>
    </section>
  );
};

InternalRouteLayout.Header = Header;
InternalRouteLayout.Body = Body;
InternalRouteLayout.Footer = Footer;

export default InternalRouteLayout;

import { Flow, GeneralSteps } from '../../types';
import { Name, Nationality, Birthdate } from '../../identity';
import { gql } from '@apollo/client';
import { Maybe, MutationMeArgs, AccountDocumentType } from 'generated/graphql';
import {
  ALLOWED_OPTION,
  DATE_FORMAT,
  DATE_REQUIRED,
  DATE_VALID,
  OPTIONAL_NAME_LENGTH,
  OPTIONAL_NAME_PATTERN,
  SHARE_CODE,
  UNDER_18_INVALID,
} from '../../validations/index';
import { FIELD_REQUIRED, NAME_LENGTH, NAME_PATTERN } from '../../validations';
import { RTRIdentityValuesType, StepNamesRTRIdentity } from './types';
import PrimaryDocuments from 'questionFlow/identity/PrimaryDocuments';
import UploadIdentity from 'questionFlow/identity/Upload';
import {
  getAllRTRDocumentOptionsByNationalityOnly,
  getRTRDocumentOptionsByNationality,
  getRTRDocumentOptionsByNationalityAndStatus,
  STATUS_OPTIONS,
} from './data/right-to-rent-docs-list';
import SecondaryDocuments from 'questionFlow/identity/SecondaryDocuments';
import NoDocuments from 'questionFlow/genericQuestions/NoDocuments';
import ShareCodeQuestion from 'questionFlow/identity/ShareCode';
import StatusQuestion from 'questionFlow/identity/Status';
import { uniq } from 'lodash';
import BiometricEducation from 'questionFlow/identity/BiometricEducation';
import MissingInformation from 'questionFlow/genericQuestions/MissingInformation';
import { hasDocumentSignposting } from 'questionFlow/genericQuestions/Upload/utils';
import { docHasMultipleSides } from './utils';
import IdentityDocSignposting from 'questionFlow/identity/IdentityDocSignposting';
import ExpiredVisa from 'questionFlow/identity/ExpiredVisa';
import GenericIdentityDocSignposting from 'questionFlow/identity/GenericIdentityDocSignposting';
import Permissions from 'questionFlow/identity/Permissions';
import Camera from 'questionFlow/identity/Camera';
import PhotoOptOut from 'questionFlow/identity/PhotoOptOut';

const gqlQueryFragment = gql`
  fragment rightToRentIdentity on Query {
    me {
      uuid
      name {
        firstName
        lastName
        middleName
      }
      profile {
        nationality
        birthdate
        shareCode
      }
      identityDocuments(remedialSectionUuid: $remedialSectionUuid) {
        id
        type
        path
      }
    }
    sectionMetaData {
      identitySectionMetaData {
        enableIdvt
      }
    }
  }
`;

const mutation = gql`
  mutation MutateMeRTR(
    $firstName: String
    $middleName: String
    $lastName: String
    $birthdate: Date
    $nationality: String
    $shareCode: String
    $identityDocuments: [DocumentInputType]
    $remedialSectionUuid: UUID
  ) {
    me(
      firstName: $firstName
      middleName: $middleName
      lastName: $lastName
      birthdate: $birthdate
      nationality: $nationality
      shareCode: $shareCode
      identityDocuments: $identityDocuments
      remedialSectionUuid: $remedialSectionUuid
    ) {
      name {
        firstName
      }
    }
  }
`;

interface ReturnType {
  [StepNamesRTRIdentity.primaryDocument]?: Maybe<string>;
  [StepNamesRTRIdentity.secondaryDocumentOne]?: Maybe<AccountDocumentType>;
  [StepNamesRTRIdentity.secondaryDocumentTwo]?: Maybe<AccountDocumentType>;
  [StepNamesRTRIdentity.status]?: Maybe<string>;
}
const determineSelectionsFromUploads = (
  nationality?: Maybe<string>,
  reversedUploads?: Maybe<AccountDocumentType[]>,
  shareCode?: Maybe<string>,
): ReturnType => {
  let result = {
    [StepNamesRTRIdentity.primaryDocument]: null,
    [StepNamesRTRIdentity.secondaryDocumentOne]: null,
    [StepNamesRTRIdentity.secondaryDocumentTwo]: null,
    [StepNamesRTRIdentity.status]: null,
  } as ReturnType;

  if (nationality && reversedUploads && reversedUploads.length > 0) {
    const uploads = [...reversedUploads].reverse();
    const AllPossibleMatchedDocumentDefinitions = getAllRTRDocumentOptionsByNationalityOnly(
      nationality,
    );

    if (AllPossibleMatchedDocumentDefinitions) {
      if (shareCode) {
        try {
          const secondaryDocumentWithShareCodeOptions = getRTRDocumentOptionsByNationalityAndStatus(
            nationality,
            STATUS_OPTIONS.SETTLED,
          ).documents.secondary[0].options;
          return {
            ...result,
            [StepNamesRTRIdentity.primaryDocument]: STATUS_OPTIONS.SETTLED,
            [StepNamesRTRIdentity.secondaryDocumentOne]: secondaryDocumentWithShareCodeOptions.includes(
              uploads[0].type,
            )
              ? uploads[0].type
              : null,
          };
        } catch (e) {
          //Share code and nationality can not match, prioritise nationality and assume share code is out of date. contintue
        }
      }

      const primaryOptions = AllPossibleMatchedDocumentDefinitions[0].documents.primary[0].options;
      if (primaryOptions.includes(uploads[0].type)) {
        return {
          ...result,
          [StepNamesRTRIdentity.primaryDocument]: uploads[0].type,
        };
      }

      const allSecondaryOneOptions = uniq(
        AllPossibleMatchedDocumentDefinitions.reduce(
          (result, definition) => [...result, ...definition.documents.secondary[0].options],
          [] as String[],
        ),
      );

      //Using some to allow short circuit once results are identified
      uploads.some(upload => {
        if (result.secondaryDocumentOne && upload.type === result.secondaryDocumentOne) {
          return false;
        }

        if (!result.secondaryDocumentOne && allSecondaryOneOptions.includes(upload.type)) {
          result.secondaryDocumentOne = upload.type;
          result.primaryDocument = 'none';
        } else if (!result.secondaryDocumentTwo) {
          AllPossibleMatchedDocumentDefinitions.filter(
            definition => !definition.requireShareCode,
          ).some(definition => {
            const secondaryDocumentTwoOptions =
              definition.documents.secondary[0].quantity !== 1
                ? definition.documents.secondary[0].options
                : definition.documents.secondary[1].options;

            if (secondaryDocumentTwoOptions.includes(upload.type)) {
              result.secondaryDocumentTwo = upload.type;
              result.status = definition.status || null;
              result.primaryDocument = 'none';
              return true;
            }
            return false;
          });
        }

        return result.secondaryDocumentOne && result.secondaryDocumentTwo;
      });
    }
  }
  return result;
};

const RTRIdentityFlow: Flow<
  {},
  RTRIdentityValuesType,
  StepNamesRTRIdentity,
  void,
  MutationMeArgs
> = {
  start: () => StepNamesRTRIdentity.name,
  getQueryParams: () => {},
  queryFragment: gqlQueryFragment,
  mutation,
  handleMutationResponse: () => ({}),
  getData: ({ identitySectionMetaData }: any) => identitySectionMetaData,
  getDefaultValues: values => {
    return {
      name: {
        first_name: values.name.firstName || '',
        middle_name: values.name.middleName || '',
        last_name: values.name.lastName || '',
      },
      birthdate: values.profile.birthdate || '',
      nationality: values.profile.nationality || '',
      ...determineSelectionsFromUploads(
        values.profile.nationality,
        values.identityDocuments,
        values.profile.shareCode,
      ),
      shareCode: values.profile.shareCode,
      upload: values.identityDocuments
        ? [...values.identityDocuments].reverse().map(({ id, type, path }) => ({ id, type, path }))
        : [],
      uuid: null,
    };
  },
  steps: {
    [StepNamesRTRIdentity.name]: {
      component: Name,
      validationRules: {
        first_name: [FIELD_REQUIRED, NAME_LENGTH, NAME_PATTERN],
        middle_name: [OPTIONAL_NAME_LENGTH, OPTIONAL_NAME_PATTERN],
        last_name: [FIELD_REQUIRED, NAME_LENGTH, NAME_PATTERN],
      },
      getMutationValues: ({ name }: RTRIdentityValuesType) => ({
        firstName: name?.first_name || '',
        middleName: name?.middle_name || null,
        lastName: name?.last_name || '',
      }),
      chooseSuccessor: () => StepNamesRTRIdentity.birthdate,
    },
    [StepNamesRTRIdentity.birthdate]: {
      component: Birthdate,
      getMutationValues: ({ birthdate }: Partial<RTRIdentityValuesType>) => ({
        birthdate,
      }),
      validationRules: {
        [StepNamesRTRIdentity.birthdate]: [
          DATE_REQUIRED,
          DATE_FORMAT,
          UNDER_18_INVALID,
          DATE_VALID,
        ],
        day: [],
        month: [],
        year: [],
      },
      chooseSuccessor: () => StepNamesRTRIdentity.nationality,
    },
    [StepNamesRTRIdentity.nationality]: {
      component: Nationality,
      getMutationValues: ({ nationality }: Partial<RTRIdentityValuesType>) => ({
        nationality,
      }),
      validationRules: {
        [StepNamesRTRIdentity.nationality]: [FIELD_REQUIRED, ALLOWED_OPTION],
      },
      chooseSuccessor: () => StepNamesRTRIdentity.primaryDocument,
    },
    [StepNamesRTRIdentity.primaryDocument]: {
      component: PrimaryDocuments,
      validationRules: {
        [StepNamesRTRIdentity.primaryDocument]: [FIELD_REQUIRED, ALLOWED_OPTION],
      },
      chooseSuccessor: ({ value, values }) => {
        if (
          typeof value === 'string' &&
          Object.values(AccountDocumentType).includes(value as AccountDocumentType)
        ) {
          if (value === AccountDocumentType.UkBiometricResidence) {
            return StepNamesRTRIdentity.biometricEducation;
          }

          if (hasDocumentSignposting(value)) {
            return StepNamesRTRIdentity.documentSignpostingPrimary;
          }

          return StepNamesRTRIdentity.upload;
        }
        if (value === STATUS_OPTIONS.SETTLED) {
          return StepNamesRTRIdentity.secondaryDocumentOne;
        }
        const docDefinition = getRTRDocumentOptionsByNationality(values.nationality as string);
        return docDefinition.status
          ? StepNamesRTRIdentity.status
          : StepNamesRTRIdentity.secondaryDocumentOne;
      },
    },
    [StepNamesRTRIdentity.upload]: {
      component: props => {
        const hasSides = docHasMultipleSides(props.values.primaryDocument);
        return (
          <UploadIdentity
            side={hasSides ? 'front' : ''}
            documentKey={StepNamesRTRIdentity.primaryDocument}
            {...props}
          />
        );
      },
      validationRules: {
        [StepNamesRTRIdentity.upload]: [FIELD_REQUIRED],
      },
      getMutationValues: ({ upload }: Partial<RTRIdentityValuesType>) => ({
        identityDocuments: upload?.map(({ __typename, ...doc }) => doc),
      }),
      chooseSuccessor: ({ values, data }) => {
        if (((data as any) || {}).enableIdvt && values.primaryDocument === 'UK_PASSPORT') {
          return StepNamesRTRIdentity.livePhotoSignposting;
        }
        if (docHasMultipleSides(values.primaryDocument)) {
          return StepNamesRTRIdentity.documentSignpostingPrimaryBack;
        }
      },
    },
    [StepNamesRTRIdentity.uploadBack]: {
      component: props => (
        <UploadIdentity
          {...props}
          side="back"
          documentKey={StepNamesRTRIdentity.primaryDocument}
          name={StepNamesRTRIdentity.upload}
        />
      ),
      validationRules: {
        [StepNamesRTRIdentity.upload]: [FIELD_REQUIRED],
      },
      getMutationValues: ({ upload }: Partial<RTRIdentityValuesType>) => ({
        identityDocuments: upload?.map(({ __typename, ...doc }) => doc),
      }),
    },
    [StepNamesRTRIdentity.secondaryDocumentOne]: {
      component: SecondaryDocuments,
      validationRules: {
        [StepNamesRTRIdentity.secondaryDocumentOne]: [FIELD_REQUIRED, ALLOWED_OPTION],
      },
      chooseSuccessor: ({ value }) => {
        if (value === 'none') {
          return StepNamesRTRIdentity.none;
        }
        if (typeof value === 'string' && hasDocumentSignposting(value)) {
          return StepNamesRTRIdentity.documentSignpostingSecondaryOne;
        }
        return StepNamesRTRIdentity.uploadSecondary;
      },
    },
    [StepNamesRTRIdentity.secondaryDocumentTwo]: {
      component: props => <SecondaryDocuments isSecondSelection {...props} />,
      validationRules: {
        [StepNamesRTRIdentity.secondaryDocumentTwo]: [FIELD_REQUIRED, ALLOWED_OPTION],
      },
      chooseSuccessor: ({ value }) => {
        if (value === 'none') {
          return StepNamesRTRIdentity.none;
        }
        if (typeof value === 'string' && hasDocumentSignposting(value)) {
          return StepNamesRTRIdentity.documentSignpostingSecondaryTwo;
        }
        return StepNamesRTRIdentity.uploadSecondaryTwo;
      },
    },
    [StepNamesRTRIdentity.uploadSecondary]: {
      component: props => {
        const hasSides = docHasMultipleSides(props.values.secondaryDocumentOne);

        return (
          <UploadIdentity
            {...props}
            side={hasSides ? 'front' : ''}
            documentKey={StepNamesRTRIdentity.secondaryDocumentOne}
            name={StepNamesRTRIdentity.upload}
          />
        );
      },
      validationRules: {
        [StepNamesRTRIdentity.uploadSecondary]: [FIELD_REQUIRED],
      },
      getMutationValues: ({ upload }: Partial<RTRIdentityValuesType>) => ({
        identityDocuments: upload?.map(({ __typename, ...doc }) => doc),
      }),
      chooseSuccessor: ({ values }) => {
        if (docHasMultipleSides(values.secondaryDocumentOne)) {
          return StepNamesRTRIdentity.documentSignpostingSecondaryOneBack;
        }

        const docDefinition = getRTRDocumentOptionsByNationalityAndStatus(
          values.nationality as string,
          values.primaryDocument === 'none' ? undefined : values.primaryDocument,
        );
        const secondaryDocDefinition = docDefinition.documents.secondary;
        if (
          secondaryDocDefinition[0].quantity === 1 &&
          !secondaryDocDefinition[1] &&
          docDefinition.requireShareCode
        ) {
          return StepNamesRTRIdentity.shareCode;
        }
        return StepNamesRTRIdentity.secondaryDocumentTwo;
      },
    },
    [StepNamesRTRIdentity.uploadSecondaryBack]: {
      component: props => (
        <UploadIdentity
          {...props}
          side="back"
          documentKey={StepNamesRTRIdentity.secondaryDocumentOne}
          name={StepNamesRTRIdentity.upload}
        />
      ),
      validationRules: {
        [StepNamesRTRIdentity.uploadSecondary]: [FIELD_REQUIRED],
      },
      getMutationValues: ({ upload }: Partial<RTRIdentityValuesType>) => ({
        identityDocuments: upload?.map(({ __typename, ...doc }) => doc),
      }),
      chooseSuccessor: ({ values }) => {
        const docDefinition = getRTRDocumentOptionsByNationalityAndStatus(
          values.nationality as string,
          values.primaryDocument === 'none' ? undefined : values.primaryDocument,
        );
        const secondaryDocDefinition = docDefinition.documents.secondary;
        if (
          secondaryDocDefinition[0].quantity === 1 &&
          !secondaryDocDefinition[1] &&
          docDefinition.requireShareCode
        ) {
          return StepNamesRTRIdentity.shareCode;
        }
        return StepNamesRTRIdentity.secondaryDocumentTwo;
      },
    },
    [StepNamesRTRIdentity.uploadSecondaryTwo]: {
      component: props => {
        const hasSides = docHasMultipleSides(props.values.secondaryDocumentTwo);

        return (
          <UploadIdentity
            {...props}
            side={hasSides ? 'front' : ''}
            documentKey={StepNamesRTRIdentity.secondaryDocumentTwo}
            name={StepNamesRTRIdentity.upload}
            isNotFirstDocUpload
          />
        );
      },
      validationRules: {
        [StepNamesRTRIdentity.uploadSecondaryTwo]: [FIELD_REQUIRED],
      },
      getMutationValues: ({ upload }: Partial<RTRIdentityValuesType>) => ({
        identityDocuments: upload?.map(({ __typename, ...doc }) => doc),
      }),
      chooseSuccessor: ({ values }) => {
        if (docHasMultipleSides(values.secondaryDocumentTwo)) {
          return StepNamesRTRIdentity.documentSignpostingSecondaryTwoBack;
        }
      },
    },
    [StepNamesRTRIdentity.uploadSecondaryTwoBack]: {
      component: props => (
        <UploadIdentity
          {...props}
          side="back"
          documentKey={StepNamesRTRIdentity.secondaryDocumentTwo}
          name={StepNamesRTRIdentity.upload}
          isNotFirstDocUpload
        />
      ),
      validationRules: {
        [StepNamesRTRIdentity.uploadSecondaryTwo]: [FIELD_REQUIRED],
      },
      getMutationValues: ({ upload }: Partial<RTRIdentityValuesType>) => ({
        identityDocuments: upload?.map(({ __typename, ...doc }) => doc),
      }),
    },
    [StepNamesRTRIdentity.documentSignpostingPrimary]: {
      component: props => {
        const hasSides = docHasMultipleSides(props.values.primaryDocument);

        return (
          <IdentityDocSignposting
            side={hasSides ? 'front' : ''}
            documentKey={StepNamesRTRIdentity.primaryDocument}
            {...props}
          />
        );
      },
      validationRules: {
        agreement: [FIELD_REQUIRED],
      },
      chooseSuccessor: () => StepNamesRTRIdentity.upload,
    },
    [StepNamesRTRIdentity.documentSignpostingPrimaryBack]: {
      component: props => (
        <IdentityDocSignposting
          {...props}
          side="back"
          documentKey={StepNamesRTRIdentity.primaryDocument}
        />
      ),
      validationRules: {
        agreement: [FIELD_REQUIRED],
        expiryDate: [DATE_REQUIRED, DATE_FORMAT],
      },
      chooseSuccessor: ({ value, values }) => {
        if (!value && values.primaryDocument === AccountDocumentType.TimeLimitedPassport) {
          return StepNamesRTRIdentity.expiredVisa;
        }
        return StepNamesRTRIdentity.uploadBack;
      },
    },
    [StepNamesRTRIdentity.expiredVisa]: {
      component: ExpiredVisa,
      validationRules: {},
      chooseSuccessor: () => StepNamesRTRIdentity.primaryDocument,
    },
    [StepNamesRTRIdentity.documentSignpostingSecondaryOne]: {
      component: props => {
        const hasSides = docHasMultipleSides(props.values.secondaryDocumentOne);

        return (
          <IdentityDocSignposting
            side={hasSides ? 'front' : ''}
            documentKey={StepNamesRTRIdentity.secondaryDocumentOne}
            {...props}
          />
        );
      },
      validationRules: {
        agreement: [FIELD_REQUIRED],
      },
      chooseSuccessor: () => StepNamesRTRIdentity.uploadSecondary,
    },
    [StepNamesRTRIdentity.documentSignpostingSecondaryOneBack]: {
      component: props => (
        <IdentityDocSignposting
          {...props}
          side="back"
          documentKey={StepNamesRTRIdentity.secondaryDocumentOne}
        />
      ),
      validationRules: {
        agreement: [FIELD_REQUIRED],
      },
      chooseSuccessor: () => StepNamesRTRIdentity.uploadSecondaryBack,
    },
    [StepNamesRTRIdentity.documentSignpostingSecondaryTwo]: {
      component: props => {
        const hasSides = docHasMultipleSides(props.values.secondaryDocumentTwo);

        return (
          <IdentityDocSignposting
            side={hasSides ? 'front' : ''}
            documentKey={StepNamesRTRIdentity.secondaryDocumentTwo}
            {...props}
          />
        );
      },
      validationRules: {
        agreement: [FIELD_REQUIRED],
      },
      chooseSuccessor: () => StepNamesRTRIdentity.uploadSecondaryTwo,
    },
    [StepNamesRTRIdentity.documentSignpostingSecondaryTwoBack]: {
      component: props => (
        <IdentityDocSignposting
          {...props}
          documentKey={StepNamesRTRIdentity.secondaryDocumentTwo}
          side="back"
        />
      ),
      validationRules: {
        agreement: [FIELD_REQUIRED],
      },
      chooseSuccessor: () => StepNamesRTRIdentity.uploadSecondaryTwoBack,
    },
    [GeneralSteps.genericUpload]: {
      component: props => (
        <GenericIdentityDocSignposting
          {...props}
          documentKey={StepNamesRTRIdentity.primaryDocument}
        />
      ),
      validationRules: {
        agreement: [FIELD_REQUIRED],
      },
      chooseSuccessor: () => StepNamesRTRIdentity.upload,
    },
    [StepNamesRTRIdentity.none]: {
      component: NoDocuments,
      validationRules: {},
    },
    [StepNamesRTRIdentity.status]: {
      component: StatusQuestion,
      validationRules: {
        [StepNamesRTRIdentity.status]: [FIELD_REQUIRED],
      },
      chooseSuccessor: () => StepNamesRTRIdentity.secondaryDocumentOne,
    },
    [StepNamesRTRIdentity.biometricEducation]: {
      component: BiometricEducation,
      validationRules: {},
      chooseSuccessor: () => StepNamesRTRIdentity.secondaryDocumentOne,
    },
    [StepNamesRTRIdentity.shareCode]: {
      component: ShareCodeQuestion,
      validationRules: {
        [StepNamesRTRIdentity.shareCode]: [FIELD_REQUIRED, SHARE_CODE],
      },
      getMutationValues: ({ shareCode }) => ({
        shareCode,
      }),
    },
    [GeneralSteps.missingInformation]: {
      component: MissingInformation,
      validationRules: {},
      chooseSuccessor: ({ data }) => data.remedialSection?.start as StepNamesRTRIdentity,
    },
    [StepNamesRTRIdentity.livePhotoSignposting]: {
      component: props => (
        <IdentityDocSignposting
          {...props}
          values={{ ...props.values, livePhotoSignposting: 'LIVE_PHOTO' }}
          documentKey={StepNamesRTRIdentity.livePhotoSignposting}
        />
      ),
      validationRules: { agreement: [FIELD_REQUIRED] },
      chooseSuccessor: () => StepNamesRTRIdentity.livePhotoPermissions,
    },
    [StepNamesRTRIdentity.livePhotoPermissions]: {
      component: props => <Permissions {...props} />,
      validationRules: { permissionGranted: [FIELD_REQUIRED] },
      chooseSuccessor: ({ value }) => {
        if (value === 'skip') return StepNamesRTRIdentity.livePhotoOptOut;
        return StepNamesRTRIdentity.livePhotoCamera;
      },
    },
    [StepNamesRTRIdentity.livePhotoOptOut]: {
      component: props => <PhotoOptOut {...props} />,
      validationRules: { permissionGranted: [FIELD_REQUIRED] },
      chooseSuccessor: ({ value }) => {
        if (value === 'takePhoto') return StepNamesRTRIdentity.livePhotoCamera;
        return;
      },
    },
    [StepNamesRTRIdentity.livePhotoCamera]: {
      component: props => <Camera {...props} />,
      validationRules: {
        [StepNamesRTRIdentity.upload]: [FIELD_REQUIRED],
      },
      getMutationValues: ({ upload }: Partial<RTRIdentityValuesType>) => {
        return {
          identityDocuments: upload?.map(({ __typename, ...doc }) => doc),
        };
      },
      chooseSuccessor: ({ value }) => {
        if (value === 'skip') return StepNamesRTRIdentity.livePhotoOptOut;
        return undefined;
      },
    },
  },
};

export default RTRIdentityFlow;

import { useTranslation, TFunction, Trans } from 'react-i18next';
import {
  RTRIdentityQuestionComponentProps,
  StepNamesRTRIdentity,
} from 'questionFlow/flows/rightToRentIdentity/types';
import QuestionOrganism from 'questionFlow/QuestionOrganism';

const cmsLocation = '/questions/identity/expiredVisa';

const useContent = (t: TFunction<'translation'>) => ({
  title: t(`${cmsLocation}.title`),
  description: t(`${cmsLocation}.description`),
});

const name = StepNamesRTRIdentity.expiredVisa;

const ExpiredVisa = ({ values, onSubmit, ...props }: RTRIdentityQuestionComponentProps) => {
  const { t } = useTranslation();
  const content = useContent(t);

  const handleSubmit = () => {
    return onSubmit({ key: name, value: true });
  };
  return (
    <QuestionOrganism
      id={name}
      data-testid={name}
      onSubmit={handleSubmit}
      title={content.title}
      subtitle={
        <Trans
          i18nKey={`${cmsLocation}.description` as any}
          components={{
            br: <br></br>,
            a: (
              // eslint-disable-next-line jsx-a11y/anchor-has-content
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.gov.uk/prove-right-to-rent"
                className="text-blue-3"
              />
            ),
          }}
        />
      }
      {...props}
    ></QuestionOrganism>
  );
};

export default ExpiredVisa;

import { useEffect, useState } from 'react';
import AuthenticationService, { AuthenticationState } from './AuthenticationService';

const useAuthentication = (): AuthenticationState => {
  const [authenticationState, setAuthenticationState] = useState<AuthenticationState>(
    AuthenticationService.getAuthenticationState(),
  );

  useEffect(() => AuthenticationService.registerAuthenticationListener(setAuthenticationState), []);

  return authenticationState;
};

export default useAuthentication;

import { useTranslation, TFunction } from 'react-i18next';
import { AccountDocumentType } from 'generated/graphql';
import DocumentSignposting from 'questionFlow/genericQuestions/DocumentSignposting';
import { OnSubmitType } from 'questionFlow/types';
import {
  IdentityQuestionComponentProps,
  StepNamesDefaultIdentity,
} from 'questionFlow/flows/defaultIdentity/types';

const cmsResidenceSignpostingLocation = '/questions/documentSignposting';
const useContent = (t: TFunction<'translation'>) => ({
  title: t(`${cmsResidenceSignpostingLocation}.OTHER.title`),
  subtitle: t(`${cmsResidenceSignpostingLocation}.OTHER.subtitle`),
});

const GenericIncomeDocSignposting = ({
  values,
  data,
  documentKey = StepNamesDefaultIdentity.documents,
  onSubmit,
  ...props
}: IdentityQuestionComponentProps & { documentKey?: string }) => {
  const { t } = useTranslation();
  const content = useContent(t);

  const handleSubmit: OnSubmitType = () => {
    onSubmit({
      key: documentKey,
      value: AccountDocumentType.Other,
    });
  };

  return (
    <DocumentSignposting
      {...props}
      data={data}
      values={{
        [documentKey]: AccountDocumentType.Other,
      }}
      documentKey={documentKey}
      onSubmit={handleSubmit}
      content={content}
    />
  );
};

export default GenericIncomeDocSignposting;

import { useState } from 'react';
import axios, { AxiosError } from 'axios';
import AuthenticationService from 'authentication/AuthenticationService';
import { useHistory } from 'react-router-dom';
import TopLevelRoutes from 'routing/routes';
import { TenantReferenceData } from './types';

type Params = {
  uuid: string;
};

type Return = {
  loading: boolean;
  error: string;
  saveData: (data: Partial<TenantReferenceData>) => void;
};

export const useSaveRentalReferenceData = ({ uuid }: Params): Return => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>('');
  const history = useHistory();

  const token = AuthenticationService.accessToken;

  const saveData = async (data: Partial<TenantReferenceData>) => {
    try {
      setLoading(true);
      setError('');

      const res = await axios
        .put<Partial<TenantReferenceData>>(
          `${process.env.REACT_APP_BASE_API_URI}api/tenant-review/${uuid}/`,
          data,
          {
            withCredentials: true,
            headers: { Authorization: `Bearer ${token}` },
          },
        )
        .then(({ data: d }) => d);

      setLoading(false);
      history.push({
        pathname: TopLevelRoutes.REFERENCE_THANK_YOU,
        state: {
          name: res.first_name && res.last_name ? `${res.first_name} ${res.last_name}` : '',
        },
      });
    } catch (e) {
      console.error(e);

      setLoading(false);
      setError((e as AxiosError)?.message || 'Unexpected error');
    }
  };

  return {
    loading,
    error,
    saveData,
  };
};

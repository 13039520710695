import React from 'react';
import { Button, InternalRouteLayout, TextButton, Spinner } from 'components';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import SectionIndicator from './SectionIndicator';
import { useAnalyticsEvent } from 'monitoring/analyticsHooks';

interface ComponentProps {
  id?: string;
  title: string | React.ReactNode;
  subtitle: string | React.ReactNode;
  prompt?: string | React.ReactNode;
  onSubmit: () => void;
  onBack: () => void;
  children?: React.ReactNode;
  index: number;
  sectionName: string;
  'data-testid': string;
  isFooterHidden?: boolean;
  isQuestionNumberHidden?: boolean;
  isRemedialSection?: boolean;
  loading?: boolean;
}

const QuestionOrganism = ({
  title,
  subtitle,
  onSubmit,
  onBack,
  children,
  index,
  sectionName,
  prompt,
  isFooterHidden,
  isQuestionNumberHidden,
  isRemedialSection,
  loading,
  'data-testid': dataTestId,
}: ComponentProps) => {
  const { t } = useTranslation();
  const { broadcastEvent } = useAnalyticsEvent();

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    broadcastEvent('Clicked continue');
    onSubmit();
  };

  return (
    <>
      <InternalRouteLayout.Body>
        <div data-testid={`question-organism-${dataTestId}`}>
          <SectionIndicator selectedValue={sectionName} isRemedialSection={isRemedialSection} />
        </div>

        <div className="mt-8 grid grid-cols-1 md:grid-cols-12">
          <div className="flex flex-row w-full justify-between mb-6 col-span-12">
            <span
              className={clsx(
                'text-grey-2 text-sm invisible md:visible',
                isQuestionNumberHidden && 'md:invisible',
              )}
            >
              {t('/questions.question-number')} {index + 1}
            </span>
            {index !== 0 && (
              <TextButton id="question-back" onClick={onBack}>
                {t('/questions.back-button')}
              </TextButton>
            )}
          </div>
          <div className="col-span-8">
            <h1 className="font-semibold text-3xl mb-4">{title}</h1>
            <p className="mb-6">{subtitle}</p>
            {prompt && <h2 className="mt-8 md:mt-10 mb-6 md:text-2xl font-semibold">{prompt}</h2>}
          </div>
          <form id="form" onSubmit={handleSubmit} className="col-span-12 pb-10">
            {children}
          </form>
        </div>
      </InternalRouteLayout.Body>
      {!isFooterHidden && (
        <InternalRouteLayout.Footer aria-label="question-submit-banner">
          <Button
            className="md:w-auto"
            id="question-submit"
            type="submit"
            form="form"
            data-testid="question-submit"
          >
            {loading ? (
              <Spinner small className="mx-5" strokeClass="text-white" fillClass="text-white" />
            ) : (
              t('/questions.continue')
            )}
          </Button>
        </InternalRouteLayout.Footer>
      )}
    </>
  );
};

export default QuestionOrganism;

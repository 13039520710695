import { useMemo } from 'react';
import QuestionOrganism from '../QuestionOrganism';
import { Field } from 'components';
import { OnSubmitType, ValidationRule, ValueType } from '../types';
import { useTranslation } from 'react-i18next';
import { Options } from 'components/molecules/DropDown/types';
import { useCallback } from 'react';
import SearchableDropdown from 'components/molecules/SearchableDropdown';
import { useAnalyticsError, useAnalyticsValue } from 'monitoring/analyticsHooks';

export interface DropdownQuestionProps {
  content: {
    title: string;
    subtitle: string;
    placeholder: string;
  };
  index: number;
  initialValue?: ValueType;
  name: string;
  onBack: () => void;
  onSubmit: OnSubmitType;
  options: Options;
  sectionName: string;
  validationRules: { [key: string]: ValidationRule[] };
}

const DropdownQuestion = ({
  content,
  initialValue = '',
  name,
  onSubmit,
  options,
  validationRules,
  ...props
}: DropdownQuestionProps) => {
  const { t } = useTranslation();
  const [value, setValue] = useAnalyticsValue(name, initialValue);
  const [error, setError] = useAnalyticsError(name, '');

  const allowedOptionValues = useMemo(() => options.map(({ value }) => value), [options]);

  const validate = useCallback(
    (v: string) => validationRules[name].find(({ rule }) => !rule(v, allowedOptionValues)),
    [allowedOptionValues, name, validationRules],
  );

  const handleSubmit = useCallback(() => {
    const err = validate(value as string);

    if (err) {
      setError(t(err.error as any));
    } else {
      onSubmit({ key: name, value });
    }
  }, [name, onSubmit, t, validate, value, setError]);

  const handleOnChange = useCallback(
    (v: string) => {
      if (error && !validate(v)) {
        setError('');
      }
      setValue(v);
    },
    [error, validate, setError, setValue],
  );

  return (
    <QuestionOrganism
      id={name}
      data-testid={name}
      onSubmit={handleSubmit}
      title={content.title}
      subtitle={content.subtitle}
      {...props}
    >
      <Field className="pb-60" validationError={error} data-testid={name}>
        <SearchableDropdown
          placeholder={content.placeholder}
          options={options}
          onSelectValue={handleOnChange}
          value={value as string}
          error={!!error}
          label={content.title}
          className="md:w-64"
        />
      </Field>
    </QuestionOrganism>
  );
};

export default DropdownQuestion;

import React from 'react';
import QuestionOrganism from '../QuestionOrganism';
import { ValidationRule } from '../types';
import { Field, Input } from 'components';
import { useTranslation, TFunction, Trans } from 'react-i18next';
import {
  IdentityQuestionComponentProps,
  StepNamesDefaultIdentity,
} from 'questionFlow/flows/defaultIdentity/types';
import { useAnalyticsErrors, useAnalyticsValues } from 'monitoring/analyticsHooks';

const cmsLocation = '/questions/identity/name';

const useContent = (t: TFunction<'translation'>) => {
  return {
    title: t(`${cmsLocation}.title`),
    first_name: {
      label: t(`${cmsLocation}.first_name.label`),
      placeholder: t(`${cmsLocation}.first_name.placeholder`),
    },
    middle_name: {
      label: t(`${cmsLocation}.middle_name.label`),
      placeholder: t(`${cmsLocation}.middle_name.placeholder`),
    },
    last_name: {
      label: t(`${cmsLocation}.last_name.label`),
      placeholder: t(`${cmsLocation}.last_name.placeholder`),
    },
  };
};

enum inputs {
  first_name = 'first_name',
  middle_name = 'middle_name',
  last_name = 'last_name',
}

interface State {
  [inputs.first_name]: string;
  [inputs.middle_name]: string;
  [inputs.last_name]: string;
}

const name = StepNamesDefaultIdentity.name;

const Name = ({ values, onSubmit, validationRules, ...props }: IdentityQuestionComponentProps) => {
  const [value, setValue] = useAnalyticsValues<State>(
    values[name] || {
      [inputs.first_name]: '',
      [inputs.middle_name]: '',
      [inputs.last_name]: '',
    },
  );
  const [error, setError] = useAnalyticsErrors({
    [inputs.first_name]: '',
    [inputs.middle_name]: '',
    [inputs.last_name]: '',
  });

  const { t } = useTranslation();

  const content = useContent(t);

  const validate = (v: string, rules: ValidationRule[]) => rules.find(({ rule }) => !rule(v));

  const handleSubmit = () => {
    const errors = Object.values(inputs).reduce((e, inputName) => {
      const result = validate(value[inputName], validationRules[inputName]);
      if (result) {
        return { ...e, [inputName]: t(result.error as any) };
      } else {
        return { ...e, [inputName]: '' };
      }
    }, error);

    if (Object.values(errors).filter(e => e !== '').length === 0) {
      onSubmit({ key: name, value });
    } else {
      setError(errors);
    }
  };

  const handleOnChange = (e: React.ChangeEvent) => {
    const target = e.target as HTMLInputElement;

    if (error[target.name as inputs]) {
      const validationRulesForInput = validationRules[target.name];

      const result = validate(target.value.trim(), validationRulesForInput);
      if (result) {
        setError({ ...error, [target.name]: t(result.error as any) });
      } else {
        setError({ ...error, [target.name]: '' });
      }
    }
    setValue({ ...value, [target.name]: target.value.trim() });
  };

  const handleOnBlur = (e: React.FocusEvent) => {
    const target = e.target as HTMLInputElement;
    const validationRulesForInput = validationRules[target.name];
    const result = validate(target.value.trim(), validationRulesForInput);
    if (result) {
      setError({ ...error, [target.name]: t(result.error as any) });
    } else {
      setError({ ...error, [target.name]: '' });
    }
  };

  return (
    <QuestionOrganism
      id={name}
      data-testid={name}
      onSubmit={handleSubmit}
      title={content.title}
      subtitle={<Trans i18nKey={`${cmsLocation}.subtitle` as any} components={{ br: <br /> }} />}
      {...props}
    >
      <Field
        className="mb-4"
        data-testid={inputs.first_name}
        label={content.first_name.label}
        validationError={error[inputs.first_name]}
      >
        <Input
          id={`${name}-${inputs.first_name}`}
          name={inputs.first_name}
          defaultValue={value[inputs.first_name]}
          onChange={handleOnChange}
          aria-invalid={!!error[inputs.first_name]}
          onBlur={handleOnBlur}
          placeholder={content.first_name.placeholder}
          autoComplete="given-name"
        />
      </Field>

      <Field
        className="mb-4"
        data-testid={inputs.middle_name}
        label={content.middle_name.label}
        validationError={error[inputs.middle_name]}
      >
        <Input
          id={`${name}-${inputs.middle_name}`}
          name={inputs.middle_name}
          defaultValue={value[inputs.middle_name]}
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          aria-invalid={!!error[inputs.middle_name]}
          placeholder={content.last_name.placeholder}
          autoComplete="additional-name"
        />
      </Field>

      <Field
        className="mb-4"
        data-testid={inputs.last_name}
        label={content.last_name.label}
        validationError={error[inputs.last_name]}
      >
        <Input
          id={`${name}-${inputs.last_name}`}
          name={inputs.last_name}
          defaultValue={value[inputs.last_name]}
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          aria-invalid={!!error[inputs.last_name]}
          placeholder={content.last_name.placeholder}
          autoComplete="family-name"
        />
      </Field>
    </QuestionOrganism>
  );
};

export default Name;

import { Flow, GeneralSteps, QuestionQueryParams } from '../../types';
import {
  IncomeSectionMetaData,
  StepNamesDefaultIncome,
  whoIsPayingEnum,
  YesNoEnum,
  someoneElseEnum,
  DefaultIncomeQuestionComponentProps,
  DefaultIncomeValuesType,
  incomeConditionTypesEnum,
  incomeStartStepConditionEnum,
  IncomeSearchParams,
} from './types';
import {
  IncomeSourceTypeEnum,
  IncomeSourceType,
  Maybe,
  TimeUnitsEnum,
  AccountDocumentGqlType,
  GqlPaymentFrequencyEnum,
  SectionMetaDataType,
  AccountDocumentType,
} from 'generated/graphql';
import { gql } from '@apollo/client';
import CompanyReference from 'questionFlow/income/CompanyReference';
import CurrentEmployer from 'questionFlow/income/CurrentEmployer';
import SomeoneElse from 'questionFlow/income/SomeoneElse';
import WhoIsPaying from 'questionFlow/income/WhoIsPaying';
import OpenBanking from 'questionFlow/income/OpenBanking';
import Documents from 'questionFlow/income/Documents';
import YourSalary from 'questionFlow/income/YourSalary';
import OBSuccess from 'questionFlow/income/OBSuccess';
import OBError from 'questionFlow/income/OBError';
import validationRules from '../../validations/validationRules';
import {
  COMPANY_NAME_PATTERN,
  COMPANY_NUMBER_LENGTH,
  COMPANY_NUMBER_PATTERN,
  createNoFutureDateRule,
  createNotAfterDateRule,
  createNotBeforeDateRule,
  createNumberRangeValidationRule,
  DATE_FORMAT,
  DATE_REQUIRED,
  DATE_VALID,
  EMAIL_FORMAT,
  FIELD_REQUIRED,
  NAME_LENGTH,
  NAME_PATTERN,
  OPTIONAL_DATE_VALID,
  OPTIONAL_DATE_FORMAT,
  OPTION_REQUIRED,
  PHONE_NUMBER_VALID,
  ALLOWED_OPTION,
} from '../../validations';
import { addMonths, addWeeks, subMonths } from 'date-fns';
import CompanyDetails from 'questionFlow/income/CompanyDetails';
import UploadBankStatements from 'questionFlow/income/UploadBankStatements';
import YourIncome from 'questionFlow/income/YourIncome';
import UploadSingle from 'questionFlow/income/UploadSingle';
import NoDocuments from 'questionFlow/genericQuestions/NoDocuments';
import { MutationUpdateIncomeArgs, MutationMeArgs, UserType } from 'generated/graphql';
import { getIncomeCondition, isRecentStarter } from '../../income/utilities';
import SavingsAmount from 'questionFlow/income/SavingsAmount';
import LoanDetails from 'questionFlow/income/LoanDetails';
import YourLoan from 'questionFlow/income/YourLoan';
import { DocumentTypes } from 'questionFlow/income/utilities/documentOptions';
import IncomeType from 'questionFlow/income/IncomeType';
import YourGuarantor from 'questionFlow/income/yourGuarantor';
import AdditionalIncome from 'questionFlow/income/AdditionalIncome';
import GuarantorConfirmation from 'questionFlow/income/GuarantorConfirmation';
import ZeorPercentNotice from 'questionFlow/income/ZeroPercentNotice';
import { includes } from 'lodash';
import IncomeUploadSuccess from 'questionFlow/income/IncomeUploadSuccess';
import BenefitDetails from 'questionFlow/income/BenefitDetails';
import BenefitAmount from 'questionFlow/income/BenefitAmount';
import PensionDetails from 'questionFlow/income/PensionDetails';
import PensionAmount from 'questionFlow/income/PensionAmount';
import OpenBankingFeedback from 'questionFlow/income/OpenBankingFeedback';
import MissingInformation from 'questionFlow/genericQuestions/MissingInformation';
import { hasDocumentSignposting } from 'questionFlow/genericQuestions/Upload/utils';
import IncomeDocSignposting from 'questionFlow/income/IncomeDocSignposting';
import GenericIncomeDocSignposting from 'questionFlow/income/GenericIncomeDocSignposting';

const gqlQueryFragment = gql`
  fragment defaultIncome on Query {
    me {
      uuid
      email
      guarantor {
        uuid
        email
        firstName
        lastName
        phoneNumber
      }
      income {
        employmentStatus
        meetsAffordabilityCriteria
        incomeSources {
          uuid
          currency
          incomeType
          annualGrossIncome
          netIncome
          netIncomeFrequency
          paymentDate
          companyName
          companyNumber
          jobTitle
          startDate
          endDate
          financialInstitutionDetails {
            name
          }
          isOnProbation
          probation {
            duration
          }
          reference(remedialSectionUuid: $remedialSectionUuid) {
            name
            email
          }
          accountDocuments(remedialSectionUuid: $remedialSectionUuid) {
            id
            type
            path
          }
        }
      }
    }
    sectionMetaData {
      incomeSectionMetaData {
        conditions {
          ... on WizardBooleanConditionType {
            name
            description
            boolValue: value
          }
          ... on WizardIntegerConditionType {
            name
            description
            intValue: value
          }
          ... on WizardStringConditionType {
            name
            description
            strValue: value
          }
          ... on WizardListOfBooleansConditionType {
            name
            description
            listBoolValues: value
          }
          ... on WizardListOfIntegersConditionType {
            name
            description
            listIntValues: value
          }
          ... on WizardListOfStringsConditionType {
            name
            description
            listStrValues: value
          }
        }
      }
    }
  }
`;

const mutation = gql`
  mutation MutateIncomeWithGuarantor(
    $uuid: UUID
    $guarantorEmail: String
    $guarantorFirstName: String
    $guarantorLastName: String
    $guarantorPhoneNumber: String
    $incomeType: IncomeSourceTypeEnum
    $companyName: String
    $companyNumber: String
    $jobTitle: String
    $startDate: Date
    $endDate: Date
    $probationDuration: Int
    $probationDurationUnit: TimeUnitsEnum
    $referenceName: String
    $referenceEmail: String
    $annualGrossIncome: Decimal
    $currency: CurrencyTypesEnum
    $netIncome: Decimal
    $netIncomeFrequency: GQLPaymentFrequencyEnum
    $paymentDate: String
    $accountDocuments: [DocumentInputType]
    $removeProbation: Boolean
    $financialInstitutionName: String
    $remedialSectionUuid: UUID
  ) {
    me(
      guarantorEmail: $guarantorEmail
      guarantorFirstName: $guarantorFirstName
      guarantorLastName: $guarantorLastName
      guarantorPhoneNumber: $guarantorPhoneNumber
    ) {
      guarantor {
        uuid
      }
    }
    updateIncome(
      uuid: $uuid
      incomeType: $incomeType
      companyName: $companyName
      companyNumber: $companyNumber
      jobTitle: $jobTitle
      startDate: $startDate
      endDate: $endDate
      probationDuration: $probationDuration
      probationDurationUnit: $probationDurationUnit
      referenceName: $referenceName
      referenceEmail: $referenceEmail
      annualGrossIncome: $annualGrossIncome
      currency: $currency
      netIncome: $netIncome
      netIncomeFrequency: $netIncomeFrequency
      paymentDate: $paymentDate
      accountDocuments: $accountDocuments
      removeProbation: $removeProbation
      financialInstitutionName: $financialInstitutionName
      remedialSectionUuid: $remedialSectionUuid
    ) {
      meetsAffordabilityCriteria
      incomeSource {
        uuid
      }
    }
  }
`;

const uuidRegex = new RegExp(/\w{8}-(\w{4}-){3}\w{12}/, 'i');
const searchValidationRules = {
  uuid: (uuid: string | null) => !uuid || uuidRegex.test(uuid),
  success: (success: string | null) =>
    success && (success.toLowerCase() === 'true' || success.toLowerCase() === 'false'),
};

const deprecatedIncomeUuidUrlKey = 'income_uuid';
const relatedObjectUuidUrlKey = 'related_object_uuid';

const safelyGetQueryParams = (search: string) => {
  let params = new URLSearchParams(search);
  const uuid = params.get(relatedObjectUuidUrlKey);
  const deprecatedIncomeUuid = params.get(deprecatedIncomeUuidUrlKey);
  const success = params.get('success');
  const queryParams = {
    uuid: searchValidationRules.uuid(uuid) ? uuid : null,
    deprecatedIncomeUuid: searchValidationRules.uuid(deprecatedIncomeUuid)
      ? deprecatedIncomeUuid
      : null,
    success: searchValidationRules.success(success)
      ? success?.toLocaleLowerCase() === 'true'
      : null,
  };
  return queryParams;
};

const checkIfStartStepIsValid = (
  step: StepNamesDefaultIncome,
  values: UserType,
  queryParams: IncomeSearchParams,
) => {
  const incomeSource = findRelevantIncomeSource(values, queryParams);

  switch (step) {
    case StepNamesDefaultIncome.upload:
      return incomeSource?.accountDocuments?.length;
    case StepNamesDefaultIncome.documents:
      return incomeSource?.incomeType;
    case StepNamesDefaultIncome.openBanking:
      return incomeSource?.incomeType;
    default:
      return true;
  }
};

const chooseStartStep = (
  values: UserType,
  queryParams: IncomeSearchParams,
  data: SectionMetaDataType,
) => {
  if (queryParams.uuid || queryParams.deprecatedIncomeUuid) {
    if (queryParams.success) {
      if (values.income?.meetsAffordabilityCriteria) {
        return StepNamesDefaultIncome.success;
      }
      return StepNamesDefaultIncome.successAddIncome;
    }
    if (queryParams.success === false) {
      return StepNamesDefaultIncome.error;
    }
  } else if (values.income?.incomeSources && values.income.incomeSources.length > 0) {
    return StepNamesDefaultIncome.incomeType;
  }

  const conditionStartStep =
    //@ts-ignore
    getIncomeCondition(data, incomeConditionTypesEnum.INCOME_START_STEP)?.strValue;

  const incomeStep = incomeStartStepConditionEnum[conditionStartStep] as StepNamesDefaultIncome;

  const startStepIsValid = incomeStep
    ? checkIfStartStepIsValid(incomeStep, values, queryParams)
    : false;

  return startStepIsValid ? incomeStep : StepNamesDefaultIncome.whoIsPaying;
};

const matchUploadToDocType = (uploads: Maybe<AccountDocumentGqlType>[]) => {
  const type = uploads && uploads.length > 0 ? uploads[0]?.type : null;
  if (includes(DocumentTypes, type)) {
    return type;
  }
};

enum GqlPaymentFrequencyEnumOutput {
  Monthly = 'Monthly',
  Daily = 'Daily',
  Weekly = 'Weekly',
  Weekly_2 = '2 Weeks',
}
const GQLPaymentFrequencyBackwardsCompatabilityLookup = {
  [GqlPaymentFrequencyEnumOutput.Daily]: GqlPaymentFrequencyEnum.Daily,
  [GqlPaymentFrequencyEnumOutput.Weekly]: GqlPaymentFrequencyEnum.Weekly,
  [GqlPaymentFrequencyEnumOutput.Weekly_2]: GqlPaymentFrequencyEnum.Weekly_2,
  [GqlPaymentFrequencyEnumOutput.Monthly]: GqlPaymentFrequencyEnum.Monthly,
};

const isGqlPaymentFrequencyEnumOutput = (v?: Maybe<string>): v is GqlPaymentFrequencyEnumOutput =>
  !!(v && Object.keys(GQLPaymentFrequencyBackwardsCompatabilityLookup).includes(v));

const lookupPaymentFrequencyEnum = (value?: Maybe<string>) =>
  isGqlPaymentFrequencyEnumOutput(value)
    ? GQLPaymentFrequencyBackwardsCompatabilityLookup[value]
    : null;

const findRelevantIncomeSource = (
  values: UserType,
  queryParams: QuestionQueryParams,
): Maybe<IncomeSourceType> => {
  const uuidToMatch = queryParams?.uuid || queryParams?.deprecatedIncomeUuid;

  if (uuidToMatch) {
    const matchingIncomeSource = values.income?.incomeSources?.find(
      source => source.uuid === uuidToMatch,
    );
    if (matchingIncomeSource) {
      return matchingIncomeSource;
    }
  }

  return null;
};

const matchToGuarantor = (values: UserType, queryParams: QuestionQueryParams) => {
  if (queryParams?.uuid && values?.guarantor?.uuid === queryParams.uuid) {
    return values.guarantor;
  }
  return null;
};

type MutationArgsIncome = MutationUpdateIncomeArgs | MutationMeArgs;

const flowDefinition: Flow<
  IncomeSectionMetaData,
  DefaultIncomeValuesType,
  StepNamesDefaultIncome,
  QuestionQueryParams,
  MutationArgsIncome
> = {
  getQueryParams: safelyGetQueryParams,
  start: chooseStartStep,
  queryFragment: gqlQueryFragment,
  uuidKey: relatedObjectUuidUrlKey,
  mutation,
  handleMutationResponse: (data, values) => {
    let update = {};
    if (data?.me?.guarantor?.uuid && data.me.guarantor.uuid !== values?.uuid) {
      update = { ...update, uuid: data.me.guarantor.uuid };
    } else if (
      data.updateIncome?.incomeSource?.uuid &&
      data.updateIncome.incomeSource.uuid !== values?.uuid
    ) {
      update = { ...update, uuid: data.updateIncome.incomeSource.uuid };
    }
    if (data.updateIncome?.meetsAffordabilityCriteria && !values.meetsAffordabilityCriteria) {
      update = {
        ...update,
        meetsAffordabilityCriteria: data.updateIncome.meetsAffordabilityCriteria,
      };
    }
    return update;
  },
  getData: (data, { email }) => {
    return {
      email,
      conditions: data?.incomeSectionMetaData?.conditions,
    };
  },
  getDefaultValues: (values, queryParams) => {
    const incomeSource = findRelevantIncomeSource(values, queryParams);
    const guarantor = values.guarantor;
    matchToGuarantor(values, queryParams);

    let whoIsPaying = '';
    if (incomeSource?.incomeType) {
      if (incomeSource?.incomeType === IncomeSourceTypeEnum.TZeroPercentRentShare) {
        whoIsPaying = whoIsPayingEnum.OTHER;
      } else {
        whoIsPaying = whoIsPayingEnum.ME;
      }
    } else {
      if (matchToGuarantor(values, queryParams)) {
        whoIsPaying = whoIsPayingEnum.OTHER;
      }
    }

    return {
      meetsAffordabilityCriteria: !!values?.income?.meetsAffordabilityCriteria,
      uuid: incomeSource?.uuid || null,
      whoIsPaying,
      incomeType: incomeSource?.incomeType || null,
      currentEmployer: {
        companyName: incomeSource?.companyName || '',
        role: incomeSource?.jobTitle || '',
        startDate: incomeSource?.startDate || '',
        endDate: incomeSource?.endDate || '',
        probationStatus: incomeSource?.probation?.duration
          ? YesNoEnum.YES
          : typeof incomeSource?.isOnProbation === 'boolean'
          ? YesNoEnum.NO
          : '',
        probationLength: incomeSource?.probation?.duration.toString() || '',
      },
      [StepNamesDefaultIncome.savingsAmount]: {
        amount: incomeSource?.annualGrossIncome || '',
        currency: incomeSource?.currency || null,
      },
      [StepNamesDefaultIncome.someoneElse]:
        incomeSource?.incomeType === IncomeSourceTypeEnum.TZeroPercentRentShare
          ? someoneElseEnum.ZEROPERCENTSHARE
          : guarantor?.firstName?.length
          ? someoneElseEnum.GUARANTOR
          : '',
      [StepNamesDefaultIncome.companyReference]: {
        referenceName: incomeSource?.reference?.name?.trimEnd() || '',
        referenceEmail: incomeSource?.reference?.email || '',
      },
      [StepNamesDefaultIncome.pensionDetails]: {
        pensionProvider: incomeSource?.companyName || '',
        startDate: incomeSource?.startDate || '',
      },
      [StepNamesDefaultIncome.pensionAmount]: {
        annualValue: incomeSource?.annualGrossIncome || '',
        currency: incomeSource?.currency || '',
        paymentAmount: incomeSource?.netIncome || '',
        frequency: lookupPaymentFrequencyEnum(incomeSource?.netIncomeFrequency),
        paymentDate: incomeSource?.paymentDate || '',
      },
      [StepNamesDefaultIncome.benefitDetails]: {
        startDate: incomeSource?.startDate || '',
        reviewDate: incomeSource?.endDate || '',
      },
      [StepNamesDefaultIncome.benefitAmount]: {
        annualValue: incomeSource?.annualGrossIncome || '',
        currency: incomeSource?.currency || '',
        paymentAmount: incomeSource?.netIncome || '',
        frequency: lookupPaymentFrequencyEnum(incomeSource?.netIncomeFrequency),
        paymentDate: incomeSource?.paymentDate || '',
      },
      [StepNamesDefaultIncome.companyDetails]: {
        companyName: incomeSource?.companyName || '',
        companyNumber: incomeSource?.companyNumber || '',
        startDate: incomeSource?.startDate || '',
      },
      [StepNamesDefaultIncome.yourIncome]: {
        annualValue: incomeSource?.annualGrossIncome || '',
        paymentAmount: incomeSource?.netIncome || '',
        currency: incomeSource?.currency || null,
        frequency: lookupPaymentFrequencyEnum(incomeSource?.netIncomeFrequency),
        paymentDate: incomeSource?.paymentDate || '',
      },
      [StepNamesDefaultIncome.yourSalary]: {
        annualBaseSalary: incomeSource?.annualGrossIncome || '',
        currency: incomeSource?.currency || null,
        netSalary: incomeSource?.netIncome || '',
        frequency: lookupPaymentFrequencyEnum(incomeSource?.netIncomeFrequency),
        lastPaymentDate: incomeSource?.paymentDate || '',
        bonusStatus: '',
      },
      [StepNamesDefaultIncome.loanDetails]: {
        bodyName: incomeSource?.financialInstitutionDetails?.name || '',
        startDate: incomeSource?.startDate || '',
        endDate: incomeSource?.endDate || '',
      },
      [StepNamesDefaultIncome.yourLoan]: {
        annualValue: incomeSource?.annualGrossIncome || '',
        paymentAmount: incomeSource?.netIncome || '',
        currency: incomeSource?.currency || null,
        frequency: lookupPaymentFrequencyEnum(incomeSource?.netIncomeFrequency),
        paymentDate: incomeSource?.paymentDate || '',
      },
      [StepNamesDefaultIncome.yourGuarantor]: {
        firstName: guarantor?.firstName || '',
        lastName: guarantor?.lastName || '',
        email: guarantor?.email || '',
        phoneNumber: guarantor?.phoneNumber || '',
      },
      [StepNamesDefaultIncome.documents]: matchUploadToDocType(
        incomeSource?.accountDocuments || [],
      ),
      [StepNamesDefaultIncome.upload]: incomeSource?.accountDocuments,
    };
  },
  steps: {
    [StepNamesDefaultIncome.whoIsPaying]: {
      component: WhoIsPaying,
      validationRules: {
        whoIsPaying: [OPTION_REQUIRED],
      },
      chooseSuccessor: ({ value }) => {
        return value === whoIsPayingEnum.ME
          ? StepNamesDefaultIncome.incomeType
          : StepNamesDefaultIncome.someoneElse;
      },
    },
    [StepNamesDefaultIncome.incomeType]: {
      component: IncomeType,
      validationRules: {
        incomeType: [OPTION_REQUIRED],
      },
      getMutationValues: (values: DefaultIncomeValuesType) => {
        return {
          incomeType: values.incomeType,
          uuid: values.uuid,
        };
      },
      chooseSuccessor: ({ value }) => {
        switch (value) {
          case IncomeSourceTypeEnum.TScholarshipLoans: {
            return StepNamesDefaultIncome.loanDetails;
          }
          case IncomeSourceTypeEnum.TSavings: {
            return StepNamesDefaultIncome.savingsAmount;
          }
          case IncomeSourceTypeEnum.TSelfemployed: {
            return StepNamesDefaultIncome.companyDetails;
          }
          case IncomeSourceTypeEnum.TBenfits: {
            return StepNamesDefaultIncome.benefitDetails;
          }
          case IncomeSourceTypeEnum.TPension: {
            return StepNamesDefaultIncome.pensionDetails;
          }
          default: {
            return StepNamesDefaultIncome.currentEmployer;
          }
        }
      },
    },
    [StepNamesDefaultIncome.someoneElse]: {
      component: SomeoneElse,
      validationRules: {
        someoneElse: [OPTION_REQUIRED],
      },
      getMutationValues: values => {
        if (values.someoneElse === someoneElseEnum.ZEROPERCENTSHARE) {
          return {
            uuid: values.uuid,
            incomeType: IncomeSourceTypeEnum.TZeroPercentRentShare,
          };
        }
      },
      chooseSuccessor: ({ value }) => {
        return value === someoneElseEnum.ZEROPERCENTSHARE
          ? StepNamesDefaultIncome.zeroPercentNotice
          : StepNamesDefaultIncome.yourGuarantor;
      },
    },
    [StepNamesDefaultIncome.currentEmployer]: {
      component: CurrentEmployer,
      validationRules: {
        companyName: [FIELD_REQUIRED, NAME_LENGTH, COMPANY_NAME_PATTERN],
        role: [FIELD_REQUIRED, NAME_LENGTH, NAME_PATTERN],
        startDate: [
          DATE_REQUIRED,
          DATE_FORMAT,
          DATE_VALID,
          createNotAfterDateRule(
            addWeeks(new Date(), 7),
            '/questions/income/currentEmployer.validation.job_start_too_far',
          ),
        ],
        endDate: [
          OPTIONAL_DATE_FORMAT,
          OPTIONAL_DATE_VALID,
          createNotBeforeDateRule(
            new Date(),
            '/questions/income/currentEmployer.validation.job_already_ended',
            true,
          ),
        ],
        probationStatus: [FIELD_REQUIRED],
        probationLength: [
          FIELD_REQUIRED,
          createNumberRangeValidationRule(
            0,
            13,
            '/questions/income/currentEmployer.validation.probation_length',
          ),
        ],
      },
      getMutationValues: ({ currentEmployer, uuid }) => ({
        uuid: uuid,
        companyName: currentEmployer.companyName,
        startDate: currentEmployer.startDate,
        endDate: currentEmployer.endDate || null,
        removeProbation: currentEmployer.probationStatus === YesNoEnum.NO,
        probationDuration:
          currentEmployer.probationStatus === YesNoEnum.YES
            ? parseInt(currentEmployer.probationLength, 10)
            : null,
        probationDurationUnit:
          currentEmployer.probationStatus === YesNoEnum.YES ? TimeUnitsEnum.Months : null,
        jobTitle: currentEmployer.role,
      }),
      chooseSuccessor: _ => StepNamesDefaultIncome.yourSalary,
    },
    [StepNamesDefaultIncome.loanDetails]: {
      component: LoanDetails,
      validationRules: {
        bodyName: [FIELD_REQUIRED, NAME_LENGTH, COMPANY_NAME_PATTERN],
        startDate: [DATE_REQUIRED, DATE_FORMAT, DATE_VALID],
        endDate: [
          DATE_REQUIRED,
          DATE_FORMAT,
          DATE_VALID,
          createNotBeforeDateRule(
            new Date(),
            '/questions/income/loanDetails.validation.past_end_date',
          ),
        ],
      },
      getMutationValues: ({ loanDetails, uuid }) => ({
        uuid,
        financialInstitutionName: loanDetails.bodyName,
        startDate: loanDetails.startDate,
        endDate: loanDetails.endDate,
      }),
      chooseSuccessor: _ => StepNamesDefaultIncome.yourLoan,
    },
    [StepNamesDefaultIncome.yourLoan]: {
      component: YourLoan,
      validationRules: {
        annualValue: [
          FIELD_REQUIRED,
          createNumberRangeValidationRule(
            0,
            1000000,
            '/questions/income/yourLoan.validation.annual_value',
          ),
        ],
        paymentAmount: [
          FIELD_REQUIRED,
          createNumberRangeValidationRule(
            0,
            1000000,
            '/questions/income/yourLoan.validation.instalment_amount',
          ),
        ],
        currency: [FIELD_REQUIRED],
        frequency: [FIELD_REQUIRED],
        paymentDate: [
          DATE_REQUIRED,
          DATE_FORMAT,
          DATE_VALID,
          createNotBeforeDateRule(
            subMonths(new Date(), 6),
            '/questions/income/yourLoan.validation.most_recent_instalment_date',
          ),
        ],
      },
      chooseSuccessor: _ => StepNamesDefaultIncome.documents,
      getMutationValues: ({ uuid, yourLoan }) => ({
        uuid,
        annualGrossIncome: yourLoan.annualValue,
        currency: yourLoan.currency,
        netIncomeFrequency: yourLoan.frequency,
        paymentDate: yourLoan.paymentDate,
        netIncome: yourLoan.paymentAmount || null,
      }),
    },
    [StepNamesDefaultIncome.pensionDetails]: {
      component: PensionDetails,
      validationRules: {
        pensionProvider: [FIELD_REQUIRED, NAME_LENGTH, COMPANY_NAME_PATTERN],
        startDate: [
          DATE_REQUIRED,
          DATE_FORMAT,
          DATE_VALID,
          createNotAfterDateRule(
            addMonths(new Date(), 1),
            '/questions/income/pensionDetails.validation.start_date',
          ),
        ],
      },
      chooseSuccessor: _ => StepNamesDefaultIncome.pensionAmount,
      getMutationValues: ({ pensionDetails, uuid }) => ({
        uuid,
        companyName: pensionDetails.pensionProvider,
        startDate: pensionDetails.startDate,
      }),
    },
    [StepNamesDefaultIncome.pensionAmount]: {
      component: PensionAmount,
      validationRules: {
        annualValue: [
          FIELD_REQUIRED,
          createNumberRangeValidationRule(
            0,
            10000000,
            '/questions/income/pensionAmount.validation.annual_range',
          ),
        ],
        currency: [FIELD_REQUIRED],
        paymentAmount: [
          FIELD_REQUIRED,
          createNumberRangeValidationRule(
            0,
            10000000,
            '/questions/income/pensionAmount.validation.average_range',
          ),
        ],
        frequency: [FIELD_REQUIRED],
        paymentDate: [
          DATE_REQUIRED,
          DATE_FORMAT,
          DATE_VALID,
          createNoFutureDateRule('/questions/income/yourSalary.validation.payment_date'),
          createNotBeforeDateRule(
            subMonths(new Date(), 6),
            '/questions/income/pensionAmount.validation.payment_date',
          ),
        ],
      },
      chooseSuccessor: _ => StepNamesDefaultIncome.openBanking,
      getMutationValues: ({ pensionAmount, uuid }) => ({
        uuid,
        annualGrossIncome: pensionAmount.annualValue,
        currency: pensionAmount.currency,
        netIncome: pensionAmount.paymentAmount || null,
        netIncomeFrequency: pensionAmount.frequency,
        paymentDate: pensionAmount.paymentDate,
      }),
    },
    [StepNamesDefaultIncome.benefitDetails]: {
      component: BenefitDetails,
      validationRules: {
        startDate: [
          DATE_REQUIRED,
          DATE_FORMAT,
          DATE_VALID,
          createNoFutureDateRule('/questions/income/benefitDetails.validation.start_date'),
        ],
        reviewDate: [
          DATE_REQUIRED,
          DATE_FORMAT,
          DATE_VALID,
          createNotBeforeDateRule(
            new Date(),
            '/questions/income/benefitDetails.validation.review_date',
          ),
        ],
      },
      chooseSuccessor: _ => StepNamesDefaultIncome.benefitAmount,
      getMutationValues: ({ benefitDetails, uuid }) => ({
        uuid,
        startDate: benefitDetails.startDate,
        endDate: benefitDetails.reviewDate,
      }),
    },
    [StepNamesDefaultIncome.benefitAmount]: {
      component: BenefitAmount,
      validationRules: {
        annualValue: [
          FIELD_REQUIRED,
          createNumberRangeValidationRule(
            0,
            10000000,
            '/questions/income/benefitAmount.validation.annual_range',
          ),
        ],
        currency: [FIELD_REQUIRED],
        paymentAmount: [
          FIELD_REQUIRED,
          createNumberRangeValidationRule(
            0,
            10000000,
            '/questions/income/benefitAmount.validation.average_range',
          ),
        ],
        frequency: [FIELD_REQUIRED],
        paymentDate: [
          DATE_REQUIRED,
          DATE_FORMAT,
          DATE_VALID,
          createNoFutureDateRule('/questions/income/yourSalary.validation.payment_date'),
          createNotBeforeDateRule(
            subMonths(new Date(), 6),
            '/questions/income/benefitAmount.validation.payment_date',
          ),
        ],
      },
      getMutationValues: ({ benefitAmount, uuid }) => ({
        uuid,
        annualGrossIncome: benefitAmount.annualValue,
        currency: benefitAmount.currency,
        netIncome: benefitAmount.paymentAmount || null,
        netIncomeFrequency: benefitAmount.frequency,
        paymentDate: benefitAmount.paymentDate,
      }),
      chooseSuccessor: _ => StepNamesDefaultIncome.openBanking,
    },
    [StepNamesDefaultIncome.companyDetails]: {
      component: CompanyDetails,
      validationRules: {
        companyName: [FIELD_REQUIRED, NAME_LENGTH, COMPANY_NAME_PATTERN],
        companyNumber: [COMPANY_NUMBER_LENGTH, COMPANY_NUMBER_PATTERN],
        startDate: [DATE_REQUIRED, DATE_FORMAT, DATE_VALID],
      },
      chooseSuccessor: _ => StepNamesDefaultIncome.yourIncome,
      getMutationValues: ({ companyDetails, uuid }) => ({
        uuid,
        companyName: companyDetails.companyName,
        companyNumber: companyDetails.companyNumber,
        startDate: companyDetails.startDate,
      }),
    },
    [StepNamesDefaultIncome.companyReference]: {
      component: CompanyReference,
      validationRules: {
        referenceName: [FIELD_REQUIRED, NAME_LENGTH, NAME_PATTERN],
        referenceEmail: [FIELD_REQUIRED, EMAIL_FORMAT],
      },
      getMutationValues: ({ companyReference, uuid }, remedialSectionUuid) => ({
        uuid: uuid,
        referenceName: companyReference.referenceName,
        referenceEmail: companyReference.referenceEmail,
        remedialSectionUuid,
      }),
      chooseSuccessor: ({ values }) => {
        return isRecentStarter(values.currentEmployer.startDate)
          ? StepNamesDefaultIncome.documents
          : StepNamesDefaultIncome.openBanking;
      },
    },
    [StepNamesDefaultIncome.savingsAmount]: {
      component: SavingsAmount,
      validationRules: {
        amount: [
          FIELD_REQUIRED,
          createNumberRangeValidationRule(
            0,
            100000000,
            '/questions/income/savingsAmount.validation.amount_range',
          ),
        ],
        currency: [FIELD_REQUIRED],
      },
      chooseSuccessor: () => StepNamesDefaultIncome.openBanking,
      getMutationValues: ({ savingsAmount, uuid }) => ({
        uuid,
        annualGrossIncome: savingsAmount.amount,
        currency: savingsAmount.currency,
      }),
    },
    [StepNamesDefaultIncome.yourSalary]: {
      component: YourSalary,
      validationRules: {
        annualBaseSalary: [
          FIELD_REQUIRED,
          createNumberRangeValidationRule(
            0,
            10000000,
            '/questions/income/yourSalary.validation.annual_range',
          ),
        ],
        currency: [FIELD_REQUIRED],
        netSalary: [
          FIELD_REQUIRED,
          createNumberRangeValidationRule(
            0,
            10000000,
            '/questions/income/yourSalary.validation.net_range',
          ),
        ],
        frequency: [FIELD_REQUIRED],
        lastPaymentDate: [
          DATE_REQUIRED,
          DATE_FORMAT,
          DATE_VALID,
          createNoFutureDateRule('/questions/income/yourSalary.validation.payment_date'),
          createNotBeforeDateRule(
            subMonths(new Date(), 3),
            '/questions/income/yourSalary.validation.payment_date',
          ),
        ],
        bonusStatus: [FIELD_REQUIRED],
      },
      chooseSuccessor: () => StepNamesDefaultIncome.companyReference,
      getMutationValues: ({ yourSalary, uuid }) => ({
        uuid,
        annualGrossIncome: yourSalary.annualBaseSalary,
        currency: yourSalary.currency,
        netIncome: yourSalary.netSalary || null,
        netIncomeFrequency: yourSalary.frequency,
        paymentDate: yourSalary.lastPaymentDate,
      }),
    },
    [StepNamesDefaultIncome.openBanking]: {
      component: OpenBanking,
      validationRules: {},
      chooseSuccessor: _ => StepNamesDefaultIncome.feedback,
    },
    [StepNamesDefaultIncome.feedback]: {
      component: OpenBankingFeedback,
      validationRules: {
        reason: [OPTION_REQUIRED],
      },
      chooseSuccessor: _ => StepNamesDefaultIncome.documents,
    },
    [StepNamesDefaultIncome.documents]: {
      component: Documents,
      validationRules: {
        documents: [OPTION_REQUIRED, ALLOWED_OPTION],
      },
      chooseSuccessor: ({ value }) => {
        if (value === 'none') {
          return StepNamesDefaultIncome.noDocumentsNotice;
        }
        if (value === AccountDocumentType.BankStatement) {
          return StepNamesDefaultIncome.bankStatementSignposting;
        }
        if (typeof value === 'string' && hasDocumentSignposting(value)) {
          return StepNamesDefaultIncome.documentSignposting;
        }
        return StepNamesDefaultIncome.upload;
      },
    },
    [StepNamesDefaultIncome.upload]: {
      component: UploadSingle,
      validationRules: {
        upload: [
          {
            name: 'required',
            rule: validationRules.required,
            error: 'validation.required',
          },
        ],
      },
      setFlowCompleted: true,
      chooseSuccessor: ({ values: { upload, meetsAffordabilityCriteria } }) => {
        if (meetsAffordabilityCriteria && !upload?.length) {
          return;
        }
        return meetsAffordabilityCriteria
          ? StepNamesDefaultIncome.incomeUploadSuccess
          : StepNamesDefaultIncome.additionalIncome;
      },
      getMutationValues: ({ upload, uuid }) => ({
        uuid,
        accountDocuments: upload?.map(({ __typename, ...doc }) => doc),
      }),
    },
    [StepNamesDefaultIncome.bankStatementSignposting]: {
      component: IncomeDocSignposting,
      validationRules: {
        agreement: [
          {
            name: 'required',
            rule: validationRules.required,
            error: 'validation.required',
          },
        ],
      },
      chooseSuccessor: _ => StepNamesDefaultIncome.uploadBankStatements,
    },
    [StepNamesDefaultIncome.documentSignposting]: {
      component: IncomeDocSignposting,
      validationRules: {
        agreement: [FIELD_REQUIRED],
      },
      chooseSuccessor: _ => StepNamesDefaultIncome.upload,
    },
    [StepNamesDefaultIncome.uploadBankStatements]: {
      component: UploadBankStatements,
      validationRules: {
        upload: [
          {
            name: 'required',
            rule: validationRules.required,
            error: 'validation.required',
          },
        ],
      },
      getMutationValues: ({ upload, uuid }) => ({
        uuid,
        accountDocuments: upload?.map(({ __typename, ...doc }) => doc),
      }),
      setFlowCompleted: true,
      chooseSuccessor: ({ values: { upload, meetsAffordabilityCriteria } }) => {
        if (meetsAffordabilityCriteria && !upload?.length) {
          return;
        }
        return meetsAffordabilityCriteria
          ? StepNamesDefaultIncome.incomeUploadSuccess
          : StepNamesDefaultIncome.additionalIncome;
      },
    },
    [GeneralSteps.genericUpload]: {
      component: GenericIncomeDocSignposting,
      validationRules: {
        agreement: [FIELD_REQUIRED],
      },
      chooseSuccessor: () => StepNamesDefaultIncome.upload,
    },
    [StepNamesDefaultIncome.noDocumentsNotice]: {
      component: (props: DefaultIncomeQuestionComponentProps) => (
        <NoDocuments
          cmsLocation="/questions/income/noDocumentsNotice"
          stepName="noDocumentsNotice"
          {...props}
        />
      ),
      validationRules: {
        upload: [
          {
            name: 'required',
            rule: validationRules.required,
            error: 'validation.required',
          },
        ],
      },
    },
    [StepNamesDefaultIncome.yourIncome]: {
      component: YourIncome,
      validationRules: {
        annualValue: [
          FIELD_REQUIRED,
          createNumberRangeValidationRule(
            0,
            10000000,
            '/questions/income/yourIncome.validation.annual_range',
          ),
        ],
        currency: [FIELD_REQUIRED],
        paymentAmount: [
          FIELD_REQUIRED,
          createNumberRangeValidationRule(
            0,
            10000000,
            '/questions/income/yourIncome.validation.average_range',
          ),
        ],
        frequency: [FIELD_REQUIRED],
        paymentDate: [
          DATE_REQUIRED,
          DATE_FORMAT,
          DATE_VALID,
          createNoFutureDateRule('/questions/income/yourSalary.validation.payment_date'),
          createNotBeforeDateRule(
            subMonths(new Date(), 3),
            '/questions/income/yourSalary.validation.payment_date',
          ),
        ],
      },
      chooseSuccessor: _ => StepNamesDefaultIncome.openBanking,
      getMutationValues: ({ yourIncome, uuid }) => ({
        uuid,
        annualGrossIncome: yourIncome.annualValue,
        currency: yourIncome.currency,
        netIncome: yourIncome.paymentAmount || null,
        netIncomeFrequency: yourIncome.frequency,
        paymentDate: yourIncome.paymentDate,
      }),
    },
    [StepNamesDefaultIncome.additionalIncome]: {
      component: AdditionalIncome,
      validationRules: {},
    },
    [StepNamesDefaultIncome.incomeUploadSuccess]: {
      component: IncomeUploadSuccess,
      validationRules: {},
    },
    [StepNamesDefaultIncome.yourGuarantor]: {
      component: YourGuarantor,
      validationRules: {
        firstName: [FIELD_REQUIRED, NAME_LENGTH, NAME_PATTERN],
        lastName: [FIELD_REQUIRED, NAME_LENGTH, NAME_PATTERN],
        email: [FIELD_REQUIRED, EMAIL_FORMAT],
        phoneNumber: [FIELD_REQUIRED, PHONE_NUMBER_VALID],
      },
      chooseSuccessor: _ => StepNamesDefaultIncome.guarantorConfirmation,
      getMutationValues: ({ yourGuarantor }) => ({
        guarantorFirstName: yourGuarantor?.firstName,
        guarantorLastName: yourGuarantor?.lastName,
        guarantorEmail: yourGuarantor?.email,
        guarantorPhoneNumber: yourGuarantor?.phoneNumber,
      }),
    },
    [StepNamesDefaultIncome.guarantorConfirmation]: {
      component: GuarantorConfirmation,
      validationRules: {},
    },
    [StepNamesDefaultIncome.zeroPercentNotice]: {
      component: ZeorPercentNotice,
      validationRules: {
        someoneElse: [OPTION_REQUIRED],
      },
    },
    [StepNamesDefaultIncome.success]: {
      component: OBSuccess,
      validationRules: {},
    },
    [StepNamesDefaultIncome.successAddIncome]: {
      component: OBSuccess,
      validationRules: {},
      chooseSuccessor: _ => StepNamesDefaultIncome.additionalIncome,
      getMutationValues: () => ({}),
      setFlowCompleted: true,
    },
    [StepNamesDefaultIncome.error]: {
      component: OBError,
      validationRules: {},
      chooseSuccessor: ({ value }) => value as StepNamesDefaultIncome,
    },
    [GeneralSteps.missingInformation]: {
      component: MissingInformation,
      validationRules: {},
      chooseSuccessor: ({ data }) => data.remedialSection?.start as StepNamesDefaultIncome,
    },
  },
};

export default flowDefinition;

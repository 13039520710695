import { useTranslation } from 'react-i18next';
import OnboardingCard from '../OnboardingCard';
import IdentitySvg from './identity.svg';
import { IdentificationIcon, HashtagIcon, DocumentTextIcon } from '@heroicons/react/outline';
import DocumentsList from '../DocumentsList';
import CarSvg from './CarSvg';

export interface IdentityPageProps {
  index: number;
  RTR?: boolean;
  stepsComponent: React.ReactNode;
  onClick: () => void;
}

const IdentityPage = ({ RTR, stepsComponent, index, onClick }: IdentityPageProps) => {
  const { t } = useTranslation();

  const documents = RTR
    ? [
        {
          name: t('onboarding.identity.passport_rtr'),
          Icon: IdentificationIcon,
        },
        {
          name: t('onboarding.identity.passport'),
          note: t('onboarding.identity.share_code_note'),
          extra: t('onboarding.identity.share_code'),
          Icon: HashtagIcon,
        },
      ]
    : [
        {
          name: t('onboarding.identity.passport'),
          Icon: IdentificationIcon,
        },
        {
          name: t('onboarding.identity.driving_license'),
          Icon: CarSvg,
        },
        {
          name: t('onboarding.identity.identity_card'),
          note: t('onboarding.identity.identity_card_note'),
          Icon: DocumentTextIcon,
        },
      ];

  return (
    <OnboardingCard
      title={t('onboarding.identity.title')}
      imgSrc={IdentitySvg}
      buttonProps={{
        text: t('onboarding.identity.submit_button'),
        onClick,
      }}
      steps={stepsComponent}
      index={index}
    >
      <DocumentsList
        description={
          RTR ? t('onboarding.identity.description_rtr') : t('onboarding.identity.description')
        }
        documents={documents}
      />
    </OnboardingCard>
  );
};

export default IdentityPage;

import clsx from 'clsx';
import React from 'react';
import { ReactNode } from 'react';

interface AcceptsClass {
  className?: string;
}

interface Props {
  children?: ReactNode | undefined;
}

const CenteredCard = ({ children }: Props) => (
  <div
    className={clsx('absolute inset-0 bg-grey-1 flex items-center content-center justify-center')}
  >
    <div
      className={clsx(
        'flex-auto self-stretch md:flex-initial md:self-center',
        'bg-white md:w-[848px] md:h-[747px]',
        'flex flex-col items-center justify-between',
      )}
    >
      {children}
    </div>
  </div>
);

const Header: React.FC<AcceptsClass> = ({ className, ...props }) => (
  <header className={clsx('self-stretch flex-initial', className)} {...props} />
);
const Body: React.FC<AcceptsClass> = ({ className, ...props }) => (
  <main className={clsx('self-auto flex-initial box-border', className)} {...props} />
);
const Footer: React.FC<AcceptsClass> = ({ className, ...props }) => (
  <footer
    className={clsx('self-stretch flex-initial bg-light-blue-1 md:bg-white', className)}
    {...props}
  />
);

CenteredCard.Header = Header;
CenteredCard.Body = Body;
CenteredCard.Footer = Footer;

export default CenteredCard;

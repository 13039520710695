import { DateInput, DropDown, Field, Input } from 'components';
import { OnDateChange } from 'components/molecules/DateOfBirthInput/DateOfBirthInput';
import { ResidentialHistoryType } from 'generated/graphql';
import { useAnalyticsErrors, useAnalyticsValues } from 'monitoring/analyticsHooks';
import {
  DefaultResidenceQuestionComponentProps,
  DefaultResidenceValuesType,
  StepNamesDefaultResidence,
} from 'questionFlow/flows/defaultResidence/types';
import useCurrencyCodeOptions from 'questionFlow/genericQuestions/useCurrencyCodeOptions';
import QuestionOrganism from 'questionFlow/QuestionOrganism';
import { ValidationRule } from 'questionFlow/types';
import { TFunction, useTranslation } from 'react-i18next';
import { useRentFrequencyOptions } from './useRentFrequencyOptions';
const cmsLocation = '/questions/residence/rental-details';

const useContent = (t: TFunction): Record<string, any> => ({
  title: t(`${cmsLocation}.title`),
  subtitle: t(`${cmsLocation}.subtitle`),
  rentFrequency: {
    label: t(`${cmsLocation}.rentFrequency.label`),
    placeholder: t(`${cmsLocation}.rentFrequency.placeholder`),
  },
  rent: {
    label: t(`${cmsLocation}.rent.label`),
    placeholder: t(`${cmsLocation}.rent.placeholder`),
  },
  rentPaymentDate: {
    label: t(`${cmsLocation}.rentPaymentDate.label`),
    dayInputLabel: t(`${cmsLocation}.rentPaymentDate.dayInputLabel`),
    monthInputLabel: t(`${cmsLocation}.rentPaymentDate.monthInputLabel`),
    yearInputLabel: t(`${cmsLocation}.rentPaymentDate.yearInputLabel`),
  },
  currency: {
    label: t(`${cmsLocation}.currency.label`),
    placeholder: t(`${cmsLocation}.currency.placeholder`),
  },
});

enum fieldEnums {
  rent = 'rent',
  rentFrequency = 'rentFrequency',
  rentPaymentDate = 'rentPaymentDate',
  currency = 'currency',
}

const initialState = {
  [fieldEnums.rent]: '',
  [fieldEnums.rentFrequency]: '',
  [fieldEnums.rentPaymentDate]: '',
  [fieldEnums.currency]: '',
};

const getSelectedResidentialHistory = (values: DefaultResidenceValuesType) => {
  if (values[StepNamesDefaultResidence.selectAddress]) {
    return values[StepNamesDefaultResidence.selectAddress];
  }
  if (
    values[StepNamesDefaultResidence.residentialHistory] &&
    (values[StepNamesDefaultResidence.residentialHistory] as ResidentialHistoryType[]).length === 1
  ) {
    return (values[StepNamesDefaultResidence.residentialHistory] as ResidentialHistoryType[])[0];
  }
  return {};
};

const RentalDetails = ({
  values,
  validationRules,
  onSubmit,
  ...props
}: DefaultResidenceQuestionComponentProps) => {
  const { t } = useTranslation();
  const content = useContent(t);
  const [rentalDetails, setRentalDetails] = useAnalyticsValues({
    ...initialState,
    ...getSelectedResidentialHistory(values),
  });
  const [errors, setErrors] = useAnalyticsErrors<Record<keyof typeof initialState, string>>(
    initialState,
  );
  const currencyOptions = useCurrencyCodeOptions();
  const frequencyOptions = useRentFrequencyOptions();

  const validate = (v: string = '', rules: ValidationRule[], comparator?: any) =>
    rules.find(({ rule }) => !rule(v, comparator));

  const validateAndSetError = (key: string, newValue: string) => {
    const validationResult = validate(newValue, validationRules[key]);

    if (validationResult) {
      setErrors({ ...errors, [key]: t(validationResult.error as any) });
    } else {
      setErrors({ ...errors, [key]: '' });
    }
  };

  const handleSelect = (key: fieldEnums) => (newValue: string) => {
    if (errors[key]) {
      validateAndSetError(key, newValue);
    }
    setRentalDetails({ ...rentalDetails, [key]: newValue });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (errors.rent) {
      validateAndSetError(fieldEnums.rent, e.target.value);
    }
    setRentalDetails({ ...rentalDetails, [fieldEnums.rent]: e.target.value });
  };

  const handleDateInputChange: OnDateChange = ({ value, valid }) => {
    if (errors.rentPaymentDate && valid) {
      setErrors({ ...errors, [fieldEnums.rentPaymentDate]: '' });
    }
    setRentalDetails({ ...rentalDetails, [fieldEnums.rentPaymentDate]: value });
  };

  const handleBlur = (key: fieldEnums) => {
    validateAndSetError(key, rentalDetails[key]);
  };

  const handleSubmit = () => {
    const errorsResult = Object.values(fieldEnums).reduce((result, key) => {
      const validationResult = validate(
        rentalDetails[key],
        validationRules[key],
        frequencyOptions.map(({ value }) => value),
      );

      if (validationResult) {
        return { ...result, [key]: t(validationResult.error as any) };
      }
      return result;
    }, initialState);

    if (Object.values(errorsResult).filter(e => e !== '').length === 0) {
      onSubmit({ key: StepNamesDefaultResidence.selectAddress, value: rentalDetails });
    } else {
      setErrors(errorsResult);
    }
  };

  return (
    <QuestionOrganism
      id="rental-details"
      data-testid="rental-details"
      onSubmit={handleSubmit}
      title={content.title}
      subtitle={content.subtitle}
      {...props}
    >
      <Field
        className="mb-2"
        data-testid="rent-frequency"
        label={content.rentFrequency.label}
        validationError={errors.rentFrequency}
      >
        <DropDown
          placeholder={content.rentFrequency.placeholder}
          options={frequencyOptions}
          onSelectValue={handleSelect(fieldEnums.rentFrequency)}
          value={rentalDetails.rentFrequency || ''}
          error={!!errors.rentFrequency}
          label={content.rentFrequency.label}
        />
      </Field>

      <div className="grid w-max gap-x-10 grid-cols-1 md:grid-cols-2">
        <Field
          className="mb-2"
          data-testid="rent"
          label={content.rent.label}
          validationError={errors.rent}
        >
          <Input
            id="rent"
            inputMode="numeric"
            type="number"
            blockNegative={true}
            value={rentalDetails.rent || ''}
            onChange={handleChange}
            onBlur={() => handleBlur(fieldEnums.rent)}
            placeholder={content.rent.placeholder}
            aria-invalid={!!errors.rent}
          />
        </Field>

        <Field
          className="mb-2"
          data-testid="currency"
          label={content.currency.label}
          validationError={errors.currency}
        >
          <DropDown
            size="sm"
            placeholder={content.currency.placeholder}
            options={currencyOptions}
            onSelectValue={handleSelect(fieldEnums.currency)}
            value={rentalDetails.currency || ''}
            error={!!errors.currency}
            label={content.currency.label}
          />
        </Field>
      </div>

      <Field
        className="mb-2"
        data-testid="rent-payment-date"
        label={content.rentPaymentDate.label}
        validationError={errors.rentPaymentDate}
      >
        <DateInput
          aria-invalid={!!errors.rentPaymentDate}
          data-testid="rent-payment-date-date-input"
          onChange={handleDateInputChange}
          onBlur={() => handleBlur(fieldEnums.rentPaymentDate)}
          defaultValue={rentalDetails.rentPaymentDate || ''}
          error={!!errors.rentPaymentDate}
          id="rent-payment-date"
          dayLabel={content.rentPaymentDate.dayInputLabel}
          monthLabel={content.rentPaymentDate.monthInputLabel}
          yearLabel={content.rentPaymentDate.yearInputLabel}
        />
      </Field>
    </QuestionOrganism>
  );
};

export default RentalDetails;

import { PostcoderAddress } from './postcoderApi';
import UKAddressAutoComplete from './UKAddressAutoComplete';
import InternationalAddressAutoComplete from './InternationalAddressAutoComplete';
interface Props {
  id: string;
  onSelectAddress: (address: PostcoderAddress) => void;
  label: string;
  placeholder: string;
  countryCode?: string;
  className?: string;
}

const AddressAutoComplete = ({ countryCode, ...props }: Props) => {
  return countryCode && countryCode !== 'GB' ? (
    <InternationalAddressAutoComplete {...props} countryCode={countryCode} />
  ) : (
    <UKAddressAutoComplete {...props} />
  );
};

export default AddressAutoComplete;

const validationRules = {
  required: (value: any) => !!value,
  gt: (min: number) => (value: number) => value > min,
  gte: (min: number) => (value: number) => value >= min,
  lt: (max: number) => (value: number) => value < max,
  lte: (max: number) => (value: number) => value <= max,
  minLength: (minLengthInc: number) => (value: string) => value.length >= minLengthInc,
  maxLength: (maxLengthInc: number) => (value: string) => value.length <= maxLengthInc,
  pattern: (pattern: RegExp) => (value: string) => pattern.test(value),
  alphaNumericPattern: (value: string) => /^[a-zA-Z0-9 ]*$/.test(value),
  unicodePattern: (value: string) => /^[\p{L}']{1}[ \p{L}'-.]*[\p{L}.]{1}$/u.test(value),
  unicodeNumericAndSpecialPattern: (value: string) =>
    /^[\p{L}'0-9]{1}[ \p{L}'-.0-9/]*$/u.test(value),
  ukPostcode: (value: string) => /^[a-z]{1}[a-z\d]{1,3}[ ]?\d{1}[a-z]{2}$/i.test(value),
  genericPostZipCode: (value: string) => /^[a-z\d-]{2,10}$/i.test(value),
};

export default validationRules;

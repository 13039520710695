import React from 'react';
import clsx from 'clsx';
import { ArrowRightIcon, CheckCircleIcon } from '@heroicons/react/outline';
import { useTranslatedValues } from 'hooks/useTranslatedValues';

export interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  children?: React.ReactNode;
  name: string;
  startedDtm?: boolean;
  completedDtm?: boolean;
  submittedDtm?: boolean;
  wasSubmitted?: boolean;
  subtitle?: string;
  optional?: boolean;
}
const translations = {
  todo: '/dashboard.todo',
  inProgress: '/dashboard.inProgress',
  completed: '/dashboard.completed',
  submittedLabel: '/dashboard.submittedLabel',
  income: '/questions.sections.income',
  addIncome: '/dashboard.addIncome',
  additionalIncome: '/dashboard.additionalIncome',
} as const;

interface ProgressStateParams extends Partial<Props> {
  content: { [key: string]: string };
}

const getProgressState = ({
  startedDtm,
  completedDtm,
  submittedDtm,
  optional,
  content,
}: ProgressStateParams) => {
  if (submittedDtm) {
    return {
      title: content.submittedLabel,
      pillColor: 'bg-green-1',
    };
  }
  if (completedDtm) {
    return {
      title: content.completed,
      pillColor: 'bg-green-1',
    };
  }

  if (startedDtm) {
    return {
      title: content.inProgress,
      pillColor: 'bg-pink-1',
    };
  }

  if (optional) {
    return {
      title: content.addIncome,
      pillColor: 'bg-light-blue-1',
    };
  }

  return {
    title: content.todo,
    pillColor: 'bg-grey-1',
  };
};

const SectionCard = ({
  className,
  children,
  name,
  startedDtm,
  completedDtm,
  submittedDtm,
  wasSubmitted,
  subtitle,
  optional,
  ...props
}: Props) => {
  const content = useTranslatedValues(translations);

  const { title, pillColor } = getProgressState({
    startedDtm,
    completedDtm,
    submittedDtm,
    optional,
    content,
  });

  return (
    <button
      {...props}
      disabled={!!submittedDtm || wasSubmitted}
      aria-label={`${name} - ${title}`}
      data-testid="section-card"
      className={clsx(
        'disabled:cursor-not-allowed disabled:opacity-60 border border-grey-3 py-6 px-4 flex flex-row items-center justify-between w-full rounded',
        optional && 'border-dashed',
        className,
      )}
    >
      <div className="flex flex-col overflow-hidden text-left">
        <span className="font-semibold">
          {name === content.income && optional ? content.additionalIncome : name}
        </span>
        {subtitle && <p className="text-xs truncate">{subtitle}</p>}
      </div>
      <div className="flex flex-row flex-none">
        <div className={clsx('rounded-full py-2 px-3 w-24 text-xs whitespace-nowrap', pillColor)}>
          {title}
        </div>
        {submittedDtm ? (
          <CheckCircleIcon className="w-6 h-6 self-center ml-4 text-green-3" />
        ) : (
          <ArrowRightIcon className="w-6 h-6 self-center ml-4 text-blue-3" />
        )}
      </div>
    </button>
  );
};

export default SectionCard;

import { useAnalyticsErrors, useAnalyticsValues } from 'monitoring/analyticsHooks';
import { DefaultIncomeValuesType } from 'questionFlow/flows/defaultIncome/types';
import {
  CurrentEmployerQuestion,
  CurrentEmployerValues,
  CurrentEmployerQuestionValues,
} from './types';

export const adaptToExternalFormat = ({
  startDate,
  endDate,
  ...input
}: CurrentEmployerValues): CurrentEmployerQuestionValues => ({
  ...input,
  startDate: startDate.value,
  endDate: endDate.value,
});

export const useValues = (initialValues?: DefaultIncomeValuesType) => {
  const currentEmployer = initialValues?.currentEmployer as
    | CurrentEmployerQuestionValues
    | undefined;

  return useAnalyticsValues<CurrentEmployerValues>({
    companyName: currentEmployer?.companyName || '',
    role: currentEmployer?.role || '',
    startDate: {
      valid: true,
      value: currentEmployer?.startDate || '',
    },
    endDate: {
      valid: true,
      value: currentEmployer?.endDate || '',
    },
    probationStatus: currentEmployer?.probationStatus || '',
    probationLength: currentEmployer?.probationLength || '',
  });
};

export const useErrors = () =>
  useAnalyticsErrors<Record<CurrentEmployerQuestion, string>>({
    companyName: '',
    role: '',
    startDate: '',
    endDate: '',
    probationStatus: '',
    probationLength: '',
  });

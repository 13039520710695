import { useMemo } from 'react';
import { useTranslation, Trans, TFunction } from 'react-i18next';
import { Options } from 'components/atoms/RadioGroup/RadioGroup';
import RadioQuestion from 'questionFlow/genericQuestions/RadioQuestion';
import {
  BRITISH_IRISH,
  getRTRDocumentOptionsByNationality,
  STATUS_OPTIONS,
} from 'questionFlow/flows/rightToRentIdentity/data/right-to-rent-docs-list';
import {
  RTRIdentityQuestionComponentProps,
  StepNamesRTRIdentity,
} from 'questionFlow/flows/rightToRentIdentity/types';

const cmsLocation = '/questions/identity/documents';

const useContent = (t: TFunction<'translation'>) => ({
  title: t(`${cmsLocation}.title`),
  prompt: t(`${cmsLocation}.prompt`),
  none: t(`${cmsLocation}.none`),
});

const name = StepNamesRTRIdentity.primaryDocument;

const getOptions = (nationality: string = '', t: TFunction) => {
  const docDefinition = getRTRDocumentOptionsByNationality(nationality);
  if (!docDefinition || !docDefinition?.documents?.primary) {
    throw new Error(`Could not locate primary document definition for nationality ${nationality}`);
  }

  return docDefinition.nationalities !== BRITISH_IRISH
    ? [
        ...docDefinition.documents.primary[0].options.map(doc => ({
          value: doc,
          name: doc,
          label: t(`${cmsLocation}.${doc}` as any),
        })),
        {
          value: STATUS_OPTIONS.SETTLED,
          label: t(`${cmsLocation}.SHARE_CODE`),
          name: 'SHARE_CODE',
        },
        { value: 'none', label: t(`${cmsLocation}.none` as any), name: 'none' },
      ]
    : [
        ...docDefinition.documents.primary[0].options.map(doc => ({
          value: doc,
          name: doc,
          label: t(`${cmsLocation}.${doc}` as any),
        })),
        { value: 'none', label: t(`${cmsLocation}.none` as any), name: 'none' },
      ];
};

const PrimaryDocuments = ({ values, ...props }: RTRIdentityQuestionComponentProps) => {
  const { t } = useTranslation();
  const content = useContent(t);

  const options: Options = useMemo(() => {
    return getOptions(values.nationality, t);
  }, [values.nationality, t]);

  return (
    <RadioQuestion
      content={{
        prompt: content.prompt,
        title: content.title,
        subtitle: (
          <Trans
            i18nKey={`${cmsLocation}.subtitle` as any}
            components={{ bold: <span className="font-semibold" /> }}
          />
        ),
      }}
      initialValue={values[name] || ''}
      name={name}
      options={options}
      large
      {...props}
    />
  );
};

export default PrimaryDocuments;

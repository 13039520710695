import { IdentityQuestionComponentProps } from 'questionFlow/flows/defaultIdentity/types';
import { useTranslatedValues } from 'hooks/useTranslatedValues';
import QuestionOrganism from 'questionFlow/QuestionOrganism';
import { Button } from 'components';
import { useEffect, useState } from 'react';

const cmsLocation = '/questions/identity/permissions';

const translations = {
  title: `${cmsLocation}.title`,
  subtitle: `${cmsLocation}.subtitle`,
  buttonTextNoPermissions: `${cmsLocation}.buttonTextNoPermissions`,
  buttonTextPermissionsGranted: `${cmsLocation}.buttonTextPermissionsGranted`,
  optOut: `${cmsLocation}.optOut`,
} as const;

const permissionsAvailable = typeof navigator.permissions !== 'undefined';

const getCameraPermissions = async () =>
  navigator.permissions.query({ name: 'camera' } as any).then(res => res.state);

const Permissions = ({ data, values, ...props }: IdentityQuestionComponentProps) => {
  const content = useTranslatedValues(translations);

  const [cameraPermission, setCameraPermission] = useState(false);

  useEffect(() => {
    if (permissionsAvailable)
      getCameraPermissions().then(state => setCameraPermission(state === 'granted'));
  }, []);

  const getLocalStream = async () => {
    await navigator.mediaDevices.getUserMedia({ video: true });
    const cameraPermissions = await getCameraPermissions();
    if (cameraPermissions === 'granted') setCameraPermission(true);
  };

  const handleSubmit = () => {
    if (cameraPermission || !permissionsAvailable)
      props.onSubmit({ key: 'permissionGranted', value: true });
  };

  return (
    <QuestionOrganism
      {...props}
      onSubmit={handleSubmit}
      title={content.title}
      subtitle={content.subtitle}
      data-testid="permissions"
    >
      <img src="/assets/images/camera_permissions.png" alt="Camera permissions" />
      {permissionsAvailable && (
        <Button onClick={getLocalStream} className="mt-10" disabled={cameraPermission}>
          {cameraPermission
            ? content.buttonTextPermissionsGranted
            : content.buttonTextNoPermissions}
        </Button>
      )}
      <div
        className="mt-6 hover:underline cursor-pointer"
        onClick={() => {
          props.onSubmit({ key: 'permissionGranted', value: 'skip' });
        }}
      >
        {content.optOut}
      </div>
    </QuestionOrganism>
  );
};

export default Permissions;

import { useTranslatedValues } from 'hooks/useTranslatedValues';
import LanguagePicker from 'i18n/LanguagePicker';
import HomepplLogo from 'assets/images/HomepplLogo.svg';
import clsx from 'clsx';

export interface Props {
  className?: string;
  showLanguage?: boolean;
}

const translations = {
  poweredBy: 'header.poweredBy',
} as const;

const PublicNavigationHeader = ({ className, showLanguage }: Props) => {
  const content = useTranslatedValues(translations);

  return (
    <div
      data-testid="public-navigation-header"
      className={clsx('flex flex-row justify-between', className)}
    >
      <div className="flex flex-row items-center text-xs">
        {content.poweredBy} <img className="ml-2.5" src={HomepplLogo} alt="Homeppl" />
      </div>
      {showLanguage ? (
        <div className="flex flex-row items-center">
          <LanguagePicker />
        </div>
      ) : null}
    </div>
  );
};

export default PublicNavigationHeader;

import React, { useState } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import LanguagePicker from '../../../i18n/LanguagePicker';
import { Button } from 'components';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import HomepplLogoLarge from 'assets/images/HomepplLogo-full.svg';

export interface Props {
  className?: string;
  options: { label: string; value: string }[];
  content: { [key: string]: string };
}

const transitionClasses = 'transition-all duration-300 ease-in-out';

const NavigationHeaderMobile = ({ className, options, content, ...props }: Props) => {
  const [open, setOpen] = useState(false);

  return (
    <div className="md:hidden">
      <div
        {...props}
        data-testid="navigation-header-mobile"
        className={clsx(' flex flex-row justify-between items-center', className)}
      >
        <Button
          data-testid="navigation-header-menu-trigger"
          aria-label={content.menu}
          aria-haspopup="true"
          className="px-2.5 py-2.5"
          onClick={() => setOpen(true)}
        >
          <MenuIcon className="text-white h-6 w-6" />
        </Button>

        <LanguagePicker />
      </div>

      <div
        data-testid="mobile-modal"
        className={clsx(
          !open && 'invisible',
          'fixed inset-0 h-screen w-screen flex flex-row items-stretch bg-grey-0.5 bg-opacity-60 z-50',
          transitionClasses,
        )}
      >
        <div
          data-testid="mobile-modal-content"
          className={clsx(
            open ? 'translate-x-0' : '-translate-x-full',
            'w-full transform h-full bg-white p-6 pt-8 flex-grow flex flex-col justify-between',
            transitionClasses,
          )}
        >
          <div>
            <Button
              aria-label={content.closeMenu}
              className="px-2.5 py-2.5"
              onClick={() => setOpen(false)}
            >
              <XIcon className="text-white h-6 w-6" />
            </Button>
            <div className="mt-11 flex flex-col">
              {options.map((o, i) => (
                <Link
                  key={o.label}
                  className={clsx(i === 0 && 'border-t', 'border-b border-blue-2 py-5 w-full')}
                  to={o.value}
                >
                  {o.label}
                </Link>
              ))}
            </div>
          </div>
          <div>
            <span className="mb-2 text-xs">{content.poweredBy}</span>
            <img src={HomepplLogoLarge} alt="Homeppl" className="w-24" />
          </div>
        </div>
        <div role="button" tabIndex={-1} onClick={() => setOpen(false)} className="w-20" />
      </div>
    </div>
  );
};

export default NavigationHeaderMobile;

import { TFunction, useTranslation } from 'react-i18next';

type ArgumentTypes<F extends Function> = F extends (args: infer A) => any ? A : never;
type TranslationKey = ArgumentTypes<TFunction<'translation'>>;
export type TranslationMap = Record<string, TranslationKey>;

export const useTranslatedValues = <T extends Record<string, TranslationKey>>(val: T) => {
  const { t } = useTranslation();
  const translatedMap = Object.keys(val).reduce(
    (obj, key) => ({ ...obj, [key]: t(val[key]) || '' }),
    {},
  ) as T;
  return translatedMap as Record<keyof T, string>;
};

import { gql, useMutation, WatchQueryOptions } from '@apollo/client';
import { Exact, GetResidentialHistoryQuery } from 'generated/graphql';

export const RESIDENTIAL_HISTORY_FRAGMENT = gql`
  fragment ResidentialHistoryFragment on ResidentialHistoryType {
    uuid
    isCurrent
    start
    end
    residenceType
    currency
    rent
    rentFrequency
    rentPaymentDate
    reference(remedialSectionUuid: $remedialSectionUuid) {
      firstName
      lastName
      email
      phone
      type
    }
    address {
      street
      streetNumber
      flat
      city
      postalCode
      placeId
      houseName
      houseNumber
      formattedAddress
      country
    }
    documents(remedialSectionUuid: $remedialSectionUuid) {
      path
      type
      uuid
    }
  }
`;

export const GET_RESIDENTIAL_HISTORY = gql`
  query GetResidentialHistory($remedialSectionUuid: UUID) {
    me {
      uuid
      residentialHistory {
        ...ResidentialHistoryFragment
      }
    }
  }
`;

export const UPDATE_RESIDENTIAL_HISTORY = gql`
  mutation UpdateResidentialHistoryItems(
    $residence: ResidentialHistoryInput
    $remedialSectionUuid: UUID
  ) {
    updateResidentialHistory(residence: $residence) {
      residentialHistory {
        ...ResidentialHistoryFragment
      }
    }
  }
  ${RESIDENTIAL_HISTORY_FRAGMENT}
`;

export const DELETE_RESIDENTIAL_HISTORY = gql`
  mutation DeleteResidentialHistoryItems($uuid: UUID) {
    deleteResidentialHistory(uuid: $uuid) {
      success
      uuid
    }
  }
`;
export interface useResidentialHistoryMutationHooksParams {
  updateQuery: <
    TVars = Exact<{
      [key: string]: never;
    }>
  >(
    mapFn: (
      previousQueryResult: GetResidentialHistoryQuery,
      options: Pick<WatchQueryOptions<TVars, GetResidentialHistoryQuery>, 'variables'>,
    ) => GetResidentialHistoryQuery,
  ) => void;
}

export const useUpdateResidentialHistoryMutationHook = ({
  updateQuery,
}: useResidentialHistoryMutationHooksParams) =>
  useMutation(UPDATE_RESIDENTIAL_HISTORY, {
    update(__, { data }) {
      const {
        updateResidentialHistory: { residentialHistory: updatedResidentialHistoryItem },
      } = data;

      updateQuery(prevData => {
        const existingIndex = prevData.me.residentialHistory?.findIndex(
          d => d?.uuid === updatedResidentialHistoryItem.uuid,
        );

        if (existingIndex !== undefined && existingIndex !== -1 && prevData.me.residentialHistory) {
          return {
            me: {
              ...prevData.me,
              residentialHistory: [
                ...prevData.me.residentialHistory.slice(0, existingIndex),
                updatedResidentialHistoryItem,
                ...prevData.me.residentialHistory.slice(
                  existingIndex + 1,
                  prevData.me.residentialHistory.length,
                ),
              ],
            },
          };
        }

        return {
          me: {
            ...prevData.me,
            residentialHistory: [
              ...(prevData.me.residentialHistory || []),
              updatedResidentialHistoryItem,
            ],
          },
        };
      });
    },
    onError() {},
  });

export const useDeleteResidentialHistoryHook = ({
  updateQuery,
}: useResidentialHistoryMutationHooksParams) =>
  useMutation(DELETE_RESIDENTIAL_HISTORY, {
    update(
      cache,
      {
        data: {
          deleteResidentialHistory: { uuid },
        },
      },
    ) {
      updateQuery(prevData => {
        return {
          me: {
            ...prevData.me,
            residentialHistory:
              prevData.me.residentialHistory?.filter(rhi => rhi?.uuid !== uuid) || [],
          },
        };
      });
    },

    onError() {},
  });

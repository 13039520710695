import { Trans, useTranslation } from 'react-i18next';
import OnboardingCard from '../OnboardingCard';
import WelcomeSvg from './welcome.svg';

export interface WelcomePageProps {
  name: string;
  stepsComponent: React.ReactNode;
  index: number;
  onClick: () => void;
}

const WelcomePage = ({ name, stepsComponent, index, onClick }: WelcomePageProps) => {
  const { t } = useTranslation();

  return (
    <OnboardingCard
      title={<Trans i18nKey={'onboarding.welcome.title'} values={{ name }} />}
      imgSrc={WelcomeSvg}
      buttonProps={{
        text: t('onboarding.welcome.submit_button'),
        onClick,
      }}
      steps={stepsComponent}
      index={index}
    >
      <p className="text-center font-medium">
        <Trans
          i18nKey={'onboarding.welcome.description'}
          components={{
            br: <br></br>,
          }}
        />
      </p>
    </OnboardingCard>
  );
};

export default WelcomePage;

import QuestionOrganism from 'questionFlow/QuestionOrganism';
import { useCallback } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import {
  DefaultIncomeQuestionComponentProps,
  StepNamesDefaultIncome,
} from 'questionFlow/flows/defaultIncome/types';

const cmsLocation = '/questions/income/guarantorConfirmation';

const useContent = (t: TFunction<'translation'>) => ({
  title: t(`${cmsLocation}.title`),
});

const stepName = StepNamesDefaultIncome.nonOpenBankingNotice;

const GuarantorConfirmation = ({
  data,
  onSubmit,
  values: initialValues,
  validationRules: validationRulesGeneric,
  ...props
}: DefaultIncomeQuestionComponentProps) => {
  const { t } = useTranslation();
  const content = useContent(t);

  const handleSubmit = useCallback(() => {
    onSubmit({ key: stepName, value: 'acknowledged' });
  }, [onSubmit]);

  return (
    <QuestionOrganism
      id={stepName}
      data-testid={stepName}
      onSubmit={handleSubmit}
      title={content.title}
      subtitle={''}
      {...props}
    >
      {null}
    </QuestionOrganism>
  );
};

export default GuarantorConfirmation;

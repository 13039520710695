import { RefObject, useEffect, useRef } from 'react';

const useIsMounted = (): RefObject<boolean> => {
    const isMountedRef = useRef<boolean>(true);

    useEffect(() => {
        return () => {
            isMountedRef.current = false;
        };
    }, []);

    return isMountedRef;
}

export default useIsMounted;

import { DetailedHTMLProps } from 'react';
import clsx from 'clsx';
import { Option } from '../DropDown/types';
import DownChevron from '../DropDown/DownChevron.svg';
import { ButtonHTMLAttributes } from 'react';

export interface ButtonContentsProps
  extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  placeholder?: string;
  IconComponent?: React.ElementType;
  option?: Option;
  error?: boolean;
  handleSearch?: (e: React.ChangeEvent) => void;
  isOpen?: boolean;
  handleKeyboardInput?: (e: React.KeyboardEvent) => void;
  toggleIsOpen: (e: React.FocusEvent | React.MouseEvent) => void;
}

const DropdownIcon = () => <img src={DownChevron} alt="" role="presentation" />;

const SearchableButtonInput = ({
  IconComponent = DropdownIcon,
  placeholder,
  option,
  error,
  handleSearch,
  isOpen,
  handleKeyboardInput,
  toggleIsOpen,
  ...props
}: ButtonContentsProps) => (
  <button
    {...props}
    data-testid="searchable-dropdown-button"
    onClick={toggleIsOpen}
    disabled={isOpen}
    className={clsx(
      'w-full h-full bg-white',
      error ? 'border-red-3 text-red-5' : 'border-grey-3 text-black disabled:border-grey-3',
      'border rounded',
      'hover:border-blue-4',
    )}
  >
    <div
      className={clsx(
        'absolute left-0 right-0 top-1 bottom-1 px-4',
        'flex flex-row-reverse flex-no-wrap justify-between items-center',
      )}
    >
      <IconComponent />
      {isOpen ? (
        <input
          data-testid="searchable-dropdown-search-input"
          autoFocus
          role="combobox"
          aria-expanded="true"
          aria-controls="dropdown-options"
          aria-activedescendant={option?.value}
          autoComplete="off"
          type="text"
          onKeyDown={handleKeyboardInput}
          onChange={handleSearch}
          className="h-full w-full focus:outline-none text-black opacity-100"
          placeholder={option?.label || placeholder}
          onBlur={toggleIsOpen}
        />
      ) : (
        <div className={clsx(!option && 'text-grey-2 truncate')}>
          {option?.label || placeholder}
        </div>
      )}
    </div>
  </button>
);

export default SearchableButtonInput;

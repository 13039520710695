import { useMemo } from 'react';
import {
  incomeConditionTypesEnum,
  DefaultIncomeQuestionComponentProps,
  StepNamesDefaultIncome,
} from 'questionFlow/flows/defaultIncome/types';
import { TFunction, useTranslation } from 'react-i18next';
import RadioQuestion from 'questionFlow/genericQuestions/RadioQuestion';
import { IncomeSourceTypeEnum } from 'generated/graphql';
import { OnSubmitType } from 'questionFlow/types';
import { useAnalyticsEvent } from 'monitoring/analyticsHooks';

const cmsLocation = '/questions/income/incomeType';

const useContent = (t: TFunction<'translation'>) => ({
  title: t(`${cmsLocation}.title`),
  subtitle: t(`${cmsLocation}.subtitle`),
  option_selfEmployed: t(`${cmsLocation}.selfEmployed`),
  option_permenantlyEmployed: t(`${cmsLocation}.permanentlyEmployed`),
  option_zeroHoursContract: t(`${cmsLocation}.zeroHour`),
  option_hasSavingsOrInvestments: t(`${cmsLocation}.savingsOrInvestments`),
  option_studentLoan: t(`${cmsLocation}.studentLoanOrScholarship`),
  option_benefits: t(`${cmsLocation}.benefits`),
  option_pension: t(`${cmsLocation}.pension`),
  somethingElse: t(`${cmsLocation}.other`),
});

const name = StepNamesDefaultIncome.incomeType;

const IncomeType = ({ data, values, onSubmit, ...props }: DefaultIncomeQuestionComponentProps) => {
  const { t } = useTranslation();
  const content = useContent(t);

  const { broadcastEvent } = useAnalyticsEvent();

  const submit: OnSubmitType = props => {
    onSubmit(props);
    broadcastEvent(
      `Income type: ${options.find(o => o.value === props.value)?.label || props.value}`,
    );
  };

  const options = useMemo(() => {
    const incomeTypeLabelMap: Record<IncomeSourceTypeEnum, string | null> = {
      [IncomeSourceTypeEnum.TEmployedPerm]: content.option_permenantlyEmployed,
      [IncomeSourceTypeEnum.TSelfemployed]: content.option_selfEmployed,
      [IncomeSourceTypeEnum.TScholarshipLoans]: content.option_studentLoan,
      [IncomeSourceTypeEnum.TSavings]: content.option_hasSavingsOrInvestments,
      [IncomeSourceTypeEnum.TEmployedZh]: content.option_zeroHoursContract,
      [IncomeSourceTypeEnum.TBenfits]: content.option_benefits,
      [IncomeSourceTypeEnum.TPension]: content.option_pension,
      [IncomeSourceTypeEnum.TNoincome]: null,
      [IncomeSourceTypeEnum.TEmployedExp]: null,
      [IncomeSourceTypeEnum.TEmployedPb]: null,
      [IncomeSourceTypeEnum.TGuarantor]: null,
      [IncomeSourceTypeEnum.TEmployedTemp]: null,
      [IncomeSourceTypeEnum.TZeroPercentRentShare]: null,
    };

    const allowedTypesCondition = data.conditions?.find(
      cond => cond.name === incomeConditionTypesEnum.ALLOWED_INCOME_TYPES,
    );
    // @ts-ignore
    const allowedTypesList = allowedTypesCondition?.listStrValues as Partial<
      IncomeSourceTypeEnum
    >[];

    const typesList =
      allowedTypesList && Array.isArray(allowedTypesList)
        ? allowedTypesList
        : (Object.keys(incomeTypeLabelMap) as IncomeSourceTypeEnum[]);

    let incomeTypeOptions: { value: IncomeSourceTypeEnum; label: string }[] = [];

    typesList.forEach((type: IncomeSourceTypeEnum) => {
      const label = incomeTypeLabelMap[type];
      if (!label) {
        return;
      }

      incomeTypeOptions.push({
        value: type,
        label,
      });
    });

    return incomeTypeOptions;
  }, [content, data.conditions]);

  return (
    <RadioQuestion
      content={content}
      initialValue={values[name]}
      name={name}
      options={options}
      onSubmit={submit}
      {...props}
    />
  );
};

export default IncomeType;

import { useReducer, useMemo, useEffect, useCallback, Reducer } from 'react';
import { Flow, GeneralSteps, StepNames, SubmitOptions, ValuesType, ValueType } from './types';
import reducer, { ActionType, State, Actions } from './reducer';
import { useHistory } from 'react-router-dom';
import {
  WizardSectionType,
  IncomeSourceMutation,
  SectionMetaDataType,
  UserType,
  WizardRemedialSectionType,
  Maybe,
} from 'generated/graphql';
import { useMutation } from '@apollo/client';
import * as Sentry from '@sentry/react';
import TopLevelRoutes from '../routing/routes';
import { useProgressTracking } from './wizardProgress';
import { History } from 'history';
import { useAnalyticsEvent } from 'monitoring/analyticsHooks';
import Error500 from '../pages/Error';

export interface RendererProps<
  MetaDataType,
  ValuesType,
  StepNames extends string,
  QueryParamsType
> {
  data: SectionMetaDataType & { remedialSection?: Maybe<WizardRemedialSectionType> };
  sectionFlows: Flow<MetaDataType, ValuesType, StepNames, QueryParamsType, IncomeSourceMutation>[];
  sectionName: WizardSectionType;
  defaultValues: UserType;
  onBack: () => void;
  onComplete: (promise: () => Promise<any>) => void;
}

interface QuestionSubmission {
  key: string;
  value: ValueType;
}

const constructPathname = (sectionName: string, stepName: string, search: string = '') =>
  `${TopLevelRoutes.QUESTIONS}/${sectionName.toLowerCase()}/${stepName}${search}`;

const updateUrlSearchParams = (history: History<unknown>, key: string, value: string | null) => {
  const newQueryParams = new URLSearchParams(history.location.search);
  newQueryParams.set(key, value || '');

  //TODO temporary hardcode delete until income_uuid can be full deprecated
  newQueryParams.delete('income_uuid');

  history.replace(`${history.location.pathname}?${newQueryParams.toString()}`);
};

const QuestionRenderer = ({
  data,
  sectionFlows,
  sectionName,
  defaultValues,
  onBack,
  onComplete,
}: RendererProps<WizardSectionType, ValuesType, StepNames, any>) => {
  const history = useHistory();
  const { broadcastEvent } = useAnalyticsEvent();
  const { remedialSection } = data;

  const queryParams = useMemo(() => {
    const params = new URLSearchParams(history.location.search);
    const defaultParams = {
      remedial_uuid: params.get('remedial_uuid') || null,
    };
    const sectionParams = sectionFlows[0].getQueryParams(history.location.search);
    if (sectionParams) {
      return { ...defaultParams, ...sectionParams };
    }

    return defaultParams;
  }, [history.location.search, sectionFlows]);

  const initialState = useMemo<State>(
    () => ({
      currentSectionFlowIndex: 0,
      currentStepIndex: 0,
      seenFlow: data.remedialSection
        ? [GeneralSteps.missingInformation]
        : [sectionFlows[0].start(defaultValues, queryParams, data)],
      values: sectionFlows[0].getDefaultValues(defaultValues, queryParams),
    }),
    [sectionFlows, defaultValues, queryParams, data],
  );

  const [state, dispatch] = useReducer(reducer as Reducer<State, Actions>, initialState);

  const setRelatedObjectUuid = (newValue?: string) => {
    if (
      sectionFlows[state.currentSectionFlowIndex].uuidKey &&
      newValue &&
      newValue !== state.values?.uuid
    ) {
      dispatch({ type: ActionType.UPDATE_VALUES, payload: { uuid: newValue } });
      updateUrlSearchParams(
        history,
        sectionFlows[state.currentSectionFlowIndex].uuidKey || '',
        state.values?.uuid,
      );
    }
  };

  useEffect(() => {
    if (
      sectionFlows[state.currentSectionFlowIndex].uuidKey &&
      state.values?.uuid &&
      state.values?.uuid !== queryParams.uuid
    ) {
      updateUrlSearchParams(
        history,
        sectionFlows[state.currentSectionFlowIndex].uuidKey || '',
        state.values?.uuid,
      );
    }
  }, [queryParams?.uuid, state.values?.uuid, history, sectionFlows, state.currentSectionFlowIndex]);

  const updateWizardProgress = useProgressTracking({
    uuid: state.values.uuid || null,
    sectionName,
    setRelatedObjectUuid,
  });

  const [runMutation] = useMutation(sectionFlows[state.currentSectionFlowIndex].mutation, {
    onError: err => {
      Sentry.captureException(err, {
        tags: {
          category: 'graphql',
        },
      });
    },
    onCompleted: data => {
      const update =
        sectionFlows[state.currentSectionFlowIndex].handleMutationResponse(data, state.values) ||
        {};

      if (update) {
        dispatch({
          type: ActionType.UPDATE_VALUES,
          payload: update,
        });
      }
    },
  });

  const sectionData = useMemo(() => {
    const flowData = sectionFlows[state.currentSectionFlowIndex].getData(data, defaultValues);

    return {
      ...flowData,
      ...(remedialSection && { remedialSection }),
    } as SectionMetaDataType & {
      remedialSection?: WizardRemedialSectionType | undefined;
    };
  }, [data, defaultValues, sectionFlows, state.currentSectionFlowIndex, remedialSection]);

  const currentStep = useMemo(
    () =>
      sectionFlows[state.currentSectionFlowIndex].steps[
        state.seenFlow[state.currentStepIndex] as StepNames
      ],
    [state.currentSectionFlowIndex, state.seenFlow, state.currentStepIndex, sectionFlows],
  );

  const { component: Component, chooseSuccessor, getMutationValues, ...stepProps } = currentStep;

  const handleSubmit = useCallback(
    async ({ key, value }: QuestionSubmission, options: SubmitOptions = {}) => {
      const values = { ...state.values, [key]: value };
      const currentStepName = state.seenFlow[state.currentStepIndex];
      const isLastStep = currentStepName === sectionData.remedialSection?.end;

      const nextStep =
        !isLastStep && chooseSuccessor && chooseSuccessor({ value, values, data: sectionData });

      const runValuesMutation = () =>
        getMutationValues &&
        runMutation({
          variables: {
            // todo remove remedialSectionUuid from getMutationValues after BR refactoring
            ...getMutationValues(values, remedialSection?.uuid),
            remedialSectionUuid: remedialSection?.uuid,
          },
        });
      const runUpdateWizardProgressMutation = () =>
        updateWizardProgress(
          state.seenFlow,
          !options.preventProgressCompletion && (!nextStep || !!currentStep.setFlowCompleted),
          state.seenFlow[state.currentStepIndex],
        );

      if (nextStep) {
        //Fire and forget mutations so we can move on
        if (getMutationValues) {
          runValuesMutation();
        }
        runUpdateWizardProgressMutation();

        dispatch({
          type: ActionType.NEXT_STEP,
          payload: { nextStep, values: { [key]: value } },
        });
        history.push(constructPathname(sectionName, nextStep, history.location.search));
      } else {
        // Wrap into a promise to be called by the parent page
        // so it can show loading spinner and wait for result before redirecting to dash
        const finalMutationsPromise = getMutationValues
          ? () => Promise.all([runValuesMutation(), runUpdateWizardProgressMutation()])
          : runUpdateWizardProgressMutation;

        onComplete(finalMutationsPromise);
      }
    },
    [
      state.values,
      state.seenFlow,
      state.currentStepIndex,
      getMutationValues,
      updateWizardProgress,
      currentStep,
      chooseSuccessor,
      onComplete,
      runMutation,
      sectionData,
      remedialSection?.uuid,
      history,
      sectionName,
    ],
  );

  const handleBack = useCallback(
    (_?: any, jump: number = 1) => {
      const nextIndex = state.currentStepIndex - jump;
      broadcastEvent(`Back to ${sectionName} - ${state.seenFlow[nextIndex]}`);
      const isInThisFlow = !!sectionFlows[state.currentSectionFlowIndex].steps[
        state.seenFlow[nextIndex] as StepNames
      ];
      if (nextIndex >= 0) {
        dispatch({
          type: ActionType.BACK,
          payload: {
            nextStepIndex: nextIndex,
            nextStageIndex: isInThisFlow
              ? state.currentSectionFlowIndex
              : state.currentSectionFlowIndex - jump,
          },
        });

        history.replace(
          constructPathname(sectionName, state.seenFlow[nextIndex], history.location.search),
        );
      } else {
        onBack();
      }
    },
    [history, state, onBack, sectionFlows, sectionName, broadcastEvent],
  );

  const handleBrowserNavigation = useCallback(
    (location, action) => {
      if (action === 'POP' && location.pathname.includes('questions')) {
        const stepnameToGoTo = location.pathname.split(/\//g).pop();

        const isPreviousStep = state.seenFlow.includes(stepnameToGoTo);

        if (isPreviousStep) {
          handleBack();
        } else {
          const currentStepName = state.seenFlow[state.currentStepIndex];
          handleSubmit({
            key: currentStepName,
            value: state.values[currentStepName as StepNames],
          });
        }
      }
    },
    [state, handleSubmit, handleBack],
  );

  useEffect(() => {
    // TODO this is where we can run through answered questions and actually start halfway in
    const initialPathname = constructPathname(
      sectionName,
      state.seenFlow[state.currentStepIndex],
      history.location.search,
    );
    if (state.currentStepIndex === 0 && history.location.pathname !== initialPathname) {
      history.replace(initialPathname);
    }
  }, [history, sectionName, state.seenFlow, state.currentStepIndex]);

  useEffect(() => history.listen((a, b) => handleBrowserNavigation(a, b)), [
    handleBrowserNavigation,
    history,
  ]);

  return (
    <Sentry.ErrorBoundary fallback={<Error500 />}>
      <Component
        sectionName={sectionName}
        data={sectionData}
        index={state.currentStepIndex}
        name={state.seenFlow[state.currentStepIndex]}
        onBack={handleBack}
        onSubmit={handleSubmit}
        values={state.values}
        dispatch={dispatch}
        isRemedialSection={!!remedialSection}
        {...stepProps}
      />
    </Sentry.ErrorBoundary>
  );
};

export default QuestionRenderer;

import { TFunction } from 'react-i18next';
import isRecentStarter from './isRecentStarter';
import { AccountDocumentType, IncomeSourceTypeEnum } from 'generated/graphql';
import { DefaultIncomeValuesType, whoIsPayingEnum } from 'questionFlow/flows/defaultIncome/types';

export enum DocumentTypes {
  BANKSTATEMENT = 'BANK_STATEMENT',
  PAYSLIP = 'PAYSLIP',
  TAXRETURN = 'TAX_RETURN',
  CONTRACT = 'EMPLOYMENT_CONTRACT',
  INVESTMENT = 'INVESTMENT_PORTFOLIO',
  STUDENT_LETTER = 'STUDENT_STATUS',
  PENSION_STATEMENT = 'PENSION_STATEMENT',
  BENEFITS_PAPERWORK = 'BENEFITS_PAPERWORK',
  COMPANY_PROOF = 'COMPANY_ACCOUNTS',
  OTHER = 'OTHER',
}

export const isDocumentType = (v: any): v is DocumentTypes => {
  if (typeof v !== 'string') return false;
  return Object.keys(DocumentTypes).includes(v);
};

export const mapDocumentTypeToAccountDocument = (
  document: DocumentTypes,
): keyof typeof AccountDocumentType => {
  switch (document) {
    case DocumentTypes.BANKSTATEMENT:
      return 'BankStatement';
    case DocumentTypes.PAYSLIP:
      return 'Payslip';
    case DocumentTypes.TAXRETURN:
      return 'TaxReturn';
    case DocumentTypes.CONTRACT:
      return 'EmploymentContract';
    case DocumentTypes.INVESTMENT:
      return 'InvestmentPortfolio';
    case DocumentTypes.STUDENT_LETTER:
      return 'StudentStatus';
    case DocumentTypes.PENSION_STATEMENT:
      return 'PensionStatement';
    case DocumentTypes.BENEFITS_PAPERWORK:
      return 'BenefitsPaperwork';
    case DocumentTypes.COMPANY_PROOF:
      return 'CompanyAccounts';
  }
};

export const documentOptions = {
  employedFullOrPart: [DocumentTypes.BANKSTATEMENT],
  newStarterEmployed: [DocumentTypes.CONTRACT],
  employedZeroHours: [DocumentTypes.BANKSTATEMENT],
  selfEmployed: [DocumentTypes.TAXRETURN, DocumentTypes.BANKSTATEMENT],
  savingsOrInvestments: [DocumentTypes.INVESTMENT, DocumentTypes.BANKSTATEMENT],
  studentLoanOrScholarship: [DocumentTypes.BANKSTATEMENT, DocumentTypes.STUDENT_LETTER],
  pension: [DocumentTypes.BANKSTATEMENT, DocumentTypes.PENSION_STATEMENT],
  benefits: [DocumentTypes.BANKSTATEMENT, DocumentTypes.BENEFITS_PAPERWORK],
  company: [DocumentTypes.COMPANY_PROOF],
};

const cmsLocation = '/questions/income/documents';
export const useDocumentOptionsContent = (t: TFunction<'translation'>) => ({
  [DocumentTypes.PAYSLIP]: t(`${cmsLocation}.PAYSLIP`),
  [DocumentTypes.BANKSTATEMENT]: t(`${cmsLocation}.BANKSTATEMENT`),
  [DocumentTypes.TAXRETURN]: t(`${cmsLocation}.TAXRETURN`),
  [DocumentTypes.CONTRACT]: t(`${cmsLocation}.CONTRACT`),
  [DocumentTypes.INVESTMENT]: t(`${cmsLocation}.INVESTMENT`),
  [DocumentTypes.STUDENT_LETTER]: t(`${cmsLocation}.STUDENT_LETTER`),
  [DocumentTypes.PENSION_STATEMENT]: t(`${cmsLocation}.PENSION_STATEMENT`),
  [DocumentTypes.BENEFITS_PAPERWORK]: t(`${cmsLocation}.BENEFITS_PAPERWORK`),
  [DocumentTypes.COMPANY_PROOF]: t(`${cmsLocation}.COMPANY_PROOF`),
  [DocumentTypes.OTHER]: t(`${cmsLocation}.OTHER`),
});

const selectDocumentOptions = ({
  currentEmployer,
  incomeType,
  whoIsPaying,
}: Partial<DefaultIncomeValuesType>) => {
  switch (incomeType) {
    case IncomeSourceTypeEnum.TEmployedPerm: {
      if (
        currentEmployer &&
        currentEmployer.startDate &&
        isRecentStarter(currentEmployer.startDate)
      ) {
        return documentOptions.newStarterEmployed;
      }
      return documentOptions.employedFullOrPart;
    }
    case IncomeSourceTypeEnum.TEmployedZh: {
      return documentOptions.employedZeroHours;
    }
    case IncomeSourceTypeEnum.TSelfemployed: {
      return documentOptions.selfEmployed;
    }
    case IncomeSourceTypeEnum.TSavings: {
      return documentOptions.savingsOrInvestments;
    }
    case IncomeSourceTypeEnum.TScholarshipLoans: {
      return documentOptions.studentLoanOrScholarship;
    }
    case IncomeSourceTypeEnum.TBenfits: {
      return documentOptions.benefits;
    }
    default: {
      if (whoIsPaying === whoIsPayingEnum.ME) {
        return documentOptions.pension;
      } else {
        return documentOptions.company;
      }
    }
  }
};

export default selectDocumentOptions;

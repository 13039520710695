import React from 'react';
import clsx from 'clsx';
import { OptionComponentProps } from './types';

interface Props extends OptionComponentProps {
  disabled?: boolean;
  count?: string;
  size?: 'sm' | 'lg';
}
const TextOption = React.forwardRef<HTMLLIElement, Props>(
  ({ label, selected, onClick, disabled, count, size, ...props }, ref) => (
    <li
      role="option"
      aria-selected={selected}
      aria-label={label}
      ref={ref}
      className={clsx(
        size === 'sm' ? 'w-28' : size === 'lg' ? 'w-full' : 'w-full md:w-64',
        'h-9 px-4 py-2 text-sm leading-5',
        'cursor-pointer list-none',
        selected ? 'bg-blue-4 text-white' : 'bg-white',
        !selected && !disabled && 'hover:bg-light-blue-1',
        'focus:outline-none',
      )}
      onClick={e => {
        e.preventDefault();
        onClick();
      }}
      {...props}
    >
      <span className="truncate">{label}</span>
      {count && <p className="font-semibold">{count}</p>}
    </li>
  ),
);

export default TextOption;

import { Label } from 'components/atoms';
import ValidationError from './ValidationError';
export interface Props {
  'data-testid': string;
  className?: string;
  label?: string;
  validationError?: string;
}

const Field: React.FC<Props> = ({ children, label, validationError, ...props }) => {
  const errorTestId = 'validation-error-for-' + props['data-testid'];
  const Container = label ? Label : 'div';

  return (
    <Container {...props}>
      {label && <span className="block mb-0.5">{label}</span>}
      {children}
      <ValidationError className="mt-0.5" data-testid={errorTestId}>
        {validationError}
      </ValidationError>
    </Container>
  );
};

export default Field;

import { Button } from 'components';
import RightPatternSvg from './images/rightPattern.svg';
import LeftPatternSvg from './images/leftPattern.svg';
import clsx from 'clsx';

interface Props {
  index?: number;
  className?: string;
  title: string | React.ReactElement;
  steps?: React.ReactNode;
  imgSrc?: string;
  children: React.ReactNode;
  buttonProps: {
    text: string;
    attrs?: Record<string, any>;
    onClick?: (e: React.MouseEvent) => void;
  };
  backButtonProps: {
    text: string;
    attrs?: Record<string, any>;
    onClick?: (e: React.MouseEvent) => void;
  };
}

const OnboardingCard: React.FC<Props> = ({
  index,
  className,
  title,
  steps,
  imgSrc,
  children,
  buttonProps,
  backButtonProps,
}) => {
  return (
    <div
      className={clsx(
        'min-h-full overflow-auto w-full md:h-auto bg-white flex flex-col items-center relative p-6 md:px-8 md:border-2 border-blue-3',
        className,
      )}
    >
      {index !== undefined ? (
        <div
          className={clsx(
            'absolute top-0',
            index % 2 === 0 ? '-left-8 md:left-0' : '-right-8 md:right-0',
          )}
        >
          {index % 2 === 0 ? (
            <img src={LeftPatternSvg} alt="" />
          ) : (
            <img src={RightPatternSvg} alt="" />
          )}
        </div>
      ) : null}

      <div className="animate-fade flex flex-col items-center">
        <div className="font-serif text-2xl leading-10">{title}</div>

        {imgSrc && (
          <img className="mt-4 h-[100px]" src={imgSrc} role="presentation" alt="" height={100} />
        )}
      </div>

      <div className="min-w-full border-t-2 border-gray-200 my-8 -mx-8 px-8 self-stretch"></div>

      <div className="animate-fade">{children}</div>

      <div className="w-full mt-auto">
        {steps}

        <div className="flex items-end space-x-4 py-3 mt-6">
          {backButtonProps ? (
            <Button
              styleType="secondary"
              onClick={backButtonProps.onClick}
              className="flex-1"
              {...backButtonProps.attrs}
            >
              {backButtonProps.text}
            </Button>
          ) : null}
          <Button onClick={buttonProps.onClick} className="flex-1" {...buttonProps.attrs}>
            {buttonProps.text}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default OnboardingCard;

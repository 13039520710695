import { useState, useMemo } from 'react';
import QuestionOrganism from '../../QuestionOrganism';
import { QuestionComponentProps, StepNames } from '../../types';
import { Options } from 'components/atoms/RadioGroup/RadioGroup';
import { Trans } from 'react-i18next';
import { Dropzone, FileUploadProgress, RadioGroup } from 'components';
import { CheckCircleIcon, ExclamationIcon } from '@heroicons/react/outline';
import { DocumentUploadState } from './useDocumentUploadState';
import { AccountDocumentType } from 'generated/graphql';
import { get } from 'lodash';
import { FileFormatRule, getAcceptProp, getExtensionsText } from './uploadHelpers';
import {
  getDocTypeForCopy,
  hasDocumentSignposting,
  hasDocumentWarningModal,
  getSideCopy,
} from './utils';
import { UploadConfirmationModal } from './UploadConfirmationModal';
import UploadFileRow from './UploadFileRow';

export interface Content {
  do: string;
  dont: string;
  doItems: string;
  dontItems: string;
  dontHaveTitle: string;
  dontHaveSubtitle: string;
  dontHaveOption: string;
  required: string;
  inProgress: string;
  fileTooLarge: string;
  wrongFileFormat: string;
  fileName: string;
  formattedFileName: string;
  title: string;
  subtitle: string;
  minFiles: string;
  optionalExtra?: string;
  backSide?: string;
  front?: string;
  back?: string;
  multipleFiles?: string;
}

const inProgress = (progress: number) => progress >= 0 && progress < 100;

export interface UploadProps extends QuestionComponentProps<any, any> {
  sides: number;
  name: StepNames;
  content: Content;
  fileFormat?: FileFormatRule;
  additionalFilePath?: string;
  documentKey?: string;
  optionalUnderHeader?: JSX.Element | string;
  side?: 'front' | 'back';
  showNumericSides?: boolean;
  modalTransValues?: Record<string, string>;
  generateSuffix?: () => string;
}

export type UploadedDocType = {
  id: string;
  path: string;
  type: AccountDocumentType;
};

type PreviousUploadData = {
  upload: UploadedDocType[];
  expectedType: AccountDocumentType;
  side?: 'front' | 'back';
  content: Content;
};

const getPreviousDocs = ({ upload, expectedType, side, content }: PreviousUploadData) => {
  return upload.filter(({ type, path }) => {
    let isRightSide = true;

    if (side && content.backSide) {
      const backContent = content.backSide.replace(/[- ]/g, '').toUpperCase();
      const hasBackSuffix = path.includes(backContent);
      isRightSide = side === 'back' ? hasBackSuffix : !hasBackSuffix;
    }

    return type === expectedType && path && isRightSide;
  });
};

const getOtherSidePreviousUploadData = ({
  upload,
  expectedType,
  side,
  content,
}: PreviousUploadData) => {
  return upload.filter(({ type, path }) => {
    if (type !== expectedType || !path || !side || !content.backSide) {
      return false;
    }
    const backContent = content.backSide.replace(/[- ]/g, '').toUpperCase();
    const hasBackSuffix = path.includes(backContent);
    return side === 'back' ? !hasBackSuffix : hasBackSuffix;
  });
};

const UploadDocuments = ({
  data,
  values,
  onSubmit,
  sides,
  name,
  content,
  fileFormat = 'all',
  additionalFilePath: additionalFilePathRaw = '',
  optionalUnderHeader,
  documentKey = 'documents',
  side,
  showNumericSides,
  modalTransValues,
  generateSuffix,
  ...props
}: UploadProps) => {
  const docTypeName = useMemo(() => get(values, documentKey), [
    documentKey,
    values,
  ]) as AccountDocumentType;

  const [skip, setSkip] = useState(false);
  const [error, setError] = useState<string[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState<{ uuid: string; file: File }[]>([]);
  const [uploadedDocuments, setUploadedDocuments] = useState<Record<string, DocumentUploadState>>(
    {},
  );
  const [previousDocs, setPreviousDocs] = useState<UploadedDocType[]>(
    getPreviousDocs({
      upload: values.upload || [],
      expectedType: docTypeName as AccountDocumentType,
      side,
      content,
    }),
  );

  const handleSubmit = () => {
    let hasError = Object.values(uploadedDocuments).some(
      doc => doc.uploadError || doc.validationError,
    );

    Object.values(uploadedDocuments).forEach(doc => {
      if (inProgress(doc.progress)) {
        doc.setValidationError(content.inProgress);
        hasError = true;
      }
    });

    if (hasError && !skip) {
      return;
    }

    const otherSideDocs = getOtherSidePreviousUploadData({
      upload: values.upload || [],
      expectedType: docTypeName as AccountDocumentType,
      side,
      content,
    });

    const newDocs = Object.values(uploadedDocuments).reduce(
      (acc: any, doc: DocumentUploadState, ix: number) => {
        if (doc.path) {
          return [...acc, { type: docTypeName, path: doc.path, id: doc.id }];
        }
        return acc;
      },
      [],
    );
    const allDocs = [...previousDocs, ...newDocs];

    const hadPrevDocs =
      getPreviousDocs({
        upload: values.upload || [],
        expectedType: docTypeName as AccountDocumentType,
        side,
        content,
      }).length > 0;

    if (!allDocs.length && (hadPrevDocs || !skip)) {
      setError([content.minFiles]);
      return;
    }

    if (allDocs.length && hasDocumentWarningModal(docTypeName) && side !== 'front' && !showModal) {
      setShowModal(true);
      return;
    }
    setShowModal(false);

    onSubmit(
      { key: name, value: [...otherSideDocs, ...allDocs] },
      { preventProgressCompletion: allDocs.length < 1 },
    );
  };

  const handleSelectNoDoc = () => {
    setSkip(true);
  };

  const handleClearUpload = (uuid: string) => {
    const newUploads = uploadedFiles.filter(upload => upload.uuid !== uuid);
    setUploadedFiles(newUploads);

    const newDocs = { ...uploadedDocuments };
    delete newDocs[uuid];
    setUploadedDocuments(newDocs);
  };

  const handleRemoveDoc = (index: number) => {
    const newUploads = [...previousDocs];
    newUploads.splice(index, 1);
    setPreviousDocs(newUploads);
  };

  const handleSetUploadedFiles = (files: { uuid: string; file: File }[]) => {
    setUploadedFiles(files);
    setError([]);
  };

  const handleSaveDoc = (doc: DocumentUploadState, uuid: string) => {
    if (doc.uploadError) {
      handleClearUpload(uuid);

      setError([...error, `${doc.file?.name} ${doc.uploadError}`]);
      return;
    }

    const newUploads = { ...uploadedDocuments, [uuid]: doc };
    setUploadedDocuments(newUploads);
  };

  const options = useMemo<Options>(() => [{ value: '', label: content.dontHaveOption as string }], [
    content.dontHaveOption,
  ]);

  const sideCopy = useMemo(() => getSideCopy({ content, showNumericSides, side }), [
    content,
    showNumericSides,
    side,
  ]);

  return (
    <QuestionOrganism
      id={name}
      data-testid={name}
      onSubmit={handleSubmit}
      title={
        <>
          {showNumericSides && sideCopy}
          <Trans i18nKey={content.title as any} values={{ doc: content.fileName }} />
          {!showNumericSides && sideCopy}
        </>
      }
      subtitle={
        <>
          <Trans
            i18nKey={content.subtitle as any}
            values={{ doc: content.fileName, formats: getExtensionsText(fileFormat) }}
          />

          {optionalUnderHeader && (
            <>
              <br />
              <br />
              <span className="mb-6 mt-2">
                {content.optionalExtra}
                <span className="font-semibold">{optionalUnderHeader}</span>
              </span>
            </>
          )}
        </>
      }
      {...props}
    >
      <UploadConfirmationModal
        showModal={showModal}
        closeModal={() => setShowModal(false)}
        docTypeName={getDocTypeForCopy(docTypeName, side)}
        docType={content.fileName}
        transValues={modalTransValues}
      />

      {!hasDocumentSignposting(docTypeName) && (
        <>
          <div className="flex flex-row">
            <CheckCircleIcon className="w-5 h-5 text-green-3 mr-4" />
            <span className="font-semibold">{content.do}</span>
          </div>
          <ul className="ml-16">
            <Trans
              i18nKey={content.doItems as any}
              components={{ li: <li className="list-disc" /> }}
            />
          </ul>

          <div className="flex flex-row mt-8">
            <ExclamationIcon className="w-5 h-5 text-red-4 mr-4" />
            <span className="font-semibold mb-2">{content.dont}</span>
          </div>
          <ul className="ml-16 mb-10">
            <Trans
              i18nKey={content.dontItems as any}
              components={{ li: <li className="list-disc mb-2" /> }}
            />
          </ul>
        </>
      )}

      <p className="pt-4 mt-4 border-t border-grey-3 flex flex-col space-y-2">
        <span className="font-medium">
          <Trans i18nKey={content.title as any} values={{ doc: content.fileName }} />
        </span>
        {sides > 1 ? <span className="text-gray-500">{content.multipleFiles}</span> : null}
      </p>

      <div className="grid grid-cols-8 gap-5 sm:gap-10 my-8 sm:my-14">
        <div className="col-span-full sm:col-span-1 md:col-span-2 text-gray-700 font-medium">
          Upload
        </div>

        <div className="col-span-full sm:col-span-7 md:col-span-6">
          <Dropzone
            maxDocuments={sides - previousDocs.length}
            sides={sides}
            values={uploadedFiles}
            setValues={handleSetUploadedFiles}
            accept={getAcceptProp(fileFormat)}
          />
        </div>
        <div className="col-span-full sm:col-span-1 md:col-span-2 text-gray-700 font-medium">
          Files
        </div>

        <div className="space-y-6 col-span-full  sm:col-span-7 md:col-span-6">
          {previousDocs.map((_, index) => (
            <FileUploadProgress
              key={index}
              fileName={`${content.formattedFileName} ${
                docTypeName !== AccountDocumentType.TimeLimitedPassport && previousDocs.length > 1
                  ? index + 1
                  : ''
              }`}
              onClear={() => handleRemoveDoc(index)}
              progress={100}
            />
          ))}
          {uploadedFiles.map(({ file, uuid }) => (
            <UploadFileRow
              key={uuid}
              uuid={uuid}
              file={file}
              multipleFils={sides > 1}
              docTypeName={docTypeName}
              data={data}
              values={values}
              content={content}
              fileFormat={fileFormat}
              additionalFilePathRaw={additionalFilePathRaw}
              saveDocumentData={handleSaveDoc}
              onClear={handleClearUpload}
              generateSuffix={generateSuffix}
            />
          ))}

          <div className="flex flex-col space-y-4">
            {error.map(err => (
              <em data-testid="upload-error" className="text-sm  text-red-600">
                {err}
              </em>
            ))}
          </div>
        </div>
      </div>

      <div className="pt-10">
        <p className="font-semibold md:text-2xl mb-4">{content.dontHaveTitle}</p>
        <p className="mb-6">
          <Trans
            i18nKey={content.dontHaveSubtitle as any}
            components={{
              b: <b />,
            }}
          />
        </p>

        <RadioGroup onChange={handleSelectNoDoc} id="no-document" options={options} />
      </div>
    </QuestionOrganism>
  );
};

export default UploadDocuments;

import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/solid';
import { Checkbox, Field } from 'components';
import { Trans } from 'react-i18next';

type SignPostingLayoutProps = {
  error: string;
  imageSrc: string;
  imageAlt: string;
  checked: boolean;
  translationValues?: Record<string, string>;
  doItems: string;
  dontItems: string;
  children?: React.ReactNode;
  onCheck: (value: boolean) => void;
};

const ListItemComponent = ({
  children,
  success,
}: {
  children?: React.ReactNode;
  success: boolean;
}) => {
  return (
    <p className="flex items-center text-gray-900 py-6 font-medium">
      {success ? (
        <CheckCircleIcon className="flex-shrink-0 h-6 w-6 text-green-400" aria-hidden="true" />
      ) : (
        <XCircleIcon className="flex-shrink-0 h-6 w-6 text-red-400" aria-hidden="true" />
      )}
      <span className="ml-4">{children}</span>
    </p>
  );
};

const SignPostingLayout = ({
  doItems,
  dontItems,
  translationValues,
  imageSrc,
  imageAlt,
  checked,
  error,
  children,
  onCheck,
}: SignPostingLayoutProps) => {
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onCheck(e.currentTarget.checked);
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-12 mt-4">
      <div className="row-start-2 md:row-start-auto">
        <p className="font-medium mb-4">
          <Trans
            i18nKey={`/questions/documentSignposting.howTo`}
            components={{ b: <span className="font-bold" /> }}
            values={translationValues}
          />
        </p>

        <div className="divide-y">
          <Trans
            i18nKey={doItems as any}
            components={{
              li: <ListItemComponent success />,
              b: <span className="font-bold" />,
            }}
            values={translationValues}
          />
          <Trans
            i18nKey={dontItems as any}
            components={{
              li: <ListItemComponent success={false} />,
              b: <span className="font-bold" />,
            }}
            values={translationValues}
          />
        </div>

        {children}

        <Field data-testid="signposting-field" validationError={error} className="py-8 ml-9">
          <label className="text-sm flex" htmlFor="signposting-checkbox">
            <Checkbox
              id="signposting-checkbox"
              className="-ml-9"
              checked={checked}
              onChange={handleCheckboxChange}
              color="blue"
            />

            <span className="ml-4">
              <Trans
                i18nKey={'/questions/documentSignposting.understood'}
                components={{ r: <span className="text-red-500" /> }}
              />
            </span>
          </label>
        </Field>
      </div>
      <div className="row-start-1 md:row-start-auto">
        <img className="m-auto" alt={imageAlt} src={imageSrc} />
      </div>
    </div>
  );
};

export default SignPostingLayout;

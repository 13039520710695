import QuestionOrganism from 'questionFlow/QuestionOrganism';
import { useCallback } from 'react';
import { TFunction, useTranslation, Trans } from 'react-i18next';
import { QuestionComponentProps } from '../types';
import { StepNamesDefaultIdentity } from '../flows/defaultIdentity/types';
import { Button } from 'components';

const useContent = (t: TFunction<'translation'>, cmsLocation: string) => ({
  title: t(`${cmsLocation}.title`),
  buttonText: t(`${cmsLocation}.buttonText`),
});

interface Props extends QuestionComponentProps<any, any> {
  cmsLocation?: string;
  stepName?: string;
}

const NoDocuments = ({
  onSubmit,
  values: initialValues,
  validationRules: validationRulesGeneric,
  cmsLocation = '/questions/noDocuments',
  onBack,
  stepName = StepNamesDefaultIdentity.none,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const content = useContent(t, cmsLocation);

  const handleSubmit = useCallback(() => {
    onSubmit({ key: stepName, value: 'acknowledged' }, { preventProgressCompletion: true });
  }, [onSubmit, stepName]);

  const handleOnClick = useCallback(() => {
    onBack();
  }, [onBack]);

  return (
    <QuestionOrganism
      id={stepName}
      data-testid={stepName}
      onSubmit={handleSubmit}
      title={content.title}
      subtitle={
        <Trans
          i18nKey={`${cmsLocation}.subtitle`}
          components={{
            bold: <span className="font-semibold" />,
            p: <p className="mb-4" />,
          }}
        />
      }
      onBack={onBack}
      {...props}
    >
      <Button styleType="secondary" onClick={handleOnClick}>
        {content.buttonText}
      </Button>
    </QuestionOrganism>
  );
};

export default NoDocuments;

import { ApolloClient, InMemoryCache } from '@apollo/client';
import { createLink } from 'api/links';

const client = new ApolloClient({
  link: createLink(),
  cache: new InMemoryCache({
    typePolicies: {
      ResidentialHistoryType: {
        keyFields: ['uuid'],
      },
      UserType: {
        keyFields: ['uuid'],
      },
      ValidationType: {
        keyFields: [],
      },
      WizardProgressType: {
        keyFields: ['id'],
      },
    },
  }),
});

export const getClient = () => client;

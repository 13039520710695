import QuestionOrganism from 'questionFlow/QuestionOrganism';
import { useCallback } from 'react';
import { TFunction, useTranslation, Trans } from 'react-i18next';
import {
  DefaultIncomeQuestionComponentProps,
  StepNamesDefaultIncome,
} from 'questionFlow/flows/defaultIncome/types';

const cmsLocation = '/questions/income/zeroPercentNotice';

const useContent = (t: TFunction<'translation'>) => ({
  title: t(`${cmsLocation}.title`),
  subtitle: t(`${cmsLocation}.subtitle`),
});

const stepName = StepNamesDefaultIncome.zeroPercentNotice;

const subtitle = (
  <Trans
    i18nKey={`${cmsLocation}.subtitle`}
    components={{
      // eslint-disable-next-line
      a: <a href="mailto:support@homeppl.com" className="underline text-blue-3" />,
    }}
  />
);

const ZeroPercentNotice = ({
  data,
  onSubmit,
  values: initialValues,
  validationRules: validationRulesGeneric,
  ...props
}: DefaultIncomeQuestionComponentProps) => {
  const { t } = useTranslation();
  const content = useContent(t);

  const handleSubmit = useCallback(() => {
    onSubmit({ key: stepName, value: 'acknowledged' });
  }, [onSubmit]);

  return (
    <QuestionOrganism
      id={stepName}
      data-testid={stepName}
      onSubmit={handleSubmit}
      title={content.title}
      subtitle={subtitle}
      {...props}
    ></QuestionOrganism>
  );
};

export default ZeroPercentNotice;

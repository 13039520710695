import { WizardSectionType } from 'generated/graphql';

export const sectionOrder = [
  WizardSectionType.Identity,
  WizardSectionType.Income,
  WizardSectionType.Residence,
];

export function sortSections(key: string, order?: WizardSectionType | string[]) {
  return (a: any, b: any): number => {
    if (!a[key] || !b[key]) return 0;
    const aIndex = (order || sectionOrder).indexOf(a[key]) || 0;
    const bIndex = (order || sectionOrder).indexOf(b[key]) || 0;
    return aIndex - bIndex;
  };
}

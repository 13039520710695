import { IdentityQuestionComponentProps } from 'questionFlow/flows/defaultIdentity/types';
import { useTranslatedValues } from 'hooks/useTranslatedValues';
import QuestionOrganism from 'questionFlow/QuestionOrganism';
import { Button } from 'components';

const cmsLocation = '/questions/identity/photo-out';

const translations = {
  title: `${cmsLocation}.title`,
  subtitle: `${cmsLocation}.subtitle`,
  description: `${cmsLocation}.description`,
  buttonTextTakePhoto: `${cmsLocation}.buttonTextTakePhoto`,
  buttonTextContinue: `${cmsLocation}.buttonTextContinue`,
} as const;

const PhotoOptOut = ({ data, values, ...props }: IdentityQuestionComponentProps) => {
  const content = useTranslatedValues(translations);

  return (
    <QuestionOrganism
      {...props}
      onSubmit={() => {}}
      isFooterHidden
      title={content.title}
      subtitle={content.subtitle}
      data-testid="opt-out"
    >
      <div className="font-bold">{content.description}</div>
      <div className="mt-10 flex">
        <Button
          onClick={() => {
            props.onSubmit({ key: 'optOutDecision', value: 'takePhoto' });
          }}
        >
          {content.buttonTextTakePhoto}
        </Button>
        <Button
          styleType="secondary"
          onClick={() => {
            props.onSubmit({ key: 'optOutDecision', value: 'continue' });
          }}
          className="ml-16"
        >
          {content.buttonTextContinue}
        </Button>
      </div>
    </QuestionOrganism>
  );
};

export default PhotoOptOut;

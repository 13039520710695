import { SignPostingLayout } from 'components';
import QuestionOrganism from 'questionFlow/QuestionOrganism';
import { useTranslation, TFunction, Trans } from 'react-i18next';
import { useMemo, useState } from 'react';
import { QuestionComponentProps, ValidationRule } from 'questionFlow/types';
import { get } from 'lodash';
import { AccountDocumentType } from 'generated/graphql';
import { getNameForDocType, getSideCopy } from './Upload/utils';

const cmsLocation = '/questions/documentSignposting';

const validate = (v: string, rules: ValidationRule[]) => rules.find(({ rule }) => !rule(v));

const useContent = (
  t: TFunction<'translation'>,
  docType: AccountDocumentType,
  propsContent?: Record<string, string>,
) => {
  const getTranslationKey = (translateItem: String): string => {
    const doc = getNameForDocType(docType);
    return t(`${cmsLocation}.${doc}.${translateItem}` as any).includes(cmsLocation)
      ? `${cmsLocation}.${translateItem}`
      : `${cmsLocation}.${doc}.${translateItem}`;
  };

  return {
    title: getTranslationKey('title'),
    subtitle: getTranslationKey('subtitle'),
    doItems: getTranslationKey('doItems'),
    dontItems: getTranslationKey('dontItems'),
    front: t(`${cmsLocation}.front`),
    back: t(`${cmsLocation}.back`),
    ...propsContent,
  };
};

type DocumentSignpostingProps = {
  documentKey?: string;
  side?: 'front' | 'back';
  showNumericSides?: boolean;
  content?: Record<string, string>;
  translationValues?: Record<string, string>;
  children?: React.ReactNode;
} & QuestionComponentProps<any, any>;

const DocumentSignposting = ({
  values,
  validationRules,
  onSubmit,
  side,
  documentKey = 'documents',
  content: propsContent,
  translationValues,
  showNumericSides,
  children,
  ...props
}: DocumentSignpostingProps) => {
  const [checked, setChecked] = useState(false);
  const [error, setError] = useState('');

  const { t } = useTranslation();
  const docTypeName: AccountDocumentType = useMemo(() => get(values, documentKey), [
    documentKey,
    values,
  ]);

  const content = useContent(t, docTypeName as AccountDocumentType, propsContent);

  const handleSubmit = () => {
    const result = validate(checked ? 'checked' : '', validationRules.agreement);
    const errors = { reason: result ? t(result.error as any) : '' };

    if (errors.reason.length === 0) {
      onSubmit({ key: 'docSignposting', value: checked });
    } else {
      setError(errors.reason);
    }
  };

  const handleCheck = (value: boolean) => {
    setChecked(value);

    if (error && value) {
      setError('');
    }
  };

  const sideCopy = useMemo(() => getSideCopy({ content, showNumericSides, side }), [
    content,
    showNumericSides,
    side,
  ]);

  return (
    <QuestionOrganism
      id={`${docTypeName}-signposting`}
      data-testid={`${docTypeName}-signposting`}
      onSubmit={handleSubmit}
      title={
        <>
          {showNumericSides && sideCopy}
          <Trans i18nKey={content.title as any} values={{ doc: content.fileName }} />
          {!showNumericSides && sideCopy}
        </>
      }
      subtitle={
        <Trans i18nKey={content.subtitle} components={{ b: <span className="font-bold" /> }} />
      }
      {...props}
    >
      <SignPostingLayout
        doItems={content.doItems}
        dontItems={content.dontItems}
        error={error}
        checked={checked}
        translationValues={translationValues}
        onCheck={handleCheck}
        imageSrc={`/assets/images/${getNameForDocType(docTypeName, side)?.toLowerCase()}.png`}
        imageAlt="document example"
      >
        {children}
      </SignPostingLayout>
    </QuestionOrganism>
  );
};

export default DocumentSignposting;

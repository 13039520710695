import { SectionCard } from 'components';
import { WizardSectionType } from 'generated/graphql';
import { useHistory } from 'react-router-dom';
import { ProgressState } from './useProgressTracker';
import { QuestionRoutes } from '../../routing/routes';
import { sortSections } from 'questionFlow/SectionSorting';
import { useTranslatedValues } from 'hooks/useTranslatedValues';
import { Trans } from 'react-i18next';

const translations = {
  [WizardSectionType.Identity]: '/questions.sections.identity',
  [WizardSectionType.Income]: '/questions.sections.income',
  [WizardSectionType.Residence]: '/questions.sections.residence',
} as const;

interface SectionsProps {
  sections?: ProgressState[];
  wasSubmitted?: boolean;
  isRemedialSection?: boolean;
}
const Sections = ({ sections = [], wasSubmitted, isRemedialSection }: SectionsProps) => {
  const history = useHistory();
  const content = useTranslatedValues(translations);

  const handleSelectSection = (
    url: string,
    queryParams: { id: string; relatedObjectUuid?: string; remedial_uuid?: string },
  ) => {
    const search = new URLSearchParams({
      progress_id: queryParams.id || '',
      ...(queryParams.relatedObjectUuid
        ? { related_object_uuid: queryParams.relatedObjectUuid }
        : {}),
      ...(queryParams.remedial_uuid ? { remedial_uuid: queryParams.remedial_uuid } : {}),
    }).toString();

    history.push(`${url}?${search}`);
  };

  return (
    <div data-testid="sections" className="flex flex-col space-y-6 my-6">
      {sections
        .sort(sortSections('name'))
        .map(
          ({
            name,
            id,
            completedSteps,
            relatedObjectUuid,
            remedialSectionUuid,
            uuid,
            reason,
            ...props
          }: ProgressState) => (
            <SectionCard
              key={`${name}-${id}`}
              wasSubmitted={wasSubmitted}
              name={
                isRemedialSection ? (
                  <Trans
                    i18nKey={`/questions/missingInformation.title`}
                    values={{ step: content[name as WizardSectionType] }}
                  />
                ) : (
                  content[name as WizardSectionType]
                )
              }
              onClick={() =>
                handleSelectSection(QuestionRoutes[name as WizardSectionType], {
                  id,
                  relatedObjectUuid,
                  remedial_uuid: remedialSectionUuid,
                })
              }
              {...props}
            />
          ),
        )}
    </div>
  );
};

export default Sections;

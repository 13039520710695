import { TFunction } from 'react-i18next';

const cmsLocation = '/questions/income/loanDetails';

export const useContent = (t: TFunction<'translation'>) => ({
  title: t(`${cmsLocation}.title`),
  subtitle: t(`${cmsLocation}.subtitle`),

  bodyName: {
    label: t(`${cmsLocation}.bodyName.label`),
    placeholder: t(`${cmsLocation}.bodyName.placeholder`),
  },
  startDate: {
    label: t(`${cmsLocation}.startDate.label`),
    dayInputLabel: t(`${cmsLocation}.startDate.dayInputLabel`),
    monthInputLabel: t(`${cmsLocation}.startDate.monthInputLabel`),
    yearInputLabel: t(`${cmsLocation}.startDate.yearInputLabel`),
  },
  endDate: {
    label: t(`${cmsLocation}.endDate.label`),
    dayInputLabel: t(`${cmsLocation}.endDate.dayInputLabel`),
    monthInputLabel: t(`${cmsLocation}.endDate.monthInputLabel`),
    yearInputLabel: t(`${cmsLocation}.endDate.yearInputLabel`),
  },
});

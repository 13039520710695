import { TFunction } from 'react-i18next';

const cmsLocation = '/questions/residence/landlord-details';

export const useContent = (t: TFunction<'translation'>) => ({
  title: t(`${cmsLocation}.title`),
  subtitle: t(`${cmsLocation}.subtitle`),

  firstName: {
    label: t(`${cmsLocation}.first_name.label`),
    placeholder: t(`${cmsLocation}.first_name.placeholder`),
  },
  lastName: {
    label: t(`${cmsLocation}.last_name.label`),
    placeholder: t(`${cmsLocation}.last_name.placeholder`),
  },
  email: {
    label: t(`${cmsLocation}.email.label`),
    placeholder: t(`${cmsLocation}.email.placeholder`),
  },
  phoneNumber: {
    label: t(`${cmsLocation}.phone_number.label`),
    placeholder: t(`${cmsLocation}.phone_number.placeholder`),
  },
  type: {
    label: t(`${cmsLocation}.type.label`),
    placeholder: t(`${cmsLocation}.type.placeholder`),
  },
});

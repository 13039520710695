import React from 'react';
import clsx from 'clsx';

export interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
}

const TextButton = ({ className, ...props }: Props) => {
  return (
    <button
      {...props}
      data-testid="text-button"
      className={clsx('text-sm text-blue-3 md:hover:text-blue-2 md:active:text-blue-4', className)}
    ></button>
  );
};

export default TextButton;

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Field, SearchableDropdown } from 'components';
import { Option } from 'components/molecules/DropDown/types';
import AddressOption from './AddressOption';
import { getInternationalAddresses, PostcoderAddress } from './postcoderApi';
import SearchIconSVG from './SearchIcon.svg';
import clsx from 'clsx';
import { uniqueId, debounce } from 'lodash';
const SearchIcon = () => <img src={SearchIconSVG} alt="" role="presentation" />;

interface Props {
  id: string;
  onSelectAddress: (address: PostcoderAddress) => void;
  label: string;
  placeholder: string;
  countryCode: string;
  className?: string;
}

const InternationalAddressAutoComplete = ({
  onSelectAddress,
  label,
  placeholder,
  id,
  countryCode,
  className,
}: Props) => {
  const [results, setResults] = useState<PostcoderAddress[]>([]);
  const [selected, setSelected] = useState('');

  const options: Option[] = useMemo(
    () =>
      results.map(({ summaryline, uniquedeliverypointreferencenumber }) => ({
        label: `${summaryline}`,
        value: `${uniquedeliverypointreferencenumber}`,
      })),
    [results],
  );

  const handleSearch = useCallback(
    async (text: string) => {
      const addressList = await getInternationalAddresses(countryCode, text);

      setResults(
        addressList.map(r => ({
          ...r,
          uniquedeliverypointreferencenumber: r.uniquedeliverypointreferencenumber || uniqueId(),
        })),
      );
    },
    [countryCode],
  );

  const debouncedSearchFunction = useMemo(() => debounce(handleSearch, 800), [handleSearch]);

  useEffect(() => {
    return () => {
      debouncedSearchFunction.cancel();
    };
  }, [debouncedSearchFunction]);

  const handleKeyboardSelect = (value: string) => {
    setSelected(value);
  };

  const handleSelectAddress = async (id: string) => {
    const addressSelected = results.find(
      option => option.uniquedeliverypointreferencenumber === id,
    ) as PostcoderAddress;

    onSelectAddress(addressSelected);
  };

  const handleChange = (text: string) => {
    if (text.length >= 3) {
      debouncedSearchFunction(text);
    }
  };

  const handleClose = () => {
    setSelected('');
  };

  return (
    <div className={clsx('w-full z-0', className)}>
      <Field label={label} className="md:w-full" data-testid={id}>
        <SearchableDropdown
          id={id}
          label={label}
          size="lg"
          placeholder={placeholder}
          value={selected}
          options={options}
          onSelectValue={handleSelectAddress}
          onKeyboardSelect={handleKeyboardSelect}
          isNativeOnMobile={false}
          onSearch={handleChange}
          filter={o => o}
          OptionComponent={AddressOption}
          onClose={handleClose}
          hideEmptyOption
          IconComponent={SearchIcon}
        />
      </Field>
    </div>
  );
};

export default InternationalAddressAutoComplete;

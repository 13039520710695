import { ValidationRule } from '../../types';
import { useTranslation } from 'react-i18next';
import QuestionOrganism from '../../QuestionOrganism';
import { Field, Input } from 'components';
import { useMemo } from 'react';
import { useContent } from './content';
import { assertIsYourGuarantorStringQuestion, YourGuarantorQuestion } from './types';
import { useErrors, useValues } from './state';
import { baseAndLowerEmail } from '../../validations/index';
import {
  DefaultIncomeQuestionComponentProps,
  StepNamesDefaultIncome,
} from 'questionFlow/flows/defaultIncome/types';

const cmsLocation = '/questions/income/yourGuarantor';

const validate = (v: string, rules: ValidationRule[], valid?: boolean) => {
  return rules.find(({ rule }) => !rule(v, valid));
};

const name = StepNamesDefaultIncome.yourGuarantor;

const YourGuarantor = ({
  data,
  values: initialValues,
  onSubmit,
  validationRules,
  ...props
}: DefaultIncomeQuestionComponentProps) => {
  const { t } = useTranslation();
  const content = useContent(t);
  const [values, setValues] = useValues(initialValues);
  const [error, setError] = useErrors();

  const PERSONAL_EMAIL_RULE = useMemo(
    () =>
      Object.freeze<ValidationRule>({
        name: 'personal-email',
        rule: v => !v || baseAndLowerEmail(v) !== baseAndLowerEmail(data.email),
        error: `${cmsLocation}.validation.personalEmailMatch`,
      }),
    [data.email],
  );

  const getValidationResult = (name: YourGuarantorQuestion, givenValue?: string) => {
    const stringValue = givenValue === undefined ? values[name] : givenValue;
    const result = validate(stringValue, validationRules[name]);

    if (!result && name === 'email') {
      // Run an additional check that the email doesn't match the user's email address
      return validate(stringValue, [PERSONAL_EMAIL_RULE]);
    }

    return result;
  };

  const handleSubmit = () => {
    const errors = (Object.keys(values) as YourGuarantorQuestion[]).reduce((e, inputName) => {
      const result = getValidationResult(inputName);

      return { ...e, [inputName]: result ? t(result.error as any) : '' };
    }, error);

    if (Object.values(errors).filter(e => e !== '').length === 0) {
      onSubmit({ key: name, value: values });
    } else {
      setError(errors);
    }
  };

  const validateAndSetError = (name: YourGuarantorQuestion, newValue: string) => {
    const result = getValidationResult(name, newValue);
    setError({ ...error, [name]: result ? t(result.error as any) : '' });
  };

  const onStringValueChange = (name: YourGuarantorQuestion, value: string) => {
    if (error[name]) {
      validateAndSetError(name, value);
    }

    setValues({ ...values, [name]: value });
  };

  const handleInputOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    assertIsYourGuarantorStringQuestion(e.target.name);
    onStringValueChange(e.target.name, e.target.value.trim());
  };

  const handleInputOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    assertIsYourGuarantorStringQuestion(e.target.name);
    validateAndSetError(e.target.name, e.target.value.trim());
  };

  return (
    <QuestionOrganism
      id={name}
      data-testid={name}
      onSubmit={handleSubmit}
      title={content.title}
      subtitle={content.subtitle}
      {...props}
    >
      <div className="grid w-max md:grid-cols-2 gap-x-10 gap-y-4 pb-60">
        <Field
          data-testid={`${name}-firstName`}
          label={content.firstName.label}
          validationError={error.firstName}
        >
          <Input
            id={`${name}-firstName`}
            name={'firstName'}
            defaultValue={values.firstName}
            onChange={handleInputOnChange}
            aria-invalid={!!error.firstName}
            onBlur={handleInputOnBlur}
            placeholder={content.firstName.placeholder}
          />
        </Field>
        <Field
          data-testid={`${name}-lastName`}
          label={content.lastName.label}
          validationError={error.lastName}
        >
          <Input
            id={`${name}-lastName`}
            name={'lastName'}
            defaultValue={values.lastName}
            onChange={handleInputOnChange}
            aria-invalid={!!error.lastName}
            onBlur={handleInputOnBlur}
            placeholder={content.lastName.placeholder}
          />
        </Field>
        <Field
          data-testid={`${name}-email`}
          label={content.email.label}
          validationError={error.email}
        >
          <Input
            id={`${name}-email`}
            name={'email'}
            defaultValue={values.email}
            onChange={handleInputOnChange}
            aria-invalid={!!error.email}
            onBlur={handleInputOnBlur}
            placeholder={content.email.placeholder}
          />
        </Field>
        <Field
          data-testid={`${name}-phoneNumber`}
          label={content.phoneNumber.label}
          validationError={error.phoneNumber}
        >
          <Input
            id={`${name}-phoneNumber`}
            name={'phoneNumber'}
            defaultValue={values.phoneNumber}
            onChange={handleInputOnChange}
            aria-invalid={!!error.phoneNumber}
            onBlur={handleInputOnBlur}
            placeholder={content.phoneNumber.placeholder}
            type="tel"
            inputMode="tel"
          />
        </Field>

        <div className="hidden xl:block" />
      </div>
    </QuestionOrganism>
  );
};

export default YourGuarantor;

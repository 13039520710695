import React, { FormEventHandler, useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Checkbox } from 'components';
import useCheckbox from 'hooks/useCheckbox';
import { PRIVACY_POLICY_URL, TERMS_AND_CONDITIONS_URL } from 'services/environmentVariables';
import OnboardingCard from '../OnboardingCard';

const Anchor = ({ children, ...props }: React.AnchorHTMLAttributes<HTMLAnchorElement>) => (
  <a target="_blank" className="text-blue-2" {...props}>
    {children}
  </a>
);

export const PrivacyLink = <Anchor data-testid="privacy-link" href={PRIVACY_POLICY_URL} />;
export const TermsLink = <Anchor data-testid="terms-link" href={TERMS_AND_CONDITIONS_URL} />;

const ConsentLabel = (
  props: React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement>,
) => <label className="ml-4 text-sm leading-6" {...props} />;

export type SubmitPayload = {
  background: true;
  data: true;
  marketing: boolean;
  terms: true;
};

export interface ConsentPageProps {
  error?: boolean;
  loading?: boolean;
  onSubmitConsents: (consents: SubmitPayload) => void;
}

const ConsentPage = ({ error: externalError, loading, onSubmitConsents }: ConsentPageProps) => {
  const { t } = useTranslation();

  const [checkboxTerms] = useCheckbox(false);
  const [checkboxBackground] = useCheckbox(false);
  const [checkboxData] = useCheckbox(false);
  const [checkboxMarketing] = useCheckbox(false);
  const [showRequiredWarning, setShowRequiredWaring] = useState(false);

  const anErrorIsShowing = externalError || showRequiredWarning;
  const requiredCheckboxesAreCompleted =
    checkboxTerms.checked && checkboxBackground.checked && checkboxData.checked;

  const onSubmit: FormEventHandler = useCallback(
    e => {
      e.preventDefault();

      if (requiredCheckboxesAreCompleted) {
        onSubmitConsents({
          background: true,
          data: true,
          marketing: checkboxMarketing.checked,
          terms: true,
        });

        setShowRequiredWaring(false);
      } else {
        setShowRequiredWaring(true);
      }
    },
    [requiredCheckboxesAreCompleted, checkboxMarketing.checked, onSubmitConsents],
  );

  useEffect(() => {
    if (requiredCheckboxesAreCompleted) {
      setShowRequiredWaring(false);
    }
  }, [requiredCheckboxesAreCompleted]);

  return (
    <OnboardingCard
      title={t('onboarding.consents.title')}
      buttonProps={{
        text: t('onboarding.consents.submit_consent'),
        attrs: {
          type: 'submit',
          form: 'consent-form',
          disabled: loading || (showRequiredWarning && !requiredCheckboxesAreCompleted),
          'data-testid': 'submit-consents',
        },
      }}
    >
      <div data-testid="consent">
        <form id="consent-form" onSubmit={onSubmit} noValidate>
          <fieldset disabled={loading}>
            <Checkbox
              className="items-center font-medium"
              data-testid="onboarding-check-terms"
              id="onboarding-check-terms"
              color="blue"
              {...checkboxTerms}
            >
              <ConsentLabel htmlFor="onboarding-check-terms">
                <Trans
                  i18nKey="onboarding.consents.accept_terms_and_conditions"
                  components={{
                    privacy: PrivacyLink,
                    terms: TermsLink,
                  }}
                />
                <span className="text-red-4">*</span>
              </ConsentLabel>
            </Checkbox>

            <Checkbox
              className="mt-6 items-center font-medium"
              data-testid="onboarding-check-background"
              id="onboarding-check-background"
              color="blue"
              {...checkboxBackground}
            >
              <ConsentLabel htmlFor="onboarding-check-background">
                <Trans
                  i18nKey="onboarding.consents.accept_background_checks"
                  components={{
                    privacy: PrivacyLink,
                    terms: TermsLink,
                  }}
                />
                <span className="text-red-4">*</span>
              </ConsentLabel>
            </Checkbox>

            <Checkbox
              className="mt-6 items-center font-medium"
              data-testid="onboarding-check-data"
              id="onboarding-check-data"
              color="blue"
              {...checkboxData}
            >
              <ConsentLabel htmlFor="onboarding-check-data">
                {t('onboarding.consents.accept_data_accuracy')}
                <span className="text-red-4">*</span>
              </ConsentLabel>
            </Checkbox>

            <Checkbox
              className="mt-6 items-center font-medium"
              data-testid="onboarding-check-marketing"
              id="onboarding-check-marketing"
              color="blue"
              {...checkboxMarketing}
            >
              <ConsentLabel htmlFor="onboarding-check-marketing">
                {t('onboarding.consents.accept_marketing')}
              </ConsentLabel>
            </Checkbox>
          </fieldset>
        </form>

        <div
          className={clsx(
            // On mobile, actually remove and take up no space, on desktop, take space but don't show contents
            !anErrorIsShowing && 'hidden md:invisible md:block',
            'text-sm text-center text-red-4 mt-6',
          )}
          data-testid="consent-alert"
          role={anErrorIsShowing ? 'alert' : undefined}
        >
          {anErrorIsShowing
            ? t(
                externalError
                  ? 'onboarding.consents.something_wrong_error'
                  : 'onboarding.consents.must_accept_error',
              )
            : null}
        </div>
      </div>
    </OnboardingCard>
  );
};

export default ConsentPage;

import * as Sentry from '@sentry/react';
import {
  GetWizardProgressInfoQuery,
  GetWizardProgressInfoQueryHookResult,
  WizardSectionType,
  IncomeSourceTypeEnum,
  useGetWizardProgressInfoQuery,
  UserType,
  WizardProgressType,
  WizardSectionTemplateType,
  WizardRemedialSectionType,
} from '../../generated/graphql';
import { useState, useEffect, useMemo } from 'react';
import { useTranslation, TFunction } from 'react-i18next';
export interface ProgressState extends WizardProgressType {
  optional?: boolean;
  subtitle?: string;
  remedialSectionUuid?: string;
}

const getContent = (t: TFunction<'translation'>) => ({
  [IncomeSourceTypeEnum.TSelfemployed]: t(`/questions/income/incomeType.selfEmployed`),
  [IncomeSourceTypeEnum.TEmployedPerm]: t(`/questions/income/incomeType.permanentlyEmployed`),
  [IncomeSourceTypeEnum.TEmployedZh]: t(`/questions/income/incomeType.zeroHour`),
  [IncomeSourceTypeEnum.TSavings]: t(`/questions/income/incomeType.savingsOrInvestments`),
  [IncomeSourceTypeEnum.TScholarshipLoans]: t(
    `/questions/income/incomeType.studentLoanOrScholarship`,
  ),
  [IncomeSourceTypeEnum.TBenfits]: t('/questions/income/incomeType.benefits'),
  [IncomeSourceTypeEnum.TPension]: t('/questions/income/incomeType.pension'),
  [IncomeSourceTypeEnum.TZeroPercentRentShare]: '',
  guarantor: t('/questions/income/yourGuarantor.title'),
});

const GetIncomeData = (
  me: Partial<UserType> | undefined,
  progress: WizardProgressType,
  content,
) => {
  const matchedIncomeSource = me?.income?.incomeSources?.find(
    source => source.uuid === progress.relatedObjectUuid,
  );

  if (matchedIncomeSource) {
    return {
      subtitle: `${matchedIncomeSource.incomeType ? content[matchedIncomeSource.incomeType] : ''}${
        matchedIncomeSource.companyName ? `/${matchedIncomeSource.companyName}` : ''
      }`,
    };
  }
  if (progress.relatedObjectUuid === me?.guarantor?.uuid) {
    return {
      subtitle: `${content.guarantor}${
        me?.guarantor ? `/${me.guarantor?.firstName} ${me?.guarantor?.lastName}` : ''
      }`,
    };
  }
  return {};
};

const getSupportingData = {
  [WizardSectionType.Identity]: () => ({}),
  [WizardSectionType.Residence]: () => ({}),
  [WizardSectionType.Income]: GetIncomeData,
};

const getNewIncome = (optional?: boolean): ProgressState => ({
  id: '',
  name: WizardSectionType.Income,
  completedSteps: [],
  startedDtm: null,
  completedDtm: null,
  submittedDtm: null,
  optional,
});

const getNewRequired = (name: WizardSectionType): ProgressState => ({
  id: '',
  name: name,
  completedSteps: [],
  startedDtm: null,
  completedDtm: null,
  submittedDtm: null,
  optional: false,
});

const getNewOfType = (type: WizardSectionType) => {
  if (type === WizardSectionType.Income) {
    return getNewIncome(false);
  }
  return getNewRequired(type);
};

const getProgressState = (me: Partial<UserType>, content) => {
  const progress = (me.latestValidation?.progress || []) as WizardProgressType[];
  const sections = (me.latestValidation?.sections || []) as WizardSectionTemplateType[];
  const remedialSections = (me.latestValidation?.remedialSections ||
    []) as WizardRemedialSectionType[];

  const remedialSectionProgress: ProgressState[] = [];

  remedialSections?.forEach(section => {
    const pr = progress.find((pr: WizardProgressType) => pr.id === section.progressId);

    if (pr) {
      remedialSectionProgress.push({
        ...pr,
        optional: false,
        remedialSectionUuid: section.uuid,
      });
    } else {
      console.error('No progress found for remedial section');
      Sentry.captureException(`No progress found for remedial section`, { extra: { section } });
    }
  });

  const sectionProgress: ProgressState[] = [];

  //Add any sections which don't have progress yet
  sections.forEach(section => {
    const hasProgress = progress
      .filter(pr => pr.name === section.name)
      .filter(pr => !remedialSectionProgress.find(rpr => rpr.id === pr.id));

    if (hasProgress.length) {
      if (section.name === WizardSectionType.Income) {
        hasProgress.forEach(pr => {
          sectionProgress.push({
            ...pr,
            optional: false,
            ...getSupportingData[pr.name as WizardSectionType](me, pr, content),
          });
        });
      } else {
        sectionProgress.push({
          ...hasProgress[0],
          optional: false,
          ...getSupportingData[hasProgress[0].name as WizardSectionType](
            me,
            hasProgress[0],
            content,
          ),
        });
      }
    } else {
      sectionProgress.push(getNewOfType(section.name as WizardSectionType));
    }
  });

  return { sectionProgress, remedialSectionProgress };
};

const addAdditionalIncome = (me: Partial<UserType>, sectionProgress: ProgressState[]) => {
  const { income } = me;
  const sections = me.latestValidation?.sections as WizardSectionTemplateType[];

  const finalProgress = [...sectionProgress];

  //Add an optional income section if required
  if (sections.find(s => s.name === WizardSectionType.Income)) {
    const hasAnIncompleteIncome = !!sectionProgress.find(
      pr => pr.name === 'INCOME' && !pr.completedDtm,
    );

    if (
      !hasAnIncompleteIncome &&
      (!income?.meetsAffordabilityCriteria || me?.income?.someoneElsePayingForRent)
    ) {
      finalProgress.push(getNewIncome(true));
    }
  }

  return finalProgress;
};

const calculatePercentageFromState = (state: ProgressState[]) => {
  //Rounded to the next 5%.
  //In-progress sections count for half
  const stepsToComplete = state.filter(state => !state.submittedDtm && !state.optional).length;
  if (!stepsToComplete) {
    return 100;
  }

  const percentage = state.reduce((acc, curr) => {
    if (curr.submittedDtm) {
      return acc;
    }
    if (curr.completedDtm) {
      return acc + 1;
    }
    if (curr.startedDtm) {
      return acc + 0.5;
    }
    return acc;
  }, 0);

  return Math.ceil(((percentage / stepsToComplete) * 100) / 5) * 5;
};

export interface TrackerState {
  progress: number;
  sectionProgress: ProgressState[];
  remedialSectionProgress: ProgressState[];
  canSubmit: boolean;
  wasSubmitted: boolean;
  remedialWasSubmitted: boolean;
}

const wasApplicationSubmitted = (progressState: ProgressState[]) => {
  return !!progressState.every(progress => progress.submittedDtm || progress.optional);
};

const canUserSubmit = (progressState: ProgressState[], data: GetWizardProgressInfoQuery) => {
  const sections = data.me?.latestValidation?.sections || [];

  return sections.reduce((acc, curr) => {
    if (
      (curr.name === WizardSectionType.Income || curr.name === WizardSectionType.Identity) &&
      data?.me?.income?.someoneElsePayingForRent
    ) {
      return true;
    }
    return progressState.find(pr => pr.name === curr.name && !pr.completedDtm && !pr.optional)
      ? false
      : acc;
  }, true);
};

const useProgressTracker = (): [TrackerState, Partial<GetWizardProgressInfoQueryHookResult>] => {
  const { data, error, loading } = useGetWizardProgressInfoQuery({ fetchPolicy: 'network-only' });
  const { t } = useTranslation();
  const content = useMemo(() => getContent(t), [t]);

  const [state, setState] = useState<TrackerState>({
    progress: 0,
    sectionProgress: [],
    remedialSectionProgress: [],
    canSubmit: false,
    wasSubmitted: false,
    remedialWasSubmitted: false,
  });

  useEffect(() => {
    if (data && data.me) {
      let { sectionProgress, remedialSectionProgress } = getProgressState(data.me, content);

      const wasSubmitted = wasApplicationSubmitted(sectionProgress, data);
      const remedialWasSubmitted = wasApplicationSubmitted(remedialSectionProgress, data);

      sectionProgress = wasSubmitted
        ? sectionProgress
        : addAdditionalIncome(data.me, sectionProgress);

      const allSectionProgress = [...sectionProgress, ...remedialSectionProgress];

      const progress = calculatePercentageFromState(allSectionProgress);
      const canSubmit = canUserSubmit(allSectionProgress, data);

      setState({
        progress,
        sectionProgress,
        remedialSectionProgress,
        canSubmit,
        wasSubmitted,
        remedialWasSubmitted,
      });
    }
  }, [data, content]);

  return [state, { data, error, loading }];
};

export default useProgressTracker;

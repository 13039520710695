import React, { useState } from 'react';
import { Field, SearchableDropdown } from 'components';
import { Option } from 'components/molecules/DropDown/types';
import AddressOption from './AddressOption';
import {
  getAddress,
  getAddressList,
  PostcoderAddress,
  PostcoderAutocompleteResults,
} from './postcoderApi';
import SearchIconSVG from './SearchIcon.svg';
import clsx from 'clsx';

const SearchIcon = () => <img src={SearchIconSVG} alt="" role="presentation" />;

interface ExtendedOption extends Option {
  count?: string;
}

interface Props {
  id: string;
  onSelectAddress: (address: PostcoderAddress) => void;
  label: string;
  placeholder: string;
  countryCode?: string;
  className?: string;
}

const UKAddressAutoComplete = ({
  onSelectAddress,
  label,
  placeholder,
  id,
  countryCode,
  className,
}: Props) => {
  const [search, setSearch] = useState('');
  const [options, setOptions] = useState<ExtendedOption[]>([]);
  const [completedSearch, setCompletedSearch] = useState(false);
  const [selected, setSelected] = useState('');

  const handleSearch = async (text: string, pathfilter?: string) => {
    if (completedSearch) {
      setCompletedSearch(false);
    }
    const results = (await getAddressList({
      query: text,
      pathfilter,
    })) as PostcoderAutocompleteResults[];

    setOptions(
      results.map(({ id, count, summaryline, locationsummary }: PostcoderAutocompleteResults) => {
        if (count === 1) {
          return { label: `${summaryline} ${locationsummary}`, value: id };
        }
        return {
          label: `${summaryline} ${locationsummary}`,
          value: id,
          count: `${count} Addresses -->`,
        };
      }),
    );
  };

  const handleKeyboardSelect = (value: string) => {
    setSelected(value);
  };

  const handleSelectAddress = async (id: string) => {
    const addressSelected = options.find(option => option.value === id);
    if (addressSelected?.count) {
      handleSearch(search, id);
    } else {
      const result = await getAddress(id, search);
      setCompletedSearch(true);
      onSelectAddress(result);
    }
  };

  const handleChange = (text: string) => {
    setSearch(text);
    if (text.length >= 3) {
      handleSearch(text);
    }
  };

  const handleClose = () => {
    setOptions([]);
    setSearch('');
    setSelected('');
  };

  return (
    <div className={clsx('w-full z-0', className)}>
      <Field label={label} className="md:w-full" data-testid={id}>
        <SearchableDropdown
          id={id}
          label={label}
          size="lg"
          placeholder={placeholder}
          value={selected}
          options={options}
          onSelectValue={handleSelectAddress}
          onKeyboardSelect={handleKeyboardSelect}
          isNativeOnMobile={false}
          onSearch={handleChange}
          filter={o => o}
          OptionComponent={AddressOption}
          preventAutoClose={!completedSearch}
          onClose={handleClose}
          hideEmptyOption
          IconComponent={SearchIcon}
        />
      </Field>
    </div>
  );
};

export default UKAddressAutoComplete;

export type StringQuestion = 'firstName' | 'lastName' | 'email' | 'phoneNumber';
export type YourGuarantorQuestion = StringQuestion;

export function assertIsYourGuarantorStringQuestion(kind: string): asserts kind is StringQuestion {
  if (!['firstName', 'lastName', 'email', 'phoneNumber'].includes(kind)) {
    throw new Error(`${kind} is not a StringQuestion type`);
  }
}

type StringValues = Record<StringQuestion, string>;

export interface YourGuarantorValues extends StringValues {}

export type YourGuarantorQuestionValues = Record<YourGuarantorQuestion, string>;

import clsx from 'clsx';
import { useMemo, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { FileRejection, useDropzone } from 'react-dropzone';
import { PDFFileIcon, ImageFileIcon } from './icons';
import { v4 as uuidv4 } from 'uuid';

const useContent = (t: TFunction<'translation'>) => ({
  uploadFile: t(`/questions/upload.uploadFile`),
  dragAndDrop: t(`/questions/upload.dragAndDrop`),
  duplicatedFile: t(`/questions/upload.duplicatedFile`),
});

const maxFileSize = 10;

type Props = {
  maxDocuments: number;
  sides: number;
  accept?: string[];
  values: { uuid: string; file: File }[];
  setValues: (files: { uuid: string; file: File }[]) => void;
};

const Dropzone = ({ accept, maxDocuments, sides, values, setValues }: Props) => {
  const { t } = useTranslation();
  const content = useContent(t);

  const [error, setError] = useState('');

  const allowedFileTypes = useMemo(
    () => accept?.reduce((acc, type) => ({ ...acc, [type]: [] }), {}),
    [accept],
  );

  const handleDragEnter = () => {
    if (error) {
      setError('');
    }
  };

  const handleOnDropAccepted = (acceptedFiles: File[]) => {
    let tmpError = '';

    const newFiles = acceptedFiles.reduce(
      (acc, acceptedFile) => {
        if (acc.length === maxDocuments) {
          tmpError = t(`/questions/upload.maxFiles`, { sides }) as string;
          return acc;
        }
        if (acc.find(({ file }) => file.name === acceptedFile.name)) {
          tmpError = content.duplicatedFile;
          return acc;
        }
        return [...acc, { file: acceptedFile, uuid: uuidv4() }];
      },
      [...values],
    );

    setError(tmpError);
    setValues(Object.values(newFiles));
  };

  const handleOnDropRejected = (fileRejections: FileRejection[]) => {
    const err = fileRejections[0].errors[0];

    if (err.code === 'file-too-large') {
      setError(t(`/questions/upload.maxSize`, { size: maxFileSize }));
    } else if (err.code === 'file-invalid-type') {
      setError(
        t(`/questions/upload.incorrectType`, {
          type: accept?.[0] === 'application/pdf' ? 'PDF' : 'JPG / JPEG / PNG / PDF',
        }),
      );
    } else {
      setError(err.message);
    }
  };

  const { getRootProps, getInputProps, isDragAccept, isDragReject } = useDropzone({
    maxFiles: maxDocuments,
    maxSize: maxFileSize * Math.pow(10, 6),
    multiple: true,
    accept: allowedFileTypes,
    onDropAccepted: handleOnDropAccepted,
    onDropRejected: handleOnDropRejected,
    onDragEnter: handleDragEnter,
    useFsAccessApi: false,
  });

  return (
    <div className="flex justify-center items-center w-full">
      <label
        htmlFor="dropzone-file"
        data-testid="dropzone"
        className="flex flex-col justify-center items-center w-full h-44 bg-gray-50 rounded-lg border-[3px] border-gray-300 border-dashed cursor-pointer dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
      >
        <div
          {...getRootProps({
            className: clsx(
              'flex flex-col justify-center items-center pt-5 pb-6 w-full h-full',
              isDragAccept && 'bg-green-100',
              isDragReject && 'bg-red-200',
            ),
          })}
        >
          {accept?.[0] === 'application/pdf' ? (
            <PDFFileIcon className="mb-3 w-10 h-10 text-gray-400" />
          ) : (
            <ImageFileIcon className="mb-3 w-10 h-10 text-gray-400" />
          )}
          <p className="mb-2 text-sm text-gray-500 dark:text-gray-400 font-semibold">
            <span className="text-blue-600">{content.uploadFile}</span> {content.dragAndDrop}
          </p>
          <p className="text-xs text-gray-500 dark:text-gray-400">
            {accept?.[0] === 'application/pdf'
              ? 'PDF up to 10MB'
              : 'JPG / JPEG / PNG / PDF up to 10MB'}
          </p>
          <input {...getInputProps()} aria-label="file-upload" />
          <em data-testid="error" className="text-xs text-red-600 mt-2">
            {error}
          </em>
        </div>
      </label>
    </div>
  );
};

export default Dropzone;

import clsx from 'clsx';

const IconButton = ({ className, ...props }: React.ButtonHTMLAttributes<HTMLButtonElement>) => (
  <button
    className={clsx(
      'rounded w-min h-min',
      'hover:bg-light-blue-2 focus-visible:bg-light-blue-2 focus:outline-none',
      'transition duration-500 ease-in-out',
      className,
    )}
    {...props}
  />
);

export default IconButton;

import clsx from 'clsx';
import Textarea from 'components/atoms/Textarea';
import ButtonOption from 'components/molecules/ButtonOption';
import React, { useState } from 'react';

export type ReferenceQuestionProps = {
  options: [string, string];
  question: string;
  validationError: Record<string, string>;
  note: string;
  index: number;
  hasError: boolean;
  defaultValue: string;
  saveAnswer: (answer: string) => void;
  setError: (value: boolean) => void;
};

const ReferenceQuestion: React.FC<ReferenceQuestionProps> = ({
  options,
  question,
  note,
  validationError,
  index,
  hasError,
  defaultValue,
  saveAnswer,
  setError,
}) => {
  const [selectedOption, setSelectOption] = useState('');

  const handleChange = (option: string) => {
    setSelectOption(option);

    if (option === defaultValue) {
      saveAnswer(option);
    } else {
      saveAnswer('');
    }
    setError(false);
  };

  const handleTextareaBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    const answer = e.currentTarget.value;
    setError(!answer);

    if (!answer) {
      return;
    }

    saveAnswer(answer);
  };

  return (
    <div className={clsx('flex font-medium text-gray-700 md:pl-5', { 'ml-6': !index })}>
      <p className="text-lg">{!!index && <span className="mr-3">{index}.</span>}</p>
      <div className="w-full">
        <p className={clsx('text-lg mb-4', { '-ml-6': !index })}>{question}</p>

        <ButtonOption
          data-testid={`button-option-${index}`}
          options={options}
          selected={selectedOption}
          onChange={handleChange}
        />

        {selectedOption && selectedOption !== defaultValue ? (
          <div className="mt-4">
            <p className="text-base mb-2">{note}</p>
            <Textarea
              className={clsx({ 'border-red-600': hasError })}
              onBlur={handleTextareaBlur}
              large
              rows={5}
            />
          </div>
        ) : null}
        {hasError && (
          <span data-testid="error" className="text-xs font-normal text-red-5">
            {validationError}
          </span>
        )}
      </div>
    </div>
  );
};

export default ReferenceQuestion;

import { gql } from '@apollo/client';

export const SubmitValidation = gql`
  mutation SetSubmitted {
    submitValidation {
      submittedValidation {
        validationSubmitted
      }
    }
  }
`;

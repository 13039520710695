import React from 'react';
import QuestionOrganism from '../QuestionOrganism';
import { useTranslation, TFunction, Trans } from 'react-i18next';
import {
  RTRIdentityQuestionComponentProps,
  StepNamesRTRIdentity,
} from 'questionFlow/flows/rightToRentIdentity/types';
import { STATUS_OPTIONS } from 'questionFlow/flows/rightToRentIdentity/data/right-to-rent-docs-list';

const cmsLocation = '/questions/identity/biometricEducation';

const useContent = (t: TFunction<'translation'>) => {
  return {
    title: t(`${cmsLocation}.title`),
    subtitle: t(`${cmsLocation}.subtitle`),
  };
};

const name = StepNamesRTRIdentity.shareCode;

const BiometricEducation = ({
  values,
  onSubmit,
  validationRules,
  ...props
}: RTRIdentityQuestionComponentProps) => {
  const { t } = useTranslation();
  const content = useContent(t);

  const handleSubmit = () => {
    onSubmit({ key: 'primaryDocument', value: STATUS_OPTIONS.SETTLED });
  };

  return (
    <QuestionOrganism
      id={name}
      data-testid={name}
      onSubmit={handleSubmit}
      title={content.title}
      subtitle={
        <Trans
          i18nKey={`${cmsLocation}.subtitle` as any}
          components={{
            br: <br></br>,
            p: <p />,
            a: (
              // eslint-disable-next-line jsx-a11y/anchor-has-content
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.gov.uk/view-prove-immigration-status"
                className="text-blue-3"
              />
            ),
          }}
        />
      }
      {...props}
    ></QuestionOrganism>
  );
};

export default BiometricEducation;

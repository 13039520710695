export type StringQuestion = 'companyName' | 'role' | 'probationStatus' | 'probationLength';
export type DateQuestion = 'startDate' | 'endDate';
export type CurrentEmployerQuestion = StringQuestion | DateQuestion;

export function assertIsCurrentEmployerStringQuestion(
  kind: string,
): asserts kind is StringQuestion {
  if (!['companyName', 'role', 'probationStatus', 'probationLength'].includes(kind)) {
    throw new Error(`${kind} is not a StringQuestion type`);
  }
}

function isCurrentEmployerDateQuestion(kind: string): kind is DateQuestion {
  return ['startDate', 'endDate'].includes(kind);
}

export function assertIsCurrentEmployerDateQuestion(kind: string): asserts kind is DateQuestion {
  if (!isCurrentEmployerDateQuestion(kind)) {
    throw new Error(`${kind} is not a DateQuestion type`);
  }
}

type StringValues = Record<StringQuestion, string>;

export type DateValue = {
  valid: boolean;
  value: string;
};

type DateValues = Record<DateQuestion, DateValue>;

export function isDateValue(maybeDate: string | DateValue): maybeDate is DateValue {
  return typeof maybeDate !== 'string';
}

export interface CurrentEmployerValues extends DateValues, StringValues {}

export type CurrentEmployerQuestionValues = Record<CurrentEmployerQuestion, string>;

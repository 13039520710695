import {
  IdentitySectionMetaDataType,
  Maybe,
  AccountDocumentType,
  AccountDocumentGqlType,
} from 'generated/graphql';
import { QuestionComponentProps } from 'questionFlow/types';
import { STATUS_OPTIONS } from './data/right-to-rent-docs-list';
export enum StepNamesRTRIdentity {
  name = 'name',
  birthdate = 'birthdate',
  nationality = 'nationality',
  primaryDocument = 'primaryDocument',
  documentSignpostingPrimary = 'documentSignpostingPrimary',
  documentSignpostingPrimaryBack = 'documentSignpostingPrimaryBack',
  secondaryDocumentOne = 'secondaryDocumentOne',
  documentSignpostingSecondaryOne = 'documentSignpostingSecondaryOne',
  documentSignpostingSecondaryOneBack = 'documentSignpostingSecondaryOneBack',
  secondaryDocumentTwo = 'secondaryDocumentTwo',
  documentSignpostingSecondaryTwo = 'documentSignpostingSecondaryTwo',
  documentSignpostingSecondaryTwoBack = 'documentSignpostingSecondaryTwoBack',
  none = 'none',
  upload = 'upload',
  uploadBack = 'uploadBack',
  uploadSecondary = 'uploadSecondary',
  uploadSecondaryBack = 'uploadSecondaryBack',
  uploadSecondaryTwo = 'uploadSecondaryTwo',
  uploadSecondaryTwoBack = 'uploadSecondaryTwoBack',
  status = 'status',
  shareCode = 'shareCode',
  biometricEducation = 'biometricEducation',
  expiredVisa = 'expiredVisa',
  livePhotoSignposting = 'livePhotoSignposting',
  livePhotoPermissions = 'livePhotoPermissions',
  livePhotoCamera = 'livePhotoCamera',
  livePhotoOptOut = 'livePhotoOptOut',
}

export interface RTRIdentityValuesType {
  [StepNamesRTRIdentity.name]?: { first_name: string; middle_name: string; last_name: string };
  [StepNamesRTRIdentity.birthdate]?: string;
  [StepNamesRTRIdentity.nationality]?: string;
  [StepNamesRTRIdentity.primaryDocument]?:
    | Maybe<AccountDocumentType>
    | typeof STATUS_OPTIONS[keyof typeof STATUS_OPTIONS]
    | 'none';
  [StepNamesRTRIdentity.secondaryDocumentOne]?: Maybe<AccountDocumentType> | 'none';
  [StepNamesRTRIdentity.secondaryDocumentTwo]?: Maybe<AccountDocumentType> | 'none';
  [StepNamesRTRIdentity.upload]?: AccountDocumentGqlType[];
  [StepNamesRTRIdentity.shareCode]?: Maybe<string>;
  [StepNamesRTRIdentity.status]?: Maybe<string>;
  [StepNamesRTRIdentity.documentSignpostingPrimaryBack]?: boolean;

  uuid?: Maybe<string>;
}

export interface RTRIdentityQuestionComponentProps
  extends QuestionComponentProps<IdentitySectionMetaDataType, RTRIdentityValuesType> {}

import { maxBy, minBy } from 'lodash';
import { differenceInMonths } from 'date-fns';
import { ResidentialHistoryType, Maybe } from 'generated/graphql';

interface Params {
  residentialHistory?: Maybe<ResidentialHistoryType>[];
  minMonths: 3 | 36;
}

const getDateRange = ({ residentialHistory, minMonths = 36 }: Params) => {
  if (!residentialHistory) {
    return {
      months: 0,
      years: 0,
      isValid: false,
    };
  }
  let maxDateObj = maxBy(residentialHistory, rh => new Date(rh?.end).valueOf());
  const maxDate = maxDateObj?.end ? new Date(maxDateObj.end) : new Date();

  let minDateObj = minBy(residentialHistory, rh => new Date(rh?.start).valueOf());
  const minDate = minDateObj ? new Date(minDateObj.start) : new Date();

  const monthsDiff = differenceInMonths(maxDate, minDate);
  if (isNaN(monthsDiff)) {
    return { months: 0, years: 0, isValid: false };
  }

  return {
    months: monthsDiff % 12,
    years: Math.floor(monthsDiff / 12),
    isValid: monthsDiff >= minMonths,
  };
};

export default getDateRange;

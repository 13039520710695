import React, { useEffect, useMemo, useState } from 'react';
import * as Sentry from '@sentry/react';
import { QuestionRenderer } from '../../questionFlow';
import defaultIdentityFlow from '../../questionFlow/flows/defaultIdentity';
import { Spinner } from 'components';
import {
  WizardSectionStepDefinition,
  Maybe,
  SectionMetaDataType,
  UserType,
  WizardSectionTemplateType,
  WizardRemedialSectionType,
} from 'generated/graphql';
import useInitialData from 'api/data/initial-data/useInitialData';
import useGetAllQuestionData from './useGetAllQuestionData';
import Error500 from '../Error';
import { useHistory } from 'react-router-dom';
import TopLevelRoutes, { QuestionRoutes } from '../../routing/routes';
import RightToRentFlow from 'questionFlow/flows/rightToRentIdentity';
import defaultResidenceFlow from 'questionFlow/flows/defaultResidence';
import { History } from 'history';
import defaultIncomeFlow from 'questionFlow/flows/defaultIncome';

const flowMap = {
  [WizardSectionStepDefinition.IdentityDefault]: defaultIdentityFlow,
  [WizardSectionStepDefinition.IdentityRtr]: RightToRentFlow,
  [WizardSectionStepDefinition.IncomeDefault]: defaultIncomeFlow,
  [WizardSectionStepDefinition.IncomeWithGuarantor]: defaultIncomeFlow,
  [WizardSectionStepDefinition.IncomeWithoutGuarantor]: defaultIncomeFlow,
  [WizardSectionStepDefinition.ResidenceDefault]: defaultResidenceFlow,
};

const getCurrentSection = (
  pathname: string,
  initialIndex?: number,
  sections?: Maybe<WizardSectionTemplateType[]>,
) => {
  if (!sections) {
    return null;
  }
  if (initialIndex && sections[initialIndex]) {
    return sections[initialIndex];
  }

  let matchedFlow = Object.keys(QuestionRoutes).find(routeKey =>
    pathname.includes(QuestionRoutes[routeKey as keyof typeof QuestionRoutes]),
  );

  if (matchedFlow) {
    const sectionIndex = sections.findIndex(section => section.name === matchedFlow);
    if (sectionIndex !== -1) {
      return sections[sectionIndex];
    }
  }
  return sections[0];
};

const getCurrentRemedialSectionUuid = ({
  section: currentSection,
  remedialSections,
  history,
}: {
  section?: Maybe<WizardSectionTemplateType>;
  remedialSections?: Maybe<Maybe<WizardRemedialSectionType>[]>;
  history: History<unknown>;
}) => {
  const params = new URLSearchParams(history.location.search);
  const remedial_uuid = params.get('remedial_uuid') || null;

  if (!remedial_uuid) {
    return;
  }

  const currentRemedialSection = remedialSections?.find(
    remedialSection =>
      remedialSection?.uuid === remedial_uuid && remedialSection?.section === currentSection?.name,
  );

  if (remedial_uuid && !currentRemedialSection) {
    Sentry.captureException('No remedial section found for remedial_uuid', {
      extra: { remedial_uuid },
    });
  }

  return currentRemedialSection;
};

const queryParams = (
  _: WizardSectionTemplateType[],
  section: WizardSectionTemplateType,
  remedialSectionUuid?: string,
) =>
  section.steps?.reduce(
    (params, step) => {
      return { ...params, [step as WizardSectionStepDefinition]: true };
    },
    {
      [WizardSectionStepDefinition.IdentityDefault]: false,
      [WizardSectionStepDefinition.IdentityRtr]: false,
      [WizardSectionStepDefinition.IncomeDefault]: false,
      [WizardSectionStepDefinition.IncomeWithGuarantor]: false,
      [WizardSectionStepDefinition.IncomeWithoutGuarantor]: false,
      [WizardSectionStepDefinition.ResidenceDefault]: false,
      remedialSectionUuid,
    },
  );

const Questions = ({ initialIndex = 0 }: { initialIndex: number }) => {
  const initialData = useInitialData();

  const history = useHistory();
  const [loadingFinalMutations, setLoadingFinalMutations] = useState(false);

  const section = useMemo(
    () =>
      getCurrentSection(
        history.location.pathname,
        initialIndex,
        initialData?.me?.latestValidation?.sections,
      ),
    [history.location.pathname, initialIndex, initialData],
  );

  const remedialSections = initialData?.me?.latestValidation?.remedialSections;

  const remedialSection = useMemo(
    () => getCurrentRemedialSectionUuid({ section, remedialSections, history }),
    [history, remedialSections, section],
  );

  const currentSectionFlow = useMemo(
    () =>
      section?.steps
        ? section.steps.map(stepName => flowMap[stepName as WizardSectionStepDefinition])
        : undefined,
    [section],
  );

  const [getAllQuestionData, { loading, error, data }] = useGetAllQuestionData();

  useEffect(() => {
    if (initialData?.me?.latestValidation?.sections && section) {
      getAllQuestionData({
        variables: queryParams(
          initialData.me.latestValidation.sections,
          section,
          remedialSection?.uuid,
        ),
      });
    }
  }, [section, initialData, getAllQuestionData, remedialSection?.uuid]);

  const handleComplete = async (finalMutationsPromise: () => Promise<void>) => {
    setLoadingFinalMutations(true);
    try {
      await finalMutationsPromise();
      history.push(TopLevelRoutes.DASHBOARD);
    } catch (e) {
      history.push('/500');
    }
    setLoadingFinalMutations(false);
  };

  if (!initialData || loading || (!data && !error) || loadingFinalMutations) {
    return (
      <div data-testid="loading" className="w-full h-full flex items-center justify-center">
        <Spinner />
      </div>
    );
  }

  if (error || !section || !currentSectionFlow || !data?.me || !section.name) {
    Sentry.captureException(error ? error : new Error('Failed to render questions'), {
      extra: {
        error,
        section,
        hasMeData: !!data?.me,
        hasCurrentSectionFlow: !!currentSectionFlow,
      },
    });
    return <Error500 />;
  }

  return (
    <QuestionRenderer
      data={{
        ...(data.sectionMetaData as SectionMetaDataType),
        remedialSection,
      }}
      sectionFlows={currentSectionFlow}
      sectionName={section.name}
      defaultValues={data.me as UserType}
      onBack={() => {}}
      onComplete={handleComplete}
    />
  );
};

export default Questions;

import AuthenticationService from 'authentication/AuthenticationService';
import { useTranslatedValues } from 'hooks/useTranslatedValues';
import HomepplLogo from 'assets/images/HomepplLogo-full.svg';
import { LinkButton } from 'components';
import { useHistory } from 'react-router-dom';
import TopImg from './images/top.svg';
import BottomImg from './images/bottom.svg';

const translations = {
  thankYou: '/rentalReference.thankYou',
  homepplInfo: '/rentalReference.homepplInfo',
  interested: '/rentalReference.interested',
  learnMore: '/rentalReference.learnMore',
} as const;

const ReferenceThankYouPage = () => {
  const content = useTranslatedValues(translations);
  const {
    location: { state },
  } = useHistory();

  AuthenticationService.logout();

  return (
    <div className="relative bg-gray-50 overflow-hidden h-screen flex justify-center items-center">
      <div
        className="hidden sm:block sm:absolute sm:inset-y-0 sm:h-full sm:w-full"
        aria-hidden="true"
      >
        <div className="relative h-full max-w-7xl mx-auto">
          <img
            alt=""
            src={TopImg}
            className="absolute left-full transform -translate-y-1/2 -translate-x-1/2 md:-translate-y-1/2 lg:-translate-x-1/4"
          />
          <img
            alt=""
            src={BottomImg}
            className="absolute bottom-0 right-full transform translate-y-1/4 translate-x-1/4 lg:translate-x-1/4"
          />
        </div>
      </div>

      <div className="relative pt-6 pb-16 sm:pb-24">
        <main className="mt-16 mx-auto max-w-7xl px-4 sm:mt-24">
          <div className="text-center">
            <img className="mx-auto mb-10" alt="homeppl logo" src={HomepplLogo} />
            <h1 className="text-4xl tracking-tight font-bold text-gray-900 sm:text-5xl sm:tracking-tight md:text-5xl md:tracking-tight">
              <span className="block xl:inline">{content.thankYou}</span>{' '}
              <span className="block text-blue-600 xl:inline">
                {state?.name ? state.name : ''} <span className="text-gray-900">!</span>
              </span>
            </h1>
            <p className="mt-8 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
              {content.homepplInfo}
              <br />
              {content.interested}
            </p>
            <div className="mt-10 max-w-md mx-auto">
              <LinkButton className="mx-auto" href="http://homeppl.com">
                {content.learnMore}
              </LinkButton>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default ReferenceThankYouPage;

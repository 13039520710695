import { InternalRouteLayout } from 'components';
import useInitialData from 'api/data/initial-data/useInitialData';
import { capitalize } from 'lodash';
import Sections from './sections';
import Progress from './progress';
import SubmissionBox from './submissionBox';
import useProgressTracker, { TrackerState } from './useProgressTracker';
import { useMutation } from '@apollo/client';
import { SubmitValidation } from './submitValidationMutation';
import * as Sentry from '@sentry/react';
import { useAnalyticsEvent } from 'monitoring/analyticsHooks';
import { useTranslatedValues } from 'hooks/useTranslatedValues';
import Server500 from 'pages/Error';

const translations = {
  hi: '/dashboard.hi',
  info: '/dashboard.info',
  remedialInfo: '/dashboard.remedialInfo',
  submittedGreeting: '/dashboard.submittedGreeting',
  submittedInfo: '/dashboard.submittedInfo',
  remedialSubmittedInfo: '/dashboard.remedialSubmittedInfo',
  submitButton: '/dashboard.submitButton',
} as const;

const Dashboard = () => {
  const initialData = useInitialData();
  const { broadcastEvent } = useAnalyticsEvent();
  const [progressState] = useProgressTracker();

  const {
    progress,
    sectionProgress,
    remedialSectionProgress,
    wasSubmitted,
    remedialWasSubmitted,
    canSubmit,
  } = progressState as TrackerState;

  const allWasSubmitted = wasSubmitted && remedialWasSubmitted;

  const [runMutation, { loading }] = useMutation(SubmitValidation, {
    onError: err => {
      Sentry.captureException(err, {
        tags: {
          category: 'graphql',
        },
      });
    },
    refetchQueries: ['GetInitialData', 'GetWizardProgressInfo'],
  });

  const content = useTranslatedValues(translations);

  const handleSubmit = () => {
    runMutation();
    broadcastEvent('Clicked submit application');
  };
  if (!initialData.me.latestValidation) {
    return <Server500 />;
  }

  return (
    <InternalRouteLayout.Body data-testid="dashboard">
      <div className="grid gap-x-10 md:grid-cols-2 mt-6 mb-12">
        <div data-testid="welcome">
          <h1 className="text-2xl font-semibold mb-4">
            {allWasSubmitted
              ? content.submittedGreeting
              : `${content.hi} ${capitalize(initialData.me.name.firstName || '')}`}
          </h1>

          {remedialSectionProgress.length ? (
            <p>{remedialWasSubmitted ? content.remedialSubmittedInfo : content.remedialInfo}</p>
          ) : (
            <p>{wasSubmitted ? content.submittedInfo : content.info}</p>
          )}
        </div>
        <SubmissionBox
          className="hidden md:flex"
          disabled={!canSubmit}
          onClick={handleSubmit}
          submitted={allWasSubmitted}
          loading={loading}
          hasRemedialSection={remedialSectionProgress.length > 0}
        />
      </div>
      <Progress
        progress={progress}
        wasSubmitted={allWasSubmitted}
        canSubmit={canSubmit}
        hasRemedialSections={!!remedialSectionProgress.length}
      />

      {remedialSectionProgress.length ? (
        <>
          <Sections sections={remedialSectionProgress} isRemedialSection />
          <hr />
        </>
      ) : null}

      <Sections sections={sectionProgress} wasSubmitted={wasSubmitted} />

      <SubmissionBox
        className="md:hidden"
        disabled={!canSubmit}
        onClick={handleSubmit}
        submitted={allWasSubmitted}
        loading={loading}
        hasRemedialSection={remedialSectionProgress.length > 0}
      />
    </InternalRouteLayout.Body>
  );
};

export default Dashboard;

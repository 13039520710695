import React, { useCallback } from 'react';
import clsx from 'clsx';

interface Props
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  className?: string;
  large?: boolean;
  blockDecimal?: boolean;
  blockNegative?: boolean;
}

const Input = React.forwardRef<HTMLInputElement, Props>(
  ({ className, onKeyDown, onChange, type, large, blockDecimal, blockNegative, ...props }, ref) => {
    const handleChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        if (type === 'tel') {
          e.target.value = e.target.value.replace(/[^0-9+]/g, '');
        }
        if (onChange) {
          onChange(e);
        }
      },
      [type, onChange],
    );

    const handleKeyDown = useCallback(
      (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (type === 'number' && (event.key === 'e' || event.key === 'E' || event.key === '+')) {
          event.preventDefault();
        } else if (
          type === 'tel' &&
          event.key === '+' &&
          event.currentTarget.value.indexOf('+') !== -1
        ) {
          event.preventDefault();
        } else if (blockDecimal === true && event.key === '.') {
          event.preventDefault();
        } else if (blockNegative === true && event.key === '-') {
          event.preventDefault();
        } else if (onKeyDown) {
          onKeyDown(event);
        }
      },
      [type, onKeyDown, blockDecimal, blockNegative],
    );

    return (
      <input
        ref={ref}
        className={clsx(
          props['aria-invalid']
            ? 'border-red-3 text-red-5 disabled:border-red-3'
            : 'border-grey-3 text-black disabled:border-grey-3',
          large ? 'w-full' : 'w-64',
          'block box-border bg-white text-base leading-4 placeholder-grey-2 h-14 p-4 disabled:opacity-50 border rounded',
          'hover:border-blue-4 hover:text-black',
          'focus:outline-none focus:ring-2 focus:ring-light-blue-3 focus:border-light-blue-3 focus:text-black',
          'bg-clip-padding spin-button-none',
          className,
        )}
        type={type}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        {...props}
      />
    );
  },
);

export default Input;

import clsx from 'clsx';
import Spinner from '../Spinner';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  big?: boolean;
  loading?: boolean;
  styleType?: 'primary' | 'secondary' | 'neutral';
}

const buttonStyles = {
  primary: 'text-white bg-blue-3 hover:bg-blue-2 focus:bg-blue-4 disabled:hover:bg-blue-3',
  secondary: 'text-blue-3 bg-light-blue-1 focus:bg-light-blue-3 hover:bg-light-blue-2 shadow-blue',
  text: 'text-sm text-blue-3 md:hover:text-blue-2 md:active:text-blue-4',
  neutral: 'text-gray-600 bg-gray-50 border-2 border-gray-300 focus:bg-blue-100 hover:bg-blue-50',
};

const Button = ({ big, className, loading, disabled, styleType = 'primary', ...props }: Props) => (
  <button
    className={clsx(
      big ? 'text-2xl leading-7' : 'text-base leading-5 md:leading-6',
      'py-4 px-10 block font-semibold disabled:opacity-50 relative focus:outline-none',
      'disabled:cursor-default transition duration-500 ease-in-out',
      buttonStyles[styleType],
      className,
    )}
    disabled={disabled || loading}
    {...props}
  >
    {loading ? (
      <Spinner
        strokeClass="text-grey-4"
        fillClass="text-grey-4"
        className="!w-6 !h-6 !m-auto absolute left-0 right-0"
      />
    ) : null}
    <span className={clsx('contents', { invisible: loading })}>{props.children}</span>
  </button>
);

export default Button;

import QuestionOrganism from '../QuestionOrganism';
import { ValidationRule } from '../types';
import { Field, DateOfBirthInput } from 'components';
import { OnDateChange } from 'components/molecules/DateOfBirthInput/DateOfBirthInput';
import {
  IdentityQuestionComponentProps,
  StepNamesDefaultIdentity,
} from 'questionFlow/flows/defaultIdentity/types';
import { useAnalyticsError, useAnalyticsValues } from 'monitoring/analyticsHooks';
import { useTranslatedValues } from 'hooks/useTranslatedValues';
import { useTranslation } from 'react-i18next';

const cmsLocation = '/questions/identity/birthdate';

const translations = {
  title: `${cmsLocation}.title`,
  subtitle: `${cmsLocation}.subtitle`,
} as const;

const name = StepNamesDefaultIdentity.birthdate;

const DateOfBirth = ({
  values,
  onSubmit,
  validationRules,
  ...props
}: IdentityQuestionComponentProps) => {
  const [value, setValue] = useAnalyticsValues<{ birthdate: string; valid: boolean }>({
    birthdate: values[name] || '',
    valid: true,
  });
  const [error, setError] = useAnalyticsError(name, '');
  const { t } = useTranslation();
  const content = useTranslatedValues(translations);

  const validate = (v: string, rules: ValidationRule[], valid?: boolean) =>
    rules.find(({ rule }) => !rule(v, valid));

  const handleSubmit = () => {
    if (!error) {
      const foundError = validate(value.birthdate, validationRules[name], value.valid);

      if (foundError?.error) {
        setError(t(foundError.error as any));
      } else {
        onSubmit({ key: name, value: value.birthdate });
      }
    }
  };

  const handleOnChange: OnDateChange = ({ value, valid }) => {
    setValue({ birthdate: value, valid });

    if (valid && error) {
      setError('');
    }
  };

  const handleOnBlur = () => {
    if (!value.valid) {
      const foundError = validate(value.birthdate, validationRules[name], value.valid);
      if (foundError) {
        setError(t(foundError.error as any));
      } else if (error) {
        setError('');
      }
    }
  };

  return (
    <QuestionOrganism
      id={name}
      data-testid={name}
      onSubmit={handleSubmit}
      title={content.title}
      subtitle={content.subtitle}
      {...props}
    >
      <Field validationError={error} data-testid={name}>
        <DateOfBirthInput
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          defaultValue={value.birthdate}
          error={!!error}
        />
      </Field>
    </QuestionOrganism>
  );
};

export default DateOfBirth;

import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import SupportedLanguages from '../SupportedLanguages';
import FlagDropDown from 'components/organisms/FlagDropDown';
import { Option, Options } from 'components/molecules/DropDown/types';

const options: Options = SupportedLanguages.map(
  ({ code }) =>
    ({
      label: code.toUpperCase(),
      value: code,
    } as Option),
);

const LanguagePicker = ({ ...props }) => {
  const { i18n, t } = useTranslation();

  const onChange = useCallback((newLanguage: string) => i18n.changeLanguage(newLanguage), [i18n]);

  return (
    <FlagDropDown
      data-testid="language-picker"
      label={t('appbar.change_language')}
      options={options}
      onSelectValue={onChange}
      value={i18n.language}
      {...props}
    />
  );
};

export default LanguagePicker;

import { useTranslation, Trans, TFunction } from 'react-i18next';
import * as Sentry from '@sentry/react';
import { Options } from 'components/atoms/RadioGroup/RadioGroup';
import RadioQuestion from 'questionFlow/genericQuestions/RadioQuestion';
import { getInitialDocuments } from './DocumentOptions';
import {
  DefaultResidenceQuestionComponentProps,
  StepNamesDefaultResidence,
} from 'questionFlow/flows/defaultResidence/types';
import { OnSubmitType } from 'questionFlow/types';
import { ResidenceTypeEnum } from 'generated/graphql';
import {
  checkIfTenancyAgreementStepRequired,
  getCurrentDocType,
} from 'questionFlow/flows/defaultResidence/DefaultResidence';

const cmsLocation = '/questions/residence/documents';

const useContent = (t: TFunction<'translation'>, residenceType?: ResidenceTypeEnum) => {
  const content = {
    title: t(`${cmsLocation}.title`),
    subtitle: `${cmsLocation}.subtitle`,
    prompt: t(`${cmsLocation}.prompt`),
    none: t(`${cmsLocation}.none`),
    ID: t(`${cmsLocation}.ID`),
    DRIVING_LICENCE: t(`${cmsLocation}.DRIVING_LICENCE`),
    PASSPORT: t(`${cmsLocation}.PASSPORT`),
    ARMED_FORCES_DEPLOYMENT_LETTER: t(`${cmsLocation}.ARMED_FORCES_DEPLOYMENT_LETTER`),
    DIPLOMATIC_PASSPORT: t(`${cmsLocation}.DIPLOMATIC_PASSPORT`),
    LETTER_FROM_AUTHORISED_BODY: t(`${cmsLocation}.LETTER_FROM_AUTHORISED_BODY`),
    BENEFITS_PAPERWORK: t(`${cmsLocation}.BENEFITS_PAPERWORK`),
    TENANCY_AGREEMENT: t(`${cmsLocation}.TENANCY_AGREEMENT`),
    UTILITY_BILL: t(`${cmsLocation}.UTILITY_BILL`),
    COUNCIL_TAX_STATEMENT: t(`${cmsLocation}.COUNCIL_TAX_STATEMENT`),
    TV_LICENCE: t(`${cmsLocation}.TV_LICENCE`),
    TENANCY_DEPOSIT_SCHEME_REGISTRATION: t(`${cmsLocation}.TENANCY_DEPOSIT_SCHEME_REGISTRATION`),
    BANK_STATEMENT: t(`${cmsLocation}.BANK_STATEMENT`),
    PAYSLIP: t(`${cmsLocation}.PAYSLIP`),
    EMPLOYMENT_CONTRACT: t(`${cmsLocation}.EMPLOYMENT_CONTRACT`),
    OTHER: t(`${cmsLocation}.OTHER`),
    CREDIT_CHECK: t(`${cmsLocation}.CREDIT_CHECK`),
    STUDENT_STATUS: t(`${cmsLocation}.STUDENT_STATUS`),
    BURSARY: t(`${cmsLocation}.BURSARY`),
    SPONSORSHIP_LETTER: t(`${cmsLocation}.SPONSORSHIP_LETTER`),
    INVESTMENT_PORTFOLIO: t(`${cmsLocation}.INVESTMENT_PORTFOLIO`),
    PENSION_STATEMENT: t(`${cmsLocation}.PENSION_STATEMENT`),
    ACCOMMODATION_RECIEPT: t(`${cmsLocation}.ACCOMMODATION_RECIEPT`),
    INSURANCE_CONTRACT: t(`${cmsLocation}.INSURANCE_CONTRACT`),
    REFERENCE_BY_LETTING_AGENT: t(`${cmsLocation}.REFERENCE_BY_LETTING_AGENT`),
    FAMILY_MEMBER_PROOF_OF_ADDRESS: t(`${cmsLocation}.FAMILY_MEMBER_PROOF_OF_ADDRESS`),
    TB_CERTIFICATE: t(`${cmsLocation}.TB_CERTIFICATE`),
  };

  if (residenceType === ResidenceTypeEnum.SelfOwnedHome) {
    content.title = t(`${cmsLocation}.${residenceType}.title`);
    content.subtitle = `${cmsLocation}.${residenceType}.subtitle`;
  }

  return content;
};

const name = StepNamesDefaultResidence.documents;

const Documents = ({
  data,
  values,
  onSubmit,
  ...props
}: DefaultResidenceQuestionComponentProps) => {
  const { t } = useTranslation();

  let selectAddress = values?.[StepNamesDefaultResidence.selectAddress];

  if (!selectAddress && data.remedialSection) {
    selectAddress = values[StepNamesDefaultResidence.residentialHistory]?.find(
      ({ uuid }) => uuid === data.remedialSection.relatedObjectUuid,
    );
  }

  const residenceType = selectAddress?.residenceType || undefined;

  const content = useContent(t, residenceType);

  if (!residenceType) {
    Sentry.captureException('Residence type not found');
    return null;
  }

  const allOptions = getInitialDocuments(content);

  const hasTenancyAgreement = checkIfTenancyAgreementStepRequired(values);

  const options: Options = (allOptions[residenceType] || []).filter(option =>
    hasTenancyAgreement ? option.value !== 'TENANCY_AGREEMENT' : true,
  );

  const handleSubmit: OnSubmitType = ({ value }) => {
    const docType = getCurrentDocType({ ...values, 'select-address': selectAddress });

    const tenancy = hasTenancyAgreement
      ? (selectAddress?.documents || [])
          .filter(d => d?.path)
          .find(d => d?.type === 'TENANCY_AGREEMENT') ||
        (values.upload || []).find(d => d?.type === 'TENANCY_AGREEMENT')
      : null;

    const documents = [
      ...(docType === value
        ? (selectAddress?.documents || []).filter(d =>
            hasTenancyAgreement ? d?.type !== 'TENANCY_AGREEMENT' : true,
          )
        : [{ type: value, path: null }]),
      ...(tenancy ? [tenancy] : []),
    ];

    onSubmit({
      key: StepNamesDefaultResidence.selectAddress,
      value: {
        ...selectAddress,
        documents,
      },
    });
  };

  const initialValue = hasTenancyAgreement
    ? selectAddress?.documents?.filter(v => v?.type !== 'TENANCY_AGREEMENT')[0]?.type
    : selectAddress?.documents?.[0]?.type;

  return (
    <RadioQuestion
      content={{
        prompt: content.prompt,
        title: content.title,
        subtitle: (
          <Trans
            i18nKey={content.subtitle as any}
            values={{ address: selectAddress?.address?.formattedAddress }}
            components={{ bold: <span className="font-semibold" /> }}
          />
        ),
      }}
      initialValue={initialValue}
      name={name}
      options={options}
      onSubmit={handleSubmit}
      {...props}
    />
  );
};

export default Documents;

import { Flow, GeneralSteps, ValidationRule } from '../../types';
import { gql } from '@apollo/client';
import {
  ALLOWED_OPTION,
  createNoFutureDateRule,
  createNotAfterDateRule,
  createNotBeforeDateRule,
  createNumberRangeValidationRule,
  DATE_FORMAT,
  DATE_REQUIRED,
  EMAIL_FORMAT,
  FIELD_REQUIRED,
  NAME_LENGTH,
  NAME_PATTERN,
  PHONE_NUMBER_VALID,
  POSTCODE,
} from '../../validations';
import {
  StepNamesDefaultResidence,
  DefaultResidenceValuesType,
  DefaultResidenceQuestionComponentProps,
} from './types';
import {
  CurrencyTypesEnum,
  RentFrequencyEnum,
  Maybe,
  MutationUpdateResidentialHistoryArgs,
  ResidenceTypeEnum,
  ResidentialHistoryType,
  ReferenceTypeEnum,
  SectionMetaDataType,
} from 'generated/graphql';
import InUK from 'questionFlow/residence/inUK';
import ResidentialHistoryQuestion from 'questionFlow/residence/ResidentialHistory';
import { YesNoEnum } from 'questionFlow/types';
import { validationRules } from 'questionFlow';
import { add, differenceInMonths } from 'date-fns';
import SelectAddress from 'questionFlow/residence/SelectAddress';
import RentalDetails from 'questionFlow/residence/RentalDetails';
import { RESIDENTIAL_HISTORY_FRAGMENT } from 'questionFlow/residence/ResidentialHistory/gql';
import { pick } from 'lodash';
import HaveYouEver from 'questionFlow/residence/HaveYouEver';
import Thanks from 'questionFlow/residence/Thanks';
import ReuseConnection from 'questionFlow/residence/ReuseConnection';
import LandlordDetails from 'questionFlow/residence/landlordDetails/index';
import Documents from 'questionFlow/residence/Documents';
import UploadSingle from 'questionFlow/residence/UploadSingle';
import NoDocuments from 'questionFlow/genericQuestions/NoDocuments';
import ResidentialHistoryThreeMonthFilter from 'questionFlow/residence/ResidentialHistoryThreeMonthFilter';
import MissingInformation from 'questionFlow/genericQuestions/MissingInformation';
import { hasDocumentSignposting } from 'questionFlow/genericQuestions/Upload/utils';
import ResidenceDocSignposting from 'questionFlow/residence/ResidenceDocSignposting';
import GenericResidenceDocSignposting from 'questionFlow/residence/GenericResidenceDocSignposting';

const gqlQueryFragment = gql`
  fragment defaultResidence on Query {
    me {
      uuid
      email
      connectedOpenBanking
      residentialHistory {
        ...ResidentialHistoryFragment
      }
    }
    sectionMetaData {
      residenceSectionMetaData {
        conditions {
          ... on WizardBooleanConditionType {
            name
            description
            boolValue: value
          }
          ... on WizardIntegerConditionType {
            name
            description
            intValue: value
          }
          ... on WizardStringConditionType {
            name
            description
            strValue: value
          }
          ... on WizardListOfBooleansConditionType {
            name
            description
            listBoolValues: value
          }
          ... on WizardListOfIntegersConditionType {
            name
            description
            listIntValues: value
          }
          ... on WizardListOfStringsConditionType {
            name
            description
            listStrValues: value
          }
        }
      }
    }
  }
`;

const mutation = gql`
  mutation UpdateDefaultResidence($residence: ResidentialHistoryInput, $remedialSectionUuid: UUID) {
    updateResidentialHistory(residence: $residence) {
      residentialHistory {
        ...ResidentialHistoryFragment
      }
    }
  }
  ${RESIDENTIAL_HISTORY_FRAGMENT}
`;

const addressValidationRules: { [key: string]: ValidationRule[] } = {
  [StepNamesDefaultResidence.residentialHistory]: [FIELD_REQUIRED],
  flat: [
    {
      name: 'conditional-required',
      rule: (value, values) => !!value || !!values?.streetNumber,
      error: '/questions/residence/residentialHistory.validation.conditional_required',
    },
    {
      name: 'pattern',
      rule: v => !v || validationRules.unicodeNumericAndSpecialPattern(v),
      error: '/questions/residence/residentialHistory.validation.flat',
    },
    {
      name: 'max-length',
      rule: v => !v || validationRules.maxLength(60)(v),
      error: '/questions/residence/residentialHistory.validation.flat',
    },
  ],
  streetNumber: [
    {
      name: 'conditional-required',
      rule: (value, values) => !!value || !!values?.flat,
      error: '/questions/residence/residentialHistory.validation.conditional_required',
    },
    {
      name: 'pattern',
      rule: v => !v || validationRules.unicodeNumericAndSpecialPattern(v),
      error: '/questions/residence/residentialHistory.validation.number',
    },
    {
      name: 'max-length',
      rule: v => !v || validationRules.maxLength(60)(v),
      error: '/questions/residence/residentialHistory.validation.number',
    },
  ],
  street: [
    FIELD_REQUIRED,
    {
      name: 'pattern',
      rule: validationRules.unicodeNumericAndSpecialPattern,
      error: '/questions/residence/residentialHistory.validation.street',
    },
    {
      name: 'min-length',
      rule: validationRules.minLength(2),
      error: '/questions/residence/residentialHistory.validation.street',
    },
    {
      name: 'max-length',
      rule: validationRules.maxLength(60),
      error: '/questions/residence/residentialHistory.validation.street',
    },
  ],
  city: [
    FIELD_REQUIRED,
    {
      name: 'pattern',
      rule: validationRules.unicodePattern,
      error: '/questions/residence/residentialHistory.validation.city',
    },
    {
      name: 'min-length',
      rule: validationRules.minLength(2),
      error: '/questions/residence/residentialHistory.validation.city',
    },
    {
      name: 'max-length',
      rule: validationRules.maxLength(60),
      error: '/questions/residence/residentialHistory.validation.city',
    },
  ],
  postalCode: [FIELD_REQUIRED, POSTCODE],
  country: [FIELD_REQUIRED],
  isCurrent: [],
  start: [
    DATE_REQUIRED,
    DATE_FORMAT,
    createNoFutureDateRule('/questions/residence/residentialHistory.validation.in_date'),
    {
      name: 'after-end',
      rule: (value, values) => {
        if (!values?.end) return true;
        const diff = differenceInMonths(new Date(value), new Date(values.end));
        return diff <= 0;
      },
      error: '/questions/residence/residentialHistory.validation.in_date_out_date',
    },
  ],
  end: [
    DATE_REQUIRED,
    DATE_FORMAT,
    createNotAfterDateRule(
      add(new Date(), { months: 1 }),
      '/questions/residence/residentialHistory.validation.out_date',
    ),
    {
      name: 'before-start',
      rule: (value, values) => {
        if (!values?.start) return true;
        const diff = differenceInMonths(new Date(value), new Date(values.start));
        return diff >= 0;
      },
      error: '/questions/residence/residentialHistory.validation.out_date_in_date',
    },
  ],
  residenceType: [
    {
      name: 'allowed_option',
      rule: value => (Object.values(ResidenceTypeEnum) as string[]).includes(value),
      error: 'validation.required',
    },
  ],
};

export const checkIfTenancyAgreementStepRequired = (values: DefaultResidenceValuesType) =>
  values['select-address']?.residenceType === 'RENTED_FLAT' && values['select-address']?.isCurrent;

export const getCurrentDocType = (
  values: DefaultResidenceQuestionComponentProps['values'],
  type?: string,
) => {
  const isTenancyAgreementStepRequired = checkIfTenancyAgreementStepRequired(values);
  if (type) {
    return type;
  }
  const documents = values['select-address']?.documents;
  if (!documents) {
    return;
  }
  return documents.length === 1 || !isTenancyAgreementStepRequired
    ? documents[0]?.type
    : documents.find(doc => doc?.type !== 'TENANCY_AGREEMENT')?.type;
};

const containsAUKAddress = (residentialHistory?: Maybe<Maybe<ResidentialHistoryType>[]>): boolean =>
  !!residentialHistory?.find(rh => rh?.address?.country === 'GB');
const containsOnlyUKAddresses = (
  residentialHistory?: Maybe<Maybe<ResidentialHistoryType>[]>,
): boolean => !residentialHistory?.find(rh => rh?.address?.country !== 'GB');

const getResidentialHistorySucessor = (
  values: DefaultResidenceValuesType,
  data: SectionMetaDataType,
) => {
  const selectedResidentialHistory =
    values[StepNamesDefaultResidence.selectAddress] ||
    (values[StepNamesDefaultResidence.residentialHistory] as ResidentialHistoryType[])[0];

  const nextStep =
    selectedResidentialHistory?.residenceType === ResidenceTypeEnum.RentedFlat ||
    selectedResidentialHistory?.residenceType === ResidenceTypeEnum.StudentAccommodation
      ? StepNamesDefaultResidence.rentalDetails
      : StepNamesDefaultResidence.documents;

  if (nextStep !== StepNamesDefaultResidence.rentalDetails) return nextStep;

  const conditions = data?.residenceSectionMetaData?.conditions || [];
  const rentalSectionRequiredCondition = conditions.find(
    condition =>
      condition?.name === 'REQUIRE_RENTAL_REFERENCE' &&
      condition?.__typename === 'WizardBooleanConditionType',
  );

  if (
    typeof rentalSectionRequiredCondition === 'object' &&
    typeof (rentalSectionRequiredCondition as any).boolValue === 'boolean'
  ) {
    const hasRequireRentalReference = (rentalSectionRequiredCondition as any).boolValue;
    return hasRequireRentalReference ? nextStep : StepNamesDefaultResidence.documents;
  }
  return nextStep;
};

const DefaultResidenceFlow: Flow<
  {},
  DefaultResidenceValuesType,
  StepNamesDefaultResidence,
  void,
  MutationUpdateResidentialHistoryArgs
> = {
  start: () => StepNamesDefaultResidence.inUK,
  getQueryParams: () => {},
  queryFragment: gqlQueryFragment,
  mutation,
  handleMutationResponse: () => ({}),
  getData: (data, { email }) => {
    return {
      email,
      ...data,
    };
  },
  getDefaultValues: values => {
    // const selectedRH = values.residentialHistory?.find(rh => undefined);
    const hasAUKAddress = containsAUKAddress(values.residentialHistory);
    const hasOnlyUKAddresses = containsOnlyUKAddresses(values.residentialHistory);
    return {
      [StepNamesDefaultResidence.inUK]: values.residentialHistory
        ? hasOnlyUKAddresses
          ? YesNoEnum.YES
          : YesNoEnum.NO
        : undefined,
      [StepNamesDefaultResidence.residentialHistory]: values.residentialHistory || [],
      [StepNamesDefaultResidence.selectAddress]: undefined,
      [StepNamesDefaultResidence.haveYouEver]: values.residentialHistory
        ? hasAUKAddress
          ? YesNoEnum.YES
          : YesNoEnum.NO
        : undefined,
      [StepNamesDefaultResidence.reuseConnection]: undefined,
      connectedOpenBanking: values.connectedOpenBanking,
    };
  },
  steps: {
    [StepNamesDefaultResidence.inUK]: {
      component: InUK,
      validationRules: {
        [StepNamesDefaultResidence.inUK]: [FIELD_REQUIRED],
      },
      chooseSuccessor: ({ value }) =>
        value === YesNoEnum.YES
          ? StepNamesDefaultResidence.ukResidentialHistory
          : StepNamesDefaultResidence.residentialHistory,
    },
    [StepNamesDefaultResidence.ukResidentialHistory]: {
      component: props => <ResidentialHistoryQuestion {...props} minMonths={36} />,
      validationRules: {
        ...addressValidationRules,
        end: [
          ...addressValidationRules.end,
          createNotBeforeDateRule(
            new Date(new Date().setMonth(new Date().getMonth() - 36)),
            '/questions/residence/residentialHistory.validation.necessary_range_uk',
          ),
        ],
      },
      chooseSuccessor: ({ value, values, data }) => {
        const filteredResidence = ((value as ResidentialHistoryType[]) || []).filter(
          ResidentialHistoryThreeMonthFilter,
        );

        if (filteredResidence.length === 1)
          return getResidentialHistorySucessor(
            {
              ...values,
              [StepNamesDefaultResidence.selectAddress]: filteredResidence[0],
            },
            data,
          );

        return StepNamesDefaultResidence.selectAddress;
      },
    },
    [StepNamesDefaultResidence.residentialHistory]: {
      component: props => <ResidentialHistoryQuestion {...props} minMonths={3} />,
      validationRules: {
        ...addressValidationRules,
        end: [
          ...addressValidationRules.end,
          createNotBeforeDateRule(
            new Date(new Date().setMonth(new Date().getMonth() - 3)),
            '/questions/residence/residentialHistory.validation.necessary_range_non_uk',
          ),
        ],
      },
      chooseSuccessor: ({ value, values, data }) => {
        const filteredResidence = (value as ResidentialHistoryType[]).filter(
          ResidentialHistoryThreeMonthFilter,
        );

        if (filteredResidence.length > 1) return StepNamesDefaultResidence.selectAddress;
        if (containsAUKAddress(filteredResidence)) {
          return getResidentialHistorySucessor(
            {
              ...values,
              [StepNamesDefaultResidence.selectAddress]: filteredResidence[0],
            },
            data,
          );
        } else {
          return StepNamesDefaultResidence.haveYouEver;
        }
      },
    },
    [StepNamesDefaultResidence.selectAddress]: {
      component: SelectAddress,
      validationRules: {
        [StepNamesDefaultResidence.selectAddress]: [FIELD_REQUIRED],
      },
      chooseSuccessor: ({ values, data }) =>
        values[StepNamesDefaultResidence.inUK] === YesNoEnum.YES ||
        containsAUKAddress(values[StepNamesDefaultResidence.residentialHistory])
          ? getResidentialHistorySucessor(values, data)
          : StepNamesDefaultResidence.haveYouEver,
    },
    [StepNamesDefaultResidence.landlordDetails]: {
      component: LandlordDetails,
      validationRules: {
        [StepNamesDefaultResidence.landlordDetails]: [FIELD_REQUIRED],
        firstName: [FIELD_REQUIRED, NAME_LENGTH, NAME_PATTERN],
        lastName: [FIELD_REQUIRED, NAME_LENGTH, NAME_PATTERN],
        email: [FIELD_REQUIRED, EMAIL_FORMAT],
        phone: [FIELD_REQUIRED, PHONE_NUMBER_VALID],
        type: [
          {
            name: 'allowed_option',
            rule: value => (Object.values(ReferenceTypeEnum) as string[]).includes(value),
            error: 'validation.required',
          },
        ],
      },
      getMutationValues: (values, remedialSectionUuid) => {
        const reference = values?.[StepNamesDefaultResidence.selectAddress]?.reference;
        return {
          residence: {
            uuid: values[StepNamesDefaultResidence.selectAddress]?.uuid,
            referenceFirstName: reference?.firstName,
            referenceLastName: reference?.lastName,
            referenceEmail: reference?.email,
            referencePhone: reference?.phone,
            referenceType: reference?.type,
            remedialSectionUuid,
          },
        } as Partial<MutationUpdateResidentialHistoryArgs>;
      },
      chooseSuccessor: ({ values }) => {
        const isTenancyAgreementStepRequired = checkIfTenancyAgreementStepRequired(values);

        if (isTenancyAgreementStepRequired)
          return StepNamesDefaultResidence.documentSignpostingForTenancyAgreement;
        return StepNamesDefaultResidence.thanks;
      },
    },
    [StepNamesDefaultResidence.rentalDetails]: {
      component: RentalDetails,
      validationRules: {
        [StepNamesDefaultResidence.rentalDetails]: [FIELD_REQUIRED],
        rent: [
          FIELD_REQUIRED,
          createNumberRangeValidationRule(
            1,
            10000000,
            '/questions/residence/rental-details.validations.rent',
          ),
        ],
        rentFrequency: [
          {
            name: 'allowed_option',
            rule: value => (Object.values(RentFrequencyEnum) as string[]).includes(value),
            error: 'validation.required',
          },
        ],
        rentPaymentDate: [
          DATE_REQUIRED,
          DATE_FORMAT,
          createNotBeforeDateRule(
            new Date(new Date().setMonth(new Date().getMonth() - 3)),
            '/questions/residence/rental-details.validations.threeMonths',
          ),
          createNotAfterDateRule(
            new Date(),
            '/questions/residence/rental-details.validations.rentPaymentDate',
          ),
        ],
        currency: [
          {
            name: 'allowed_option',
            rule: value => (Object.values(CurrencyTypesEnum) as string[]).includes(value),
            error: 'validation.required',
          },
        ],
      },
      getMutationValues: values =>
        ({
          residence: pick(values[StepNamesDefaultResidence.selectAddress], [
            'uuid',
            'rent',
            'rentPaymentDate',
            'rentFrequency',
            'currency',
          ]),
        } as Partial<MutationUpdateResidentialHistoryArgs>),
      chooseSuccessor: () => StepNamesDefaultResidence.landlordDetails,
    },
    [StepNamesDefaultResidence.haveYouEver]: {
      component: HaveYouEver,
      validationRules: {
        [StepNamesDefaultResidence.haveYouEver]: [ALLOWED_OPTION],
      },
      chooseSuccessor: ({ value, values, data }) =>
        value === YesNoEnum.YES
          ? StepNamesDefaultResidence.singleUKResidentialHistory
          : getResidentialHistorySucessor(values, data),
    },
    [StepNamesDefaultResidence.singleUKResidentialHistory]: {
      component: props => <ResidentialHistoryQuestion {...props} single isUKAddress />,
      validationRules: addressValidationRules,
      chooseSuccessor: ({ values, data }) => getResidentialHistorySucessor(values, data),
    },
    [StepNamesDefaultResidence.documents]: {
      component: Documents,
      validationRules: {
        documents: [FIELD_REQUIRED],
      },
      chooseSuccessor: ({ values }) => {
        const docType: any = getCurrentDocType(values);

        if (docType === 'none') {
          return StepNamesDefaultResidence.none;
        }
        if (hasDocumentSignposting(docType)) {
          return StepNamesDefaultResidence.documentSignposting;
        }
        return StepNamesDefaultResidence.upload;
      },
    },
    [StepNamesDefaultResidence.documentSignposting]: {
      component: ResidenceDocSignposting,
      validationRules: {
        agreement: [FIELD_REQUIRED],
      },
      chooseSuccessor: () => StepNamesDefaultResidence.upload,
    },
    [StepNamesDefaultResidence.documentSignpostingForTenancyAgreement]: {
      component: props => <ResidenceDocSignposting {...props} type="TENANCY_AGREEMENT" />,
      validationRules: {
        agreement: [FIELD_REQUIRED],
      },
      chooseSuccessor: () => StepNamesDefaultResidence.uploadForTenancyAgreement,
    },
    [StepNamesDefaultResidence.uploadForTenancyAgreement]: {
      component: props => <UploadSingle {...props} type="TENANCY_AGREEMENT" />,
      getMutationValues: (values, remedialSectionUuid) => {
        const uploadedDocs = (values[StepNamesDefaultResidence.upload] || []).length
          ? (values[StepNamesDefaultResidence.upload] as any)
          : [];
        const existingDocuments = (values[StepNamesDefaultResidence.selectAddress]?.documents || [])
          .filter(v => v?.path)
          .filter(v => v?.type !== 'TENANCY_AGREEMENT');

        return {
          residence: {
            uuid: values[StepNamesDefaultResidence.selectAddress]?.uuid,
            documents: [...uploadedDocs, ...existingDocuments].map(({ __typename, ...doc }) => doc),
            remedialSectionUuid,
          },
        } as Partial<MutationUpdateResidentialHistoryArgs>;
      },
      validationRules: {},
      chooseSuccessor: () => StepNamesDefaultResidence.thanks,
    },
    [StepNamesDefaultResidence.none]: {
      component: NoDocuments,
      validationRules: {},
    },
    [StepNamesDefaultResidence.upload]: {
      component: UploadSingle,
      getMutationValues: (values, remedialSectionUuid) => {
        const isTenancyAgreementStepRequired = checkIfTenancyAgreementStepRequired(values);

        const uploadedDocs = (values[StepNamesDefaultResidence.upload] || []).length
          ? (values[StepNamesDefaultResidence.upload] as any)
          : [];

        const existingDocuments = (values[StepNamesDefaultResidence.selectAddress]?.documents || [])
          .filter(v => v?.path)
          .filter(v => v?.type !== uploadedDocs[0]?.type)
          .filter(v => (isTenancyAgreementStepRequired ? v?.type === 'TENANCY_AGREEMENT' : false));

        return {
          residence: {
            uuid: values[StepNamesDefaultResidence.selectAddress]?.uuid,
            documents: [...existingDocuments, ...uploadedDocs].map(({ __typename, ...doc }) => doc),
            remedialSectionUuid,
          },
        } as Partial<MutationUpdateResidentialHistoryArgs>;
      },
      validationRules: {},
    },
    [GeneralSteps.genericUpload]: {
      component: GenericResidenceDocSignposting,
      validationRules: {
        agreement: [FIELD_REQUIRED],
      },
      chooseSuccessor: () => StepNamesDefaultResidence.upload,
    },
    [StepNamesDefaultResidence.thanks]: {
      component: Thanks,
      validationRules: {},
      chooseSuccessor: () => StepNamesDefaultResidence.documents,
    },
    [StepNamesDefaultResidence.reuseConnection]: {
      component: ReuseConnection,
      validationRules: {
        [StepNamesDefaultResidence.reuseConnection]: [ALLOWED_OPTION],
      },
      chooseSuccessor: () => StepNamesDefaultResidence.placeholder,
    },
    [GeneralSteps.missingInformation]: {
      component: MissingInformation,
      validationRules: {},
      chooseSuccessor: ({ data }) => data.remedialSection?.start as StepNamesDefaultResidence,
    },
  },
};

export default DefaultResidenceFlow;

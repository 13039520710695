export type StringQuestion = 'bodyName';
export type DateQuestion = 'startDate' | 'endDate';
export type LoanDetailsQuestion = StringQuestion | DateQuestion;

export function assertIsLoanDetailsStringQuestion(kind: string): asserts kind is StringQuestion {
  if (kind !== 'bodyName') {
    throw new Error(`${kind} is not a StringQuestion type`);
  }
}

function isLoanDetailsDateQuestion(kind: string): kind is DateQuestion {
  return ['startDate', 'endDate'].includes(kind);
}

export function assertIsLoanDetailsDateQuestion(kind: string): asserts kind is DateQuestion {
  if (!isLoanDetailsDateQuestion(kind)) {
    throw new Error(`${kind} is not a DateQuestion type`);
  }
}

type StringValues = Record<StringQuestion, string>;

export type DateValue = {
  valid: boolean;
  value: string;
};

type DateValues = Record<DateQuestion, DateValue>;

export function isDateValue(maybeDate: string | DateValue): maybeDate is DateValue {
  return typeof maybeDate !== 'string';
}

export interface LoanDetailsValues extends DateValues, StringValues {}

export type ExternalFormat = Record<LoanDetailsQuestion, string>;

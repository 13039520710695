import { Field, Checkbox, DateInput, DropDown } from 'components';
import { TFunction, useTranslation } from 'react-i18next';
import { OnDateChange } from 'components/molecules/DateInput/DateInput';
import { ValidationRule } from 'questionFlow/types';
import { residentialInputs } from './ResidentialHistoryForm';
import { FullResidentialHistoryType } from './Accordion';
import useResidenceTypeOptions from './ResidenceTypeOptions';
import { ResidenceTypeEnum } from 'generated/graphql';
import { pick } from 'lodash';

interface Props {
  currentSet?: boolean;
  values: FullResidentialHistoryType;
  setValues: React.Dispatch<React.SetStateAction<FullResidentialHistoryType>>;
  errors: { [key in keyof typeof residentialInputs]: string };
  setErrors: React.Dispatch<React.SetStateAction<any>>;
  validationRules: { [key: string]: ValidationRule[] };
}

const cmsLocation = '/questions/residence/residentialHistory';

const useContent = (t: TFunction) => ({
  [residentialInputs.isCurrent]: {
    label: t(`${cmsLocation}.isCurrent.label`),
  },
  [residentialInputs.start]: {
    label: t(`${cmsLocation}.start.label`),
    monthInputLabel: t(`${cmsLocation}.start.monthInputLabel`),
    yearInputLabel: t(`${cmsLocation}.start.yearInputLabel`),
  },
  [residentialInputs.end]: {
    label: t(`${cmsLocation}.end.label`),
    monthInputLabel: t(`${cmsLocation}.end.monthInputLabel`),
    yearInputLabel: t(`${cmsLocation}.end.yearInputLabel`),
  },
  [residentialInputs.residenceType]: {
    label: t(`${cmsLocation}.residenceType.label`),
    placeholder: t(`${cmsLocation}.residenceType.placeholder`),
  },
});

const ResidentialFormFields = ({
  currentSet,
  values,
  setValues,
  errors,
  setErrors,
  validationRules,
}: Props) => {
  const { t } = useTranslation();
  const content = useContent(t);

  const ResidenceTypeOptions = useResidenceTypeOptions();

  const validate = (v: string, rules: ValidationRule[], comparison?: any) =>
    rules.find(({ rule }) => !rule(v, comparison));

  const handleCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;

    setValues({ ...values, isCurrent: checked, end: checked ? '' : values.end });
  };

  const handleDateChange: OnDateChange = ({ name, value, valid }) => {
    let inputName = name as 'start' | 'end';

    if (valid) {
      setValues({ ...values, [inputName]: value });
      if (errors[inputName]) {
        const result = validate(value, validationRules[name]);
        if (!result) {
          setErrors({ ...errors, [inputName]: '' });
        }
      }
    } else if (values[inputName]) {
      setValues({ ...values, [inputName]: '' });
    }
  };

  const handleBlur = (key: residentialInputs) => () => {
    const errorUpdate: any = {};
    const result = validate(values[key], validationRules[key], pick(values, ['start', 'end']));
    if (result) {
      errorUpdate[key] = t(result.error as any);
      setErrors({ ...errors, ...errorUpdate });
      return;
    } else {
      errorUpdate[key] = '';
    }

    let secondaryKey = null;

    if (key === residentialInputs.start) secondaryKey = residentialInputs.end;
    if (key === residentialInputs.end) secondaryKey = residentialInputs.start;

    if (secondaryKey) {
      const result = validate(
        values[secondaryKey],
        validationRules[secondaryKey],
        pick(values, [key]),
      );
      if (result) {
        errorUpdate[secondaryKey] = t(result.error as any);
      } else {
        errorUpdate[secondaryKey] = '';
      }
    }
    setErrors({ ...errors, errorUpdate });
  };

  const handleSelectResidenceType = (newValue: string) => {
    if (errors[residentialInputs.residenceType]) {
      setErrors({ ...errors, [residentialInputs.residenceType]: '' });
    }
    setValues({ ...values, [residentialInputs.residenceType]: newValue as ResidenceTypeEnum });
  };

  return (
    <>
      {((currentSet && values.isCurrent) || !currentSet) && (
        <Field data-testid="current-address" className="mb-4">
          <Checkbox
            id="current-address"
            className="items-center"
            color="grey"
            checked={values.isCurrent}
            onChange={handleCheckbox}
          >
            <label className="ml-4" htmlFor="current-address">
              {content[residentialInputs.isCurrent].label}
            </label>
          </Checkbox>
        </Field>
      )}

      <div className="grid grid-cols-1 gap-y-4 md:gap-y-0 md:grid-cols-2 w-full mb-4">
        <Field
          label={content[residentialInputs.start].label}
          data-testid="start-date"
          validationError={errors[residentialInputs.start]}
        >
          <DateInput
            id={residentialInputs.start}
            hideDayInput
            defaultValue={values[residentialInputs.start]}
            dayLabel=""
            monthLabel={content[residentialInputs.start].monthInputLabel}
            yearLabel={content[residentialInputs.start].yearInputLabel}
            onChange={handleDateChange}
            onBlur={handleBlur(residentialInputs.start)}
          />
        </Field>

        {!values.isCurrent && (
          <Field
            label={content[residentialInputs.end].label}
            data-testid="end-date"
            validationError={errors[residentialInputs.end]}
          >
            <DateInput
              id={residentialInputs.end}
              hideDayInput
              defaultValue={values[residentialInputs.end]}
              dayLabel=""
              monthLabel={content[residentialInputs.end].monthInputLabel}
              yearLabel={content[residentialInputs.end].yearInputLabel}
              onChange={handleDateChange}
              onBlur={handleBlur(residentialInputs.end)}
            />
          </Field>
        )}
      </div>

      <Field
        label={content[residentialInputs.residenceType].label}
        data-testid="residence-type"
        validationError={errors[residentialInputs.residenceType]}
      >
        <DropDown
          label={content.residenceType.label}
          placeholder={content.residenceType.placeholder}
          options={ResidenceTypeOptions}
          value={values[residentialInputs.residenceType] || ''}
          onSelectValue={handleSelectResidenceType}
          error={!!errors[residentialInputs.residenceType]}
        />
      </Field>
    </>
  );
};

export default ResidentialFormFields;

import { useMemo } from 'react';
import {
  IdentityQuestionComponentProps,
  StepNamesDefaultIdentity,
} from 'questionFlow/flows/defaultIdentity/types';
import { useTranslation, Trans, TFunction } from 'react-i18next';
import { capitalize, startCase } from 'lodash';
import { Options } from 'components/atoms/RadioGroup/RadioGroup';
import RadioQuestion from 'questionFlow/genericQuestions/RadioQuestion';

const cmsLocation = '/questions/identity/documents';

const useContent = (t: TFunction<'translation'>) => ({
  title: t(`${cmsLocation}.title`),
  prompt: t(`${cmsLocation}.prompt`),
  none: t(`${cmsLocation}.none`),
});

const name = StepNamesDefaultIdentity.documents;

const Documents = ({ data, values, ...props }: IdentityQuestionComponentProps) => {
  const { t } = useTranslation();
  const content = useContent(t);

  const options: Options = useMemo(() => {
    const countryDocsMap = data?.countriesDocsMap?.find(
      ({ countryCode }) => countryCode === values.nationality,
    );

    if (!countryDocsMap || !countryDocsMap.allowedIdentityDocs) {
      throw new Error('Country code document mapping not found!');
      //TODO throw specific sentry exception so we can track it
    }

    return [
      ...countryDocsMap.allowedIdentityDocs.map(doc => {
        const labelLocation = `${cmsLocation}.${doc}`;
        const label = t(labelLocation as any);
        return {
          value: doc,
          name: doc,
          label: label === labelLocation ? startCase(capitalize(doc)) : label,
        };
      }),
      { value: 'none', label: content.none, name: 'none' },
    ];
  }, [data.countriesDocsMap, values.nationality, t, content]);

  return (
    <RadioQuestion
      content={{
        prompt: content.prompt,
        title: content.title,
        subtitle: (
          <Trans
            i18nKey={`${cmsLocation}.subtitle` as any}
            components={{ bold: <span className="font-semibold" /> }}
          />
        ),
      }}
      initialValue={values[name]}
      name={name}
      options={options}
      {...props}
    />
  );
};

export default Documents;

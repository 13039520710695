import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  DateTime: any;
  Decimal: any;
  UUID: any;
};

export type AccountDocumentGqlType = {
  __typename?: 'AccountDocumentGQLType';
  id: Scalars['ID'];
  type: AccountDocumentType;
  path?: Maybe<Scalars['String']>;
  uuid: Scalars['UUID'];
};

export enum AccountDocumentType {
  Id = 'ID',
  DrivingLicence = 'DRIVING_LICENCE',
  Passport = 'PASSPORT',
  BritishNationalOverseasPassport = 'BRITISH_NATIONAL_OVERSEAS_PASSPORT',
  ArmedForcesDeploymentLetter = 'ARMED_FORCES_DEPLOYMENT_LETTER',
  LandlordsCheckingServiceDocument = 'LANDLORDS_CHECKING_SERVICE_DOCUMENT',
  DiplomaticPassport = 'DIPLOMATIC_PASSPORT',
  LetterFromAuthorisedBody = 'LETTER_FROM_AUTHORISED_BODY',
  GuarantorPassport = 'GUARANTOR_PASSPORT',
  GuarantorIdCard = 'GUARANTOR_ID_CARD',
  GuarantorDrivingLicence = 'GUARANTOR_DRIVING_LICENCE',
  TravelBooking = 'TRAVEL_BOOKING',
  UkVisaApplicationLetter = 'UK_VISA_APPLICATION_LETTER',
  EuShareCodeVerificationDocument = 'EU_SHARE_CODE_VERIFICATION_DOCUMENT',
  LivePhoto = 'LIVE_PHOTO',
  IdvtReport = 'IDVT_REPORT',
  UkImmigrationStatus = 'UK_IMMIGRATION_STATUS',
  UkBiometricResidence = 'UK_BIOMETRIC_RESIDENCE',
  Naturalization = 'NATURALIZATION',
  BritishCitizen = 'BRITISH_CITIZEN',
  UkDrivingLicence = 'UK_DRIVING_LICENCE',
  BirthAdoption = 'BIRTH_ADOPTION',
  ArmedForces = 'ARMED_FORCES',
  BenefitsPaperwork = 'BENEFITS_PAPERWORK',
  Ccj = 'CCJ',
  TimeLimitedPassport = 'TIME_LIMITED_PASSPORT',
  TenancyAgreement = 'TENANCY_AGREEMENT',
  UtilityBill = 'UTILITY_BILL',
  CouncilTaxStatement = 'COUNCIL_TAX_STATEMENT',
  TvLicence = 'TV_LICENCE',
  TenancyDepositSchemeRegistration = 'TENANCY_DEPOSIT_SCHEME_REGISTRATION',
  Licence = 'LICENCE',
  OwnershipCertificate = 'OWNERSHIP_CERTIFICATE',
  Mortgage = 'MORTGAGE',
  BankStatement = 'BANK_STATEMENT',
  Payslip = 'PAYSLIP',
  EmploymentContract = 'EMPLOYMENT_CONTRACT',
  Employment = 'EMPLOYMENT',
  Sa302 = 'SA302',
  Truelayer = 'TRUELAYER',
  HomepplBankStatementTransactions = 'HOMEPPL_BANK_STATEMENT_TRANSACTIONS',
  ConsentForm = 'CONSENT_FORM',
  Other = 'OTHER',
  UkPassport = 'UK_PASSPORT',
  CreditCheck = 'CREDIT_CHECK',
  StudentStatus = 'STUDENT_STATUS',
  TaxReturn = 'TAX_RETURN',
  Bursary = 'BURSARY',
  SponsorshipLetter = 'SPONSORSHIP_LETTER',
  CompanyAccounts = 'COMPANY_ACCOUNTS',
  InvestmentPortfolio = 'INVESTMENT_PORTFOLIO',
  AccountantLetter = 'ACCOUNTANT_LETTER',
  PensionStatement = 'PENSION_STATEMENT',
  AccommodationReciept = 'ACCOMMODATION_RECIEPT',
  LandRegistry = 'LAND_REGISTRY',
  InsuranceContract = 'INSURANCE_CONTRACT',
  ReferenceByLettingAgent = 'REFERENCE_BY_LETTING_AGENT',
  LightCheckReport = 'LIGHT_CHECK_REPORT',
  GuarantorCheckReport = 'GUARANTOR_CHECK_REPORT',
  Guarantor = 'GUARANTOR',
  FamilyMemberProofOfAddress = 'FAMILY_MEMBER_PROOF_OF_ADDRESS'
}

export type AddressType = {
  __typename?: 'AddressType';
  street?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  flat?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country: Scalars['String'];
  postalCode?: Maybe<Scalars['String']>;
  formattedAddress: Scalars['String'];
  placeId?: Maybe<Scalars['String']>;
  houseName?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  isUkAddress?: Maybe<Scalars['Boolean']>;
};

export enum CurrencyTypesEnum {
  Eur = 'EUR',
  Gbp = 'GBP',
  Usd = 'USD'
}




export type DeleteResidentialHistoryMutation = {
  __typename?: 'DeleteResidentialHistoryMutation';
  success?: Maybe<Scalars['Boolean']>;
  uuid?: Maybe<Scalars['UUID']>;
};

export type DocMapCountryToIdentityType = {
  __typename?: 'DocMapCountryToIdentityType';
  countryCode: Scalars['String'];
  allowedIdentityDocs?: Maybe<Array<GqlDocTypeEnum>>;
};

export type DocumentInputType = {
  id?: Maybe<Scalars['Int']>;
  uuid?: Maybe<Scalars['UUID']>;
  type: Scalars['String'];
  path: Scalars['String'];
};

export type DocumentUploadDefinitionType = {
  __typename?: 'DocumentUploadDefinitionType';
  docType: GqlDocTypeEnum;
  docName: Scalars['String'];
  acceptedFileTypes?: Maybe<Array<Maybe<GqlAcceptedFileTypesEnum>>>;
  sides: Scalars['Int'];
  sideNames?: Maybe<Array<Scalars['String']>>;
};

export type FinancialInstitutionObjectType = {
  __typename?: 'FinancialInstitutionObjectType';
  name: Scalars['String'];
  institutionType?: Maybe<Scalars['Int']>;
};

export enum GqlAcceptedFileTypesEnum {
  Pdf = 'PDF',
  Jpg = 'JPG',
  Png = 'PNG'
}

export enum GqlDocTypeEnum {
  Passport = 'PASSPORT',
  DrivingLicence = 'DRIVING_LICENCE',
  NationalId = 'NATIONAL_ID',
  ResidencePermit = 'RESIDENCE_PERMIT',
  NexusCard = 'NEXUS_CARD',
  Aadhaar = 'AADHAAR',
  Pan = 'PAN',
  Mykad = 'MYKAD',
  PostalId = 'POSTAL_ID',
  ProfessionalId = 'PROFESSIONAL_ID',
  SssIdCard = 'SSS_ID_CARD',
  VoterId = 'VOTER_ID',
  StateId = 'STATE_ID'
}

export enum GqlEmploymentStatusEnum {
  NotEmployed = 'NOT_EMPLOYED',
  Employed = 'EMPLOYED'
}

export enum GqlPaymentFrequencyEnum {
  Daily = 'DAILY',
  Monthly = 'MONTHLY',
  Weekly = 'WEEKLY',
  Weekly_2 = 'WEEKLY_2'
}

export enum GenderEnum {
  Female = 'FEMALE',
  Male = 'MALE'
}

export type GuarantorType = {
  __typename?: 'GuarantorType';
  id: Scalars['ID'];
  uuid: Scalars['UUID'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive: Scalars['Boolean'];
  dateJoined: Scalars['DateTime'];
  phoneNumber?: Maybe<Scalars['String']>;
};

export type IdentitySectionMetaDataType = {
  __typename?: 'IdentitySectionMetaDataType';
  conditions?: Maybe<Array<Maybe<WizardConditionType>>>;
  documentUploadDefinitions?: Maybe<Array<DocumentUploadDefinitionType>>;
  countriesDocsMap?: Maybe<Array<Maybe<DocMapCountryToIdentityType>>>;
  enableIdvt?: Maybe<Scalars['Boolean']>;
};

export type IncomeReferenceType = {
  __typename?: 'IncomeReferenceType';
  id: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type IncomeSectionMetaDataType = {
  __typename?: 'IncomeSectionMetaDataType';
  conditions?: Maybe<Array<Maybe<WizardConditionType>>>;
};

export type IncomeSourceMutation = {
  __typename?: 'IncomeSourceMutation';
  reference?: Maybe<IncomeReferenceType>;
  incomeSource?: Maybe<IncomeSourceType>;
  meetsAffordabilityCriteria?: Maybe<Scalars['Boolean']>;
};

export type IncomeSourceType = {
  __typename?: 'IncomeSourceType';
  uuid: Scalars['UUID'];
  currency?: Maybe<CurrencyTypesEnum>;
  incomeType: IncomeSourceTypeEnum;
  netIncomeFrequency?: Maybe<Scalars['String']>;
  netIncome?: Maybe<Scalars['Decimal']>;
  paymentDate?: Maybe<Scalars['String']>;
  reference?: Maybe<IncomeReferenceType>;
  annualGrossIncome?: Maybe<Scalars['Decimal']>;
  currencySign?: Maybe<Scalars['String']>;
  /** @deprecated Switched to financialInstitionDetails model */
  financialInstitution?: Maybe<Scalars['String']>;
  financialInstitutionDetails?: Maybe<FinancialInstitutionObjectType>;
  accountDocuments?: Maybe<Array<Maybe<AccountDocumentGqlType>>>;
  companyName?: Maybe<Scalars['String']>;
  companyNumber?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  probation?: Maybe<ProbationType>;
  isOnProbation?: Maybe<Scalars['Boolean']>;
};


export type IncomeSourceTypeReferenceArgs = {
  remedialSectionUuid?: Maybe<Scalars['UUID']>;
};


export type IncomeSourceTypeAccountDocumentsArgs = {
  remedialSectionUuid?: Maybe<Scalars['UUID']>;
};

export enum IncomeSourceTypeEnum {
  TBenfits = 'T_BENFITS',
  TEmployedExp = 'T_EMPLOYED_EXP',
  TEmployedPb = 'T_EMPLOYED_PB',
  TEmployedPerm = 'T_EMPLOYED_PERM',
  TEmployedTemp = 'T_EMPLOYED_TEMP',
  TEmployedZh = 'T_EMPLOYED_ZH',
  TGuarantor = 'T_GUARANTOR',
  TNoincome = 'T_NOINCOME',
  TPension = 'T_PENSION',
  TSavings = 'T_SAVINGS',
  TScholarshipLoans = 'T_SCHOLARSHIP_LOANS',
  TSelfemployed = 'T_SELFEMPLOYED',
  TZeroPercentRentShare = 'T_ZERO_PERCENT_RENT_SHARE'
}

export type IncomeType = {
  __typename?: 'IncomeType';
  employmentStatus?: Maybe<GqlEmploymentStatusEnum>;
  meetsAffordabilityCriteria?: Maybe<Scalars['Boolean']>;
  incomeSources?: Maybe<Array<IncomeSourceType>>;
  someoneElsePayingForRent?: Maybe<Scalars['Boolean']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  updateOnboardingConsents?: Maybe<OnboardingConsentsMutation>;
  me?: Maybe<UserMutation>;
  updateIncome?: Maybe<IncomeSourceMutation>;
  updateWizardProgress?: Maybe<WizardProgressMutation>;
  updateResidentialHistory?: Maybe<ResidentialHistoryMutation>;
  deleteResidentialHistory?: Maybe<DeleteResidentialHistoryMutation>;
  submitValidation?: Maybe<SubmitValidationMutation>;
};


export type MutationUpdateOnboardingConsentsArgs = {
  backgroundChecks: Scalars['Boolean'];
  dataAccuracy: Scalars['Boolean'];
  marketing?: Maybe<Scalars['Boolean']>;
  termsAndConditions: Scalars['Boolean'];
};


export type MutationMeArgs = {
  birthdate?: Maybe<Scalars['Date']>;
  firstName?: Maybe<Scalars['String']>;
  guarantorEmail?: Maybe<Scalars['String']>;
  guarantorFirstName?: Maybe<Scalars['String']>;
  guarantorLastName?: Maybe<Scalars['String']>;
  guarantorPhoneNumber?: Maybe<Scalars['String']>;
  identityDocuments?: Maybe<Array<Maybe<DocumentInputType>>>;
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  nationality?: Maybe<Scalars['String']>;
  remedialSectionUuid?: Maybe<Scalars['UUID']>;
  shareCode?: Maybe<Scalars['String']>;
};


export type MutationUpdateIncomeArgs = {
  accountDocuments?: Maybe<Array<Maybe<DocumentInputType>>>;
  annualGrossIncome?: Maybe<Scalars['Decimal']>;
  companyName?: Maybe<Scalars['String']>;
  companyNumber?: Maybe<Scalars['String']>;
  currency?: Maybe<CurrencyTypesEnum>;
  endDate?: Maybe<Scalars['Date']>;
  financialInstitutionName?: Maybe<Scalars['String']>;
  incomeType?: Maybe<IncomeSourceTypeEnum>;
  jobTitle?: Maybe<Scalars['String']>;
  netIncome?: Maybe<Scalars['Decimal']>;
  netIncomeFrequency?: Maybe<GqlPaymentFrequencyEnum>;
  paymentDate?: Maybe<Scalars['String']>;
  probationDuration?: Maybe<Scalars['Int']>;
  probationDurationUnit?: Maybe<TimeUnitsEnum>;
  referenceEmail?: Maybe<Scalars['String']>;
  referenceName?: Maybe<Scalars['String']>;
  remedialSectionUuid?: Maybe<Scalars['UUID']>;
  removeProbation?: Maybe<Scalars['Boolean']>;
  startDate?: Maybe<Scalars['Date']>;
  uuid?: Maybe<Scalars['UUID']>;
};


export type MutationUpdateWizardProgressArgs = {
  completed?: Maybe<Scalars['Boolean']>;
  completedSteps?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  relatedContentType?: Maybe<Scalars['String']>;
  relatedObjectUuid?: Maybe<Scalars['UUID']>;
};


export type MutationUpdateResidentialHistoryArgs = {
  residence?: Maybe<ResidentialHistoryInput>;
};


export type MutationDeleteResidentialHistoryArgs = {
  uuid?: Maybe<Scalars['UUID']>;
};

export type NameType = {
  __typename?: 'NameType';
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
};

export type OnboardingConsentsMutation = {
  __typename?: 'OnboardingConsentsMutation';
  userConsents?: Maybe<UserConsentType>;
  validationConsents?: Maybe<ValidationConsentsType>;
};

export type ProbationType = {
  __typename?: 'ProbationType';
  id: Scalars['ID'];
  duration: Scalars['Int'];
  durationUnit: TimeUnitsEnum;
  endDate?: Maybe<Scalars['Date']>;
};

export type ProfileType = {
  __typename?: 'ProfileType';
  gender?: Maybe<GenderEnum>;
  nationality?: Maybe<Scalars['String']>;
  shareCode: Scalars['String'];
  birthdate?: Maybe<Scalars['Date']>;
  isStudent: Scalars['Boolean'];
  profilePictureUrl?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  me: UserType;
  sectionMetaData?: Maybe<SectionMetaDataType>;
};

export enum ReferenceTypeEnum {
  Accountant = 'ACCOUNTANT',
  Agent = 'AGENT',
  EmployerOrHr = 'EMPLOYER_OR_HR',
  FamilyMember = 'FAMILY_MEMBER',
  Guarantor = 'GUARANTOR',
  Housemate = 'HOUSEMATE',
  Landlord = 'LANDLORD',
  ManagingCompany = 'MANAGING_COMPANY',
  Neighbor = 'NEIGHBOR'
}

export enum RentFrequencyEnum {
  Daily = 'DAILY',
  Monthly = 'MONTHLY',
  Monthly_12 = 'MONTHLY_12',
  Monthly_3 = 'MONTHLY_3',
  Weekly = 'WEEKLY',
  Weekly_2 = 'WEEKLY_2'
}

export type ResidenceSectionMetaDataType = {
  __typename?: 'ResidenceSectionMetaDataType';
  conditions?: Maybe<Array<Maybe<WizardConditionType>>>;
};

export enum ResidenceTypeEnum {
  FamilyHome = 'FAMILY_HOME',
  InformalArrangement = 'INFORMAL_ARRANGEMENT',
  RentedFlat = 'RENTED_FLAT',
  SelfOwnedHome = 'SELF_OWNED_HOME',
  StudentAccommodation = 'STUDENT_ACCOMMODATION'
}

export type ResidentialHistoryInput = {
  uuid?: Maybe<Scalars['UUID']>;
  residenceType?: Maybe<ResidenceTypeEnum>;
  start?: Maybe<Scalars['Date']>;
  end?: Maybe<Scalars['Date']>;
  rent?: Maybe<Scalars['Decimal']>;
  rentFrequency?: Maybe<RentFrequencyEnum>;
  rentPaymentDate?: Maybe<Scalars['String']>;
  isCurrent?: Maybe<Scalars['Boolean']>;
  currency?: Maybe<CurrencyTypesEnum>;
  remedialSectionUuid?: Maybe<Scalars['UUID']>;
  documents?: Maybe<Array<Maybe<DocumentInputType>>>;
  street?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  flat?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  formattedAddress?: Maybe<Scalars['String']>;
  placeId?: Maybe<Scalars['String']>;
  houseName?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  referenceFirstName?: Maybe<Scalars['String']>;
  referenceLastName?: Maybe<Scalars['String']>;
  referenceEmail?: Maybe<Scalars['String']>;
  referencePhone?: Maybe<Scalars['String']>;
  referenceType?: Maybe<ReferenceTypeEnum>;
};

export type ResidentialHistoryMutation = {
  __typename?: 'ResidentialHistoryMutation';
  residentialHistory?: Maybe<ResidentialHistoryType>;
};

export type ResidentialHistoryType = {
  __typename?: 'ResidentialHistoryType';
  uuid: Scalars['UUID'];
  residenceType?: Maybe<ResidenceTypeEnum>;
  address?: Maybe<AddressType>;
  start: Scalars['Date'];
  end: Scalars['Date'];
  isCurrent: Scalars['Boolean'];
  currency?: Maybe<CurrencyTypesEnum>;
  rent?: Maybe<Scalars['Decimal']>;
  rentFrequency?: Maybe<RentFrequencyEnum>;
  rentPaymentDate?: Maybe<Scalars['String']>;
  documents?: Maybe<Array<Maybe<AccountDocumentGqlType>>>;
  reference?: Maybe<ResidentialReferenceType>;
};


export type ResidentialHistoryTypeDocumentsArgs = {
  remedialSectionUuid?: Maybe<Scalars['UUID']>;
};


export type ResidentialHistoryTypeReferenceArgs = {
  remedialSectionUuid?: Maybe<Scalars['UUID']>;
};

export type ResidentialReferenceType = {
  __typename?: 'ResidentialReferenceType';
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  type?: Maybe<ReferenceTypeEnum>;
};

export type SectionMetaDataType = {
  __typename?: 'SectionMetaDataType';
  wizardFlowMetaData?: Maybe<WizardFlowMetaDataType>;
  identitySectionMetaData?: Maybe<IdentitySectionMetaDataType>;
  incomeSectionMetaData?: Maybe<IncomeSectionMetaDataType>;
  residenceSectionMetaData?: Maybe<ResidenceSectionMetaDataType>;
};

export type SubmitValidationMutation = {
  __typename?: 'SubmitValidationMutation';
  submittedValidation?: Maybe<ValidationType>;
};

export enum TimeUnitsEnum {
  Months = 'MONTHS',
  Weeks = 'WEEKS'
}


export type UserConsentType = {
  __typename?: 'UserConsentType';
  marketing?: Maybe<Scalars['Boolean']>;
};

export type UserMutation = {
  __typename?: 'UserMutation';
  name?: Maybe<NameType>;
  guarantor?: Maybe<GuarantorType>;
  profile?: Maybe<ProfileType>;
  identityDocuments?: Maybe<Array<Maybe<AccountDocumentGqlType>>>;
};

export type UserType = {
  __typename?: 'UserType';
  id: Scalars['ID'];
  uuid: Scalars['UUID'];
  email: Scalars['String'];
  consentedToMarketing?: Maybe<Scalars['Boolean']>;
  profile: ProfileType;
  name: NameType;
  guarantor?: Maybe<GuarantorType>;
  latestValidation?: Maybe<ValidationType>;
  income?: Maybe<IncomeType>;
  residentialHistory?: Maybe<Array<Maybe<ResidentialHistoryType>>>;
  consents?: Maybe<UserConsentType>;
  identityDocuments?: Maybe<Array<AccountDocumentGqlType>>;
  connectedOpenBanking?: Maybe<Scalars['Boolean']>;
  zendeskJwt?: Maybe<Scalars['String']>;
};


export type UserTypeIdentityDocumentsArgs = {
  remedialSectionUuid?: Maybe<Scalars['UUID']>;
};

export type ValidationConsentsType = {
  __typename?: 'ValidationConsentsType';
  termsAndConditions?: Maybe<Scalars['Boolean']>;
  backgroundChecks?: Maybe<Scalars['Boolean']>;
  dataAccuracy?: Maybe<Scalars['Boolean']>;
};

export type ValidationType = {
  __typename?: 'ValidationType';
  consents?: Maybe<ValidationConsentsType>;
  validationSubmitted?: Maybe<Scalars['Boolean']>;
  sections?: Maybe<Array<WizardSectionTemplateType>>;
  remedialSections?: Maybe<Array<Maybe<WizardRemedialSectionType>>>;
  progress?: Maybe<Array<Maybe<WizardProgressType>>>;
};

export type WizardBooleanConditionType = {
  __typename?: 'WizardBooleanConditionType';
  id: Scalars['ID'];
  sectionTemplate?: Maybe<ResidenceSectionMetaDataType>;
  flowTemplate?: Maybe<WizardFlowMetaDataType>;
  value?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type WizardConditionType = WizardBooleanConditionType | WizardIntegerConditionType | WizardStringConditionType | WizardListOfBooleansConditionType | WizardListOfIntegersConditionType | WizardListOfStringsConditionType;

export type WizardFlowMetaDataType = {
  __typename?: 'WizardFlowMetaDataType';
  conditions?: Maybe<Array<Maybe<WizardConditionType>>>;
};

export type WizardIntegerConditionType = {
  __typename?: 'WizardIntegerConditionType';
  id: Scalars['ID'];
  sectionTemplate?: Maybe<ResidenceSectionMetaDataType>;
  flowTemplate?: Maybe<WizardFlowMetaDataType>;
  value?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type WizardListOfBooleansConditionType = {
  __typename?: 'WizardListOfBooleansConditionType';
  id: Scalars['ID'];
  sectionTemplate?: Maybe<ResidenceSectionMetaDataType>;
  flowTemplate?: Maybe<WizardFlowMetaDataType>;
  value?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type WizardListOfIntegersConditionType = {
  __typename?: 'WizardListOfIntegersConditionType';
  id: Scalars['ID'];
  sectionTemplate?: Maybe<ResidenceSectionMetaDataType>;
  flowTemplate?: Maybe<WizardFlowMetaDataType>;
  value?: Maybe<Array<Maybe<Scalars['Int']>>>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type WizardListOfStringsConditionType = {
  __typename?: 'WizardListOfStringsConditionType';
  id: Scalars['ID'];
  sectionTemplate?: Maybe<ResidenceSectionMetaDataType>;
  flowTemplate?: Maybe<WizardFlowMetaDataType>;
  value?: Maybe<Array<Maybe<Scalars['String']>>>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type WizardProgressMutation = {
  __typename?: 'WizardProgressMutation';
  wizardProgress?: Maybe<WizardProgressType>;
};

export type WizardProgressType = {
  __typename?: 'WizardProgressType';
  id: Scalars['ID'];
  name?: Maybe<WizardSectionType>;
  startedDtm?: Maybe<Scalars['DateTime']>;
  completedDtm?: Maybe<Scalars['DateTime']>;
  submittedDtm?: Maybe<Scalars['DateTime']>;
  completedSteps?: Maybe<Array<Maybe<Scalars['String']>>>;
  relatedObjectUuid?: Maybe<Scalars['UUID']>;
};

export type WizardRemedialSectionType = {
  __typename?: 'WizardRemedialSectionType';
  relatedObjectUuid?: Maybe<Scalars['UUID']>;
  uuid: Scalars['UUID'];
  section?: Maybe<WizardSectionType>;
  progressId?: Maybe<Scalars['String']>;
  start?: Maybe<WizardSectionStep>;
  end?: Maybe<WizardSectionStep>;
  reasons?: Maybe<Array<Maybe<Scalars['String']>>>;
  comment?: Maybe<Scalars['String']>;
};

export enum WizardSectionStep {
  CompanyReference = 'companyReference',
  Documents = 'documents',
  DocumentSignpostingForTenancyAgreement = 'documentSignpostingForTenancyAgreement',
  GenericUpload = 'genericUpload',
  LandlordDetails = 'landlordDetails',
  OpenBanking = 'openBanking',
  PrimaryDocument = 'primaryDocument',
  SecondaryDocumentOne = 'secondaryDocumentOne',
  SecondaryDocumentTwo = 'secondaryDocumentTwo',
  ShareCode = 'shareCode',
  Upload = 'upload',
  UploadBankStatements = 'uploadBankStatements',
  UploadForTenancyAgreement = 'uploadForTenancyAgreement',
  UploadSecondary = 'uploadSecondary',
  UploadSecondaryTwo = 'uploadSecondaryTwo'
}

export enum WizardSectionStepDefinition {
  IdentityDefault = 'IDENTITY_DEFAULT',
  IdentityRtr = 'IDENTITY_RTR',
  IncomeDefault = 'INCOME_DEFAULT',
  IncomeWithoutGuarantor = 'INCOME_WITHOUT_GUARANTOR',
  IncomeWithGuarantor = 'INCOME_WITH_GUARANTOR',
  ResidenceDefault = 'RESIDENCE_DEFAULT'
}

export type WizardSectionTemplateType = {
  __typename?: 'WizardSectionTemplateType';
  steps?: Maybe<Array<Maybe<WizardSectionStepDefinition>>>;
  name?: Maybe<WizardSectionType>;
};

export enum WizardSectionType {
  Identity = 'IDENTITY',
  Income = 'INCOME',
  Residence = 'RESIDENCE'
}

export type WizardStringConditionType = {
  __typename?: 'WizardStringConditionType';
  id: Scalars['ID'];
  sectionTemplate?: Maybe<ResidenceSectionMetaDataType>;
  flowTemplate?: Maybe<WizardFlowMetaDataType>;
  value?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type UpdateConsentsMutationVariables = Exact<{
  marketing: Scalars['Boolean'];
  termsAndConditions: Scalars['Boolean'];
  backgroundChecks: Scalars['Boolean'];
  dataAccuracy: Scalars['Boolean'];
}>;


export type UpdateConsentsMutation = (
  { __typename?: 'Mutation' }
  & { updateOnboardingConsents?: Maybe<(
    { __typename?: 'OnboardingConsentsMutation' }
    & { userConsents?: Maybe<(
      { __typename?: 'UserConsentType' }
      & Pick<UserConsentType, 'marketing'>
    )>, validationConsents?: Maybe<(
      { __typename?: 'ValidationConsentsType' }
      & Pick<ValidationConsentsType, 'termsAndConditions' | 'backgroundChecks' | 'dataAccuracy'>
    )> }
  )> }
);

export type GetInitialDataQueryVariables = Exact<{ [key: string]: never; }>;


export type GetInitialDataQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'UserType' }
    & Pick<UserType, 'uuid' | 'zendeskJwt' | 'email'>
    & { name: (
      { __typename?: 'NameType' }
      & Pick<NameType, 'firstName' | 'lastName'>
    ), consents?: Maybe<(
      { __typename?: 'UserConsentType' }
      & Pick<UserConsentType, 'marketing'>
    )>, latestValidation?: Maybe<(
      { __typename?: 'ValidationType' }
      & Pick<ValidationType, 'validationSubmitted'>
      & { sections?: Maybe<Array<(
        { __typename?: 'WizardSectionTemplateType' }
        & Pick<WizardSectionTemplateType, 'name' | 'steps'>
      )>>, remedialSections?: Maybe<Array<Maybe<(
        { __typename?: 'WizardRemedialSectionType' }
        & Pick<WizardRemedialSectionType, 'section' | 'relatedObjectUuid' | 'progressId' | 'start' | 'end' | 'reasons' | 'comment' | 'uuid'>
      )>>>, consents?: Maybe<(
        { __typename?: 'ValidationConsentsType' }
        & Pick<ValidationConsentsType, 'termsAndConditions' | 'backgroundChecks' | 'dataAccuracy'>
      )> }
    )> }
  ) }
);

export type GetWizardProgressInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type GetWizardProgressInfoQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'UserType' }
    & Pick<UserType, 'uuid'>
    & { guarantor?: Maybe<(
      { __typename?: 'GuarantorType' }
      & Pick<GuarantorType, 'uuid' | 'firstName' | 'lastName'>
    )>, income?: Maybe<(
      { __typename?: 'IncomeType' }
      & Pick<IncomeType, 'meetsAffordabilityCriteria' | 'someoneElsePayingForRent'>
      & { incomeSources?: Maybe<Array<(
        { __typename?: 'IncomeSourceType' }
        & Pick<IncomeSourceType, 'uuid' | 'incomeType' | 'companyName'>
      )>> }
    )>, latestValidation?: Maybe<(
      { __typename?: 'ValidationType' }
      & { progress?: Maybe<Array<Maybe<(
        { __typename?: 'WizardProgressType' }
        & Pick<WizardProgressType, 'id' | 'name' | 'completedSteps' | 'startedDtm' | 'completedDtm' | 'submittedDtm' | 'relatedObjectUuid'>
      )>>>, sections?: Maybe<Array<(
        { __typename?: 'WizardSectionTemplateType' }
        & Pick<WizardSectionTemplateType, 'name' | 'steps'>
      )>>, remedialSections?: Maybe<Array<Maybe<(
        { __typename?: 'WizardRemedialSectionType' }
        & Pick<WizardRemedialSectionType, 'section' | 'relatedObjectUuid' | 'progressId' | 'start' | 'end' | 'reasons' | 'comment' | 'uuid'>
      )>>> }
    )> }
  ) }
);

export type SetSubmittedMutationVariables = Exact<{ [key: string]: never; }>;


export type SetSubmittedMutation = (
  { __typename?: 'Mutation' }
  & { submitValidation?: Maybe<(
    { __typename?: 'SubmitValidationMutation' }
    & { submittedValidation?: Maybe<(
      { __typename?: 'ValidationType' }
      & Pick<ValidationType, 'validationSubmitted'>
    )> }
  )> }
);

export type GetAllIncomeUuiDsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllIncomeUuiDsQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'UserType' }
    & Pick<UserType, 'uuid'>
    & { income?: Maybe<(
      { __typename?: 'IncomeType' }
      & { incomeSources?: Maybe<Array<(
        { __typename?: 'IncomeSourceType' }
        & Pick<IncomeSourceType, 'uuid'>
      )>> }
    )> }
  ) }
);

export type GetAllQuestionDataQueryVariables = Exact<{
  IDENTITY_DEFAULT: Scalars['Boolean'];
  INCOME_DEFAULT: Scalars['Boolean'];
  IDENTITY_RTR: Scalars['Boolean'];
  RESIDENCE_DEFAULT: Scalars['Boolean'];
  remedialSectionUuid?: Maybe<Scalars['UUID']>;
  INCOME_WITH_GUARANTOR: Scalars['Boolean'];
  INCOME_WITHOUT_GUARANTOR: Scalars['Boolean'];
}>;


export type GetAllQuestionDataQuery = (
  { __typename?: 'Query' }
  & DefaultIdentityFragment
  & DefaultIncomeFragment
  & DefaultIncomeFragment
  & DefaultIncomeFragment
  & RightToRentIdentityFragment
  & DefaultResidenceFragment
);

export type DefaultIdentityFragment = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'UserType' }
    & Pick<UserType, 'uuid'>
    & { name: (
      { __typename?: 'NameType' }
      & Pick<NameType, 'firstName' | 'lastName' | 'middleName'>
    ), profile: (
      { __typename?: 'ProfileType' }
      & Pick<ProfileType, 'nationality' | 'birthdate'>
    ), identityDocuments?: Maybe<Array<(
      { __typename?: 'AccountDocumentGQLType' }
      & Pick<AccountDocumentGqlType, 'id' | 'type' | 'path'>
    )>> }
  ), sectionMetaData?: Maybe<(
    { __typename?: 'SectionMetaDataType' }
    & { identitySectionMetaData?: Maybe<(
      { __typename?: 'IdentitySectionMetaDataType' }
      & Pick<IdentitySectionMetaDataType, 'enableIdvt'>
      & { documentUploadDefinitions?: Maybe<Array<(
        { __typename?: 'DocumentUploadDefinitionType' }
        & Pick<DocumentUploadDefinitionType, 'docType' | 'docName' | 'acceptedFileTypes' | 'sides' | 'sideNames'>
      )>>, countriesDocsMap?: Maybe<Array<Maybe<(
        { __typename?: 'DocMapCountryToIdentityType' }
        & Pick<DocMapCountryToIdentityType, 'countryCode' | 'allowedIdentityDocs'>
      )>>> }
    )> }
  )> }
);

export type MutateMeMutationVariables = Exact<{
  firstName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['Date']>;
  nationality?: Maybe<Scalars['String']>;
  identityDocuments?: Maybe<Array<Maybe<DocumentInputType>> | Maybe<DocumentInputType>>;
  remedialSectionUuid?: Maybe<Scalars['UUID']>;
}>;


export type MutateMeMutation = (
  { __typename?: 'Mutation' }
  & { me?: Maybe<(
    { __typename?: 'UserMutation' }
    & { name?: Maybe<(
      { __typename?: 'NameType' }
      & Pick<NameType, 'firstName'>
    )> }
  )> }
);

export type DefaultIncomeFragment = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'UserType' }
    & Pick<UserType, 'uuid' | 'email'>
    & { guarantor?: Maybe<(
      { __typename?: 'GuarantorType' }
      & Pick<GuarantorType, 'uuid' | 'email' | 'firstName' | 'lastName' | 'phoneNumber'>
    )>, income?: Maybe<(
      { __typename?: 'IncomeType' }
      & Pick<IncomeType, 'employmentStatus' | 'meetsAffordabilityCriteria'>
      & { incomeSources?: Maybe<Array<(
        { __typename?: 'IncomeSourceType' }
        & Pick<IncomeSourceType, 'uuid' | 'currency' | 'incomeType' | 'annualGrossIncome' | 'netIncome' | 'netIncomeFrequency' | 'paymentDate' | 'companyName' | 'companyNumber' | 'jobTitle' | 'startDate' | 'endDate' | 'isOnProbation'>
        & { financialInstitutionDetails?: Maybe<(
          { __typename?: 'FinancialInstitutionObjectType' }
          & Pick<FinancialInstitutionObjectType, 'name'>
        )>, probation?: Maybe<(
          { __typename?: 'ProbationType' }
          & Pick<ProbationType, 'duration'>
        )>, reference?: Maybe<(
          { __typename?: 'IncomeReferenceType' }
          & Pick<IncomeReferenceType, 'name' | 'email'>
        )>, accountDocuments?: Maybe<Array<Maybe<(
          { __typename?: 'AccountDocumentGQLType' }
          & Pick<AccountDocumentGqlType, 'id' | 'type' | 'path'>
        )>>> }
      )>> }
    )> }
  ), sectionMetaData?: Maybe<(
    { __typename?: 'SectionMetaDataType' }
    & { incomeSectionMetaData?: Maybe<(
      { __typename?: 'IncomeSectionMetaDataType' }
      & { conditions?: Maybe<Array<Maybe<(
        { __typename?: 'WizardBooleanConditionType' }
        & Pick<WizardBooleanConditionType, 'name' | 'description'>
        & { boolValue: WizardBooleanConditionType['value'] }
      ) | (
        { __typename?: 'WizardIntegerConditionType' }
        & Pick<WizardIntegerConditionType, 'name' | 'description'>
        & { intValue: WizardIntegerConditionType['value'] }
      ) | (
        { __typename?: 'WizardStringConditionType' }
        & Pick<WizardStringConditionType, 'name' | 'description'>
        & { strValue: WizardStringConditionType['value'] }
      ) | (
        { __typename?: 'WizardListOfBooleansConditionType' }
        & Pick<WizardListOfBooleansConditionType, 'name' | 'description'>
        & { listBoolValues: WizardListOfBooleansConditionType['value'] }
      ) | (
        { __typename?: 'WizardListOfIntegersConditionType' }
        & Pick<WizardListOfIntegersConditionType, 'name' | 'description'>
        & { listIntValues: WizardListOfIntegersConditionType['value'] }
      ) | (
        { __typename?: 'WizardListOfStringsConditionType' }
        & Pick<WizardListOfStringsConditionType, 'name' | 'description'>
        & { listStrValues: WizardListOfStringsConditionType['value'] }
      )>>> }
    )> }
  )> }
);

export type MutateIncomeWithGuarantorMutationVariables = Exact<{
  uuid?: Maybe<Scalars['UUID']>;
  guarantorEmail?: Maybe<Scalars['String']>;
  guarantorFirstName?: Maybe<Scalars['String']>;
  guarantorLastName?: Maybe<Scalars['String']>;
  guarantorPhoneNumber?: Maybe<Scalars['String']>;
  incomeType?: Maybe<IncomeSourceTypeEnum>;
  companyName?: Maybe<Scalars['String']>;
  companyNumber?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  probationDuration?: Maybe<Scalars['Int']>;
  probationDurationUnit?: Maybe<TimeUnitsEnum>;
  referenceName?: Maybe<Scalars['String']>;
  referenceEmail?: Maybe<Scalars['String']>;
  annualGrossIncome?: Maybe<Scalars['Decimal']>;
  currency?: Maybe<CurrencyTypesEnum>;
  netIncome?: Maybe<Scalars['Decimal']>;
  netIncomeFrequency?: Maybe<GqlPaymentFrequencyEnum>;
  paymentDate?: Maybe<Scalars['String']>;
  accountDocuments?: Maybe<Array<Maybe<DocumentInputType>> | Maybe<DocumentInputType>>;
  removeProbation?: Maybe<Scalars['Boolean']>;
  financialInstitutionName?: Maybe<Scalars['String']>;
  remedialSectionUuid?: Maybe<Scalars['UUID']>;
}>;


export type MutateIncomeWithGuarantorMutation = (
  { __typename?: 'Mutation' }
  & { me?: Maybe<(
    { __typename?: 'UserMutation' }
    & { guarantor?: Maybe<(
      { __typename?: 'GuarantorType' }
      & Pick<GuarantorType, 'uuid'>
    )> }
  )>, updateIncome?: Maybe<(
    { __typename?: 'IncomeSourceMutation' }
    & Pick<IncomeSourceMutation, 'meetsAffordabilityCriteria'>
    & { incomeSource?: Maybe<(
      { __typename?: 'IncomeSourceType' }
      & Pick<IncomeSourceType, 'uuid'>
    )> }
  )> }
);

export type DefaultResidenceFragment = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'UserType' }
    & Pick<UserType, 'uuid' | 'email' | 'connectedOpenBanking'>
    & { residentialHistory?: Maybe<Array<Maybe<(
      { __typename?: 'ResidentialHistoryType' }
      & ResidentialHistoryFragmentFragment
    )>>> }
  ), sectionMetaData?: Maybe<(
    { __typename?: 'SectionMetaDataType' }
    & { residenceSectionMetaData?: Maybe<(
      { __typename?: 'ResidenceSectionMetaDataType' }
      & { conditions?: Maybe<Array<Maybe<(
        { __typename?: 'WizardBooleanConditionType' }
        & Pick<WizardBooleanConditionType, 'name' | 'description'>
        & { boolValue: WizardBooleanConditionType['value'] }
      ) | (
        { __typename?: 'WizardIntegerConditionType' }
        & Pick<WizardIntegerConditionType, 'name' | 'description'>
        & { intValue: WizardIntegerConditionType['value'] }
      ) | (
        { __typename?: 'WizardStringConditionType' }
        & Pick<WizardStringConditionType, 'name' | 'description'>
        & { strValue: WizardStringConditionType['value'] }
      ) | (
        { __typename?: 'WizardListOfBooleansConditionType' }
        & Pick<WizardListOfBooleansConditionType, 'name' | 'description'>
        & { listBoolValues: WizardListOfBooleansConditionType['value'] }
      ) | (
        { __typename?: 'WizardListOfIntegersConditionType' }
        & Pick<WizardListOfIntegersConditionType, 'name' | 'description'>
        & { listIntValues: WizardListOfIntegersConditionType['value'] }
      ) | (
        { __typename?: 'WizardListOfStringsConditionType' }
        & Pick<WizardListOfStringsConditionType, 'name' | 'description'>
        & { listStrValues: WizardListOfStringsConditionType['value'] }
      )>>> }
    )> }
  )> }
);

export type UpdateDefaultResidenceMutationVariables = Exact<{
  residence?: Maybe<ResidentialHistoryInput>;
  remedialSectionUuid?: Maybe<Scalars['UUID']>;
}>;


export type UpdateDefaultResidenceMutation = (
  { __typename?: 'Mutation' }
  & { updateResidentialHistory?: Maybe<(
    { __typename?: 'ResidentialHistoryMutation' }
    & { residentialHistory?: Maybe<(
      { __typename?: 'ResidentialHistoryType' }
      & ResidentialHistoryFragmentFragment
    )> }
  )> }
);

export type RightToRentIdentityFragment = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'UserType' }
    & Pick<UserType, 'uuid'>
    & { name: (
      { __typename?: 'NameType' }
      & Pick<NameType, 'firstName' | 'lastName' | 'middleName'>
    ), profile: (
      { __typename?: 'ProfileType' }
      & Pick<ProfileType, 'nationality' | 'birthdate' | 'shareCode'>
    ), identityDocuments?: Maybe<Array<(
      { __typename?: 'AccountDocumentGQLType' }
      & Pick<AccountDocumentGqlType, 'id' | 'type' | 'path'>
    )>> }
  ), sectionMetaData?: Maybe<(
    { __typename?: 'SectionMetaDataType' }
    & { identitySectionMetaData?: Maybe<(
      { __typename?: 'IdentitySectionMetaDataType' }
      & Pick<IdentitySectionMetaDataType, 'enableIdvt'>
    )> }
  )> }
);

export type MutateMeRtrMutationVariables = Exact<{
  firstName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['Date']>;
  nationality?: Maybe<Scalars['String']>;
  shareCode?: Maybe<Scalars['String']>;
  identityDocuments?: Maybe<Array<Maybe<DocumentInputType>> | Maybe<DocumentInputType>>;
  remedialSectionUuid?: Maybe<Scalars['UUID']>;
}>;


export type MutateMeRtrMutation = (
  { __typename?: 'Mutation' }
  & { me?: Maybe<(
    { __typename?: 'UserMutation' }
    & { name?: Maybe<(
      { __typename?: 'NameType' }
      & Pick<NameType, 'firstName'>
    )> }
  )> }
);

export type FakeMutationMutationVariables = Exact<{ [key: string]: never; }>;


export type FakeMutationMutation = (
  { __typename?: 'Mutation' }
  & { me?: Maybe<(
    { __typename?: 'UserMutation' }
    & { name?: Maybe<(
      { __typename?: 'NameType' }
      & Pick<NameType, 'firstName' | 'lastName'>
    )> }
  )> }
);

export type FirstFragment = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'UserType' }
    & Pick<UserType, 'uuid'>
  ) }
);

export type ResidentialHistoryFragmentFragment = (
  { __typename?: 'ResidentialHistoryType' }
  & Pick<ResidentialHistoryType, 'uuid' | 'isCurrent' | 'start' | 'end' | 'residenceType' | 'currency' | 'rent' | 'rentFrequency' | 'rentPaymentDate'>
  & { reference?: Maybe<(
    { __typename?: 'ResidentialReferenceType' }
    & Pick<ResidentialReferenceType, 'firstName' | 'lastName' | 'email' | 'phone' | 'type'>
  )>, address?: Maybe<(
    { __typename?: 'AddressType' }
    & Pick<AddressType, 'street' | 'streetNumber' | 'flat' | 'city' | 'postalCode' | 'placeId' | 'houseName' | 'houseNumber' | 'formattedAddress' | 'country'>
  )>, documents?: Maybe<Array<Maybe<(
    { __typename?: 'AccountDocumentGQLType' }
    & Pick<AccountDocumentGqlType, 'path' | 'type' | 'uuid'>
  )>>> }
);

export type GetResidentialHistoryQueryVariables = Exact<{
  remedialSectionUuid?: Maybe<Scalars['UUID']>;
}>;


export type GetResidentialHistoryQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'UserType' }
    & Pick<UserType, 'uuid'>
    & { residentialHistory?: Maybe<Array<Maybe<(
      { __typename?: 'ResidentialHistoryType' }
      & ResidentialHistoryFragmentFragment
    )>>> }
  ) }
);

export type UpdateResidentialHistoryItemsMutationVariables = Exact<{
  residence?: Maybe<ResidentialHistoryInput>;
  remedialSectionUuid?: Maybe<Scalars['UUID']>;
}>;


export type UpdateResidentialHistoryItemsMutation = (
  { __typename?: 'Mutation' }
  & { updateResidentialHistory?: Maybe<(
    { __typename?: 'ResidentialHistoryMutation' }
    & { residentialHistory?: Maybe<(
      { __typename?: 'ResidentialHistoryType' }
      & ResidentialHistoryFragmentFragment
    )> }
  )> }
);

export type DeleteResidentialHistoryItemsMutationVariables = Exact<{
  uuid?: Maybe<Scalars['UUID']>;
}>;


export type DeleteResidentialHistoryItemsMutation = (
  { __typename?: 'Mutation' }
  & { deleteResidentialHistory?: Maybe<(
    { __typename?: 'DeleteResidentialHistoryMutation' }
    & Pick<DeleteResidentialHistoryMutation, 'success' | 'uuid'>
  )> }
);

export type ProgressFragmentFragment = (
  { __typename?: 'WizardProgressType' }
  & Pick<WizardProgressType, 'id' | 'name' | 'completedDtm' | 'completedSteps' | 'relatedObjectUuid'>
);

export type ProgressQueryVariables = Exact<{ [key: string]: never; }>;


export type ProgressQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'UserType' }
    & Pick<UserType, 'uuid'>
    & { latestValidation?: Maybe<(
      { __typename?: 'ValidationType' }
      & { progress?: Maybe<Array<Maybe<(
        { __typename?: 'WizardProgressType' }
        & ProgressFragmentFragment
      )>>> }
    )> }
  ) }
);

export type MutateProgressMutationVariables = Exact<{
  completedSteps?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  id?: Maybe<Scalars['Int']>;
  completed?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  relatedObjectUuid?: Maybe<Scalars['UUID']>;
}>;


export type MutateProgressMutation = (
  { __typename?: 'Mutation' }
  & { updateWizardProgress?: Maybe<(
    { __typename?: 'WizardProgressMutation' }
    & { wizardProgress?: Maybe<(
      { __typename?: 'WizardProgressType' }
      & ProgressFragmentFragment
    )> }
  )> }
);

export const DefaultIdentityFragmentDoc = gql`
    fragment defaultIdentity on Query {
  me {
    uuid
    name {
      firstName
      lastName
      middleName
    }
    profile {
      nationality
      birthdate
    }
    identityDocuments(remedialSectionUuid: $remedialSectionUuid) {
      id
      type
      path
    }
  }
  sectionMetaData {
    identitySectionMetaData {
      documentUploadDefinitions {
        docType
        docName
        acceptedFileTypes
        sides
        sideNames
      }
      countriesDocsMap {
        countryCode
        allowedIdentityDocs
      }
      enableIdvt
    }
  }
}
    `;
export const DefaultIncomeFragmentDoc = gql`
    fragment defaultIncome on Query {
  me {
    uuid
    email
    guarantor {
      uuid
      email
      firstName
      lastName
      phoneNumber
    }
    income {
      employmentStatus
      meetsAffordabilityCriteria
      incomeSources {
        uuid
        currency
        incomeType
        annualGrossIncome
        netIncome
        netIncomeFrequency
        paymentDate
        companyName
        companyNumber
        jobTitle
        startDate
        endDate
        financialInstitutionDetails {
          name
        }
        isOnProbation
        probation {
          duration
        }
        reference(remedialSectionUuid: $remedialSectionUuid) {
          name
          email
        }
        accountDocuments(remedialSectionUuid: $remedialSectionUuid) {
          id
          type
          path
        }
      }
    }
  }
  sectionMetaData {
    incomeSectionMetaData {
      conditions {
        ... on WizardBooleanConditionType {
          name
          description
          boolValue: value
        }
        ... on WizardIntegerConditionType {
          name
          description
          intValue: value
        }
        ... on WizardStringConditionType {
          name
          description
          strValue: value
        }
        ... on WizardListOfBooleansConditionType {
          name
          description
          listBoolValues: value
        }
        ... on WizardListOfIntegersConditionType {
          name
          description
          listIntValues: value
        }
        ... on WizardListOfStringsConditionType {
          name
          description
          listStrValues: value
        }
      }
    }
  }
}
    `;
export const ResidentialHistoryFragmentFragmentDoc = gql`
    fragment ResidentialHistoryFragment on ResidentialHistoryType {
  uuid
  isCurrent
  start
  end
  residenceType
  currency
  rent
  rentFrequency
  rentPaymentDate
  reference(remedialSectionUuid: $remedialSectionUuid) {
    firstName
    lastName
    email
    phone
    type
  }
  address {
    street
    streetNumber
    flat
    city
    postalCode
    placeId
    houseName
    houseNumber
    formattedAddress
    country
  }
  documents(remedialSectionUuid: $remedialSectionUuid) {
    path
    type
    uuid
  }
}
    `;
export const DefaultResidenceFragmentDoc = gql`
    fragment defaultResidence on Query {
  me {
    uuid
    email
    connectedOpenBanking
    residentialHistory {
      ...ResidentialHistoryFragment
    }
  }
  sectionMetaData {
    residenceSectionMetaData {
      conditions {
        ... on WizardBooleanConditionType {
          name
          description
          boolValue: value
        }
        ... on WizardIntegerConditionType {
          name
          description
          intValue: value
        }
        ... on WizardStringConditionType {
          name
          description
          strValue: value
        }
        ... on WizardListOfBooleansConditionType {
          name
          description
          listBoolValues: value
        }
        ... on WizardListOfIntegersConditionType {
          name
          description
          listIntValues: value
        }
        ... on WizardListOfStringsConditionType {
          name
          description
          listStrValues: value
        }
      }
    }
  }
}
    ${ResidentialHistoryFragmentFragmentDoc}`;
export const RightToRentIdentityFragmentDoc = gql`
    fragment rightToRentIdentity on Query {
  me {
    uuid
    name {
      firstName
      lastName
      middleName
    }
    profile {
      nationality
      birthdate
      shareCode
    }
    identityDocuments(remedialSectionUuid: $remedialSectionUuid) {
      id
      type
      path
    }
  }
  sectionMetaData {
    identitySectionMetaData {
      enableIdvt
    }
  }
}
    `;
export const FirstFragmentDoc = gql`
    fragment First on Query {
  me {
    uuid
  }
}
    `;
export const ProgressFragmentFragmentDoc = gql`
    fragment ProgressFragment on WizardProgressType {
  id
  name
  completedDtm
  completedSteps
  relatedObjectUuid
}
    `;
export const UpdateConsentsDocument = gql`
    mutation UpdateConsents($marketing: Boolean!, $termsAndConditions: Boolean!, $backgroundChecks: Boolean!, $dataAccuracy: Boolean!) {
  updateOnboardingConsents(
    marketing: $marketing
    termsAndConditions: $termsAndConditions
    backgroundChecks: $backgroundChecks
    dataAccuracy: $dataAccuracy
  ) {
    userConsents {
      marketing
    }
    validationConsents {
      termsAndConditions
      backgroundChecks
      dataAccuracy
    }
  }
}
    `;
export type UpdateConsentsMutationFn = Apollo.MutationFunction<UpdateConsentsMutation, UpdateConsentsMutationVariables>;

/**
 * __useUpdateConsentsMutation__
 *
 * To run a mutation, you first call `useUpdateConsentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateConsentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateConsentsMutation, { data, loading, error }] = useUpdateConsentsMutation({
 *   variables: {
 *      marketing: // value for 'marketing'
 *      termsAndConditions: // value for 'termsAndConditions'
 *      backgroundChecks: // value for 'backgroundChecks'
 *      dataAccuracy: // value for 'dataAccuracy'
 *   },
 * });
 */
export function useUpdateConsentsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateConsentsMutation, UpdateConsentsMutationVariables>) {
        return Apollo.useMutation<UpdateConsentsMutation, UpdateConsentsMutationVariables>(UpdateConsentsDocument, baseOptions);
      }
export type UpdateConsentsMutationHookResult = ReturnType<typeof useUpdateConsentsMutation>;
export type UpdateConsentsMutationResult = Apollo.MutationResult<UpdateConsentsMutation>;
export type UpdateConsentsMutationOptions = Apollo.BaseMutationOptions<UpdateConsentsMutation, UpdateConsentsMutationVariables>;
export const GetInitialDataDocument = gql`
    query GetInitialData {
  me {
    uuid
    zendeskJwt
    name {
      firstName
      lastName
    }
    email
    consents {
      marketing
    }
    latestValidation {
      validationSubmitted
      sections {
        name
        steps
      }
      remedialSections {
        section
        relatedObjectUuid
        progressId
        start
        end
        reasons
        comment
        uuid
      }
      consents {
        termsAndConditions
        backgroundChecks
        dataAccuracy
      }
    }
  }
}
    `;

/**
 * __useGetInitialDataQuery__
 *
 * To run a query within a React component, call `useGetInitialDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInitialDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInitialDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetInitialDataQuery(baseOptions?: Apollo.QueryHookOptions<GetInitialDataQuery, GetInitialDataQueryVariables>) {
        return Apollo.useQuery<GetInitialDataQuery, GetInitialDataQueryVariables>(GetInitialDataDocument, baseOptions);
      }
export function useGetInitialDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInitialDataQuery, GetInitialDataQueryVariables>) {
          return Apollo.useLazyQuery<GetInitialDataQuery, GetInitialDataQueryVariables>(GetInitialDataDocument, baseOptions);
        }
export type GetInitialDataQueryHookResult = ReturnType<typeof useGetInitialDataQuery>;
export type GetInitialDataLazyQueryHookResult = ReturnType<typeof useGetInitialDataLazyQuery>;
export type GetInitialDataQueryResult = Apollo.QueryResult<GetInitialDataQuery, GetInitialDataQueryVariables>;
export const GetWizardProgressInfoDocument = gql`
    query GetWizardProgressInfo {
  me {
    uuid
    guarantor {
      uuid
      firstName
      lastName
    }
    income {
      meetsAffordabilityCriteria
      someoneElsePayingForRent
      incomeSources {
        uuid
        incomeType
        companyName
      }
    }
    latestValidation {
      progress {
        id
        name
        completedSteps
        startedDtm
        completedDtm
        submittedDtm
        relatedObjectUuid
      }
      sections {
        name
        steps
      }
      remedialSections {
        section
        relatedObjectUuid
        progressId
        start
        end
        reasons
        comment
        uuid
      }
    }
  }
}
    `;

/**
 * __useGetWizardProgressInfoQuery__
 *
 * To run a query within a React component, call `useGetWizardProgressInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWizardProgressInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWizardProgressInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetWizardProgressInfoQuery(baseOptions?: Apollo.QueryHookOptions<GetWizardProgressInfoQuery, GetWizardProgressInfoQueryVariables>) {
        return Apollo.useQuery<GetWizardProgressInfoQuery, GetWizardProgressInfoQueryVariables>(GetWizardProgressInfoDocument, baseOptions);
      }
export function useGetWizardProgressInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWizardProgressInfoQuery, GetWizardProgressInfoQueryVariables>) {
          return Apollo.useLazyQuery<GetWizardProgressInfoQuery, GetWizardProgressInfoQueryVariables>(GetWizardProgressInfoDocument, baseOptions);
        }
export type GetWizardProgressInfoQueryHookResult = ReturnType<typeof useGetWizardProgressInfoQuery>;
export type GetWizardProgressInfoLazyQueryHookResult = ReturnType<typeof useGetWizardProgressInfoLazyQuery>;
export type GetWizardProgressInfoQueryResult = Apollo.QueryResult<GetWizardProgressInfoQuery, GetWizardProgressInfoQueryVariables>;
export const SetSubmittedDocument = gql`
    mutation SetSubmitted {
  submitValidation {
    submittedValidation {
      validationSubmitted
    }
  }
}
    `;
export type SetSubmittedMutationFn = Apollo.MutationFunction<SetSubmittedMutation, SetSubmittedMutationVariables>;

/**
 * __useSetSubmittedMutation__
 *
 * To run a mutation, you first call `useSetSubmittedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetSubmittedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setSubmittedMutation, { data, loading, error }] = useSetSubmittedMutation({
 *   variables: {
 *   },
 * });
 */
export function useSetSubmittedMutation(baseOptions?: Apollo.MutationHookOptions<SetSubmittedMutation, SetSubmittedMutationVariables>) {
        return Apollo.useMutation<SetSubmittedMutation, SetSubmittedMutationVariables>(SetSubmittedDocument, baseOptions);
      }
export type SetSubmittedMutationHookResult = ReturnType<typeof useSetSubmittedMutation>;
export type SetSubmittedMutationResult = Apollo.MutationResult<SetSubmittedMutation>;
export type SetSubmittedMutationOptions = Apollo.BaseMutationOptions<SetSubmittedMutation, SetSubmittedMutationVariables>;
export const GetAllIncomeUuiDsDocument = gql`
    query GetAllIncomeUUIDs {
  me {
    uuid
    income {
      incomeSources {
        uuid
      }
    }
  }
}
    `;

/**
 * __useGetAllIncomeUuiDsQuery__
 *
 * To run a query within a React component, call `useGetAllIncomeUuiDsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllIncomeUuiDsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllIncomeUuiDsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllIncomeUuiDsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllIncomeUuiDsQuery, GetAllIncomeUuiDsQueryVariables>) {
        return Apollo.useQuery<GetAllIncomeUuiDsQuery, GetAllIncomeUuiDsQueryVariables>(GetAllIncomeUuiDsDocument, baseOptions);
      }
export function useGetAllIncomeUuiDsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllIncomeUuiDsQuery, GetAllIncomeUuiDsQueryVariables>) {
          return Apollo.useLazyQuery<GetAllIncomeUuiDsQuery, GetAllIncomeUuiDsQueryVariables>(GetAllIncomeUuiDsDocument, baseOptions);
        }
export type GetAllIncomeUuiDsQueryHookResult = ReturnType<typeof useGetAllIncomeUuiDsQuery>;
export type GetAllIncomeUuiDsLazyQueryHookResult = ReturnType<typeof useGetAllIncomeUuiDsLazyQuery>;
export type GetAllIncomeUuiDsQueryResult = Apollo.QueryResult<GetAllIncomeUuiDsQuery, GetAllIncomeUuiDsQueryVariables>;
export const GetAllQuestionDataDocument = gql`
    query getAllQuestionData($IDENTITY_DEFAULT: Boolean!, $INCOME_DEFAULT: Boolean!, $IDENTITY_RTR: Boolean!, $RESIDENCE_DEFAULT: Boolean!, $remedialSectionUuid: UUID, $INCOME_WITH_GUARANTOR: Boolean!, $INCOME_WITHOUT_GUARANTOR: Boolean!) {
  ...defaultIdentity @include(if: $IDENTITY_DEFAULT)
  ...defaultIncome @include(if: $INCOME_DEFAULT)
  ...defaultIncome @include(if: $INCOME_WITH_GUARANTOR)
  ...defaultIncome @include(if: $INCOME_WITHOUT_GUARANTOR)
  ...rightToRentIdentity @include(if: $IDENTITY_RTR)
  ...defaultResidence @include(if: $RESIDENCE_DEFAULT)
}
    ${DefaultIdentityFragmentDoc}
${DefaultIncomeFragmentDoc}
${RightToRentIdentityFragmentDoc}
${DefaultResidenceFragmentDoc}`;

/**
 * __useGetAllQuestionDataQuery__
 *
 * To run a query within a React component, call `useGetAllQuestionDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllQuestionDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllQuestionDataQuery({
 *   variables: {
 *      IDENTITY_DEFAULT: // value for 'IDENTITY_DEFAULT'
 *      INCOME_DEFAULT: // value for 'INCOME_DEFAULT'
 *      IDENTITY_RTR: // value for 'IDENTITY_RTR'
 *      RESIDENCE_DEFAULT: // value for 'RESIDENCE_DEFAULT'
 *      remedialSectionUuid: // value for 'remedialSectionUuid'
 *      INCOME_WITH_GUARANTOR: // value for 'INCOME_WITH_GUARANTOR'
 *      INCOME_WITHOUT_GUARANTOR: // value for 'INCOME_WITHOUT_GUARANTOR'
 *   },
 * });
 */
export function useGetAllQuestionDataQuery(baseOptions: Apollo.QueryHookOptions<GetAllQuestionDataQuery, GetAllQuestionDataQueryVariables>) {
        return Apollo.useQuery<GetAllQuestionDataQuery, GetAllQuestionDataQueryVariables>(GetAllQuestionDataDocument, baseOptions);
      }
export function useGetAllQuestionDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllQuestionDataQuery, GetAllQuestionDataQueryVariables>) {
          return Apollo.useLazyQuery<GetAllQuestionDataQuery, GetAllQuestionDataQueryVariables>(GetAllQuestionDataDocument, baseOptions);
        }
export type GetAllQuestionDataQueryHookResult = ReturnType<typeof useGetAllQuestionDataQuery>;
export type GetAllQuestionDataLazyQueryHookResult = ReturnType<typeof useGetAllQuestionDataLazyQuery>;
export type GetAllQuestionDataQueryResult = Apollo.QueryResult<GetAllQuestionDataQuery, GetAllQuestionDataQueryVariables>;
export const MutateMeDocument = gql`
    mutation MutateMe($firstName: String, $middleName: String, $lastName: String, $birthdate: Date, $nationality: String, $identityDocuments: [DocumentInputType], $remedialSectionUuid: UUID) {
  me(
    firstName: $firstName
    middleName: $middleName
    lastName: $lastName
    birthdate: $birthdate
    nationality: $nationality
    identityDocuments: $identityDocuments
    remedialSectionUuid: $remedialSectionUuid
  ) {
    name {
      firstName
    }
  }
}
    `;
export type MutateMeMutationFn = Apollo.MutationFunction<MutateMeMutation, MutateMeMutationVariables>;

/**
 * __useMutateMeMutation__
 *
 * To run a mutation, you first call `useMutateMeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMutateMeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mutateMeMutation, { data, loading, error }] = useMutateMeMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      middleName: // value for 'middleName'
 *      lastName: // value for 'lastName'
 *      birthdate: // value for 'birthdate'
 *      nationality: // value for 'nationality'
 *      identityDocuments: // value for 'identityDocuments'
 *      remedialSectionUuid: // value for 'remedialSectionUuid'
 *   },
 * });
 */
export function useMutateMeMutation(baseOptions?: Apollo.MutationHookOptions<MutateMeMutation, MutateMeMutationVariables>) {
        return Apollo.useMutation<MutateMeMutation, MutateMeMutationVariables>(MutateMeDocument, baseOptions);
      }
export type MutateMeMutationHookResult = ReturnType<typeof useMutateMeMutation>;
export type MutateMeMutationResult = Apollo.MutationResult<MutateMeMutation>;
export type MutateMeMutationOptions = Apollo.BaseMutationOptions<MutateMeMutation, MutateMeMutationVariables>;
export const MutateIncomeWithGuarantorDocument = gql`
    mutation MutateIncomeWithGuarantor($uuid: UUID, $guarantorEmail: String, $guarantorFirstName: String, $guarantorLastName: String, $guarantorPhoneNumber: String, $incomeType: IncomeSourceTypeEnum, $companyName: String, $companyNumber: String, $jobTitle: String, $startDate: Date, $endDate: Date, $probationDuration: Int, $probationDurationUnit: TimeUnitsEnum, $referenceName: String, $referenceEmail: String, $annualGrossIncome: Decimal, $currency: CurrencyTypesEnum, $netIncome: Decimal, $netIncomeFrequency: GQLPaymentFrequencyEnum, $paymentDate: String, $accountDocuments: [DocumentInputType], $removeProbation: Boolean, $financialInstitutionName: String, $remedialSectionUuid: UUID) {
  me(
    guarantorEmail: $guarantorEmail
    guarantorFirstName: $guarantorFirstName
    guarantorLastName: $guarantorLastName
    guarantorPhoneNumber: $guarantorPhoneNumber
  ) {
    guarantor {
      uuid
    }
  }
  updateIncome(
    uuid: $uuid
    incomeType: $incomeType
    companyName: $companyName
    companyNumber: $companyNumber
    jobTitle: $jobTitle
    startDate: $startDate
    endDate: $endDate
    probationDuration: $probationDuration
    probationDurationUnit: $probationDurationUnit
    referenceName: $referenceName
    referenceEmail: $referenceEmail
    annualGrossIncome: $annualGrossIncome
    currency: $currency
    netIncome: $netIncome
    netIncomeFrequency: $netIncomeFrequency
    paymentDate: $paymentDate
    accountDocuments: $accountDocuments
    removeProbation: $removeProbation
    financialInstitutionName: $financialInstitutionName
    remedialSectionUuid: $remedialSectionUuid
  ) {
    meetsAffordabilityCriteria
    incomeSource {
      uuid
    }
  }
}
    `;
export type MutateIncomeWithGuarantorMutationFn = Apollo.MutationFunction<MutateIncomeWithGuarantorMutation, MutateIncomeWithGuarantorMutationVariables>;

/**
 * __useMutateIncomeWithGuarantorMutation__
 *
 * To run a mutation, you first call `useMutateIncomeWithGuarantorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMutateIncomeWithGuarantorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mutateIncomeWithGuarantorMutation, { data, loading, error }] = useMutateIncomeWithGuarantorMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      guarantorEmail: // value for 'guarantorEmail'
 *      guarantorFirstName: // value for 'guarantorFirstName'
 *      guarantorLastName: // value for 'guarantorLastName'
 *      guarantorPhoneNumber: // value for 'guarantorPhoneNumber'
 *      incomeType: // value for 'incomeType'
 *      companyName: // value for 'companyName'
 *      companyNumber: // value for 'companyNumber'
 *      jobTitle: // value for 'jobTitle'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      probationDuration: // value for 'probationDuration'
 *      probationDurationUnit: // value for 'probationDurationUnit'
 *      referenceName: // value for 'referenceName'
 *      referenceEmail: // value for 'referenceEmail'
 *      annualGrossIncome: // value for 'annualGrossIncome'
 *      currency: // value for 'currency'
 *      netIncome: // value for 'netIncome'
 *      netIncomeFrequency: // value for 'netIncomeFrequency'
 *      paymentDate: // value for 'paymentDate'
 *      accountDocuments: // value for 'accountDocuments'
 *      removeProbation: // value for 'removeProbation'
 *      financialInstitutionName: // value for 'financialInstitutionName'
 *      remedialSectionUuid: // value for 'remedialSectionUuid'
 *   },
 * });
 */
export function useMutateIncomeWithGuarantorMutation(baseOptions?: Apollo.MutationHookOptions<MutateIncomeWithGuarantorMutation, MutateIncomeWithGuarantorMutationVariables>) {
        return Apollo.useMutation<MutateIncomeWithGuarantorMutation, MutateIncomeWithGuarantorMutationVariables>(MutateIncomeWithGuarantorDocument, baseOptions);
      }
export type MutateIncomeWithGuarantorMutationHookResult = ReturnType<typeof useMutateIncomeWithGuarantorMutation>;
export type MutateIncomeWithGuarantorMutationResult = Apollo.MutationResult<MutateIncomeWithGuarantorMutation>;
export type MutateIncomeWithGuarantorMutationOptions = Apollo.BaseMutationOptions<MutateIncomeWithGuarantorMutation, MutateIncomeWithGuarantorMutationVariables>;
export const UpdateDefaultResidenceDocument = gql`
    mutation UpdateDefaultResidence($residence: ResidentialHistoryInput, $remedialSectionUuid: UUID) {
  updateResidentialHistory(residence: $residence) {
    residentialHistory {
      ...ResidentialHistoryFragment
    }
  }
}
    ${ResidentialHistoryFragmentFragmentDoc}`;
export type UpdateDefaultResidenceMutationFn = Apollo.MutationFunction<UpdateDefaultResidenceMutation, UpdateDefaultResidenceMutationVariables>;

/**
 * __useUpdateDefaultResidenceMutation__
 *
 * To run a mutation, you first call `useUpdateDefaultResidenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDefaultResidenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDefaultResidenceMutation, { data, loading, error }] = useUpdateDefaultResidenceMutation({
 *   variables: {
 *      residence: // value for 'residence'
 *      remedialSectionUuid: // value for 'remedialSectionUuid'
 *   },
 * });
 */
export function useUpdateDefaultResidenceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDefaultResidenceMutation, UpdateDefaultResidenceMutationVariables>) {
        return Apollo.useMutation<UpdateDefaultResidenceMutation, UpdateDefaultResidenceMutationVariables>(UpdateDefaultResidenceDocument, baseOptions);
      }
export type UpdateDefaultResidenceMutationHookResult = ReturnType<typeof useUpdateDefaultResidenceMutation>;
export type UpdateDefaultResidenceMutationResult = Apollo.MutationResult<UpdateDefaultResidenceMutation>;
export type UpdateDefaultResidenceMutationOptions = Apollo.BaseMutationOptions<UpdateDefaultResidenceMutation, UpdateDefaultResidenceMutationVariables>;
export const MutateMeRtrDocument = gql`
    mutation MutateMeRTR($firstName: String, $middleName: String, $lastName: String, $birthdate: Date, $nationality: String, $shareCode: String, $identityDocuments: [DocumentInputType], $remedialSectionUuid: UUID) {
  me(
    firstName: $firstName
    middleName: $middleName
    lastName: $lastName
    birthdate: $birthdate
    nationality: $nationality
    shareCode: $shareCode
    identityDocuments: $identityDocuments
    remedialSectionUuid: $remedialSectionUuid
  ) {
    name {
      firstName
    }
  }
}
    `;
export type MutateMeRtrMutationFn = Apollo.MutationFunction<MutateMeRtrMutation, MutateMeRtrMutationVariables>;

/**
 * __useMutateMeRtrMutation__
 *
 * To run a mutation, you first call `useMutateMeRtrMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMutateMeRtrMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mutateMeRtrMutation, { data, loading, error }] = useMutateMeRtrMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      middleName: // value for 'middleName'
 *      lastName: // value for 'lastName'
 *      birthdate: // value for 'birthdate'
 *      nationality: // value for 'nationality'
 *      shareCode: // value for 'shareCode'
 *      identityDocuments: // value for 'identityDocuments'
 *      remedialSectionUuid: // value for 'remedialSectionUuid'
 *   },
 * });
 */
export function useMutateMeRtrMutation(baseOptions?: Apollo.MutationHookOptions<MutateMeRtrMutation, MutateMeRtrMutationVariables>) {
        return Apollo.useMutation<MutateMeRtrMutation, MutateMeRtrMutationVariables>(MutateMeRtrDocument, baseOptions);
      }
export type MutateMeRtrMutationHookResult = ReturnType<typeof useMutateMeRtrMutation>;
export type MutateMeRtrMutationResult = Apollo.MutationResult<MutateMeRtrMutation>;
export type MutateMeRtrMutationOptions = Apollo.BaseMutationOptions<MutateMeRtrMutation, MutateMeRtrMutationVariables>;
export const FakeMutationDocument = gql`
    mutation FakeMutation {
  me {
    name {
      firstName
      lastName
    }
  }
}
    `;
export type FakeMutationMutationFn = Apollo.MutationFunction<FakeMutationMutation, FakeMutationMutationVariables>;

/**
 * __useFakeMutationMutation__
 *
 * To run a mutation, you first call `useFakeMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFakeMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fakeMutationMutation, { data, loading, error }] = useFakeMutationMutation({
 *   variables: {
 *   },
 * });
 */
export function useFakeMutationMutation(baseOptions?: Apollo.MutationHookOptions<FakeMutationMutation, FakeMutationMutationVariables>) {
        return Apollo.useMutation<FakeMutationMutation, FakeMutationMutationVariables>(FakeMutationDocument, baseOptions);
      }
export type FakeMutationMutationHookResult = ReturnType<typeof useFakeMutationMutation>;
export type FakeMutationMutationResult = Apollo.MutationResult<FakeMutationMutation>;
export type FakeMutationMutationOptions = Apollo.BaseMutationOptions<FakeMutationMutation, FakeMutationMutationVariables>;
export const GetResidentialHistoryDocument = gql`
    query GetResidentialHistory($remedialSectionUuid: UUID) {
  me {
    uuid
    residentialHistory {
      ...ResidentialHistoryFragment
    }
  }
}
    ${ResidentialHistoryFragmentFragmentDoc}`;

/**
 * __useGetResidentialHistoryQuery__
 *
 * To run a query within a React component, call `useGetResidentialHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetResidentialHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetResidentialHistoryQuery({
 *   variables: {
 *      remedialSectionUuid: // value for 'remedialSectionUuid'
 *   },
 * });
 */
export function useGetResidentialHistoryQuery(baseOptions?: Apollo.QueryHookOptions<GetResidentialHistoryQuery, GetResidentialHistoryQueryVariables>) {
        return Apollo.useQuery<GetResidentialHistoryQuery, GetResidentialHistoryQueryVariables>(GetResidentialHistoryDocument, baseOptions);
      }
export function useGetResidentialHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetResidentialHistoryQuery, GetResidentialHistoryQueryVariables>) {
          return Apollo.useLazyQuery<GetResidentialHistoryQuery, GetResidentialHistoryQueryVariables>(GetResidentialHistoryDocument, baseOptions);
        }
export type GetResidentialHistoryQueryHookResult = ReturnType<typeof useGetResidentialHistoryQuery>;
export type GetResidentialHistoryLazyQueryHookResult = ReturnType<typeof useGetResidentialHistoryLazyQuery>;
export type GetResidentialHistoryQueryResult = Apollo.QueryResult<GetResidentialHistoryQuery, GetResidentialHistoryQueryVariables>;
export const UpdateResidentialHistoryItemsDocument = gql`
    mutation UpdateResidentialHistoryItems($residence: ResidentialHistoryInput, $remedialSectionUuid: UUID) {
  updateResidentialHistory(residence: $residence) {
    residentialHistory {
      ...ResidentialHistoryFragment
    }
  }
}
    ${ResidentialHistoryFragmentFragmentDoc}`;
export type UpdateResidentialHistoryItemsMutationFn = Apollo.MutationFunction<UpdateResidentialHistoryItemsMutation, UpdateResidentialHistoryItemsMutationVariables>;

/**
 * __useUpdateResidentialHistoryItemsMutation__
 *
 * To run a mutation, you first call `useUpdateResidentialHistoryItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateResidentialHistoryItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateResidentialHistoryItemsMutation, { data, loading, error }] = useUpdateResidentialHistoryItemsMutation({
 *   variables: {
 *      residence: // value for 'residence'
 *      remedialSectionUuid: // value for 'remedialSectionUuid'
 *   },
 * });
 */
export function useUpdateResidentialHistoryItemsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateResidentialHistoryItemsMutation, UpdateResidentialHistoryItemsMutationVariables>) {
        return Apollo.useMutation<UpdateResidentialHistoryItemsMutation, UpdateResidentialHistoryItemsMutationVariables>(UpdateResidentialHistoryItemsDocument, baseOptions);
      }
export type UpdateResidentialHistoryItemsMutationHookResult = ReturnType<typeof useUpdateResidentialHistoryItemsMutation>;
export type UpdateResidentialHistoryItemsMutationResult = Apollo.MutationResult<UpdateResidentialHistoryItemsMutation>;
export type UpdateResidentialHistoryItemsMutationOptions = Apollo.BaseMutationOptions<UpdateResidentialHistoryItemsMutation, UpdateResidentialHistoryItemsMutationVariables>;
export const DeleteResidentialHistoryItemsDocument = gql`
    mutation DeleteResidentialHistoryItems($uuid: UUID) {
  deleteResidentialHistory(uuid: $uuid) {
    success
    uuid
  }
}
    `;
export type DeleteResidentialHistoryItemsMutationFn = Apollo.MutationFunction<DeleteResidentialHistoryItemsMutation, DeleteResidentialHistoryItemsMutationVariables>;

/**
 * __useDeleteResidentialHistoryItemsMutation__
 *
 * To run a mutation, you first call `useDeleteResidentialHistoryItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteResidentialHistoryItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteResidentialHistoryItemsMutation, { data, loading, error }] = useDeleteResidentialHistoryItemsMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useDeleteResidentialHistoryItemsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteResidentialHistoryItemsMutation, DeleteResidentialHistoryItemsMutationVariables>) {
        return Apollo.useMutation<DeleteResidentialHistoryItemsMutation, DeleteResidentialHistoryItemsMutationVariables>(DeleteResidentialHistoryItemsDocument, baseOptions);
      }
export type DeleteResidentialHistoryItemsMutationHookResult = ReturnType<typeof useDeleteResidentialHistoryItemsMutation>;
export type DeleteResidentialHistoryItemsMutationResult = Apollo.MutationResult<DeleteResidentialHistoryItemsMutation>;
export type DeleteResidentialHistoryItemsMutationOptions = Apollo.BaseMutationOptions<DeleteResidentialHistoryItemsMutation, DeleteResidentialHistoryItemsMutationVariables>;
export const ProgressDocument = gql`
    query Progress {
  me {
    uuid
    latestValidation {
      progress {
        ...ProgressFragment
      }
    }
  }
}
    ${ProgressFragmentFragmentDoc}`;

/**
 * __useProgressQuery__
 *
 * To run a query within a React component, call `useProgressQuery` and pass it any options that fit your needs.
 * When your component renders, `useProgressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProgressQuery({
 *   variables: {
 *   },
 * });
 */
export function useProgressQuery(baseOptions?: Apollo.QueryHookOptions<ProgressQuery, ProgressQueryVariables>) {
        return Apollo.useQuery<ProgressQuery, ProgressQueryVariables>(ProgressDocument, baseOptions);
      }
export function useProgressLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProgressQuery, ProgressQueryVariables>) {
          return Apollo.useLazyQuery<ProgressQuery, ProgressQueryVariables>(ProgressDocument, baseOptions);
        }
export type ProgressQueryHookResult = ReturnType<typeof useProgressQuery>;
export type ProgressLazyQueryHookResult = ReturnType<typeof useProgressLazyQuery>;
export type ProgressQueryResult = Apollo.QueryResult<ProgressQuery, ProgressQueryVariables>;
export const MutateProgressDocument = gql`
    mutation MutateProgress($completedSteps: [String], $id: Int, $completed: Boolean, $name: String, $relatedObjectUuid: UUID) {
  updateWizardProgress(
    completedSteps: $completedSteps
    name: $name
    id: $id
    completed: $completed
    relatedObjectUuid: $relatedObjectUuid
  ) {
    wizardProgress {
      ...ProgressFragment
    }
  }
}
    ${ProgressFragmentFragmentDoc}`;
export type MutateProgressMutationFn = Apollo.MutationFunction<MutateProgressMutation, MutateProgressMutationVariables>;

/**
 * __useMutateProgressMutation__
 *
 * To run a mutation, you first call `useMutateProgressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMutateProgressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mutateProgressMutation, { data, loading, error }] = useMutateProgressMutation({
 *   variables: {
 *      completedSteps: // value for 'completedSteps'
 *      id: // value for 'id'
 *      completed: // value for 'completed'
 *      name: // value for 'name'
 *      relatedObjectUuid: // value for 'relatedObjectUuid'
 *   },
 * });
 */
export function useMutateProgressMutation(baseOptions?: Apollo.MutationHookOptions<MutateProgressMutation, MutateProgressMutationVariables>) {
        return Apollo.useMutation<MutateProgressMutation, MutateProgressMutationVariables>(MutateProgressDocument, baseOptions);
      }
export type MutateProgressMutationHookResult = ReturnType<typeof useMutateProgressMutation>;
export type MutateProgressMutationResult = Apollo.MutationResult<MutateProgressMutation>;
export type MutateProgressMutationOptions = Apollo.BaseMutationOptions<MutateProgressMutation, MutateProgressMutationVariables>;
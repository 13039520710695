import { ValidationRule } from '../../types';
import { useTranslation } from 'react-i18next';
import QuestionOrganism from '../../QuestionOrganism';
import { DateInput, DropDown, Field, Input } from 'components';
import { OnDateChange } from 'components/molecules/DateInput/DateInput';
import { useContent, useProbationStatusOptions } from './content';
import { Trans } from 'react-i18next';
import {
  assertIsCurrentEmployerStringQuestion,
  assertIsCurrentEmployerDateQuestion,
  CurrentEmployerQuestion,
  DateQuestion,
  DateValue,
  StringQuestion,
  isDateValue,
} from './types';
import { adaptToExternalFormat, useErrors, useValues } from './state';
import {
  DefaultIncomeQuestionComponentProps,
  StepNamesDefaultIncome,
  YesNoEnum,
} from 'questionFlow/flows/defaultIncome/types';

const validate = (v: string, rules: ValidationRule[], valid?: boolean) =>
  rules.find(({ rule }) => !rule(v, valid));

const name = StepNamesDefaultIncome.currentEmployer;

const cmsLocation = '/questions/income/currentEmployer';

const CurrentEmployer = ({
  data,
  values: initialValues,
  onSubmit,
  validationRules,
  ...props
}: DefaultIncomeQuestionComponentProps) => {
  const { t } = useTranslation();
  const content = useContent(t);
  const [values, setValues] = useValues(initialValues);
  const [error, setError] = useErrors();

  const yesNoOptions = useProbationStatusOptions(content);

  const getValidationResult = (name: CurrentEmployerQuestion, givenValue?: string | DateValue) => {
    const stringOrDateValue = givenValue === undefined ? values[name] : givenValue;

    // Probation status only exists if on probation, ignore it otherwise
    if (name === 'probationLength' && values['probationStatus'] !== 'yes') {
      return undefined;
    }

    return isDateValue(stringOrDateValue)
      ? validate(stringOrDateValue.value, validationRules[name], stringOrDateValue.valid)
      : validate(stringOrDateValue, validationRules[name]);
  };

  const handleSubmit = () => {
    const errors = (Object.keys(values) as CurrentEmployerQuestion[]).reduce((e, inputName) => {
      const result = getValidationResult(inputName);

      return { ...e, [inputName]: result ? t(result.error as any) : '' };
    }, error);

    if (Object.values(errors).filter(e => e !== '').length === 0) {
      onSubmit({ key: name, value: adaptToExternalFormat(values) });
    } else {
      setError(errors);
    }
  };

  const validateAndSetError = (name: CurrentEmployerQuestion, newValue: string) => {
    const result = getValidationResult(name, newValue);
    setError({ ...error, [name]: result ? t(result.error as any) : '' });
  };

  const onStringValueChange = (name: CurrentEmployerQuestion, value: string) => {
    if (error[name]) {
      validateAndSetError(name, value);
    }

    setValues({ ...values, [name]: value });
  };

  const handleInputOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    assertIsCurrentEmployerStringQuestion(e.target.name);
    onStringValueChange(e.target.name, e.target.value.trim());
  };

  const handleInputOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    assertIsCurrentEmployerStringQuestion(e.target.name);
    validateAndSetError(e.target.name, e.target.value.trim());
  };

  const onDropdownChange = (name: StringQuestion, optionValue: string) =>
    onStringValueChange(name, optionValue);

  const handleDateChange: OnDateChange = ({ name, value, valid }) => {
    assertIsCurrentEmployerDateQuestion(name);

    setValues({
      ...values,
      [name]: {
        value,
        valid,
      },
    });

    if (valid && error[name]) {
      setError({ ...error, [name]: '' });
    }
  };

  const handleDateOnBlur = (name: DateQuestion) => {
    const result = getValidationResult(name);
    setError({ ...error, [name]: result ? t(result.error as any) : '' });
  };

  const requireProbationLength = values['probationStatus'] === YesNoEnum.YES;

  const subtitle = (
    <Trans
      i18nKey={`${cmsLocation}.subtitle`}
      components={{
        strong: <strong></strong>,
        bold: <b></b>,
      }}
    />
  );

  return (
    <QuestionOrganism
      id={name}
      data-testid={name}
      onSubmit={handleSubmit}
      title={content.title}
      subtitle={subtitle}
      {...props}
    >
      <div className="grid w-max md:grid-cols-2 gap-x-10 gap-y-4 pb-60">
        <Field
          data-testid={`${name}-companyName`}
          label={content.companyName.label}
          validationError={error.companyName}
        >
          <Input
            id={`${name}-companyName`}
            name={'companyName'}
            defaultValue={values.companyName}
            onChange={handleInputOnChange}
            aria-invalid={!!error.companyName}
            onBlur={handleInputOnBlur}
            placeholder={content.companyName.placeholder}
          />
        </Field>

        <Field data-testid={`${name}-role`} label={content.role.label} validationError={error.role}>
          <Input
            id={`${name}-role`}
            name={'role'}
            defaultValue={values.role}
            onChange={handleInputOnChange}
            aria-invalid={!!error.role}
            onBlur={handleInputOnBlur}
            placeholder={content.role.placeholder}
          />
        </Field>

        <Field
          data-testid={`${name}-startDate`}
          label={content.startDate.label}
          validationError={error.startDate}
        >
          <DateInput
            data-testid="start-date-input"
            onChange={handleDateChange}
            onBlur={() => handleDateOnBlur('startDate')}
            defaultValue={values.startDate.value}
            error={!!error.startDate}
            id="startDate"
            dayLabel={content.startDate.dayInputLabel}
            monthLabel={content.startDate.monthInputLabel}
            yearLabel={content.startDate.yearInputLabel}
          />
        </Field>

        <Field
          data-testid={`${name}-endDate`}
          label={content.endDate.label}
          validationError={error.endDate}
        >
          <DateInput
            data-testid="end-date-input"
            onChange={handleDateChange}
            onBlur={() => handleDateOnBlur('endDate')}
            defaultValue={values.endDate.value}
            error={!!error.endDate}
            id="endDate"
            dayLabel={content.endDate.dayInputLabel}
            monthLabel={content.endDate.monthInputLabel}
            yearLabel={content.endDate.yearInputLabel}
          />
        </Field>

        <Field
          data-testid={`${name}-probationStatus`}
          label={content.probationStatus.label}
          validationError={error.probationStatus}
        >
          <DropDown
            placeholder={content.probationStatus.placeholder}
            options={yesNoOptions}
            onSelectValue={value => onDropdownChange('probationStatus', value)}
            value={values.probationStatus}
            error={!!error.probationStatus}
            label={content.probationStatus.label}
          />
        </Field>

        {requireProbationLength && (
          <Field
            data-testid={`${name}-probationLength`}
            label={content.probationLength.label}
            validationError={error.probationLength}
          >
            <Input
              id={`${name}-probation-length`}
              name={'probationLength'}
              defaultValue={values.probationLength}
              onChange={handleInputOnChange}
              aria-invalid={!!error.probationLength}
              onBlur={handleInputOnBlur}
              placeholder={content.probationLength.placeholder}
            />
          </Field>
        )}
      </div>
    </QuestionOrganism>
  );
};

export default CurrentEmployer;

import { Field, Input } from 'components';
import QuestionOrganism from 'questionFlow/QuestionOrganism';
import { useCallback, useMemo } from 'react';
import { TFunction, useTranslation, Trans } from 'react-i18next';
import { ValidationRule } from '../types';
import {
  DefaultIncomeQuestionComponentProps,
  StepNamesDefaultIncome,
} from 'questionFlow/flows/defaultIncome/types';
import { useAnalyticsErrors, useAnalyticsValues } from 'monitoring/analyticsHooks';

export enum CompanyReferenceFieldsEnum {
  referenceName = 'referenceName',
  referenceEmail = 'referenceEmail',
}

export type CompanyReferenceValues = {
  [key in CompanyReferenceFieldsEnum]: string;
};

const cmsLocation = '/questions/income/companyReference';

const useContent = (t: TFunction<'translation'>) => ({
  title: t(`${cmsLocation}.title`),
  subtitle: t(`${cmsLocation}.subtitle`),

  referenceName: {
    label: t(`${cmsLocation}.referenceName.label`),
    placeholder: t(`${cmsLocation}.referenceName.placeholder`),
  },
  referenceEmail: {
    label: t(`${cmsLocation}.referenceEmail.label`),
    placeholder: t(`${cmsLocation}.referenceEmail.placeholder`),
  },
});

interface InputEventTarget {
  name: CompanyReferenceFieldsEnum;
  value: string;
}

export const useErrors = () =>
  useAnalyticsErrors<CompanyReferenceValues>({
    [CompanyReferenceFieldsEnum.referenceName]: '',
    [CompanyReferenceFieldsEnum.referenceEmail]: '',
  });

const validate = (v: string, rules: ValidationRule[]) => rules.find(({ rule }) => !rule(v));

const stepName = StepNamesDefaultIncome.companyReference;

const CompanyReference = ({
  data,
  onSubmit,
  values: initialValues,
  validationRules,
  ...props
}: DefaultIncomeQuestionComponentProps) => {
  const { t } = useTranslation();
  const content = useContent(t);
  const [values, setValues] = useAnalyticsValues<CompanyReferenceValues>({
    [CompanyReferenceFieldsEnum.referenceName]:
      initialValues?.companyReference?.referenceName || '',
    [CompanyReferenceFieldsEnum.referenceEmail]:
      initialValues?.companyReference?.referenceEmail || '',
  });
  const [error, setError] = useErrors();

  const PERSONAL_EMAIL_RULE = useMemo(
    () =>
      Object.freeze<ValidationRule>({
        name: 'personal-email',
        rule: v => !v || v.toLowerCase() !== data.email.toLowerCase(),
        error: `${cmsLocation}.validation.personalEmailMatch`,
      }),
    [data.email],
  );

  const getValidationResult = useCallback(
    (name: CompanyReferenceFieldsEnum, givenValue?: string) => {
      const actualValue = givenValue === undefined ? values[name] : givenValue;
      const result = validate(actualValue, validationRules[name]);

      if (!result && name === 'referenceEmail') {
        // Run an additional check that the email doesn't match the user's email address
        return validate(actualValue, [PERSONAL_EMAIL_RULE]);
      }

      return result;
    },
    [PERSONAL_EMAIL_RULE, validationRules, values],
  );

  const handleSubmit = useCallback(() => {
    const errors = (Object.keys(values) as CompanyReferenceFieldsEnum[]).reduce((e, inputName) => {
      const result = getValidationResult(inputName);

      return { ...e, [inputName]: result ? t(result.error as any) : '' };
    }, error);

    if (Object.values(errors).filter(e => e !== '').length === 0) {
      onSubmit({ key: stepName, value: values });
    } else {
      setError(errors);
    }
  }, [error, getValidationResult, onSubmit, setError, t, values]);

  const validateAndSetError = useCallback(
    (name: CompanyReferenceFieldsEnum, newValue: string) => {
      const result = getValidationResult(name, newValue);
      setError({ ...error, [name]: result ? t(result.error as any) : '' });
    },
    [error, getValidationResult, setError, t],
  );

  const handleInputOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target as InputEventTarget;

      if (error[name as CompanyReferenceFieldsEnum]) {
        validateAndSetError(name, value.trim());
      }

      setValues({ ...values, [name]: value.trim() });
    },
    [error, setValues, validateAndSetError, values],
  );

  const handleInputOnBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      const { name, value } = e.target as InputEventTarget;
      validateAndSetError(name, value.trim());
    },
    [validateAndSetError],
  );

  const subtitle = (
    <Trans
      i18nKey={`${cmsLocation}.subtitle`}
      components={{
        bold: <b />,
      }}
    />
  );

  return (
    <QuestionOrganism
      id={stepName}
      data-testid={stepName}
      onSubmit={handleSubmit}
      title={content.title}
      subtitle={subtitle}
      {...props}
    >
      <div className="grid w-max md:grid-cols-2 gap-x-10 gap-y-4">
        <Field
          data-testid={`${stepName}-referenceName`}
          label={content.referenceName.label}
          validationError={error.referenceName}
        >
          <Input
            id={`${stepName}-referenceName`}
            name={'referenceName'}
            defaultValue={values.referenceName}
            onChange={handleInputOnChange}
            aria-invalid={!!error.referenceName}
            onBlur={handleInputOnBlur}
            placeholder={content.referenceName.placeholder}
          />
        </Field>

        <Field
          data-testid={`${stepName}-referenceEmail`}
          label={content.referenceEmail.label}
          validationError={error.referenceEmail}
        >
          <Input
            id={`${stepName}-referenceEmail`}
            name={'referenceEmail'}
            defaultValue={values.referenceEmail}
            onChange={handleInputOnChange}
            aria-invalid={!!error.referenceEmail}
            onBlur={handleInputOnBlur}
            placeholder={content.referenceEmail.placeholder}
            type="email"
          />
        </Field>
      </div>
    </QuestionOrganism>
  );
};

export default CompanyReference;

/* eslint-disable jsx-a11y/anchor-has-content */
import { Button } from 'components';
import clsx from 'clsx';
import { Trans } from 'react-i18next';
import { useTranslatedValues } from 'hooks/useTranslatedValues';

interface SubmissionBoxProps {
  disabled?: boolean;
  hasRemedialSection?: boolean;
  className?: string;
  onClick: () => void;
  submitted?: boolean;
  loading?: boolean;
}

const translations = {
  submission: '/dashboard.submission',
  remedialSubmission: '/dashboard.remedialSubmission',
  submitButton: '/dashboard.submitButton',
  submitted: '/dashboard.submitted',
} as const;

const SubmissionBox = ({
  hasRemedialSection,
  disabled,
  className,
  onClick,
  submitted,
  loading,
}: SubmissionBoxProps) => {
  const content = useTranslatedValues(translations);

  return (
    <div
      data-testid="submission-box"
      className={clsx('bg-light-blue-1 rounded p-4 mt-6 md:mt-0 flex-col', className)}
    >
      {submitted ? (
        <div className="self-center">
          <Trans
            i18nKey="/dashboard.submitted"
            components={{
              bold: <span className="font-semibold" />,
              a: <a className="font-semibold" href="mailto:support@homeppl.com" />,
            }}
          />
        </div>
      ) : (
        <>
          {hasRemedialSection ? content.remedialSubmission : content.submission}
          <Button
            onClick={onClick}
            className="mt-4 disabled:opacity-20 w-min whitespace-nowrap"
            disabled={disabled}
            loading={loading}
          >
            {content.submitButton}
          </Button>
        </>
      )}
    </div>
  );
};

export default SubmissionBox;

import { Trans, useTranslation } from 'react-i18next';
import OnboardingCard from '../OnboardingCard';
import WelcomeSvg from '../WelcomePage/welcome.svg';

export interface AllSetPageProps {
  index: number;
  stepsComponent: React.ReactNode;
  onClick: () => void;
}

const AllSetPage = ({ stepsComponent, index, onClick }: AllSetPageProps) => {
  const { t } = useTranslation();

  return (
    <OnboardingCard
      title={t('onboarding.allset.title')}
      imgSrc={WelcomeSvg}
      buttonProps={{
        text: t('onboarding.allset.submit_button'),
        onClick,
      }}
      steps={stepsComponent}
      index={index}
    >
      <p className="text-center font-medium">
        <Trans
          i18nKey={'onboarding.allset.description'}
          components={{
            br: <br></br>,
          }}
        />
      </p>
    </OnboardingCard>
  );
};

export default AllSetPage;

import { KnownCountryCode } from 'components/atoms/Flag/types';
import DropDown from '../../molecules/DropDown';
import { Options } from '../../molecules/DropDown/types';
import FlagOption from './FlagOption';
import DownChevron from './Chevron.svg';
import { ButtonContentsProps } from '../../molecules/DropDown/DropDown';
import clsx from 'clsx';
import Flag from 'components/atoms/Flag';
import { lookupFlag } from 'components/atoms/Flag/Flag';
export interface FlagDropDownProps {
  label: string;
  options: Options;
  onSelectValue: (newValue: string) => void;
  placeholder?: string;
  value: string;
}

const FallbackIcon = () => <img src={DownChevron} alt="" role="presentation" />;

const ButtonContents = ({
  IconComponent = FallbackIcon,
  option,
  error,
  placeholder,
  ...props
}: ButtonContentsProps) => (
  <button
    {...props}
    className={clsx(
      'w-full h-full',
      error ? 'border-red-3 text-red-5' : 'border-grey-3 text-black disabled:border-grey-3',
      'border rounded',
      'disabled:opacity-50',
      'hover:border-blue-4',
    )}
  >
    <div
      className={clsx(
        'absolute left-0 right-1 top-1 bottom-0 p-1',
        'flex flex-row-reverse flex-no-wrap justify-between items-center',
        'font-semibold ext-sm leading-5',
      )}
    >
      <IconComponent />
      {option && (
        <>
          {option.label}
          <Flag countryCode={option.value as KnownCountryCode} />
        </>
      )}
    </div>
  </button>
);

const FlagDropDown = ({ options, onSelectValue, value, ...props }: FlagDropDownProps) => (
  <DropDown
    className="w-[99px] h-8 text-center"
    selectStyle={{
      paddingLeft: '38px',
      backgroundPosition: 'left, right',
      backgroundImage: `url("${lookupFlag[value as KnownCountryCode]}"), url("${DownChevron}")`,
    }}
    options={options}
    onSelectValue={onSelectValue}
    OptionComponent={FlagOption}
    value={value}
    ButtonContentsComponent={ButtonContents}
    {...props}
  />
);

export default FlagDropDown;

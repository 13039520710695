import {
  AccountDocumentGqlType,
  AccountDocumentType,
  ResidentialHistoryType,
} from 'generated/graphql';
import { LandlordDetailsInterface } from 'questionFlow/residence/landlordDetails';
import { QuestionComponentProps, YesNoEnum } from 'questionFlow/types';

export enum StepNamesDefaultResidence {
  'inUK' = 'in-uk',
  'residentialHistory' = 'residential-history',
  'ukResidentialHistory' = 'uk-residential-history',
  'singleUKResidentialHistory' = 'single-uk-residential-history',
  'selectAddress' = 'select-address',
  'rentalDetails' = 'rental-details',
  'landlordDetails' = 'landlordDetails',
  'haveYouEver' = 'have-you-ever',
  'documents' = 'documents',
  'none' = 'none',
  'upload' = 'upload',
  'thanks' = 'thanks',
  'reuseConnection' = 'already-connected',
  'placeholder' = 'placeholder',
  'documentSignposting' = 'documentSignposting',
  'documentSignpostingForTenancyAgreement' = 'documentSignpostingForTenancyAgreement',
  'uploadForTenancyAgreement' = 'uploadForTenancyAgreement',
}

export interface DefaultResidenceValuesType {
  connectedOpenBanking?: boolean;
  uuid: string | null;
  [StepNamesDefaultResidence.inUK]?: YesNoEnum;
  [StepNamesDefaultResidence.residentialHistory]?: ResidentialHistoryType[];
  [StepNamesDefaultResidence.selectAddress]?: ResidentialHistoryType;
  [StepNamesDefaultResidence.rentalDetails]?: Partial<ResidentialHistoryType>;
  [StepNamesDefaultResidence.landlordDetails]?: LandlordDetailsInterface;
  [StepNamesDefaultResidence.haveYouEver]?: YesNoEnum;
  [StepNamesDefaultResidence.reuseConnection]?: YesNoEnum;
  [StepNamesDefaultResidence.documents]?: AccountDocumentType;
  [StepNamesDefaultResidence.upload]?: AccountDocumentGqlType[];
}

export interface DefaultResidenceQuestionComponentProps
  extends QuestionComponentProps<{}, DefaultResidenceValuesType> {}

import { useCallback } from 'react';
import { FormEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, Input, Button, Label } from 'components';
import { getEmailReason } from 'validation';
import useInput from 'hooks/useInput';
import BaseOtlPage from '../BaseOtlPage';
import clsx from 'clsx';
import CenteredCard from 'components/templates/CenteredCard';

export interface SignInPageProps {
  disabled?: boolean;
  error?: boolean;
  isSessionExpired?: boolean;
  onLogin: (email: string) => void;
}

const SignInPage = ({ disabled, error, onLogin, isSessionExpired }: SignInPageProps) => {
  const [email, emailProps, { canSubmit, validationError }] = useInput('', getEmailReason);
  const { t } = useTranslation();

  const infoLineTop = t(error ? 'signup.error_top_line' : 'signup.description_top_line');
  const infoLineBottom = t(error ? 'signup.error_bottom_line' : 'signup.description_bottom_line');

  const onSubmit: FormEventHandler = useCallback(
    e => {
      e.preventDefault();

      if (canSubmit()) {
        onLogin(email);
      }
    },
    [onLogin, email, canSubmit],
  );

  return (
    <BaseOtlPage>
      <CenteredCard.Body className="md:flex-auto md:flex md:flex-row md:justify-center md:content-center md:items-end">
        <form
          onSubmit={onSubmit}
          className="flex flex-col items-center flex-auto text-center mx-6"
          id="sign-in-form"
          noValidate
        >
          <Label
            className="text-2xl leading-8 font-semibold flex flex-col items-center md:text-[32px] md:leading-10"
            data-testid="email-label"
            htmlFor="email-input"
          >
            {t(isSessionExpired ? 'signup.session_expired' : 'signup.signin_prompt')}
          </Label>
          {isSessionExpired ? (
            <p data-testid="expired-reason" className="text-base leading-6 mt-6 md:max-w-[404px]">
              {t('signup.session_expired_reason')}
            </p>
          ) : null}
          <Field className="m-6" data-testid="email-entry" validationError={validationError}>
            <Input
              disabled={disabled}
              className="text-center"
              data-testid="email-input"
              id="email-input"
              placeholder={t('signup.enter_email')}
              type="email"
              {...emailProps}
            />
          </Field>
          {/* Below span is deliberatley broken at a specific place instead of leaving it to wrapping */}
          <span
            role={error ? 'alert' : undefined}
            className={clsx('text-base leading-6', error && 'text-red-5')}
            data-testid="signin-info"
          >
            {infoLineTop} <br /> {infoLineBottom}
          </span>
          <Button disabled={disabled} className="my-6 md:px-32" form="sign-in-form" type="submit">
            {t('signup.send_me_magic_link')}
          </Button>
        </form>
      </CenteredCard.Body>
    </BaseOtlPage>
  );
};

export default SignInPage;

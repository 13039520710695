import QuestionOrganism from 'questionFlow/QuestionOrganism';
import { useMemo, useState } from 'react';
import { TFunction, useTranslation, Trans } from 'react-i18next';
import {
  DefaultIncomeQuestionComponentProps,
  StepNamesDefaultIncome,
} from 'questionFlow/flows/defaultIncome/types';
import { DropDown, Field } from 'components';
import { useAnalyticsErrors, useAnalyticsEvent } from 'monitoring/analyticsHooks';
import { ValidationRule } from 'questionFlow/types';

const cmsLocation = '/questions/income/feedback';

const useContent = (t: TFunction<'translation'>) => ({
  title: t(`${cmsLocation}.title`),
  subtitle: t(`${cmsLocation}.subtitle`),
  placeholder: t(`${cmsLocation}.placeholder`),
  option_cannotFindBank: t(`${cmsLocation}.option_cannotFindBank`),
  option_techinalDifficulties: t(`${cmsLocation}.option_techinalDifficulties`),
  option_notComfortable: t(`${cmsLocation}.option_notComfortable`),
});

const stepName = StepNamesDefaultIncome.feedback;
const validate = (v: string, rules: ValidationRule[]) => rules.find(({ rule }) => !rule(v));

const OpenBankingFeedback = ({
  data,
  values,
  validationRules,
  onSubmit,
  ...props
}: DefaultIncomeQuestionComponentProps) => {
  const { t } = useTranslation();
  const content = useContent(t);

  const [reason, setReason] = useState<string>(values.feedback || '');
  const [error, setError] = useAnalyticsErrors<Record<string, string>>({
    reason: '',
  });

  const { broadcastEvent } = useAnalyticsEvent();

  const handleSubmit = () => {
    const result = validate(reason, validationRules.reason);
    const errors = { reason: result ? t(result.error as any) : '' };

    if (errors.reason.length === 0) {
      broadcastEvent(`Open banking feedback: ${reason}`);
      onSubmit({ key: stepName, value: reason });
    } else {
      setError(errors);
    }
  };

  const subtitle = (
    <Trans
      i18nKey={`${cmsLocation}.subtitle`}
      components={{
        b: <b />,
      }}
    />
  );

  const options = useMemo(
    () => [
      {
        value: 'Unable to find my bank or country in the list',
        label: content.option_cannotFindBank,
      },
      { value: 'Experienced technical difficulties', label: content.option_techinalDifficulties },
      {
        value: 'Not comfortable sharing my bank account details',
        label: content.option_notComfortable,
      },
    ],
    [content],
  );

  return (
    <QuestionOrganism
      id={stepName}
      data-testid={stepName}
      onSubmit={handleSubmit}
      title={content.title}
      subtitle={subtitle}
      {...props}
    >
      <Field data-testid={`${stepName}-reason`} validationError={error.reason}>
        <DropDown
          label="feedback-reason"
          options={options}
          placeholder={content.placeholder}
          value={reason}
          onSelectValue={setReason}
          size="lg"
        />
      </Field>
    </QuestionOrganism>
  );
};

export default OpenBankingFeedback;

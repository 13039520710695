import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import SupportedLanguages from './SupportedLanguages';
import I18NextLocizeBackend from 'i18next-locize-backend';
import { createLocizeConfig, saveTranslationsEnabled } from './BackendConfig';

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(I18NextLocizeBackend)
  .init({
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    saveMissing: saveTranslationsEnabled(),
    backend: createLocizeConfig(),
    detection: {
      caches: ['cookie'],
      order: ['cookie', 'querystring', 'navigator', 'htmlTag'],
    },
    react: {
      useSuspense: true,
    },
    supportedLngs: SupportedLanguages.map(({ code }) => code),
  });

import AmountsQuestion from '../genericQuestions/AmountsQuestion';
import {
  DefaultIncomeQuestionComponentProps,
  StepNamesDefaultIncome,
} from 'questionFlow/flows/defaultIncome/types';

import { TFunction, useTranslation } from 'react-i18next';

const cmsLocation = '/questions/income/benefitAmount';

const useContent = (t: TFunction<'translation'>) => ({
  title: t(`${cmsLocation}.title`),
  subtitle: t(`${cmsLocation}.subtitle`),
  annualValue: {
    label: t(`${cmsLocation}.annualValue.label`),
    placeholder: t(`${cmsLocation}.annualValue.placeholder`),
  },
  netIncome: {
    label: t(`${cmsLocation}.netIncome.label`),
    placeholder: t(`${cmsLocation}.netIncome.placeholder`),
  },
  paymentDate: t(`${cmsLocation}.paymentDate`),
});

const name = StepNamesDefaultIncome.benefitAmount;

const BenefitAmount = ({
  data,
  onSubmit,
  values,
  validationRules,
  ...props
}: DefaultIncomeQuestionComponentProps) => {
  const { t } = useTranslation();
  const content = useContent(t);
  return (
    <AmountsQuestion
      onSubmit={onSubmit}
      validationRules={validationRules}
      data={data}
      values={values}
      annualLabel={content.annualValue.label}
      annualPlaceholderText={content.annualValue.placeholder}
      paymentLabel={content.netIncome.label}
      paymentPlaceholderText={content.netIncome.placeholder}
      paymentDateLabel={content.paymentDate}
      title={content.title}
      subtitle={content.subtitle}
      name={name}
      {...props}
    />
  );
};
export default BenefitAmount;

import { forwardRef } from 'react';
import clsx from 'clsx';
import Flag from 'components/atoms/Flag';
import { KnownCountryCode } from 'components/atoms/Flag/types';
import { OptionComponentProps } from '../../molecules/DropDown/types';

const FlagOption = forwardRef<HTMLLIElement, OptionComponentProps>(
  ({ label, onClick, selected, value }, ref) => (
    <li
      role="option"
      aria-selected={selected}
      aria-label={label}
      className={clsx(
        'w-[100px] h-11',
        'flex items-center',
        'text-sm leading-5',
        'cursor-pointer list-none',
        'hover:bg-light-blue-1',
        selected ? 'bg-blue-4 hover:bg-blue-4 text-white' : 'bg-white',
      )}
      onClick={e => {
        e.preventDefault();
        onClick();
      }}
      value={value}
      ref={ref}
    >
      <Flag className="w-9 h-5 m-1" countryCode={value as KnownCountryCode} />
      <span className="m-4">{label}</span>
    </li>
  ),
);

export default FlagOption;

import clsx from 'clsx';
import { Button } from 'components/atoms';

export interface Props {
  options: [string, string];
  selected?: string;
  className?: string;
  'data-testid'?: string;
  onChange: (value: string) => void;
}

const ButtonOption: React.FC<Props> = ({
  options,
  selected,
  'data-testid': testId = 'button-options',
  onChange,
}) => {
  return (
    <div className="flex w-full" data-testid={testId}>
      {options.map((option, index) => (
        <Button
          key={index}
          type="button"
          styleType="neutral"
          onClick={() => onChange(option)}
          className={clsx('flex-1 font-normal !text-sm', {
            'rounded-l-md': index % 2 === 0,
            'rounded-r-md': index % 2 === 1,
            'bg-blue-100 border-blue-600': option === selected,
          })}
        >
          {option}
        </Button>
      ))}
    </div>
  );
};

export default ButtonOption;

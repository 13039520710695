import { CurrencyTypesEnum, GqlPaymentFrequencyEnum, Maybe } from 'generated/graphql';

export type StringQuestion =
  | 'annualBaseSalary'
  | 'currency'
  | 'netSalary'
  | 'frequency'
  | 'bonusStatus';
export type DateQuestion = 'lastPaymentDate';
export type YourSalaryQuestion = StringQuestion | DateQuestion;

export function assertIsYourSalaryStringQuestion(kind: string): asserts kind is StringQuestion {
  if (!['annualBaseSalary', 'currency', 'netSalary', 'frequency', 'bonusStatus'].includes(kind)) {
    throw new Error(`${kind} is not a StringQuestion type`);
  }
}

export function assertYourSalaryDateQuestion(kind: string): asserts kind is DateQuestion {
  if (kind !== 'lastPaymentDate') {
    throw new Error(`${kind} is not a DateQuestion type`);
  }
}

type StringValues = Record<StringQuestion, string>;

export type DateValue = {
  valid: boolean;
  value: string;
};

type DateValues = Record<DateQuestion, DateValue>;

export function isDateValue(maybeDate: string | DateValue): maybeDate is DateValue {
  return typeof maybeDate !== 'string';
}

export interface YourSalaryValues extends DateValues, StringValues {}

export type YourSalaryQuestionValues = {
  annualBaseSalary: string;
  currency: Maybe<CurrencyTypesEnum>;
  netSalary: string;
  frequency: Maybe<GqlPaymentFrequencyEnum>;
  bonusStatus: string;
  lastPaymentDate: string;
};

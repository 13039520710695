import { Options } from 'components/molecules/DropDown/types';
import { ReferenceTypeEnum } from 'generated/graphql';
import { useMemo } from 'react';
import { TFunction, useTranslation } from 'react-i18next';

const cmsLocation = '/questions/residence/landlord-details';

export const useContent = (t: TFunction<'translation'>) => ({
  type: {
    label: t(`${cmsLocation}.type.label`),
    placeholder: t(`${cmsLocation}.type.placeholder`),
    option_private_landlord: t(`${cmsLocation}.type.option_private_landlord`),
    option_managing_company: t(`${cmsLocation}.type.option_managing_company`),
    option_letting_agent: t(`${cmsLocation}.type.option_letting_agent`),
  },
});

export const useRentTypeOptions = () => {
  const { t } = useTranslation();
  const content = useContent(t);

  const { option_private_landlord, option_managing_company, option_letting_agent } = content.type;

  const typeOptions: Options = useMemo(
    () => [
      { value: ReferenceTypeEnum.Landlord, label: option_private_landlord },
      { value: ReferenceTypeEnum.ManagingCompany, label: option_managing_company },
      { value: ReferenceTypeEnum.Agent, label: option_letting_agent },
    ],
    [option_private_landlord, option_managing_company, option_letting_agent],
  );

  return typeOptions;
};

import { useAnalyticsErrors, useAnalyticsValues } from 'monitoring/analyticsHooks';
import { DefaultIncomeValuesType } from 'questionFlow/flows/defaultIncome/types';
import { YourGuarantorQuestion, YourGuarantorValues, YourGuarantorQuestionValues } from './types';

export const adaptToExternalFormat = ({
  ...input
}: YourGuarantorValues): YourGuarantorQuestionValues => ({
  ...input,
});

export const useValues = (initialValues?: DefaultIncomeValuesType) => {
  const yourGuarantor = initialValues?.yourGuarantor as YourGuarantorQuestionValues | undefined;

  return useAnalyticsValues<YourGuarantorValues>({
    firstName: yourGuarantor?.firstName || '',
    lastName: yourGuarantor?.lastName || '',
    email: yourGuarantor?.email || '',
    phoneNumber: yourGuarantor?.phoneNumber || '',
  });
};

export const useErrors = () =>
  useAnalyticsErrors<Record<YourGuarantorQuestion, string>>({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
  });

import { useTranslation } from 'react-i18next';
import OnboardingCard from '../OnboardingCard';
import ResidenceSvg from './residence.svg';
import { HomeIcon, UserGroupIcon } from '@heroicons/react/outline';
import DocumentsList from '../DocumentsList';

export interface ResidencePageProps {
  index: number;
  stepsComponent: React.ReactNode;
  onClick: () => void;
}

const ResidencePage = ({ stepsComponent, index, onClick }: ResidencePageProps) => {
  const { t } = useTranslation();

  const documents = [
    {
      name: t('onboarding.residence.address'),
      note: t('onboarding.residence.address_note'),
      Icon: HomeIcon,
    },
    {
      name: t('onboarding.residence.landlord'),
      note: t('onboarding.residence.landlord_note'),
      Icon: UserGroupIcon,
    },
  ];
  return (
    <OnboardingCard
      title={t('onboarding.residence.title')}
      imgSrc={ResidenceSvg}
      buttonProps={{
        text: t('onboarding.residence.submit_button'),
        onClick,
      }}
      steps={stepsComponent}
      index={index}
    >
      <DocumentsList description={t('onboarding.residence.description')} documents={documents} />
    </OnboardingCard>
  );
};

export default ResidencePage;

import clsx from 'clsx';
import { sortSections } from 'questionFlow/SectionSorting';
import { IndicatorOption } from '.';

interface ProgressItemProps extends React.HTMLAttributes<HTMLDivElement> {
  label: string;
  selected: boolean;
  value: string;
  className?: string;
}

export const ProgressItem = ({
  label,
  selected,
  value,
  className,
  ...props
}: ProgressItemProps) => (
  <div
    aria-hidden={!selected}
    className={clsx(
      'flex-auto',
      'p-2.5 pb-2 mx-2 border-b-2 border-pink-2',
      'font-semibold text-sm leading-4 md:text-base md:leading-6',
      !selected && 'opacity-20',
      className,
    )}
    {...props}
  >
    {label}
  </div>
);

export interface Props extends React.HTMLAttributes<HTMLElement> {
  options: IndicatorOption[];
  selectedValue?: string;
}

const createIdForValue = (value?: string) => `progress-item-for-${value}`;

const ProgressIndicator = ({ className, selectedValue, options, ...props }: Props) => {
  const selectedProgressItemId = createIdForValue(selectedValue);

  return (
    <section
      {...props}
      aria-labelledby={selectedProgressItemId}
      className={clsx('flex -mx-2', className)}
      data-testid="progressindicator"
    >
      {options.sort(sortSections('value')).map(({ label, value }, index) => (
        <ProgressItem
          data-testid={`progressindicator-item-${index}`}
          key={value}
          id={createIdForValue(value)}
          label={label}
          selected={value === selectedValue}
          value={value}
        />
      ))}
    </section>
  );
};

export default ProgressIndicator;

import { KnownCountryCode } from './types';

import FlagDE from './images/DE.svg';
import FlagES from './images/ES.svg';
import FlagFR from './images/FR.svg';
import FlagUK from './images/UK.svg';
import FlagIT from './images/IT.svg';
import FlagPT from './images/PT.svg';

interface Props extends Omit<React.ComponentPropsWithoutRef<'img'>, 'src'> {
  countryCode: KnownCountryCode;
}

export const lookupFlag = Object.freeze<{ [key in KnownCountryCode]: string }>({
  de: FlagDE,
  es: FlagES,
  fr: FlagFR,
  en: FlagUK,
  it: FlagIT,
  pt: FlagPT,
});

const Flag = ({ countryCode, ...props }: Props) => {
  return <img src={lookupFlag[countryCode]} alt="" {...props} />;
};

export default Flag;

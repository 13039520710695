import AllCountryCodes from 'questionFlow/data/all_country_codes.json';
import { pull } from 'lodash';
import { Maybe } from 'generated/graphql';

export const BRITISH_IRISH = ['GB', 'IE'];
export const EEA_SWISS = [
  'BE',
  'ES',
  'HU',
  'SK',
  'BG',
  'FR',
  'MT',
  'FI',
  'CZ',
  'GR',
  'HR',
  'NL',
  'SE',
  'DK',
  'IT',
  'AT',
  'DE',
  'CY',
  'PL',
  'IS',
  'EE',
  'LV',
  'PT',
  'LI',
  'LT',
  'RO',
  'NO',
  'EL',
  'LU',
  'SI',
];
export const FRIENDLY_COUNTRIES = ['AU', 'CA', 'JP', 'NZ', 'SG', 'KR', 'US', 'HK'];
export const REMAINING_COUNTRIES = pull(
  [...AllCountryCodes],
  ...[...BRITISH_IRISH, ...EEA_SWISS, ...FRIENDLY_COUNTRIES],
);

export const STATUS_OPTIONS = {
  ONGOING_VISA_APPLICATION: 'ONGOING_VISA_APPLICATION',
  NO_APPLICATIONS: 'NO_APPLICATIONS',
  SETTLED: 'SETTLED',
  OVER_6_MONTHS: 'OVER_6_MONTHS',
  UNDER_6_MONTHS: 'UNDER_6_MONTHS',
  WILL_BE_UNDER_6: 'WILL_BE_UNDER_6',
};

const data = Object.freeze([
  {
    nationalities: BRITISH_IRISH,
    documents: {
      primary: [
        {
          options: ['UK_PASSPORT', 'NATURALIZATION', 'BRITISH_CITIZEN'],
          quantity: 1,
        },
      ],
      secondary: [
        {
          options: ['UK_DRIVING_LICENCE', 'BIRTH_ADOPTION', 'ARMED_FORCES', 'DBS'],
          quantity: 2,
        },
      ],
    },
  },
  {
    nationalities: [...EEA_SWISS, ...FRIENDLY_COUNTRIES],
    status: STATUS_OPTIONS.ONGOING_VISA_APPLICATION,
    documents: {
      primary: [{ options: ['UK_BIOMETRIC_RESIDENCE', 'TIME_LIMITED_PASSPORT'], quantity: 1 }],
      secondary: [
        { options: ['PASSPORT', 'BRITISH_NATIONAL_OVERSEAS_PASSPORT', 'NATIONAL_ID'], quantity: 1 },
        { options: ['UK_VISA_APPLICATION_LETTER'], quantity: 1 },
      ],
    },
  },
  {
    nationalities: [...EEA_SWISS, ...FRIENDLY_COUNTRIES],
    status: STATUS_OPTIONS.NO_APPLICATIONS,
    documents: {
      primary: [{ options: ['UK_BIOMETRIC_RESIDENCE', 'TIME_LIMITED_PASSPORT'], quantity: 1 }],
      secondary: [
        { options: ['PASSPORT', 'NATIONAL_ID'], quantity: 1 },
        { options: ['TRAVEL_BOOKING'], quantity: 1 },
      ],
    },
  },
  {
    nationalities: [...EEA_SWISS, ...FRIENDLY_COUNTRIES],
    status: STATUS_OPTIONS.SETTLED,
    requireShareCode: true,
    documents: {
      primary: [{ options: ['UK_BIOMETRIC_RESIDENCE', 'TIME_LIMITED_PASSPORT'], quantity: 1 }],
      secondary: [{ options: ['PASSPORT'], quantity: 1 }],
    },
  },
  {
    nationalities: REMAINING_COUNTRIES,
    documents: {
      primary: [
        {
          options: ['UK_BIOMETRIC_RESIDENCE', 'TIME_LIMITED_PASSPORT'],
          quantity: 1,
        },
      ],
      secondary: [
        { options: ['PASSPORT'], quantity: 1 },
        { options: ['UK_VISA_APPLICATION_LETTER'], quantity: 1 },
      ],
    },
  },
  {
    nationalities: REMAINING_COUNTRIES,
    status: STATUS_OPTIONS.SETTLED,
    requireShareCode: true,
    documents: {
      primary: [{ options: ['UK_BIOMETRIC_RESIDENCE', 'TIME_LIMITED_PASSPORT'] }],
      secondary: [{ options: ['PASSPORT'], quantity: 1 }],
    },
  },
]);

export const getRTRDocumentOptionsByNationality = (nationality: string) => {
  const documentOptions = data.find(({ nationalities }) => nationalities.includes(nationality));
  if (!documentOptions) {
    throw new Error(`No matching document upload options for RTR nationality ${nationality}`);
  }
  return documentOptions;
};

export const getAllRTRDocumentOptionsByNationalityOnly = (nationality: string) =>
  data.filter(({ nationalities }) => nationalities.includes(nationality));

export const getRTRDocumentOptionsByNationalityAndStatus = (
  nationality: string,
  chosenStatus?: Maybe<string>,
) => {
  const documentOptions = data.find(({ nationalities, status }) => {
    return nationalities.includes(nationality) && (!chosenStatus || status === chosenStatus);
  });
  if (!documentOptions) {
    throw new Error(
      `No matching document upload options for RTR nationality ${nationality} and status ${chosenStatus}`,
    );
  }
  return documentOptions;
};

export default data;

import { useAnalyticsErrors, useAnalyticsValues } from 'monitoring/analyticsHooks';
import { DefaultIncomeValuesType } from 'questionFlow/flows/defaultIncome/types';
import { LoanDetailsQuestion, LoanDetailsValues, ExternalFormat } from './types';

export const adaptToExternalFormat = ({
  startDate,
  endDate,
  ...input
}: LoanDetailsValues): ExternalFormat => ({
  ...input,
  startDate: startDate.value,
  endDate: endDate.value,
});

export const useValues = (initialValues?: DefaultIncomeValuesType) => {
  const loanDetails = initialValues?.loanDetails as ExternalFormat | undefined;

  return useAnalyticsValues<LoanDetailsValues>({
    bodyName: loanDetails?.bodyName || '',
    startDate: {
      valid: true,
      value: loanDetails?.startDate || '',
    },
    endDate: {
      valid: true,
      value: loanDetails?.endDate || '',
    },
  });
};

export const useErrors = () =>
  useAnalyticsErrors<Record<LoanDetailsQuestion, string>>({
    bodyName: '',
    startDate: '',
    endDate: '',
  });

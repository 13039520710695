import { shouldPolyfill as shouldPolyfillDisplayNames } from '@formatjs/intl-displaynames/should-polyfill';
import { shouldPolyfill as shouldPolyfillCanonical } from '@formatjs/intl-getcanonicallocales/should-polyfill';
import { shouldPolyfill as shouldPolyfillLocale } from '@formatjs/intl-locale/should-polyfill';

import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { SupportedLanguageCode } from './SupportedLanguages';

async function polyfill(locale: string) {
  if (shouldPolyfillLocale()) {
    await import('@formatjs/intl-locale/polyfill');
  }
  if (shouldPolyfillCanonical()) {
    await import('@formatjs/intl-getcanonicallocales/polyfill');
  }
  if (shouldPolyfillDisplayNames(locale)) {
    await import('@formatjs/intl-displaynames/polyfill');
    Object.values(SupportedLanguageCode).forEach(async locale =>
      import(`@formatjs/intl-displaynames/locale-data/${locale}`),
    );
  }
}

const usePolyfill = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    polyfill(i18n.language);
  }, [i18n.language]);

  return null;
};

export default usePolyfill;

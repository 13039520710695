import I18NextLocizeBackend from 'i18next-locize-backend';
import { getEnv } from 'services/environmentVariables';

export const createLocizeConfig = (): I18NextLocizeBackend.BackendOptions => {
  const projectId = getEnv('LOCIZE_PROJECT_ID');

  if (projectId === undefined) {
    throw new Error(
      'Locize project id environment variable must be set. See Readme for more details.',
    );
  }

  const apiKey = 'LOCIZE_API_KEY';
  const loadPath = '/assets/locales/{{lng}}/translation.json';

  return {
    apiKey,
    loadPath,
    projectId,
    referenceLng: 'en',
  };
};

export const saveTranslationsEnabled = (): boolean => false;

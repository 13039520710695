import { Options } from 'components/molecules/DropDown/types';
import { RentFrequencyEnum } from 'generated/graphql';
import { useMemo } from 'react';
import { TFunction, useTranslation } from 'react-i18next';

export const cmsLocation = '/questions/residence/rental-details';

export const useContent = (t: TFunction<'translation'>) => ({
  frequency: {
    label: t(`${cmsLocation}.rentFrequency.label`),
    placeholder: t(`${cmsLocation}.rentFrequency.placeholder`),
    option_daily: t(`${cmsLocation}.rentFrequency.option_daily`),
    option_weekly: t(`${cmsLocation}.rentFrequency.option_weekly`),
    option_fortnightly: t(`${cmsLocation}.rentFrequency.option_fortnightly`),
    option_monthly: t(`${cmsLocation}.rentFrequency.option_monthly`),
    option_quarterly: t(`${cmsLocation}.rentFrequency.option_quarterly`),
    option_annually: t(`${cmsLocation}.rentFrequency.option_annually`),
  },
});

export const useRentFrequencyOptions = () => {
  const { t } = useTranslation();
  const content = useContent(t);

  const {
    option_daily,
    option_weekly,
    option_fortnightly,
    option_monthly,
    option_quarterly,
    option_annually,
  } = content.frequency;

  const frequencyOptions: Options = useMemo(
    () => [
      { value: RentFrequencyEnum.Daily, label: option_daily },
      { value: RentFrequencyEnum.Weekly, label: option_weekly },
      { value: RentFrequencyEnum.Weekly_2, label: option_fortnightly },
      { value: RentFrequencyEnum.Monthly, label: option_monthly },
      { value: RentFrequencyEnum.Monthly_3, label: option_quarterly },
      { value: RentFrequencyEnum.Monthly_12, label: option_annually },
    ],
    [
      option_daily,
      option_weekly,
      option_fortnightly,
      option_monthly,
      option_quarterly,
      option_annually,
    ],
  );

  return frequencyOptions;
};

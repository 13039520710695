import { Field, Input } from 'components';
import QuestionOrganism from 'questionFlow/QuestionOrganism';
import { useCallback } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { ValidationRule } from '../types';
import {
  DefaultIncomeQuestionComponentProps,
  DefaultIncomeValuesType,
  StepNamesDefaultIncome,
} from '../flows/defaultIncome/types';
import { DateQuestion } from './CurrentEmployer/types';
import DateInput, { OnDateChange } from 'components/molecules/DateInput/DateInput';
import { DateValue, isDateValue } from './YourSalary/types';
import { useAnalyticsErrors, useAnalyticsValues } from 'monitoring/analyticsHooks';
const cmsLocation = '/questions/income/companyDetails';

const useContent = (t: TFunction<'translation'>) => ({
  title: t(`${cmsLocation}.title`),
  subtitle: t(`${cmsLocation}.subtitle`),
  startDate: {
    label: t(`${cmsLocation}.startDate.label`),
    dayInputLabel: t(`${cmsLocation}.startDate.dayInputLabel`),
    monthInputLabel: t(`${cmsLocation}.startDate.monthInputLabel`),
    yearInputLabel: t(`${cmsLocation}.startDate.yearInputLabel`),
  },
  companyName: {
    label: t(`${cmsLocation}.companyName.label`),
    placeholder: t(`${cmsLocation}.companyName.placeholder`),
  },
  companyNumber: {
    label: t(`${cmsLocation}.companyNumber.label`),
    placeholder: t(`${cmsLocation}.companyNumber.placeholder`),
  },
});

export type CompanyDetailsQuestionName = 'companyName' | 'companyNumber' | 'startDate';
export type CompanyDetailsValues = {
  companyName: string;
  companyNumber: string;
  startDate:
    | {
        value: DateValue | string;
        valid: boolean;
      }
    | string;
};

export const useValues = (initialValues?: DefaultIncomeValuesType) => {
  const currentDetails = initialValues?.companyDetails as CompanyDetailsValues | undefined;

  return useAnalyticsValues<CompanyDetailsValues>({
    companyName: currentDetails?.companyName || '',
    companyNumber: currentDetails?.companyNumber || '',
    startDate: currentDetails?.startDate
      ? {
          value: currentDetails.startDate,
          valid: true,
        }
      : {
          value: '',
          valid: false,
        },
  });
};

export const useErrors = () =>
  useAnalyticsErrors<Record<CompanyDetailsQuestionName, string>>({
    companyName: '',
    companyNumber: '',
    startDate: '',
  });

function assertIsCompanyDetailsQuestionName(
  maybe: string,
): asserts maybe is CompanyDetailsQuestionName {
  if (maybe === 'companyName' || maybe === 'companyNumber') {
    return;
  }

  throw new Error(maybe + ' is not a known CompanyDetailsQuestionName');
}

const validate = (v: string, rules: ValidationRule[], valid?: boolean) =>
  rules.find(({ rule }) => !rule(v, valid));
const stepName = StepNamesDefaultIncome.companyDetails;

const CompanyDetails = ({
  data,
  onSubmit,
  values: initialValues,
  validationRules: validationRulesGeneric,
  ...props
}: DefaultIncomeQuestionComponentProps) => {
  const validationRules = (validationRulesGeneric as any) as Record<
    CompanyDetailsQuestionName,
    ValidationRule[]
  >;
  const { t } = useTranslation();
  const content = useContent(t);
  const [values, setValues] = useValues(initialValues);
  const [error, setError] = useErrors();

  const getValidationResult = useCallback(
    (name: CompanyDetailsQuestionName, givenValue?: string) => {
      const actualValue = givenValue === undefined ? values[name] : givenValue;
      return isDateValue(actualValue)
        ? validate(actualValue.value, validationRules[name], actualValue.valid)
        : validate(actualValue, validationRules[name]);
    },
    [validationRules, values],
  );

  const adaptToExternalFormat = ({
    startDate,
    ...input
  }: CompanyDetailsValues): Record<CompanyDetailsQuestionName, string> => ({
    ...input,
    startDate: startDate.value,
  });

  const handleSubmit = useCallback(() => {
    const errors = (Object.keys(values) as CompanyDetailsQuestionName[]).reduce((e, inputName) => {
      const result = getValidationResult(inputName);

      return { ...e, [inputName]: result ? t(result.error as any) : '' };
    }, error);

    if (Object.values(errors).filter(e => e !== '').length === 0) {
      onSubmit({ key: stepName, value: adaptToExternalFormat(values) });
    } else {
      setError(errors);
    }
  }, [error, getValidationResult, onSubmit, setError, t, values]);

  const validateAndSetError = useCallback(
    (name: CompanyDetailsQuestionName, newValue: string) => {
      const result = getValidationResult(name, newValue);
      setError({ ...error, [name]: result ? t(result.error as any) : '' });
    },
    [error, getValidationResult, setError, t],
  );

  const handleInputOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      assertIsCompanyDetailsQuestionName(name);

      if (error[name]) {
        validateAndSetError(name, value.trim());
      }

      setValues({ ...values, [name]: value.trim() });
    },
    [error, setValues, validateAndSetError, values],
  );

  const handleInputOnBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      assertIsCompanyDetailsQuestionName(e.target.name);
      validateAndSetError(e.target.name, e.target.value.trim());
    },
    [validateAndSetError],
  );

  const handleDateOnBlur = (name: DateQuestion) => {
    const result = getValidationResult(name);
    setError({ ...error, [name]: result ? t(result.error as any) : '' });
  };

  const handleDateChange: OnDateChange = ({ name, value, valid }) => {
    setValues({
      ...values,
      [name]: {
        value,
        valid,
      },
    });

    if (valid && error[name]) {
      setError({ ...error, [name]: '' });
    }
  };

  return (
    <QuestionOrganism
      id={stepName}
      data-testid={stepName}
      onSubmit={handleSubmit}
      title={content.title}
      subtitle={content.subtitle}
      {...props}
    >
      <Field
        data-testid={`${stepName}-companyName`}
        label={content.companyName.label}
        validationError={error.companyName}
      >
        <Input
          id={`${stepName}-companyName`}
          name={'companyName'}
          defaultValue={values.companyName}
          onChange={handleInputOnChange}
          aria-invalid={!!error.companyName}
          onBlur={handleInputOnBlur}
          placeholder={content.companyName.placeholder}
        />
      </Field>

      <Field
        data-testid={`${stepName}-companyNumber`}
        label={content.companyNumber.label}
        validationError={error.companyNumber}
      >
        <Input
          id={`${stepName}-companyNumber`}
          name={'companyNumber'}
          defaultValue={values.companyNumber}
          onChange={handleInputOnChange}
          aria-invalid={!!error.companyNumber}
          onBlur={handleInputOnBlur}
          placeholder={content.companyNumber.placeholder}
        />
      </Field>

      <Field
        data-testid={`${stepName}-startDate`}
        label={content.startDate.label}
        validationError={error.startDate}
      >
        <DateInput
          data-testid="start-date-input"
          onChange={handleDateChange}
          onBlur={() => handleDateOnBlur('startDate')}
          defaultValue={values.startDate.value}
          error={!!error.startDate}
          id="startDate"
          dayLabel={content.startDate.dayInputLabel}
          monthLabel={content.startDate.monthInputLabel}
          yearLabel={content.startDate.yearInputLabel}
        />
      </Field>
    </QuestionOrganism>
  );
};

export default CompanyDetails;

import React from 'react';
import OtlRoute from 'pages/otl';
import Questions from 'pages/questions/questions';
import Dashboard from 'pages/Dashboard';
import { Redirect, Route, Switch } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import Logout from 'pages/Logout';
import TopLevelRoutes, { QuestionRoutes } from './routes';
import OpenBankingPage from 'pages/OpenBanking';
import RentalRefrencePage from 'pages/RentalRefrence';
import ReferenceThankYouPage from 'pages/RentalRefrence/ReferenceThankYouPage';

interface RouteDefinition {
  readonly component: React.ComponentType<any>;
  readonly exact: boolean;
  readonly path: string;
  readonly isProtected: boolean;
}

const createDefinition = (
  path: string,
  component: React.ComponentType<any>,
  isProtected: boolean,
  exact: boolean = false,
): RouteDefinition =>
  Object.freeze<RouteDefinition>({
    path,
    component,
    isProtected,
    exact,
  });

const ROUTE_DEFINITIONS = Object.freeze<RouteDefinition[]>([
  createDefinition(TopLevelRoutes.OTL, OtlRoute, false),
  createDefinition(`${QuestionRoutes.INCOME}/add`, Questions, true, true),
  createDefinition(TopLevelRoutes.QUESTIONS, Questions, true, false),
  createDefinition(TopLevelRoutes.RENTAL_REFERENCE, RentalRefrencePage, false, true),
  createDefinition(TopLevelRoutes.REFERENCE_THANK_YOU, ReferenceThankYouPage, false, true),
  createDefinition(TopLevelRoutes.DASHBOARD, Dashboard, true, true),
  createDefinition(TopLevelRoutes.LOGOUT, Logout, false),
  createDefinition(TopLevelRoutes.OPEN_BANKING, OpenBankingPage, true, true),
]);

const RouteSwitcher = () => {
  return (
    <Switch>
      {ROUTE_DEFINITIONS.map(route =>
        route.isProtected ? (
          <ProtectedRoute key={route.path} {...route} />
        ) : (
          <Route key={route.path} {...route} />
        ),
      )}
      <ProtectedRoute path="*">
        <Redirect to={TopLevelRoutes.DASHBOARD} />
      </ProtectedRoute>
    </Switch>
  );
};

export default RouteSwitcher;

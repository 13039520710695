import {
  DefaultResidenceQuestionComponentProps,
  StepNamesDefaultResidence,
} from 'questionFlow/flows/defaultResidence/types';
import { useMemo } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import RadioQuestion from 'questionFlow/genericQuestions/RadioQuestion';
import { OnSubmitType } from 'questionFlow/types';
import * as Sentry from '@sentry/react';
import { ResidentialHistoryType } from 'generated/graphql';
import ResidentialHistoryThreeMonthFilter from './ResidentialHistoryThreeMonthFilter';
import { useTranslatedValues } from 'hooks/useTranslatedValues';

const formatDates = (startDate: string, endDate: string, language: string, t: TFunction) => {
  const formattedStartDate = new Date(startDate).toLocaleDateString(language, {
    year: 'numeric',
    month: 'short',
  });
  const formattedEndDate = endDate
    ? new Date(endDate).toLocaleDateString(language, { year: 'numeric', month: 'short' })
    : t('/questions/residence/residentialHistory.current');
  return `${formattedStartDate} - ${formattedEndDate}`;
};

const cmsLocation = '/questions/residence/select-address';

const translations = {
  title: `${cmsLocation}.title`,
  subtitle: `${cmsLocation}.subtitle`,
} as const;

const name = StepNamesDefaultResidence.selectAddress;

const SelectAddress = ({ values, onSubmit, ...props }: DefaultResidenceQuestionComponentProps) => {
  const { i18n, t } = useTranslation();
  const content = useTranslatedValues(translations);

  if (!values || !values[StepNamesDefaultResidence.residentialHistory]) {
    Sentry.captureException('No residential history for select address');
    throw new Error('No residence history for select address');
  }

  const filteredList = useMemo(
    () =>
      values[StepNamesDefaultResidence.residentialHistory]?.filter(
        ResidentialHistoryThreeMonthFilter,
      ),
    [values],
  );

  const initialValue = useMemo(
    () =>
      values[name]?.uuid
        ? values[name]?.uuid
        : filteredList?.find(o => !!o?.rent || !!o?.documents?.[0])?.uuid,
    [values, filteredList],
  );

  const options = useMemo(
    () =>
      filteredList?.map(residentialHistoryItem => ({
        label: residentialHistoryItem?.address?.formattedAddress.toUpperCase(),
        secondaryLabel: formatDates(
          residentialHistoryItem.start,
          residentialHistoryItem.end,
          i18n.language,
          t,
        ),
        value: residentialHistoryItem.uuid,
      })) || [],
    [i18n.language, t, filteredList],
  );

  const handleSubmit: OnSubmitType = ({ key, value }) => {
    const matchedItem = (values[
      StepNamesDefaultResidence.residentialHistory
    ] as ResidentialHistoryType[]).find(({ uuid }) => uuid === value);

    if (matchedItem) {
      onSubmit({ key, value: matchedItem });
    } else {
      Sentry.captureException(`Could not match uuid ${value} to a residentialHistory record`);
      throw new Error(`Could not match uuid ${value} to a residentialHistory record`);
    }
  };

  return (
    <RadioQuestion
      content={content}
      initialValue={initialValue}
      name={name}
      options={options}
      onSubmit={handleSubmit}
      {...props}
    />
  );
};

export default SelectAddress;

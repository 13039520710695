import React from 'react';
import clsx from 'clsx';

export interface Props extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  href: string;
  className?: string;
  children?: React.ReactNode;
  big?: boolean;
  secondary?: boolean;
}

const primaryClasses = (big?: boolean) => [
  big ? 'text-2xl leading-7' : 'text-base leading-5 md:leading-6',
  'py-4 px-10 max-w-max',
  'block font-semibold text-white bg-blue-3 disabled:opacity-50',
  'hover:bg-blue-2 focus:bg-blue-4 focus:outline-none disabled:hover:bg-blue-3 disabled:cursor-default',
  'transition duration-500 ease-in-out',
];

const secondaryClasses = [
  'block py-4 text-base leading-7 px-10 max-w-max items-center',
  'font-semibold text-blue-3 bg-light-blue-1 disabled:opacity-50 border border-blue-3',
  'hover:bg-light-blue-2 focus:bg-light-blue-3 focus:outline-none',
  'transition duration-500 ease-in-out',
];

const LinkButton = ({ className, children, big, href, secondary, ...props }: Props) => {
  return (
    <a
      href={href}
      className={clsx(secondary ? primaryClasses(big) : secondaryClasses, className)}
      data-testid="linkbutton"
      {...props}
    >
      {children}
    </a>
  );
};

export default LinkButton;

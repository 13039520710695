import React, { useMemo, useRef } from 'react';
import clsx from 'clsx';
import { TrashIcon } from '@heroicons/react/outline';
import { useTranslatedValues } from 'hooks/useTranslatedValues';

export interface Props {
  className?: string;
  children?: React.ReactNode;
  error?: string;
  progress: number;
  disabled?: boolean;
  fileName?: string;
  placeholder?: string;
  onClear: () => void;
}

const translations = {
  errorMessage: `document-uploader.error-message`,
  clearFile: `document-uploader.clear-file`,
} as const;

const FileUploadProgress = ({
  className,
  children,
  error,
  progress = 0,
  placeholder,
  fileName,
  onClear,
  ...props
}: Props) => {
  const content = useTranslatedValues(translations);
  const fileInput = useRef<HTMLInputElement>(null);

  const handleClear = () => {
    if (fileInput.current) {
      fileInput.current.value = '';
    }
    onClear();
  };

  const progressLabel = useMemo(() => {
    if (error) {
      return error;
    }
    if (fileName) {
      return fileName;
    }
    return placeholder;
  }, [error, fileName, placeholder]);

  const isEmpty = !fileName;

  return (
    <div
      {...props}
      data-testid="document-uploader"
      className={clsx('flex flex-row space-x-4', className)}
    >
      <div className="flex flex-col justify-center w-full">
        <div className="flex flex-row justify-between pb-2 space-x-8">
          <span
            id={placeholder}
            data-testid="progress-label"
            className={clsx(
              fileName && 'break-word',
              error && 'text-red-5',
              isEmpty && 'text-grey-2',
            )}
          >
            {progressLabel}
          </span>
        </div>
        <div className={clsx('h-1 bg-grey-3 rounded-2xl', error && 'bg-red-3 opacity-30')}>
          <div
            data-testid="progress"
            className={clsx(
              'h-full rounded-2xl',
              'transition-all duration-200',
              progress === 100 ? 'bg-green-4' : 'bg-blue-4',
            )}
            style={{ width: `${progress}%` }}
          />
        </div>
      </div>
      <button
        data-testid="clear-file"
        aria-label={content.clearFile}
        onClick={handleClear}
        type="button"
        disabled={isEmpty}
        className={clsx(
          isEmpty ? 'text-grey-2 cursor-not-allowed' : 'hover:text-red-3 focus:text-red-5',
        )}
      >
        <TrashIcon className="w-6 h-7" />
      </button>
    </div>
  );
};

export default FileUploadProgress;

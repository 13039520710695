import { AdditionalUserInfo } from 'api/data/uploadToS3/useUploadToS3';
import { FileUploadProgress } from 'components';
import { useEffect, useMemo } from 'react';
import { Content } from './Upload';
import { FileFormatRule } from './uploadHelpers';
import useDocumentUploadState, { DocumentUploadState } from './useDocumentUploadState';

type FileDataProps = {
  data: any;
  values: any;
  file: File;
  uuid: string;
  docTypeName: string;
  content: Content;
  multipleFils: boolean;
  fileFormat?: FileFormatRule;
  additionalFilePathRaw?: string;
  saveDocumentData: (data: DocumentUploadState, uuid: string) => void;
  onClear: (uuid: string) => void;
  generateSuffix?: () => string;
};

const UploadFileRow = ({
  values,
  data,
  file,
  uuid,
  content,
  docTypeName,
  multipleFils,
  fileFormat = 'all',
  additionalFilePathRaw,
  saveDocumentData,
  onClear,
  generateSuffix,
}: FileDataProps) => {
  const additionalUserInfo: AdditionalUserInfo | undefined =
    values && values.birthdate ? { dob: values.birthdate } : undefined;

  const isRemedial = useMemo(() => !!data?.remedialSection, [data]);

  const additionalFilePath = isRemedial
    ? `${additionalFilePathRaw}remedial-section/${data.remedialSection.uuid}/`
    : additionalFilePathRaw;

  const fileName = useMemo(() => {
    // we need unique names when there are multiple files uploaded
    // (index is not reliable because we can remove files)
    const id = uuid.split('-')[0];

    return `${docTypeName}${
      generateSuffix && generateSuffix() ? `_${generateSuffix().toUpperCase()}` : ''
    }${multipleFils ? `_${id}` : ''}`
      .replace(/[- ]/g, '')
      .toUpperCase();
  }, [docTypeName, uuid, multipleFils, generateSuffix]);

  const documentUpload = useDocumentUploadState(
    undefined,
    content,
    fileName,
    fileFormat,
    additionalFilePath,
    additionalUserInfo,
  );

  useEffect(() => {
    documentUpload.handleUpload(file);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  useEffect(() => {
    saveDocumentData(documentUpload, uuid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentUpload.path, documentUpload.validationError, documentUpload.uploadError]);

  const handleClear = () => {
    onClear(uuid);
  };

  return (
    <FileUploadProgress
      progress={documentUpload.progress}
      fileName={documentUpload.file?.name}
      error={documentUpload.uploadError || documentUpload.validationError}
      placeholder={fileName}
      onClear={handleClear}
    />
  );
};

export default UploadFileRow;

import { useMemo } from 'react';
import { useTranslation, TFunction } from 'react-i18next';
import { AccountDocumentType, IncomeSourceTypeEnum } from 'generated/graphql';
import DocumentSignposting from 'questionFlow/genericQuestions/DocumentSignposting';
import { StepNamesDefaultIdentity } from 'questionFlow/flows/defaultIdentity/types';
import { get } from 'lodash';
import { DefaultIncomeQuestionComponentProps } from 'questionFlow/flows/defaultIncome/types';

const cmsDocumentLocation = '/questions/residence/documents';
const cmsResidenceSignpostingLocation = '/questions/income/documentSignposting';

const useContent = (
  t: TFunction<'translation'>,
  fileName: AccountDocumentType,
  values: Record<string, string>,
) => {
  const translationLocation: Partial<Record<AccountDocumentType, string>> = {
    [AccountDocumentType.EmploymentContract]: t(`${cmsDocumentLocation}.EMPLOYMENT_CONTRACT`),
    [AccountDocumentType.BankStatement]: t(`${cmsDocumentLocation}.BANK_STATEMENT`),
  };

  const doesTranslationExist = (translateItem: string) => {
    return t(`${cmsResidenceSignpostingLocation}.${fileName}.${translateItem}` as any).includes(
      cmsResidenceSignpostingLocation,
    )
      ? {}
      : {
          [translateItem]: t(
            `${cmsResidenceSignpostingLocation}.${fileName}.${translateItem}` as any,
            {
              doc: translationLocation[fileName] || '{{doc}}',
              ...values,
            },
          ),
        };
  };

  return {
    ...doesTranslationExist('title'),
    ...doesTranslationExist('subtitle'),
    ...doesTranslationExist('doItems'),
    ...doesTranslationExist('dontItems'),
    ...translationLocation,
  };
};

const IncomeDocSignposting = ({
  documentKey = StepNamesDefaultIdentity.documents,
  values,
  ...props
}: DefaultIncomeQuestionComponentProps & { documentKey?: string }) => {
  const { t } = useTranslation();

  const docType = useMemo(() => get(values, documentKey), [documentKey, values]);

  const docCount = useMemo(
    () => (values.incomeType === IncomeSourceTypeEnum.TSelfemployed ? 6 : 3),
    [values.incomeType],
  );

  const translatedContent = useContent(t, docType as AccountDocumentType, {
    count: docCount.toString(),
    company: values.currentEmployer.companyName,
  });

  const content = useMemo(() => {
    return {
      ...translatedContent,
      fileName: translatedContent[docType as keyof typeof translatedContent] as string,
      formattedFileName: translatedContent[docType as keyof typeof translatedContent] as string,
    };
  }, [translatedContent, docType]);

  return (
    <DocumentSignposting
      {...props}
      values={values}
      content={content}
      translationValues={{
        company: values.companyDetails.companyName,
      }}
    />
  );
};

export default IncomeDocSignposting;

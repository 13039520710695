import EmailValidator from 'email-validator';
import getEmptyReason from './getEmptyReason';
import { GetInvalidReason } from './types';

const getEmailReason: GetInvalidReason = (source: string) => {
  const emptyReason = getEmptyReason(source);

  if (emptyReason) {
    return emptyReason;
  }

  return EmailValidator.validate(source) ? undefined : 'invalid-email';
};

export default getEmailReason;

import { AxiosError } from 'axios';
import * as Sentry from '@sentry/react';
import AxiosProvider from 'api/axios/AxiosProvider';
import AuthenticationService from 'authentication/AuthenticationService';
import { GetInitialDataQuery } from 'generated/graphql';
import { AdditionalUserInfo, UploadErrorTranslations } from './useUploadToS3';
import { getEnv } from 'services/environmentVariables';
import { QuestionRoutes } from 'routing/routes';

export const runChecks = async (): Promise<any> => {
  const token = AuthenticationService.accessToken;
  const url = getEnv('CHECK_DOCUMENT_URL_URI');
  if (!url) return;
  const response = await AxiosProvider.post<ResponseType>(
    url,
    {},
    {
      headers: { Authorization: `Bearer ${token}` },
    },
  ).catch(err => {
    Sentry.captureException(err, { tags: { category: 'node-api' } });
    throw err;
  });
  if (!response || !response?.data) {
    throw new Error('No response from call to create check');
  }
  return response.data;
};

type OnfidoVerificationErrorType = 'detect_blur' | 'detect_cutoff' | 'document_detection';

type OnfidoVerificationError = {
  type: string;
  message: string;
  fields: Record<OnfidoVerificationErrorType, string[]>;
};

export const verifyDocument = async (
  fileUrl: string,
  user: GetInitialDataQuery['me'],
  payload: { type: string; side?: 'front' | 'back' },
  content: UploadErrorTranslations,
  additionalUserInfo?: AdditionalUserInfo,
): Promise<{ type: 'ok' } | { type: 'error'; message: string }> => {
  const url = getEnv('VERIFY_DOCUMENT_URL_URI');
  if (!url) return { type: 'ok' };
  const token = AuthenticationService.accessToken;
  const { firstName, lastName } = user.name;
  try {
    await AxiosProvider.post(
      url,
      {
        url: fileUrl,
        firstName,
        lastName,
        ...payload,
        ...(additionalUserInfo ? additionalUserInfo : {}),
      },
      { headers: { Authorization: `Bearer ${token}` } },
    );
    return { type: 'ok' };
  } catch (e) {
    const err = e as AxiosError;

    if (err.response?.status === 422) {
      const data = err.response.data as OnfidoVerificationError;
      if (data.type === 'validation_error') {
        if (Object.keys(data.fields).includes('detect_blur'))
          return { type: 'error', message: content.errorMessageBlurDetected };
        if (Object.keys(data.fields).includes('detect_cutoff'))
          return { type: 'error', message: content.errorMessageCutoffDetected };
      }
      return { type: 'error', message: content.errorMessageDocumentDetection };
    }
    Sentry.captureException(err);
    return { type: 'ok' };
  }
};

type CheckVerificationOutput =
  | { verificationRequired: false }
  | { verificationRequired: true; value: { type: string; side?: 'front' | 'back' } };

const supportedDocuments = {
  UK_PASSPORT: { type: 'passport', side: 'front' },
  PASSPORT: { type: 'passport', side: 'front' },
  TIME_LIMITED_PASSPORT: { type: 'passport', side: 'front' },
  TIME_LIMITED_PASSPORT_VISAPAGE: { type: 'visa', side: 'front' },
  NATIONAL_ID: { type: 'national_identity_card', side: 'front' },
  NATIONAL_ID_BACK: { type: 'national_identity_card', side: 'back' },
  BRITISH_NATIONAL_OVERSEAS_PASSPORT: { type: 'passport', side: 'front' },
  UK_DRIVING_LICENCE: { type: 'driving_licence', side: 'front' },
  UK_DRIVING_LICENCE_BACK: { type: 'driving_licence', side: 'back' },
  DRIVING_LICENCE: { type: 'driving_licence', side: 'front' },
  DRIVING_LICENCE_BACK: { type: 'driving_licence', side: 'back' },
  UK_VISA_APPLICATION_LETTER: { type: 'unknown', side: 'front' },
  NATURALIZATION: { type: 'unknown', side: 'front' },
  BIRTH_ADOPTION: { type: 'unknown', side: 'front' },
} as const;

// fake-ubdpUz5sIZtitAs.pdf is a special file name used in e2e tests for which we skip document identity check
const isTestRun = (fileName: string) => fileName.startsWith('fake-ubdpUz5sIZtitAs');

export const checkVerification = (
  documentName: string,
  fileName: string,
): CheckVerificationOutput => {
  const url = getEnv('VERIFY_DOCUMENT_URL_URI');
  const isToSkip = isTestRun(fileName);
  const isIdentityFlow = window.location.pathname.startsWith(QuestionRoutes.IDENTITY);
  if (!url || isToSkip || !isIdentityFlow) return { verificationRequired: false };
  const documentsToVerify: string[] = Object.keys(supportedDocuments);
  const verificationRequired = documentsToVerify.includes(documentName);
  if (!verificationRequired) return { verificationRequired: false };
  return {
    verificationRequired: true,
    value: supportedDocuments[documentName as keyof typeof supportedDocuments],
  };
};

export const uploadPhoto = async (
  fileUrl: string,
  user: GetInitialDataQuery['me'],
  content: UploadErrorTranslations,
  additionalUserInfo?: AdditionalUserInfo,
): Promise<{ type: 'ok' } | { type: 'error'; message: string }> => {
  const url = getEnv('UPLOAD_PHOTO_URL_URI');
  if (!url) return { type: 'ok' };
  const token = AuthenticationService.accessToken;
  const { firstName, lastName } = user.name;
  try {
    await AxiosProvider.post(
      url,
      {
        url: fileUrl,
        firstName,
        lastName,
        dob: additionalUserInfo?.dob,
        ...(additionalUserInfo ? additionalUserInfo : {}),
      },
      { headers: { Authorization: `Bearer ${token}` } },
    );
    return { type: 'ok' };
  } catch (e) {
    const err = e as AxiosError;

    if (err.response?.status === 422) {
      const data = err.response.data as OnfidoVerificationError;
      if (data.type === 'validation_error') {
        if (Object.keys(data.fields).includes('face_detection'))
          return { type: 'error', message: content.errorMessageFaceDetection };
      }
      return { type: 'error', message: content.errorMessageDocumentDetection };
    }
    Sentry.captureException(err);
    return { type: 'ok' };
  }
};

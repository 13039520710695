import { useTranslation } from 'react-i18next';
import OnboardingCard from '../OnboardingCard';
import IncomeSvg from './income.svg';
import { OfficeBuildingIcon, DocumentTextIcon, BriefcaseIcon } from '@heroicons/react/outline';
import DocumentsList from '../DocumentsList';

export interface IncomePageProps {
  index: number;
  stepsComponent: React.ReactNode;
  onClick: () => void;
}

const IncomePage = ({ stepsComponent, onClick, index }: IncomePageProps) => {
  const { t } = useTranslation();

  const documents = [
    {
      name: t('onboarding.income.bank'),
      note: t('onboarding.income.bank_note'),
      Icon: OfficeBuildingIcon,
    },
    {
      name: t('onboarding.income.employer'),
      Icon: BriefcaseIcon,
    },
    {
      name: t('onboarding.income.other'),
      note: t('onboarding.income.other_note'),
      Icon: DocumentTextIcon,
    },
  ];

  return (
    <OnboardingCard
      title={t('onboarding.income.title')}
      imgSrc={IncomeSvg}
      buttonProps={{
        text: t('onboarding.income.submit_button'),
        onClick,
      }}
      steps={stepsComponent}
      index={index}
    >
      <DocumentsList description={t('onboarding.income.description')} documents={documents} />
    </OnboardingCard>
  );
};

export default IncomePage;

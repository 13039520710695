import AmountsQuestion from '../genericQuestions/AmountsQuestion';
import {
  DefaultIncomeQuestionComponentProps,
  StepNamesDefaultIncome,
} from 'questionFlow/flows/defaultIncome/types';

import { TFunction, useTranslation } from 'react-i18next';

const cmsLocation = '/questions/income/yourIncome';

const useContent = (t: TFunction<'translation'>) => ({
  title: t(`${cmsLocation}.title`),
  subtitle: t(`${cmsLocation}.subtitle`),

  annualIncome: {
    label: t(`${cmsLocation}.annualIncome.label`),
    placeholder: t(`${cmsLocation}.annualIncome.placeholder`),
  },
  averageMonthlyPayment: {
    label: t(`${cmsLocation}.averageMonthlyPayment.label`),
    placeholder: t(`${cmsLocation}.averageMonthlyPayment.placeholder`),
  },
  lastPaymentDateLabel: t(`${cmsLocation}.lastPaymentDateLabel`),
});

const name = StepNamesDefaultIncome.yourIncome;

const YourIncome = ({
  data,
  onSubmit,
  values,
  validationRules,
  ...props
}: DefaultIncomeQuestionComponentProps) => {
  const { t } = useTranslation();
  const content = useContent(t);

  return (
    <AmountsQuestion
      onSubmit={onSubmit}
      validationRules={validationRules}
      data={data}
      values={values}
      annualLabel={content.annualIncome.label}
      annualPlaceholderText={content.annualIncome.placeholder}
      paymentLabel={content.averageMonthlyPayment.label}
      paymentPlaceholderText={content.averageMonthlyPayment.placeholder}
      paymentDateLabel={content.lastPaymentDateLabel}
      title={content.title}
      subtitle={content.subtitle}
      name={name}
      {...props}
    />
  );
};
export default YourIncome;

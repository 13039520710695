import { AccountDocumentType } from 'generated/graphql';

const fileFormatRulesMap: Record<string, { accept: string[]; extensions: string[] }> = {
  all: {
    accept: ['*'],
    extensions: ['*'],
  },
  documentsOnly: {
    accept: ['application/pdf', 'application/msword'],
    extensions: ['pdf', 'docx'],
  },
  pdfOnly: {
    accept: ['application/pdf'],
    extensions: ['pdf'],
  },
  imagesAndPDFs: {
    accept: ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf'],
    extensions: ['jpg', 'jpeg', 'png', 'pdf'],
  },
  imagesAndDocuments: {
    accept: ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf', 'application/msword'],
    extensions: ['jpg', 'jpeg', 'png', 'pdf', 'docx'],
  },
};

export type FileFormatRule = keyof typeof fileFormatRulesMap;

const documentFilesMap: Record<keyof typeof AccountDocumentType, FileFormatRule> = {
  Id: 'imagesAndPDFs',
  DrivingLicence: 'imagesAndPDFs',
  Passport: 'imagesAndPDFs',
  BritishNationalOverseasPassport: 'imagesAndPDFs',
  ArmedForcesDeploymentLetter: 'imagesAndPDFs',
  LandlordsCheckingServiceDocument: 'pdfOnly',
  DiplomaticPassport: 'imagesAndPDFs',
  LetterFromAuthorisedBody: 'imagesAndPDFs',
  GuarantorPassport: 'imagesAndPDFs',
  GuarantorIdCard: 'imagesAndPDFs',
  GuarantorDrivingLicence: 'imagesAndPDFs',
  TravelBooking: 'imagesAndPDFs',
  UkVisaApplicationLetter: 'imagesAndPDFs',
  EuShareCodeVerificationDocument: 'pdfOnly',
  UkImmigrationStatus: 'imagesAndPDFs',
  UkBiometricResidence: 'imagesAndPDFs',
  Naturalization: 'imagesAndPDFs',
  BritishCitizen: 'imagesAndPDFs',
  UkDrivingLicence: 'imagesAndPDFs',
  BirthAdoption: 'imagesAndPDFs',
  ArmedForces: 'imagesAndPDFs',
  BenefitsPaperwork: 'imagesAndPDFs',
  Ccj: 'imagesAndPDFs',
  TimeLimitedPassport: 'imagesAndPDFs',
  TenancyAgreement: 'imagesAndPDFs',
  UtilityBill: 'imagesAndPDFs',
  CouncilTaxStatement: 'imagesAndPDFs',
  TvLicence: 'imagesAndPDFs',
  TenancyDepositSchemeRegistration: 'imagesAndPDFs',
  Licence: 'imagesAndPDFs',
  OwnershipCertificate: 'imagesAndPDFs',
  Mortgage: 'imagesAndPDFs',
  BankStatement: 'pdfOnly',
  Payslip: 'pdfOnly',
  EmploymentContract: 'imagesAndPDFs',
  Employment: 'imagesAndPDFs',
  Sa302: 'pdfOnly',
  Truelayer: 'imagesAndPDFs',
  ConsentForm: 'imagesAndPDFs',
  Other: 'all',
  UkPassport: 'imagesAndPDFs',
  CreditCheck: 'pdfOnly',
  StudentStatus: 'imagesAndPDFs',
  TaxReturn: 'pdfOnly',
  Bursary: 'imagesAndDocuments',
  SponsorshipLetter: 'imagesAndDocuments',
  CompanyAccounts: 'pdfOnly',
  InvestmentPortfolio: 'pdfOnly',
  AccountantLetter: 'documentsOnly',
  PensionStatement: 'imagesAndPDFs',
  AccommodationReciept: 'imagesAndPDFs',
  LandRegistry: 'pdfOnly',
  InsuranceContract: 'imagesAndPDFs',
  ReferenceByLettingAgent: 'imagesAndDocuments',
  LightCheckReport: 'pdfOnly',
  GuarantorCheckReport: 'pdfOnly',
  Guarantor: 'all',
  FamilyMemberProofOfAddress: 'imagesAndPDFs',
  LivePhoto: 'imagesAndDocuments',
} as const;

export const getFileFormatRuleSet = (doc: FileFormatRule) => fileFormatRulesMap[doc];

export const getFileFormatRuleForDocumentKey = (doc: keyof typeof AccountDocumentType) =>
  documentFilesMap[doc];

export const getDocumentKeyByDoc = (doc: AccountDocumentType) =>
  Object.keys(AccountDocumentType)[
    Object.values(AccountDocumentType).indexOf(doc)
  ] as keyof typeof AccountDocumentType;

export const getFileFormatRuleForDocument = (doc: AccountDocumentType) =>
  getFileFormatRuleForDocumentKey(getDocumentKeyByDoc(doc));

export const getFormatsForDocument = (doc: keyof typeof AccountDocumentType) => {
  const type = getFileFormatRuleForDocumentKey(doc);
  return getFileFormatRuleSet(type);
};

export const getAcceptProp = (type: FileFormatRule): string[] | undefined => {
  const { accept } = getFileFormatRuleSet(type);
  if (accept[0] === '*') return;
  return accept;
};

export const isFileNameAllowed = (type: FileFormatRule, fileName: string) => {
  const { extensions } = getFileFormatRuleSet(type);
  if (extensions[0] === '*') return true;
  return extensions.some(e => fileName.endsWith(e));
};

export const getExtensionsText = (type: FileFormatRule) => {
  const { extensions } = getFileFormatRuleSet(type);
  if (extensions[0] === '*') return 'any file';
  if (extensions.length === 1) return extensions[0].toUpperCase();
  return extensions.map(v => v.toUpperCase()).join(' / ');
};

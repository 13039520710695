import React from 'react';
import clsx from 'clsx';

const Label = ({
  className,
  ...props
}: React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement>) => (
  <label
    className={clsx('block text-base leading-5 w-full md:w-min whitespace-nowrap', className)}
    {...props}
  />
);

export default Label;

import { useState, useEffect, useRef } from 'react';
import AxiosProvider from '../../axios/AxiosProvider';
import axios, { CancelToken } from 'axios';
import * as Sentry from '@sentry/react';
import AuthenticationService from '../../../authentication/AuthenticationService';
import { useAnalyticsError, useAnalyticsEvent } from 'monitoring/analyticsHooks';
import { useTranslatedValues } from 'hooks/useTranslatedValues';
import useInitialData from '../initial-data/useInitialData';
import { checkVerification, uploadPhoto, verifyDocument } from './documentVerification';

interface getUrlParams extends Record<string, string> {
  filename: string;
  filetype: string;
  additionalFilePath: string;
}

interface ResponseType {
  url: string;
}

interface PreSignedUrlData {
  url: string;
  fields: { [key: string]: string };
}

const getSignedUrl = async (data: getUrlParams, cancelToken: CancelToken): Promise<any> => {
  const token = AuthenticationService.accessToken;

  const response = await AxiosProvider.get<ResponseType>(
    `${process.env.REACT_APP_SIGNED_URL_URI}?${new URLSearchParams(data)}`,
    {
      cancelToken,
      headers: { Authorization: `Bearer ${token}` },
    },
  ).catch(err => {
    Sentry.captureException(err, { tags: { category: 'node-api' } });
    throw err;
  });
  if (!response || !response?.data?.url) {
    throw new Error('No response from call to get signed URI');
  }
  return response.data;
};

const getFileBlob = (file: File) => {
  const fileLink = URL.createObjectURL(file);
  return fetch(fileLink).then(res => res.blob());
};

const uploadOnSignedUrl = async (
  preSignedFileData: PreSignedUrlData,
  file: File,
  cancelToken: CancelToken,
  newFileName: string,
) => {
  // prepare the formData with the file to upload and the headers needed by aws
  const formData = new FormData();
  Object.keys(preSignedFileData.fields).forEach(key => {
    formData.append(key, preSignedFileData.fields[key]);
  });
  formData.append('file', await getFileBlob(file), newFileName);

  const uploadtOS3Response = await AxiosProvider.post(preSignedFileData.url, formData, {
    cancelToken,
  }).catch(err => {
    Sentry.captureException(err);
    throw err;
  });

  const fileLocation = uploadtOS3Response?.headers?.location;

  if (uploadtOS3Response.status !== 204 || !fileLocation) {
    Sentry.captureException(
      `Did not receive 204 from file upload, received ${uploadtOS3Response.status} and fileLocation ${fileLocation}`,
    );
    throw new Error(`Did not receive 204 from file upload, received ${uploadtOS3Response.status}`);
  }

  return fileLocation;
};

const translations = {
  errorMessage: `document-uploader.error-message`,
  errorMessageDocumentDetection: 'document-uploader.error-message-document-detection',
  errorMessageBlurDetected: 'document-uploader.error-message-detect-blur',
  errorMessageCutoffDetected: 'document-uploader.error-message-detect-cutoff',
  errorMessageFaceDetection: 'document-uploader.error-message-photo',
  errorMessageFaceDetectionDescription: 'document-uploader.error-message-photo-description',
} as const;

export type AdditionalUserInfo = {
  dob: string;
};

export type UploadErrorTranslations = Record<keyof typeof translations, string>;

const useUploadToS3 = (
  file?: File,
  previouslyUploadedFile?: boolean,
  existingPath?: string,
  documentName?: string,
  additionalFilePath: string = '',
  additionalUserInfo?: AdditionalUserInfo,
) => {
  const content = useTranslatedValues(translations);
  const initialData = useInitialData();
  const { broadcastEvent } = useAnalyticsEvent();

  const [progress, setProgress] = useState(previouslyUploadedFile ? 100 : 0);
  const [error, setError] = useAnalyticsError('s3upload', '');
  const [path, setPath] = useState(existingPath || '');
  const skippedPreviouslyUploadedFile = useRef(false);
  const mounted = useRef(false);

  useEffect(() => {
    if (path && !file) {
      setPath('');
    }
  }, [path, file]);

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    }
    setError('');

    if (previouslyUploadedFile && !skippedPreviouslyUploadedFile.current) {
      skippedPreviouslyUploadedFile.current = true;
      return;
    }

    const source = axios.CancelToken.source();

    if (file) {
      const upload = async () => {
        try {
          setProgress(10);
          const newFileName = documentName
            ? `${documentName}.${file.name.split('.').pop()}`
            : file.name;

          const preSignedFileData = await getSignedUrl(
            {
              filename: newFileName,
              filetype: file.type,
              additionalFilePath,
            },
            source.token,
          );

          if (preSignedFileData) {
            const result = await uploadOnSignedUrl(
              preSignedFileData,
              file,
              source.token,
              newFileName,
            );

            const checkVerificationResult = checkVerification(documentName || '', file.name || '');

            if (checkVerificationResult.verificationRequired) {
              const documentVerification = await verifyDocument(
                result,
                initialData.me,
                checkVerificationResult.value,
                content,
                additionalUserInfo,
              );
              broadcastEvent(
                `Verified document "${documentName || '<missing documentName>'}" with result ${
                  documentVerification.type
                }`,
              );
              if (documentVerification.type === 'error') {
                setError(documentVerification.message);
                setProgress(0);
                return;
              }
            }

            if (documentName === 'LIVE_PHOTO') {
              const response = await uploadPhoto(
                result,
                initialData.me,
                content,
                additionalUserInfo,
              );
              if (response.type === 'error') {
                setError(response.message);
                setProgress(0);
                return;
              }
            }

            if (result && mounted.current) {
              setProgress(100);
              setPath(result);
            }
          }
        } catch (err) {
          if (axios.isCancel(err)) {
            setProgress(0);
          } else {
            setError(content.errorMessage);
            setProgress(0);
          }
        }
      };

      upload();
    } else {
      source.cancel('File removed');
      setProgress(0);
    }

    return () => {
      source.cancel('axios request cancelled');
      mounted.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    file,
    previouslyUploadedFile,
    documentName,
    additionalFilePath,
    setError,
    initialData.me,
    content.errorMessage,
    content.errorMessageBlurDetected,
    content.errorMessageCutoffDetected,
    content.errorMessageDocumentDetection,
  ]);

  useEffect(() => {
    if (progress > 0 && progress < 98) {
      const interval = setInterval(() => {
        setProgress(progress + (progress >= 70 ? 1 : 10));
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [progress]);

  return [{ progress, error, path }];
};

export default useUploadToS3;

import QuestionOrganism from 'questionFlow/QuestionOrganism';
import { TFunction, Trans, useTranslation } from 'react-i18next';
import {
  DefaultIncomeQuestionComponentProps,
  StepNamesDefaultIncome,
} from 'questionFlow/flows/defaultIncome/types';

import { Button, TextButton } from 'components';
import { useMemo } from 'react';
import { shouldRenderManualUpload } from './OpenBanking';
import { ShieldCheckIcon } from '@heroicons/react/outline';
import TrueLayer from 'assets/images/TrueLayer.png';
import FCA from 'assets/images/FCA.png';
import { InformationCircleIcon } from '@heroicons/react/solid';

const cmsLocation = '/questions/income/OBError';
const altCmsLocation = '/questions/income/openBanking';

const useContent = (t: TFunction<'translation'>) => ({
  title: t(`${cmsLocation}.title`),
  subtitle: t(`${cmsLocation}.subtitle`),
  reconnect: t(`${cmsLocation}.reconnect`),
  description: t(`${cmsLocation}.description`),
  manual: t(`${cmsLocation}.manual`),
  back: t(`${cmsLocation}.back`),
  altTextTrueLayerLogo: `${altCmsLocation}.altTextTrueLayerLogo`,
  altTextFCALogo: `${altCmsLocation}.altTextFCALogo`,
});

const stepName = StepNamesDefaultIncome.error;

const OBError = ({
  data,
  onSubmit,
  values,
  validationRules,
  ...props
}: DefaultIncomeQuestionComponentProps) => {
  const { t } = useTranslation();
  const content = useContent(t);

  const handleSubmit = (value: string) => {
    onSubmit({ key: stepName, value });
  };

  const renderManualUpload = useMemo(() => shouldRenderManualUpload(data.conditions), [data]);

  return (
    <QuestionOrganism
      id={stepName}
      data-testid={stepName}
      onSubmit={() => {}}
      title={content.title}
      subtitle={
        <Trans
          i18nKey={`${cmsLocation}.subtitle`}
          components={{ b: <span className="font-semibold" /> }}
        />
      }
      isQuestionNumberHidden
      isFooterHidden
      {...props}
    >
      <Button className="mb-10" onClick={() => handleSubmit(StepNamesDefaultIncome.openBanking)}>
        <span className="flex flex-row">
          <ShieldCheckIcon className="h-6 w-6 mr-2" />
          {content.reconnect}
        </span>
      </Button>
      <div className="w-full lg:w-2/3">
        <p className="text-sm mb-9">{content.description}</p>
        <div className="mb-9">
          <img src={TrueLayer} className="mr-10 inline-block" alt={content.altTextTrueLayerLogo} />
          <img src={FCA} className="w-100 inline-block" alt={content.altTextFCALogo} />
        </div>
        {renderManualUpload ? (
          <>
            <div className="w-full border-b-2 border-grey-3 h-1 mb-10" />
            <div className="flex flex-row space-x-5">
              <InformationCircleIcon className="h-6 w-6 text-black flex-none" />
              <div className="">
                <p className="mb-4">
                  <Trans
                    i18nKey={`${cmsLocation}.manual_description`}
                    components={{ b: <span className="font-semibold" /> }}
                  />
                </p>
                <TextButton
                  onClick={() => handleSubmit(StepNamesDefaultIncome.documents)}
                  className="text-base underline"
                >
                  {content.manual}
                </TextButton>
              </div>
            </div>
          </>
        ) : null}
      </div>
    </QuestionOrganism>
  );
};

export default OBError;

import clsx from 'clsx';
import { OptionComponentProps, Options } from '../DropDown/types';
import TextOption from '../DropDown/TextOption';
import { useRef, forwardRef, useEffect, useImperativeHandle } from 'react';
import { useTranslatedValues } from 'hooks/useTranslatedValues';

export interface OptionsListProps {
  className?: string;
  onClose: () => void;
  options: Options;
  OptionComponent?: React.ComponentType<OptionComponentProps>;
  onSelectValue: (newValue: string) => void;
  value: string;
  hideEmptyOption?: boolean;
  size?: 'sm' | 'lg';
}

const translations = {
  noResults: 'searchableDropdown.noResults',
} as const;

const OptionsList = forwardRef<HTMLUListElement, OptionsListProps>(
  (
    {
      className,
      onClose,
      options,
      OptionComponent = TextOption,
      onSelectValue,
      value,
      hideEmptyOption,
      size,
      ...props
    },
    ref,
  ) => {
    const content = useTranslatedValues(translations);
    const selectedRef = useRef<HTMLLIElement>(null);
    const listboxRef = useRef<HTMLUListElement>(null);

    //Magic for handling multiple forward refs
    useImperativeHandle(
      ref,
      () =>
        ({
          get selectedRef() {
            return selectedRef.current;
          },
          get listboxRef() {
            return listboxRef.current;
          },
        } as any),
    );

    useEffect(() => {
      const target = selectedRef?.current;
      if (target && target.parentNode) {
        const parentNode = target.parentNode as HTMLElement;
        parentNode.scrollTop = target.offsetTop - target.offsetHeight;
      }
    }, [ref]);

    return hideEmptyOption && options.length === 0 ? null : (
      <ul
        data-testid="searchable-dropdown-options-list"
        id="dropdown-options"
        ref={listboxRef}
        tabIndex={-1}
        role="listbox"
        aria-activedescendant={value}
        className={clsx(
          className,
          size === 'lg' && 'w-full',
          'max-h-60 w-full overflow-y-scroll',
          'box-border rounded bg-white border-black border py-1 select-none focus:outline-none',
        )}
        {...props}
      >
        {options.length > 0 ? (
          options.map((option, i) => (
            <OptionComponent
              key={option.value}
              onClick={() => onSelectValue(option.value)}
              selected={option.value === value}
              ref={option.value === value ? selectedRef : undefined}
              size={size}
              {...option}
            />
          ))
        ) : (
          <OptionComponent
            disabled
            onClick={onClose}
            selected={false}
            ref={undefined}
            label={content.noResults}
            size={size}
            value=""
          />
        )}
      </ul>
    );
  },
);

export default OptionsList;

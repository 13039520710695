import { ExclamationCircleIcon, CheckCircleIcon } from '@heroicons/react/outline';
import QuestionOrganism from 'questionFlow/QuestionOrganism';
import { QuestionComponentProps } from 'questionFlow/types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import AddressAccordion from './Accordion';
import { TFunction, Trans, useTranslation } from 'react-i18next';
import { uniqueId, omit } from 'lodash';
import clsx from 'clsx';
import ValidationError from 'components/molecules/Field/ValidationError';
import { StepNamesDefaultResidence } from 'questionFlow/flows/defaultResidence/types';
import { ActionType } from 'questionFlow/reducer';
import { ResidentialHistoryType, useGetResidentialHistoryQuery } from 'generated/graphql';
import { useUpdateResidentialHistoryMutationHook, useDeleteResidentialHistoryHook } from './gql';
import getDateRange from './GetDateRange';
import ResidentialHistoryThreeMonthFilter from '../ResidentialHistoryThreeMonthFilter';
import { useAnalyticsError } from 'monitoring/analyticsHooks';

const cmsLocation = '/questions/residence/residentialHistory';

const useContent = (t: TFunction): Record<string, string> => ({
  title: t(`${cmsLocation}.title`),
  subtitle: t(`${cmsLocation}.subtitle`),
  subtitleMonths: t(`${cmsLocation}.subtitleMonths`),
  subtitleSingle: t(`${cmsLocation}.subtitleSingle`),
  count: t(`${cmsLocation}.count`),
  necessaryError: t(`${cmsLocation}.validation.at_least_necessary`),
  openAccordion: t(`${cmsLocation}.validation.open_accordion`),
  durationError: t(`${cmsLocation}.validation.duration`),
  durationMonthsError: t(`${cmsLocation}.validation.duration3months`),
  singleError: t(`${cmsLocation}.singleError`),
  error: t(`${cmsLocation}.error`),
  year: t('utility.year'),
  years: t('utility.years'),
  month: t('utility.month'),
  months: t('utility.months'),
});

const pluralized = (value: number, single: string, plural: string) =>
  `${value} ${value === 1 ? single : plural}`;

interface Props extends QuestionComponentProps<any, any> {
  minMonths: 3 | 36;
  single?: boolean;
  isUKAddress?: boolean;
}

const key = StepNamesDefaultResidence.residentialHistory;

const ResidentialHistoryQuestion = ({
  values,
  validationRules,
  onSubmit,
  minMonths = 36,
  isUKAddress = false,
  single,
  dispatch,

  ...props
}: Props) => {
  const { t } = useTranslation();
  const content = useContent(t);

  const { data, updateQuery } = useGetResidentialHistoryQuery();

  const residentialHistory = useMemo<ResidentialHistoryType[]>(() => {
    if (single) {
      return (data?.me?.residentialHistory as ResidentialHistoryType[]).filter(
        rh => rh?.address?.country === 'GB',
      );
    } else return (data?.me?.residentialHistory as ResidentialHistoryType[]) || [];
  }, [data?.me?.residentialHistory, single]);

  const [
    runMutation,
    { loading: processingUpdate, error: updateError },
  ] = useUpdateResidentialHistoryMutationHook({ updateQuery });

  const [
    deleteRH,
    { loading: processingDeletion, error: deletionError },
  ] = useDeleteResidentialHistoryHook({ updateQuery });

  const [durationError, setDurationError] = useAnalyticsError('residentalHistoryDuration', '');
  const [validationError, setValidationError] = useAnalyticsError('residentalHistory', '');
  const [waitForLoading, setWaitForLoading] = useState(false);
  const [criteriaMet, setCriteriaMet] = useState(false);
  const [openState, setOpenState] = useState(false);

  const dateRange = useMemo(
    () =>
      single
        ? { isValid: residentialHistory.length > 0, years: 0, months: 0 }
        : getDateRange({ residentialHistory: residentialHistory, minMonths }),
    [single, residentialHistory, minMonths],
  );

  useEffect(() => {
    const filteredHistory = isUKAddress
      ? residentialHistory
      : residentialHistory.filter(ResidentialHistoryThreeMonthFilter);
    setCriteriaMet(dateRange.isValid && filteredHistory.length > 0);
  }, [dateRange.isValid, residentialHistory, isUKAddress]);

  const handleSubmit = useCallback(() => {
    if (openState) {
      setValidationError(content.openAccordion);
      setTimeout(() => {
        document.getElementById('validation-error')?.scrollIntoView();
      }, 200);
      return;
    }
    if (!updateError && !deletionError) {
      if (!waitForLoading && (processingUpdate || processingDeletion)) {
        setWaitForLoading(true);
      } else if (!dateRange.isValid) {
        setDurationError(
          minMonths === 3
            ? content.durationMonthsError
            : single
            ? content.singleError
            : content.durationError,
        );
      } else {
        if (!isUKAddress) {
          const filteredHistory = residentialHistory.filter(ResidentialHistoryThreeMonthFilter);

          if (filteredHistory.length === 0) {
            setValidationError(content.necessaryError);
            return;
          }
          if (filteredHistory.length === 1) {
            dispatch({
              type: ActionType.UPDATE_VALUES,
              payload: {
                [StepNamesDefaultResidence.selectAddress]: filteredHistory[0],
              },
            });
          }
        }
        onSubmit({ key, value: residentialHistory as any });
      }
    }
  }, [
    updateError,
    deletionError,
    waitForLoading,
    processingUpdate,
    processingDeletion,
    dateRange.isValid,
    minMonths,
    content.durationMonthsError,
    content.singleError,
    content.durationError,
    content.necessaryError,
    content.openAccordion,
    single,
    residentialHistory,
    isUKAddress,
    onSubmit,
    dispatch,
    openState,
    setDurationError,
    setValidationError,
  ]);

  useEffect(() => {
    if (waitForLoading && !processingUpdate && !processingDeletion) {
      setWaitForLoading(false);
      handleSubmit();
    }
  }, [waitForLoading, processingUpdate, processingDeletion, handleSubmit]);

  const handleUpdateItem = (newHistoryItem: ResidentialHistoryType) => {
    const uuid = newHistoryItem.uuid;
    const newDocs = newHistoryItem?.documents?.map(doc => omit(doc, '__typename'));

    const flattenedInput = {
      ...omit(newHistoryItem, ['uuid', 'address', 'reference', '__typename']),
      ...omit(newHistoryItem.address, ['__typename']),
      ...(!!uuid && { uuid }),
      ...(!!newDocs && { documents: newDocs }),
    };

    runMutation({
      variables: { residence: flattenedInput },
      optimisticResponse: {
        updateResidentialHistory: {
          residentialHistory: {
            ...newHistoryItem,
            uuid: uuid || uniqueId(),
          },
        },
      },
    });
  };

  const handleDeleteItem = (uuid: string) => {
    deleteRH({
      variables: { uuid },
      optimisticResponse: {
        deleteResidentialHistory: {
          success: true,
          uuid,
        },
      },
    });
  };

  const subtitle = useMemo(
    () =>
      minMonths === 3 ? content.subtitleMonths : single ? content.subtitleSingle : content.subtitle,
    [content.subtitleSingle, content.subtitle, content.subtitleMonths, minMonths, single],
  );

  return (
    <QuestionOrganism
      id="uk-address"
      data-testid="uk-address"
      onSubmit={handleSubmit}
      title={content.title}
      subtitle={<Trans i18nKey={subtitle as any} />}
      loading={waitForLoading}
      {...props}
    >
      {(updateError || deletionError) && (
        <ValidationError className="-mt-3 mb-8">{content.error}</ValidationError>
      )}
      <div className="mt-3">
        {!single && (
          <div
            className={clsx(
              dateRange.isValid ? 'bg-green-1' : 'bg-light-blue-1',
              ' rounded-full py-4 px-6 w-min whitespace-nowrap text-xs mb-8 flex items-center',
            )}
          >
            <span>
              <span className="font-semibold">
                {pluralized(dateRange.years, content.year, content.years)}{' '}
                {pluralized(dateRange.months, content.month, content.months)}
              </span>{' '}
              worth of addresses
            </span>
            {dateRange.years >= 3 ? (
              <CheckCircleIcon className="h-5 w-5 inline text-green-3 ml-3" />
            ) : (
              <ExclamationCircleIcon className="h-5 w-5 inline text-blue-3 ml-3" />
            )}
          </div>
        )}
        {durationError && !dateRange.isValid && (
          <ValidationError className="-mt-6 mb-8">{durationError}</ValidationError>
        )}
        <AddressAccordion
          residentialHistory={residentialHistory}
          setResidentialHistoryItem={handleUpdateItem}
          deleteResidentialHistoryItem={handleDeleteItem}
          validationRules={validationRules}
          criteriaMet={criteriaMet}
          single={single}
          isUKAddress={isUKAddress}
          setOpenState={setOpenState}
        />
        {validationError && (!criteriaMet || openState) && (
          <ValidationError className="-mb-6 mt-8" id="validation-error">
            {validationError}
          </ValidationError>
        )}
        {/* Allows space for country dropdown */}
        <div className="pt-32" />
      </div>
    </QuestionOrganism>
  );
};

export default ResidentialHistoryQuestion;

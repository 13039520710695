import React from 'react';
import clsx from 'clsx';
import Check from './check.svg';

interface Props
  extends Omit<
    React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
    'type'
  > {
  label: string;
  large?: boolean;
  secondaryLabel?: string;
}

const Radio = ({ className, id, label, secondaryLabel, large, ...props }: Props) => {
  id = id || label;

  return (
    <div
      className={clsx(
        className,
        secondaryLabel ? 'w-full md:w-96' : large ? 'w-64 md:w-80' : 'w-64',
        'h-14 relative box-border',
      )}
    >
      <input
        id={id}
        type="radio"
        className="opacity-0 absolute top-0 bottom-0 left-0 right-0 peer"
        {...props}
      />
      <label
        htmlFor={id}
        className={clsx(
          secondaryLabel ? 'flex-col justify-center items-start' : 'items-center ',
          'border rounded pl-12 pr-2 flex text-sm leading-4',
          'absolute top-0 bottom-0 left-0 right-0 bg-white text-black border-grey-3',
          'hover:border-blue-4 peer-checked:border-blue-4',
          'peer-checked:bg-blue-4 peer-checked:text-white peer-disabled:border-grey-3 peer-disabled:opacity-50',
          'cursor-pointer peer-disabled:cursor-default',
        )}
      >
        <span className={clsx('w-full', secondaryLabel && 'truncate')}>{label}</span>
        {secondaryLabel && <span className="font-semibold mt-1">{secondaryLabel}</span>}
      </label>
      <img
        src={Check}
        alt=""
        role="presentation"
        className={clsx(
          'absolute left-4 top-4 bottom-4 rounded-xl border-solid border box-border h-6 w-6 border-grey-3',
          'flex items-center justify-center select-none object-none pointer-events-none',
          'peer-checked:border-2 peer-checked:border-green-4',
        )}
      />
    </div>
  );
};

export default Radio;

import QuestionOrganism from 'questionFlow/QuestionOrganism';
import { TFunction, useTranslation } from 'react-i18next';
import {
  DefaultIncomeQuestionComponentProps,
  StepNamesDefaultIncome,
} from 'questionFlow/flows/defaultIncome/types';

const cmsLocation = '/questions/income/OBSuccess';

const useContent = (t: TFunction<'translation'>) => ({
  title: t(`${cmsLocation}.title`),
  subtitle: t(`${cmsLocation}.subtitle`),
});

const stepName = StepNamesDefaultIncome.success;

const OBSuccess = ({
  data,
  onSubmit,
  values,
  validationRules,
  ...props
}: DefaultIncomeQuestionComponentProps) => {
  const { t } = useTranslation();
  const content = useContent(t);

  const handleSubmit = () => {
    onSubmit({ key: stepName, value: 'acknowledged' });
  };

  return (
    <QuestionOrganism
      id={stepName}
      data-testid={stepName}
      onSubmit={handleSubmit}
      title={content.title}
      subtitle={content.subtitle}
      isQuestionNumberHidden
      {...props}
    >
      {null}
    </QuestionOrganism>
  );
};

export default OBSuccess;

import { gql } from '@apollo/client';
import { useGetAllQuestionDataLazyQuery } from 'generated/graphql';

export const GET_ALL_QUESTION_DATA = gql`
  query getAllQuestionData(
    $IDENTITY_DEFAULT: Boolean!
    $INCOME_DEFAULT: Boolean!
    $IDENTITY_RTR: Boolean!
    $RESIDENCE_DEFAULT: Boolean!
    $remedialSectionUuid: UUID
    $INCOME_WITH_GUARANTOR: Boolean!
    $INCOME_WITHOUT_GUARANTOR: Boolean!
  ) {
    ...defaultIdentity @include(if: $IDENTITY_DEFAULT)
    ...defaultIncome @include(if: $INCOME_DEFAULT)
    ...defaultIncome @include(if: $INCOME_WITH_GUARANTOR)
    ...defaultIncome @include(if: $INCOME_WITHOUT_GUARANTOR)
    ...rightToRentIdentity @include(if: $IDENTITY_RTR)
    ...defaultResidence @include(if: $RESIDENCE_DEFAULT)
  }
`;

const GetAllQuestionDataHook = () => {
  const result = useGetAllQuestionDataLazyQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  return result;
};

export default GetAllQuestionDataHook;

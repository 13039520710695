import { useMemo } from 'react';
import {
  DefaultResidenceQuestionComponentProps,
  StepNamesDefaultResidence,
} from 'questionFlow/flows/defaultResidence/types';
import * as Sentry from '@sentry/react';
import { useTranslation, TFunction } from 'react-i18next';
import { AccountDocumentType } from 'generated/graphql';
import Upload from '../genericQuestions/Upload';
import { getFileFormatRuleForDocument } from 'questionFlow/genericQuestions/Upload/uploadHelpers';
import { getCurrentDocType } from 'questionFlow/flows/defaultResidence/DefaultResidence';

const cmsDocumentLocation = '/questions/residence/documents';
const cmsUploadLocation = '/questions/residence/upload';

const useContent = (t: TFunction<'translation'>, fileName: AccountDocumentType) => {
  const translationLocation = {
    [AccountDocumentType.TenancyAgreement]: t(`${cmsDocumentLocation}.TENANCY_AGREEMENT`),
    [AccountDocumentType.UtilityBill]: t(`${cmsDocumentLocation}.UTILITY_BILL`),
    [AccountDocumentType.BankStatement]: t(`${cmsDocumentLocation}.BANK_STATEMENT`),
    [AccountDocumentType.DrivingLicence]: t(`${cmsDocumentLocation}.DRIVING_LICENCE`),
    [AccountDocumentType.Payslip]: t(`${cmsDocumentLocation}.PAYSLIP`),
    [AccountDocumentType.Other]: t(`${cmsDocumentLocation}.OTHER`),
    [AccountDocumentType.AccommodationReciept]: t(`${cmsDocumentLocation}.ACCOMMODATION_RECIEPT`),
  };

  const doesTranslationExist = (translateItem: String) =>
    t(`${cmsUploadLocation}.${fileName}.${translateItem}`).includes(cmsUploadLocation)
      ? t(`${cmsUploadLocation}.${translateItem}`, {
          doc: translationLocation[fileName] || '{{doc}}',
        })
      : t(`${cmsUploadLocation}.${fileName}.${translateItem}`, {
          doc: translationLocation[fileName] || '{{doc}}',
        });

  return {
    do: doesTranslationExist('do'),
    dont: doesTranslationExist('dont'),
    doItems: doesTranslationExist('doItems'),
    dontItems: doesTranslationExist('dontItems'),
    dontHaveTitle: doesTranslationExist('dontHave.title'),
    dontHaveSubtitle: doesTranslationExist('dontHave.subtitle'),
    dontHaveOption: doesTranslationExist('dontHave.option'),
    optionalExtra: doesTranslationExist('optionalExtra'),
    required: t('validation.required'),
    inProgress: t('validation.inProgress'),
    fileTooLarge: t('validation.fileTooLarge'),
    wrongFileFormat: t('validation.wrongFileFormat'),
    uploadDoc: t('/questions/upload.uploadDoc'),
    multipleFiles: t('/questions/upload.multipleFiles'),
    minFiles: t('/questions/upload.minFiles'),
    ...translationLocation,
  };
};

const name = StepNamesDefaultResidence.upload;

const UploadSingle = ({
  values,
  data,
  ...props
}: DefaultResidenceQuestionComponentProps & { type?: string }) => {
  const { t } = useTranslation();
  const docType = getCurrentDocType(values, props.type);
  const translatedContent = useContent(t, docType as AccountDocumentType);
  const fileFormat = docType ? getFileFormatRuleForDocument(docType) : 'all';

  const content = useMemo(() => {
    return {
      ...translatedContent,
      title: `${cmsUploadLocation}.${docType}.title`,
      subtitle: `${cmsUploadLocation}.${docType}.subtitle`,
      fileName: translatedContent[docType as keyof typeof translatedContent] as string,
      formattedFileName: translatedContent[docType as keyof typeof translatedContent] as string,
    };
  }, [translatedContent, docType]);

  const selectAddress = values['select-address'];
  if (!selectAddress) {
    Sentry.captureException('Selected address not found');
    return null;
  }

  return (
    <Upload
      values={{
        documents: docType,
        upload: selectAddress.documents,
      }}
      {...props}
      fileFormat={fileFormat}
      sides={selectAddress.documents?.[0]?.type === AccountDocumentType.Other ? 6 : 1}
      content={content}
      name={name}
      optionalUnderHeader={selectAddress.address?.formattedAddress}
      additionalFilePath={`residential_history/${selectAddress.uuid}/`}
    />
  );
};

export default UploadSingle;

import AuthenticationService from 'authentication/AuthenticationService';
import AxiosProvider from 'api/axios/AxiosProvider';
import * as Sentry from '@sentry/react';

interface ResponseType {
  truelayer_auth_url: string;
}

interface Params {
  uuid: string;
}

export const composeUrlWithParams = (url: string, params: Record<string, string> = {}) => {
  const paramsString = Object.keys(params)
    .map(k => `${k}=${params[k]}`)
    .join('&');
  return url + (paramsString ? `?${paramsString}` : '');
};

const goToTruelayerAuthUrl = async ({ uuid }: Params) => {
  const trueLayerUrl = composeUrlWithParams(process.env.REACT_APP_TRUELAYER_URI || '', {
    object_uuid: uuid || '',
    object_type: 'income_source',
    wizard_section: 'income',
  });
  const token = AuthenticationService.accessToken;

  const response = await AxiosProvider.get<ResponseType>(trueLayerUrl, {
    headers: { Authorization: `Bearer ${token}` },
  }).catch(err => {
    Sentry.captureException(err, { tags: { category: 'node-api' } });
    throw err;
  });

  if (!response || !response?.data?.truelayer_auth_url) {
    Sentry.captureException('No trulayer response', { tags: { category: 'node-api' } });
    throw new Error('No response from call to start TrueLayer');
  }
  window.location.replace(response.data.truelayer_auth_url);
};

export default goToTruelayerAuthUrl;

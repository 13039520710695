import { IdentityQuestionComponentProps } from 'questionFlow/flows/defaultIdentity/types';
import { useTranslatedValues } from 'hooks/useTranslatedValues';
import QuestionOrganism from 'questionFlow/QuestionOrganism';
import { useCallback, useEffect, useRef, useState } from 'react';
import Webcam from 'react-webcam';
import { Button } from 'components';
import useDocumentUploadState from 'questionFlow/genericQuestions/Upload/useDocumentUploadState';

const cmsLocation = '/questions/identity/photo';

const translations = {
  title: `${cmsLocation}.title`,
  subtitle: `${cmsLocation}.subtitle`,
  titleCheck: `${cmsLocation}.titleCheck`,
  subtitleCheck: `${cmsLocation}.subtitleCheck`,
  titleUploaded: `${cmsLocation}.titleUploaded`,
  subtitleUploaded: `${cmsLocation}.subtitleUploaded`,
  buttonTextTakePhoto: `${cmsLocation}.buttonTextTakePhoto`,
  buttonTextTakePhotoAgain: `${cmsLocation}.buttonTextTakePhotoAgain`,
  buttonTextSubmit: `${cmsLocation}.buttonTextSubmit`,
  errorMessagePhoto: `${cmsLocation}.errorMessagePhoto`,
  errorMessagePhotoDescription: `${cmsLocation}.errorMessagePhotoDescription`,
  optOut: `${cmsLocation}.optOut`,
} as const;

const videoConstraints = {
  width: { min: 480, ideal: 1080 },
  height: { min: 480, ideal: 1080 },
  aspectRatio: 1,
  facingMode: 'user',
};

const urltoFile = (url: string, filename: string, mimeType: string) => {
  return fetch(url)
    .then(function(res) {
      return res.arrayBuffer();
    })
    .then(function(buf) {
      return new File([buf], filename, { type: mimeType });
    });
};

const sizeClassName = 'w-[320px] lg:w-[480px] h-[320px] lg:h-[480px]';

type Photo = { type: 'empty' } | { type: 'new'; base64: string } | { type: 'old'; path: string };

const Camera = ({ data, values, ...props }: IdentityQuestionComponentProps) => {
  const [photo, setPhoto] = useState<Photo>(() => {
    const uploadedPhoto = values.upload?.find(p => p.type === 'LIVE_PHOTO');
    return uploadedPhoto?.path ? { type: 'old', path: uploadedPhoto.path } : { type: 'empty' };
  });
  const content = useTranslatedValues(translations);
  const webcamRef = useRef<any>(null);
  const capture = useCallback(() => {
    const base64 = webcamRef.current.getScreenshot({ width: 960, height: 960 });
    setPhoto({ type: 'new', base64 });
  }, [webcamRef]);

  const documentUpload = useDocumentUploadState(
    undefined,
    {} as any,
    'LIVE_PHOTO',
    'all',
    'residential_history/ee619839-a6f5-4cc2-9b34-aeec35ef66cd/',
    { dob: values.birthdate || '' },
  );

  const { uploadError } = documentUpload;

  useEffect(() => {
    if (photo?.type === 'new') {
      urltoFile(photo.base64, 'LIVE_PHOTO.jpg', 'image/jpeg').then(file => {
        documentUpload.handleUpload(file);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [photo]);

  const clearPhoto = () => setPhoto({ type: 'empty' });

  const handleSubmit = () => {
    const existingDocs = values.upload?.filter(doc => doc.type !== 'LIVE_PHOTO') || [];
    if (photo.type === 'new' && documentUpload.path && !uploadError) {
      props.onSubmit(
        {
          key: 'upload',
          value: [...existingDocs, { type: 'LIVE_PHOTO', path: documentUpload.path }],
        },
        { preventProgressCompletion: false },
      );
    }
    if (photo.type === 'old') {
      props.onSubmit(
        { key: 'upload', value: [...existingDocs, { type: 'LIVE_PHOTO', path: photo.path }] },
        { preventProgressCompletion: false },
      );
    }
  };

  return (
    <QuestionOrganism
      {...props}
      onSubmit={handleSubmit}
      title={
        uploadError
          ? content.errorMessagePhoto
          : photo.type === 'empty'
          ? content.title
          : content.titleCheck
      }
      subtitle={
        uploadError
          ? content.errorMessagePhotoDescription
          : photo.type === 'empty'
          ? content.subtitle
          : content.subtitleCheck
      }
      data-testid="camera"
      isFooterHidden
    >
      {photo.type === 'new' && (
        <img src={photo.base64} className={sizeClassName} alt="Webcam portrait" />
      )}
      {photo.type === 'old' && <div>Photo already uploaded</div>}
      {photo.type === 'empty' && (
        <div className={`relative ${sizeClassName}`}>
          <Webcam
            className="absolute"
            audio={false}
            height={480}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            width={480}
            videoConstraints={videoConstraints}
          />
          <div
            className={`absolute bg-contain ${sizeClassName}`}
            style={{ backgroundImage: 'url("/assets/images/live_photo_helper.png")' }}
          />
        </div>
      )}
      <div className="mt-10 flex justify-between w-[320px] lg:w-[480px]">
        {photo.type !== 'empty' ? (
          <>
            <Button type="button" styleType="secondary" onClick={clearPhoto}>
              {content.buttonTextTakePhotoAgain}
            </Button>
            <Button disabled={!!uploadError} onClick={handleSubmit}>
              {content.buttonTextSubmit}
            </Button>
          </>
        ) : (
          <Button type="button" styleType="primary" onClick={capture}>
            {content.buttonTextTakePhoto}
          </Button>
        )}
      </div>
      <div
        className="mt-6 hover:underline cursor-pointer"
        onClick={() => {
          props.onSubmit({ key: 'permissionGranted', value: 'skip' });
        }}
      >
        {content.optOut}
      </div>
    </QuestionOrganism>
  );
};

export default Camera;

import { Options } from 'components/atoms/RadioGroup/RadioGroup';
import { ResidenceTypeEnum } from 'generated/graphql';

type DocumentOptions = {
  [key in ResidenceTypeEnum]?: Options;
};

type Content = {
  [key: string]: string;
};

function optionGenerator(content: Content) {
  return (key: string) => ({
    label: content[key],
    value: key,
  });
}

export function getInitialDocuments(content: Content): DocumentOptions {
  const optionFromKey = optionGenerator(content);

  const none = {
    label: content.none,
    value: 'none',
  };

  return {
    [ResidenceTypeEnum.RentedFlat]: [
      optionFromKey('TENANCY_AGREEMENT'),
      optionFromKey('UTILITY_BILL'),
      optionFromKey('BANK_STATEMENT'),
      optionFromKey('DRIVING_LICENCE'),
      none,
    ],
    [ResidenceTypeEnum.FamilyHome]: [
      optionFromKey('UTILITY_BILL'),
      optionFromKey('BANK_STATEMENT'),
      optionFromKey('DRIVING_LICENCE'),
      none,
    ],
    [ResidenceTypeEnum.SelfOwnedHome]: [
      optionFromKey('UTILITY_BILL'),
      optionFromKey('BANK_STATEMENT'),
      optionFromKey('DRIVING_LICENCE'),
      none,
    ],
    [ResidenceTypeEnum.StudentAccommodation]: [
      optionFromKey('TENANCY_AGREEMENT'),
      optionFromKey('UTILITY_BILL'),
      optionFromKey('BANK_STATEMENT'),
      optionFromKey('ACCOMMODATION_RECIEPT'),
      none,
    ],
    [ResidenceTypeEnum.InformalArrangement]: [
      optionFromKey('UTILITY_BILL'),
      optionFromKey('BANK_STATEMENT'),
      optionFromKey('DRIVING_LICENCE'),
      optionFromKey('ACCOMMODATION_RECIEPT'),
      none,
    ],
  };
}

import { useMemo } from 'react';
import { AccountDocumentType, IdentitySectionMetaDataType } from '../../generated/graphql';
import {
  DefaultIdentityValuesType,
  StepNamesDefaultIdentity,
} from 'questionFlow/flows/defaultIdentity/types';
import { useTranslation, TFunction } from 'react-i18next';
import Upload from '../genericQuestions/Upload';
import {
  RTRIdentityValuesType,
  StepNamesRTRIdentity,
} from 'questionFlow/flows/rightToRentIdentity/types';
import { OnSubmitType, QuestionComponentProps } from 'questionFlow/types';
import { get } from 'lodash';
import mockDefinitions from '__mocks__/mock-document-definitions-map.json';
import { getFileFormatRuleForDocument } from 'questionFlow/genericQuestions/Upload/uploadHelpers';
import { getDocTypeForCopy } from 'questionFlow/genericQuestions/Upload/utils';

const cmsLocation = '/questions/identity/upload';

const useContent = (t: TFunction<'translation'>, fileName: AccountDocumentType) => {
  const doesTranslationExist = (translateItem: String) =>
    t(`${cmsLocation}.${fileName.trim()}.${translateItem}` as any).includes(cmsLocation)
      ? t(`${cmsLocation}.${translateItem}` as any)
      : t(`${cmsLocation}.${fileName.trim()}.${translateItem}` as any);

  return {
    do: doesTranslationExist('do'),
    dont: doesTranslationExist('dont'),
    doItems: doesTranslationExist('doItems'),
    dontItems: doesTranslationExist('dontItems'),
    dontHaveTitle: doesTranslationExist('dontHave.title'),
    dontHaveSubtitle: doesTranslationExist('dontHave.subtitle'),
    dontHaveOption: doesTranslationExist('dontHave.option'),
    backSide: doesTranslationExist('backSide'),
    required: t('validation.required'),
    inProgress: t('validation.inProgress'),
    fileTooLarge: t('validation.fileTooLarge'),
    wrongFileFormat: t('validation.wrongFileFormat'),
    extra: t('/questions/upload.extra'),
    front: t('/questions/upload.front'),
    back: t('/questions/upload.back'),
    uploadDoc: t('/questions/upload.uploadDoc'),
    multipleFiles: t('/questions/upload.multipleFiles'),
    minFiles: t('/questions/upload.minFiles'),
  };
};

export interface Props extends QuestionComponentProps<IdentitySectionMetaDataType | {}, any> {
  documentKey: keyof RTRIdentityValuesType | keyof DefaultIdentityValuesType;
  name: StepNamesDefaultIdentity | StepNamesRTRIdentity;
  isNotFirstDocUpload?: boolean;
  side?: 'front' | 'back';
}

const UploadIdentity = ({
  values,
  data,
  side,
  documentKey = StepNamesDefaultIdentity.documents,
  name = StepNamesDefaultIdentity.upload,
  isNotFirstDocUpload,
  onSubmit,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const docTypeName = useMemo(() => get(values, documentKey), [documentKey, values]);

  const modifiedUploads = useMemo(() => {
    if (isNotFirstDocUpload) {
      const firstIndexOfThisDocType = values.upload.findIndex(
        ({ type }: AccountDocumentType) => type === docTypeName,
      );

      if (firstIndexOfThisDocType !== -1) {
        return {
          values: {
            ...values,
            upload: values.upload.slice(firstIndexOfThisDocType),
          },
          index: firstIndexOfThisDocType,
        };
      }
      return { values: { ...values, upload: [] }, index: values.upload.length };
    }
    return { values };
  }, [docTypeName, isNotFirstDocUpload, values]);

  const handleSubmit: OnSubmitType = ({ key, value }, options) => {
    const livePhoto = values.upload.filter(doc => doc.type === 'LIVE_PHOTO');
    if (isNotFirstDocUpload) {
      //If this is second secondary document, add to secondaryOne
      const firstDocumentUploaded = values.upload.filter(
        ({ type }: AccountDocumentType) =>
          type === values[StepNamesRTRIdentity.secondaryDocumentOne],
      );
      onSubmit(
        {
          key,
          value: [...livePhoto, ...firstDocumentUploaded, ...(value as AccountDocumentType[])],
        },
        options,
      );
    } else if (values[StepNamesRTRIdentity.secondaryDocumentTwo]) {
      //If this is the first secondary document and there are pre-populated secondary secondary docs
      const secondDocumentsToKeepPrepopulated = values.upload.filter(
        ({ type }: AccountDocumentType) =>
          type === values[StepNamesRTRIdentity.secondaryDocumentTwo],
      );
      onSubmit(
        {
          key,
          value: [
            ...livePhoto,
            ...(value as AccountDocumentType[]),
            ...secondDocumentsToKeepPrepopulated,
          ],
        },
        options,
      );
    } else {
      onSubmit({ key, value: [...livePhoto, ...value] }, options);
    }
  };

  const translatedContent = useContent(t, docTypeName as AccountDocumentType);

  const { sides } = useMemo(() => {
    const definition = mockDefinitions.find(d => d.docType === docTypeName);
    if (!definition) throw new Error(`No document definition for ${docTypeName}`);

    const accept = definition.acceptedFileTypes.toString();
    return {
      ...definition,
      accept,
      sides: docTypeName === AccountDocumentType.Other ? 6 : definition.sides,
    };
  }, [docTypeName]);

  const content = useMemo(() => {
    const type = getDocTypeForCopy(docTypeName, side);
    return {
      ...translatedContent,
      title: `${cmsLocation}.${type}.title`,
      subtitle: `${cmsLocation}.${type}.subtitle`,
      fileName: t(`/questions/identity/documents.${type}` as any),
      formattedFileName: t(`/questions/identity/documents.${type}` as any),
    };
  }, [translatedContent, docTypeName, side, t]);

  const fileFormat = getFileFormatRuleForDocument(docTypeName);

  return (
    <Upload
      {...props}
      values={modifiedUploads.values}
      data={data}
      fileFormat={fileFormat}
      content={content}
      name={name}
      side={side}
      sides={sides > 1 && side ? 1 : sides}
      documentKey={documentKey}
      onSubmit={handleSubmit}
      generateSuffix={() => (side === 'back' ? content.backSide : '')}
      showNumericSides={docTypeName === AccountDocumentType.TimeLimitedPassport}
    />
  );
};

export default UploadIdentity;

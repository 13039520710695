import { useTranslatedValues } from 'hooks/useTranslatedValues';
import React, { useMemo } from 'react';
import NavigationHeaderDesktop from './NavigationHeader.desktop';
import NavigationHeaderMobile from './NavigationHeader.mobile';

export interface Props {
  className?: string;
  children?: React.ReactNode;
}

const translations = {
  poweredBy: 'header.poweredBy',
  menu: 'header.menu',
  closeMenu: 'header.close-menu',
  dashboard: 'header.dashboard',
  logout: 'header.logout',
} as const;

const NavigationHeader = (props: Props) => {
  const content = useTranslatedValues(translations);

  const options = useMemo(
    () => [
      {
        label: content.dashboard,
        value: '/dashboard',
      },
      { label: content.logout, value: '/logout' },
    ],
    [content],
  );

  return (
    <>
      <NavigationHeaderMobile content={content} options={options} {...props} />
      <NavigationHeaderDesktop content={content} options={options} {...props} />
    </>
  );
};

export default NavigationHeader;

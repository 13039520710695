import React, { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { GetInvalidReason } from 'validation/types';
import useValidationMessage from 'validation/useValidationMessage';

type OnInputChangeEvent = (e: React.ChangeEvent<HTMLInputElement>) => void;
type OnInputBlurEvent = (e: React.FocusEvent<HTMLInputElement>) => void;

interface InputProps {
  'aria-invalid'?: boolean;
  onBlur: OnInputBlurEvent;
  onChange: OnInputChangeEvent;
  value: string;
}

interface Validation {
  canSubmit: () => boolean;
  invalid: boolean;
  validationError?: string;
}

export type UseInputResult = [string, InputProps, Validation, Dispatch<SetStateAction<string>>];

const noop = () => undefined;

const useInput = (initialState: string = '', getInvalidReason: GetInvalidReason = noop): UseInputResult => {
  const [value, setter] = useState(initialState);
  // Internally we will always track if there is a reason the input is invalid
  const [invalidReason, setInvalidReason] = useState(() => getInvalidReason(initialState));
  // Only emit the validation reason following a blur, it will be externally hidden again on any change
  const [errorShouldDisplay, setShouldErrorDisplay] = useState(false);

  const invalid = !!invalidReason;
  const presentableValidationMessage = useValidationMessage(invalidReason);

  const onChange: OnInputChangeEvent = useCallback(
    ({ target }) => {
      // Definetley needed here and not on blur, if user presses enter to submit for example
      setInvalidReason(getInvalidReason(target.value));
      // Making any change will clear the error display
      setShouldErrorDisplay(false);
      setter(target.value);
    },
    [getInvalidReason],
  );

  const onBlur: OnInputBlurEvent = useCallback(() => {
    setShouldErrorDisplay(!!invalidReason);
  }, [invalidReason]);

  const canSubmit = useCallback(() => {
    if (invalid) {
      setShouldErrorDisplay(true);
      return false;
    }

    return true;
  }, [invalid]);

  return [
    value,
    {
      'aria-invalid': errorShouldDisplay ? true : undefined,
      onBlur,
      onChange,
      value,
    },
    {
      canSubmit,
      invalid,
      validationError: errorShouldDisplay ? presentableValidationMessage : undefined,
    },
    setter,
  ];
};

export default useInput;

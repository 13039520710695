import {
  DefaultIncomeQuestionComponentProps,
  StepNamesDefaultIncome,
} from 'questionFlow/flows/defaultIncome/types';

import { useTranslation, TFunction } from 'react-i18next';
import Upload from '../genericQuestions/Upload';
import { useMemo } from 'react';
import { IncomeSourceTypeEnum } from 'generated/graphql';
import { getFileFormatRuleForDocumentKey } from 'questionFlow/genericQuestions/Upload/uploadHelpers';

const cmsLocation = '/questions/income/upload';

const useContent = (t: TFunction<'translation'>, docCount: number) => {
  const translationLocation =
    docCount === 3
      ? '/questions/income/upload.BANK_STATEMENT3'
      : '/questions/income/upload.BANK_STATEMENT6';

  return {
    do: t(`${translationLocation}.do`),
    dont: t(`${translationLocation}.dont`),
    doItems: t(`${translationLocation}.doItems`),
    dontItems: t(`${translationLocation}.dontItems`),
    dontHaveTitle: t(`${translationLocation}.dontHave.title`),
    dontHaveSubtitle: t(`${translationLocation}.dontHave.subtitle`),
    dontHaveOption: t(`${translationLocation}.dontHave.option`),
    required: t('validation.required'),
    inProgress: t('validation.inProgress'),
    fileTooLarge: t('validation.fileTooLarge'),
    wrongFileFormat: t('validation.wrongFileFormat'),
    fileName: t(`${cmsLocation}.titleBankStatements`),
    formattedFileName: t(`/questions/income/documents.BANKSTATEMENT`),
    uploadDoc: t('/questions/upload.uploadDoc'),
    multipleFiles: t('/questions/upload.multipleFiles'),
    minFiles: t('/questions/upload.minFiles'),
  };
};

const UploadBankStatements = ({ values, ...props }: DefaultIncomeQuestionComponentProps) => {
  const fileFormat = getFileFormatRuleForDocumentKey('BankStatement');
  const sides = useMemo(() => (values.incomeType === IncomeSourceTypeEnum.TSelfemployed ? 6 : 3), [
    values.incomeType,
  ]);
  const docCount = useMemo(
    () => (values.incomeType === IncomeSourceTypeEnum.TSelfemployed ? 6 : 3),
    [values.incomeType],
  );

  const { t } = useTranslation();
  const translatedContent = useContent(t, docCount);

  const content = useMemo(() => {
    return {
      ...translatedContent,
      title: `${cmsLocation}.${values.documents}${docCount}.title`,
      subtitle: `${cmsLocation}.${values.documents}${docCount}.subtitle`,
    };
  }, [translatedContent, values.documents, docCount]);

  return (
    <Upload
      {...props}
      values={values}
      sides={sides}
      fileFormat={fileFormat}
      content={content}
      name={StepNamesDefaultIncome.upload}
      additionalFilePath={`income_sources/${values.uuid}/`}
      modalTransValues={{ count: docCount.toString() }}
    />
  );
};

export default UploadBankStatements;

import { DropDown, Field, Input } from 'components';
import QuestionOrganism from 'questionFlow/QuestionOrganism';
import { TFunction, useTranslation } from 'react-i18next';
import { useCallback } from 'react';

import { CurrencyTypesEnum, Maybe } from 'generated/graphql';
import {
  DefaultIncomeQuestionComponentProps,
  DefaultIncomeValuesType,
  StepNamesDefaultIncome,
} from 'questionFlow/flows/defaultIncome/types';
import { ValidationRule } from 'questionFlow/types';
import useCurrencyCodeOptions from 'questionFlow/genericQuestions/useCurrencyCodeOptions';
import { useAnalyticsErrors, useAnalyticsValues } from 'monitoring/analyticsHooks';

const cmsLocation = '/questions/income/savingsAmount';

export const useContent = (t: TFunction<'translation'>) => ({
  title: t(`${cmsLocation}.title`),
  subtitle: t(`${cmsLocation}.subtitle`),

  amount: {
    label: t(`${cmsLocation}.amount.label`),
    placeholder: t(`${cmsLocation}.amount.placeholder`),
  },
  currency: {
    label: t(`${cmsLocation}.currency.label`),
    placeholder: t(`${cmsLocation}.currency.placeholder`),
  },
});

export type SavingsAmountQuestion = 'amount' | 'currency';
export interface SavingsAmountValues {
  amount: string;
  currency: Maybe<CurrencyTypesEnum>;
}

export const useValues = (initialValues?: DefaultIncomeValuesType) => {
  const currentReference = initialValues?.savingsAmount as SavingsAmountValues | undefined;

  return useAnalyticsValues<SavingsAmountValues>({
    amount: currentReference?.amount || '',
    currency: currentReference?.currency || null,
  });
};

export const useErrors = () =>
  useAnalyticsErrors<Record<SavingsAmountQuestion, string>>({
    amount: '',
    currency: '',
  });

function assertIsSavingsAmountQuestion(maybe: string): asserts maybe is SavingsAmountQuestion {
  if (maybe === 'amount' || maybe === 'currency') {
    return;
  }

  throw new Error(maybe + ' is not a known SavingsAmountQuestionName');
}

const validate = (v: string, rules: ValidationRule[]) => rules.find(({ rule }) => !rule(v));

const stepName = StepNamesDefaultIncome.savingsAmount;

const SavingsAmount = ({
  data,
  onSubmit,
  values: initialValues,
  validationRules,
  ...props
}: DefaultIncomeQuestionComponentProps) => {
  const { t } = useTranslation();
  const content = useContent(t);
  const [values, setValues] = useValues(initialValues);
  const [error, setError] = useErrors();
  const currencyOptions = useCurrencyCodeOptions();

  const getValidationResult = useCallback(
    (name: SavingsAmountQuestion, givenValue?: string) => {
      const actualValue = givenValue === undefined ? values[name] : givenValue;
      const result = validate(actualValue, validationRules[name]);

      return result;
    },
    [validationRules, values],
  );

  const handleSubmit = useCallback(() => {
    const errors = (Object.keys(values) as SavingsAmountQuestion[]).reduce((e, inputName) => {
      const result = getValidationResult(inputName);

      return { ...e, [inputName]: result ? t(result.error as any) : '' };
    }, error);

    if (Object.values(errors).filter(e => e !== '').length === 0) {
      onSubmit({ key: stepName, value: values });
    } else {
      setError(errors);
    }
  }, [error, getValidationResult, onSubmit, setError, t, values]);

  const validateAndSetError = useCallback(
    (name: SavingsAmountQuestion, newValue: string) => {
      const result = getValidationResult(name, newValue);
      setError({ ...error, [name]: result ? t(result.error as any) : '' });
    },
    [error, getValidationResult, setError, t],
  );

  const onStringValueChange = (name: SavingsAmountQuestion, value: string) => {
    if (error[name]) {
      validateAndSetError(name, value);
    }

    setValues({ ...values, [name]: value });
  };

  const handleInputOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    assertIsSavingsAmountQuestion(e.target.name);
    onStringValueChange(e.target.name, e.target.value);
  };

  const handleInputOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    assertIsSavingsAmountQuestion(e.target.name);
    validateAndSetError(e.target.name, e.target.value);
  };

  const onDropdownChange = (name: SavingsAmountQuestion, optionValue: string) =>
    onStringValueChange(name, optionValue);

  return (
    <QuestionOrganism
      id={stepName}
      data-testid={stepName}
      onSubmit={handleSubmit}
      title={content.title}
      subtitle={content.subtitle}
      {...props}
    >
      <div className="grid w-max md:grid-cols-2 gap-x-10 gap-y-4 pb-60">
        <Field
          className="md:order-1"
          data-testid={`${stepName}-currency`}
          label={content.currency.label}
          validationError={error.currency}
        >
          <DropDown
            size="sm"
            placeholder={content.currency.placeholder}
            options={currencyOptions}
            onSelectValue={value => onDropdownChange('currency', value)}
            value={values.currency}
            error={!!error.currency}
            label={content.currency.label}
          />
        </Field>

        <Field
          data-testid={`${stepName}-amount`}
          label={content.amount.label}
          validationError={error.amount}
        >
          <Input
            id={`${stepName}-amount`}
            name={'amount'}
            defaultValue={values.amount}
            onChange={handleInputOnChange}
            aria-invalid={!!error.amount}
            onBlur={handleInputOnBlur}
            placeholder={content.amount.placeholder}
            type="number"
          />
        </Field>
      </div>
    </QuestionOrganism>
  );
};

export default SavingsAmount;

import { useEffect, Dispatch, SetStateAction } from 'react';
import useUploadToS3, { AdditionalUserInfo } from 'api/data/uploadToS3/useUploadToS3';
import { useAnalyticsError, useAnalyticsValue } from '../../../monitoring/analyticsHooks';
import { Content, UploadedDocType } from './Upload';
import { FileFormatRule, isFileNameAllowed } from './uploadHelpers';

const maxFileSize = process.env.MAX_FILE_UPLOAD_SIZE || 10 * 1024 * 1024; //10Mb

export interface DocumentUploadState {
  file: File | undefined;
  validationError: string;
  setValidationError: Dispatch<SetStateAction<string>>;
  handleUpload: (file: File) => void;
  uploadError: string;
  progress: number;
  path: string;
  id: string | undefined;
}

const useDocumentUploadState = (
  previouslyUploadedFile: UploadedDocType | undefined,
  content: Content,
  documentName: string,
  fileFormat: FileFormatRule,
  additionalFilePath?: string,
  additionalUserInfo?: AdditionalUserInfo,
): DocumentUploadState => {
  const analyticsName = `${documentName} (upload)`;

  const [file, setFile] = useAnalyticsValue<File | undefined>(
    analyticsName,
    previouslyUploadedFile ? new File([new ArrayBuffer(1)], documentName) : undefined,
  );

  const [validationError, setValidationError] = useAnalyticsError(analyticsName, '');
  const [{ error, progress, path }] = useUploadToS3(
    file,
    !!previouslyUploadedFile,
    previouslyUploadedFile?.path || '',
    documentName,
    additionalFilePath,
    additionalUserInfo,
  );

  useEffect(() => {
    if (progress === 100) {
      setValidationError('');
    }
  }, [progress, setValidationError]);

  const handleUpload = (file: File) => {
    if (file.size > maxFileSize) {
      setValidationError(content.fileTooLarge);
    } else if (!isFileNameAllowed(fileFormat, file.name)) {
      setValidationError(content.wrongFileFormat);
    } else {
      setFile(file);
      setValidationError('');
    }
  };

  return {
    id: previouslyUploadedFile?.id,
    file,
    validationError,
    uploadError: error,
    progress,
    path,
    setValidationError,
    handleUpload,
  };
};

export default useDocumentUploadState;

import { useMemo } from 'react';
import { TFunction } from 'react-i18next';
import { Options } from 'components/molecules/DropDown/types';
import { YesNoEnum } from 'questionFlow/flows/defaultIncome/types';

const cmsLocation = '/questions/income/currentEmployer';

export const useContent = (t: TFunction<'translation'>) => ({
  title: t(`${cmsLocation}.title`),

  companyName: {
    label: t(`${cmsLocation}.companyName.label`),
    placeholder: t(`${cmsLocation}.companyName.placeholder`),
  },
  role: {
    label: t(`${cmsLocation}.role.label`),
    placeholder: t(`${cmsLocation}.role.placeholder`),
  },
  startDate: {
    label: t(`${cmsLocation}.startDate.label`),
    dayInputLabel: t(`${cmsLocation}.startDate.dayInputLabel`),
    monthInputLabel: t(`${cmsLocation}.startDate.monthInputLabel`),
    yearInputLabel: t(`${cmsLocation}.startDate.yearInputLabel`),
  },
  endDate: {
    label: t(`${cmsLocation}.endDate.label`),
    dayInputLabel: t(`${cmsLocation}.endDate.dayInputLabel`),
    monthInputLabel: t(`${cmsLocation}.endDate.monthInputLabel`),
    yearInputLabel: t(`${cmsLocation}.endDate.yearInputLabel`),
  },
  probationStatus: {
    label: t(`${cmsLocation}.probationStatus.label`),
    placeholder: t(`${cmsLocation}.probationStatus.placeholder`),
    option_yes: t(`${cmsLocation}.probationStatus.option_yes`),
    option_no: t(`${cmsLocation}.probationStatus.option_no`),
  },
  probationLength: {
    label: t(`${cmsLocation}.probationLength.label`),
    placeholder: t(`${cmsLocation}.probationLength.placeholder`),
  },
});

export const useProbationStatusOptions = (content: ReturnType<typeof useContent>) => {
  const { option_no, option_yes } = content.probationStatus;
  const yesNoOptions: Options = useMemo(
    () => [
      { value: YesNoEnum.YES, label: option_yes },
      { value: YesNoEnum.NO, label: option_no },
    ],
    [option_no, option_yes],
  );

  return yesNoOptions;
};

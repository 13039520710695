import { useTranslation } from 'react-i18next';
import { InvalidReason } from './types';

const useValidationMessage = (reason?: InvalidReason) => {
  const { t } = useTranslation();

  switch (reason) {
    case 'is-empty':
      return t('validation.is_required');
    case 'invalid-email':
      return t('validation.invalid_email');
  }

  return undefined;
};

export default useValidationMessage;

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import Modal from 'react-modal';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

const rootElement = document.getElementById('root');

Sentry.init({
  enabled: process.env.NODE_ENV === 'production',
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  maxBreadcrumbs: 50,
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  attachStacktrace: true,
  tracesSampleRate: 0.01,
  release: process.env.REACT_APP_RELEASE,
  ignoreErrors: [/^ChunkLoadError: Loading chunk [0-9]+ failed.$/],
  beforeBreadcrumb(breadcrumb, hint) {
    if (breadcrumb?.category?.startsWith('ui')) {
      const { target } = hint?.event;
      breadcrumb.message =
        target.id || target.dataset.testid
          ? `${target.tagName.toLowerCase()}[@id="${target.id}"][@data-testid="${
              target.dataset.testid
            }"]`
          : breadcrumb.message;
    }
    return breadcrumb;
  },
});

if (rootElement === null) {
  throw new Error('No root element found');
}

ReactDOM.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={<div>Something went wrong </div>}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
  rootElement,
);

Modal.setAppElement(rootElement);

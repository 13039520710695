import { AccountDocumentType, GqlDocTypeEnum } from 'generated/graphql';
import { Content } from './Upload';

const DocsWithSignposting = [
  GqlDocTypeEnum.NationalId,
  AccountDocumentType.Passport,
  AccountDocumentType.UkPassport,
  AccountDocumentType.TimeLimitedPassport,
  AccountDocumentType.DrivingLicence,
  AccountDocumentType.UkDrivingLicence,
  AccountDocumentType.BankStatement,
  AccountDocumentType.EmploymentContract,
  AccountDocumentType.UtilityBill,
  AccountDocumentType.TenancyAgreement,
  AccountDocumentType.Other,
];
export const hasDocumentSignposting = (docType: string) =>
  DocsWithSignposting.includes(docType as any);

const DocsWithModalWarning = [
  AccountDocumentType.BankStatement,
  AccountDocumentType.EmploymentContract,
  AccountDocumentType.UtilityBill,
  AccountDocumentType.TimeLimitedPassport,
];

export const hasDocumentWarningModal = (docType: string) =>
  DocsWithModalWarning.includes(docType as any);

export const getNameForDocType = (
  docType: AccountDocumentType,
  side?: 'front' | 'back',
): string => {
  let name = docType;
  if (
    [
      AccountDocumentType.UkPassport,
      AccountDocumentType.TimeLimitedPassport,
      AccountDocumentType.BritishNationalOverseasPassport,
    ].includes(docType)
  ) {
    name = AccountDocumentType.Passport;
  }

  if (docType === AccountDocumentType.UkDrivingLicence) {
    name = AccountDocumentType.DrivingLicence;
  }

  return `${name}${side === 'back' ? '_back' : ''}`;
};

export const getDocTypeForCopy = (docType: AccountDocumentType, side?: 'front' | 'back') => {
  if (docType === AccountDocumentType.TimeLimitedPassport) {
    return side === 'front' ? AccountDocumentType.Passport : 'VISA';
  }
  return docType;
};

export const getSideCopy = ({
  content,
  side,
  showNumericSides,
}: {
  content: Content;
  side?: 'front' | 'back';
  showNumericSides?: boolean;
}) => {
  if (!side) {
    return;
  }
  if (showNumericSides) {
    return side === 'front' ? '1. ' : '2. ';
  } else {
    return side ? ' - ' + content[side] : '';
  }
};

import { CalendarIcon, HomeIcon, UserIcon } from '@heroicons/react/solid';
import { ProgressItem } from 'components/molecules/ProgressIndicator/ProgressIndicator';
import { useTranslatedValues } from 'hooks/useTranslatedValues';
import { Trans } from 'react-i18next';
import { TenantReferenceData } from './types';

type Props = {
  userData: TenantReferenceData;
};

const translations = {
  rentalReference: '/rentalReference.title',
  userAsk: '/rentalReference.userAsk',
  details: '/rentalReference.details',
  present: '/rentalReference.present',
} as const;

const ReferenceDetails = ({ userData }: Props) => {
  const content = useTranslatedValues(translations);

  const getFormattedDate = (date: string | null) =>
    date ? new Date(date).toLocaleString('default', { month: 'long', year: 'numeric' }) : '';

  return (
    <div className="mt-8 text-lg text-gray-800">
      <ProgressItem
        className="-ml-1"
        selected
        label={content.rentalReference}
        value={content.rentalReference}
      />
      <h1 className="font-semibold text-3xl mt-8 mb-4">{content.rentalReference}</h1>

      <p>
        <span className="font-medium">
          {userData.first_name} {userData.last_name}
        </span>{' '}
        {content.userAsk}
      </p>
      <p className="mt-4">{content.details}:</p>

      <div className="space-y-2 font-medium mt-4 bg-blue-50 p-4 shadow-sm rounded-sm">
        <div className="flex space-x-2 items-center">
          <UserIcon className="w-5 h-5 text-gray-400 flex-shrink-0" />{' '}
          <span>
            {userData.first_name} {userData.last_name}
          </span>
        </div>
        <div className="flex space-x-2 items-center">
          <HomeIcon className="w-5 h-5 text-gray-400 flex-shrink-0" />{' '}
          <span>{userData.address}</span>
        </div>
        <div className="flex space-x-2 items-center">
          <CalendarIcon className="w-5 h-5 text-gray-400 flex-shrink-0" />{' '}
          <span>
            <Trans
              i18nKey={`/rentalReference.tenancyFromTo`}
              values={{
                fromDate: getFormattedDate(userData.tenancy_start) || '-',
                endDate: getFormattedDate(userData.tenancy_end) || content.present,
              }}
              components={{
                sb: <span className="font-semibold" />,
              }}
            />
          </span>
        </div>
      </div>
    </div>
  );
};

export default ReferenceDetails;

import { useMemo } from 'react';
import {
  DefaultIncomeQuestionComponentProps,
  StepNamesDefaultIncome,
  whoIsPayingEnum,
} from '../flows/defaultIncome/types';

import { TFunction, useTranslation } from 'react-i18next';
import RadioQuestion from 'questionFlow/genericQuestions/RadioQuestion';
import { Options } from 'components/atoms/RadioGroup/RadioGroup';
const cmsLocation = '/questions/income/whoIsPaying';

const useContent = (t: TFunction<'translation'>) => ({
  title: t(`${cmsLocation}.title`),
  subtitle: t(`${cmsLocation}.subtitle`),
  me: t(`${cmsLocation}.me`),
  someoneElse: t(`${cmsLocation}.someoneElse`),
});

const name = StepNamesDefaultIncome.whoIsPaying;

const WhoIsPaying = ({ data, values, ...props }: DefaultIncomeQuestionComponentProps) => {
  const { t } = useTranslation();
  const content = useContent(t);

  const options: Options = useMemo(
    () => [
      { value: whoIsPayingEnum.ME, label: content.me },
      { value: whoIsPayingEnum.OTHER, label: content.someoneElse },
    ],
    [content.me, content.someoneElse],
  );

  return (
    <RadioQuestion
      content={content}
      initialValue={values[name]}
      name={name}
      options={options}
      {...props}
    />
  );
};

export default WhoIsPaying;

import React, { createRef, useEffect, useState } from 'react';
import clsx from 'clsx';
import { Input } from 'components';
import isExists from 'date-fns/isExists';
import { useTranslatedValues } from 'hooks/useTranslatedValues';

export type OnDateChange = (newDateValues: { name: string; valid: boolean; value: string }) => void;

export interface DateInputProps
  extends Omit<
    React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
    'onChange'
  > {
  className?: string;
  children?: React.ReactNode;
  'data-testid'?: string;
  defaultValue: string;
  error?: boolean;
  onChange: OnDateChange;
  dayLabel: string;
  monthLabel: string;
  yearLabel: string;
  hideDayInput?: boolean;
}

const translations = {
  dayPlaceholder: `date_of_birth_input.day.placeholder`,
  monthPlaceholder: `date_of_birth_input.month.placeholder`,
  yearPlaceholder: `date_of_birth_input.year.placeholder`,
} as const;

const isValidDate = ({ year, month, day }: { year: string; month: string; day: string }) => {
  const dayNumber = parseInt(day, 10);
  const monthNumber = parseInt(month, 10) - 1;
  const yearNumber = parseInt(year, 10);

  return isExists(yearNumber, monthNumber, dayNumber);
};

const getInitialState = (source: string, hideDayInput?: boolean) => {
  if (source) {
    let birthdate = new Date(source);
    if (!isNaN((birthdate as unknown) as number)) {
      return {
        day: `${birthdate.getDate()}`.padStart(2, '0'),
        month: `${birthdate.getMonth() + 1}`.padStart(2, '0'),
        year: `${birthdate.getFullYear()}`,
      };
    }
  }
  return { year: '', month: '', day: hideDayInput ? '01' : '' };
};

const shouldFocusOntoNext = (value: string, max: number) => {
  const parsedValue = parseInt(value, 10);

  return value.length >= max.toString().length || parsedValue >= max || parsedValue * 10 > max;
};

const isPermittedNumber = (value: string, max: number) =>
  !value || (/[0-9]*$/.test(value) && parseInt(value, 10) <= max);

const DateInput = ({
  className,
  children,
  onChange,
  id = 'birthdate',
  defaultValue,
  error,
  onBlur,
  'data-testid': testId = 'date-input',
  dayLabel,
  monthLabel,
  yearLabel,
  hideDayInput,
  ...props
}: DateInputProps) => {
  const [{ year, month, day }, setDate] = useState(getInitialState(defaultValue, hideDayInput));

  const content = useTranslatedValues(translations);

  const dayRef = createRef() as React.RefObject<HTMLInputElement>;
  const monthRef = createRef() as React.RefObject<HTMLInputElement>;
  const yearRef = createRef() as React.RefObject<HTMLInputElement>;

  useEffect(() => {
    //Enables blur to continue working when no day element
    if (hideDayInput && dayRef && !dayRef.current) {
      dayRef.current = 'hidden';
    }
  }, [dayRef, hideDayInput]);

  const handleChange = (key: string, max: number, nextRef?: React.RefObject<HTMLInputElement>) => (
    e: React.ChangeEvent,
  ) => {
    const target = e.target as HTMLInputElement;

    if (!isPermittedNumber(target.value, max)) {
      return;
    }

    const shouldFocusNext = shouldFocusOntoNext(target.value, max);
    const nextValue = {
      year,
      month,
      day,
      [key]: shouldFocusNext ? target.value.padStart(2, '0') : target.value,
    };

    if (nextRef?.current && shouldFocusNext) {
      nextRef.current.focus();
    }

    const isValid = isValidDate(nextValue);
    const value =
      nextValue.day.length === 0 && nextValue.month.length === 0 && nextValue.year.length === 0
        ? ''
        : `${nextValue.year}-${nextValue.month}-${nextValue.day}`;

    setDate(nextValue);
    onChange({
      name: id,
      value,
      valid: isValid,
    });
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (
      e.relatedTarget !== dayRef.current &&
      e.relatedTarget !== monthRef.current &&
      e.relatedTarget !== yearRef.current
    ) {
      onBlur && onBlur(e);
    }
  };

  const handleInputBlur = (
    e: React.FocusEvent<HTMLInputElement>,
    key: string,
    prependBelow: Number,
  ) => {
    const parsed = parseInt(e.target.value);
    if (parsed < prependBelow) {
      e.target.value = `0${parsed}`;
      setDate({ day, month, year, [key]: e.target.value });
    }
  };

  return (
    <div
      {...props}
      data-testid={testId}
      className={clsx('flex flex-row space-x-6', className)}
      onBlur={handleBlur}
    >
      {!hideDayInput && (
        <Input
          inputMode="numeric"
          type="number"
          blockDecimal={true}
          blockNegative={true}
          name="day"
          ref={dayRef}
          onChange={handleChange('day', 31, monthRef)}
          onBlur={e => handleInputBlur(e, 'day', 4)}
          maxLength={2}
          className="w-16 text-center"
          value={day}
          aria-invalid={error}
          data-testid={`${testId}-day`}
          aria-label={dayLabel}
          placeholder={content.dayPlaceholder}
        />
      )}
      <Input
        inputMode="numeric"
        type="number"
        blockDecimal={true}
        blockNegative={true}
        name="month"
        ref={monthRef}
        maxLength={2}
        onChange={handleChange('month', 12, yearRef)}
        onBlur={e => handleInputBlur(e, 'month', 2)}
        className="w-16 text-center"
        value={month}
        aria-invalid={error}
        data-testid={`${testId}-month`}
        aria-label={monthLabel}
        placeholder={content.monthPlaceholder}
      />
      <Input
        inputMode="numeric"
        type="number"
        blockDecimal={true}
        blockNegative={true}
        name="year"
        ref={yearRef}
        value={year}
        maxLength={4}
        className="w-20 text-center"
        onChange={handleChange('year', 9999)}
        data-testid={`${testId}-year`}
        aria-invalid={error}
        aria-label={yearLabel}
        placeholder={content.yearPlaceholder}
      />
    </div>
  );
};

export default DateInput;

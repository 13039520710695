import React from 'react';
import clsx from 'clsx';
import { CheckIcon } from '@heroicons/react/solid';

interface Props
  extends Omit<
    React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
    'type'
  > {
  color?: 'black' | 'grey' | 'blue';
  borderClassName?: string;
}

const Checkbox = ({ children, borderClassName, className, color = 'black', ...props }: Props) => (
  <div className={clsx(className, 'flex')}>
    {/* Using min-w below instead of just width and height to prevent the input from ever squashing in a small container */}
    <div
      className={clsx(
        'w-5 h-5 flex-shrink-0 relative border-solid border box-border bg-white',
        color === 'grey' || color === 'blue' ? 'border-grey-3' : 'border-black',
        { 'bg-blue-3 border-blue-3': color === 'blue' && props.checked },
        borderClassName,
      )}
    >
      <input {...props} type="checkbox" className="opacity-0 absolute top-0 left-0 w-4 h-4 peer" />
      <CheckIcon
        className={clsx(
          'w-4 h-4 absolute top-[1px] left-[1px] object-scale-down pointer-events-none invisible peer-checked:visible',
          { 'text-white': color === 'blue' },
        )}
      />
    </div>
    {children}
  </div>
);

export default Checkbox;

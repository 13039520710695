import { TFunction } from 'react-i18next';

import { cmsLocation as YourSalaryLocation } from '../../income/YourSalary/content';
export { useFrequencyOptions } from '../../income/YourSalary/content';

export const useContent = (t: TFunction<'translation'>) => ({
  currency: {
    label: t(`${YourSalaryLocation}.currency.label`),
    placeholder: t(`${YourSalaryLocation}.currency.placeholder`),
  },
  frequency: {
    label: t(`${YourSalaryLocation}.frequency.label`),
    placeholder: t(`${YourSalaryLocation}.frequency.placeholder`),
    option_daily: t(`${YourSalaryLocation}.frequency.option_daily`),
    option_weekly: t(`${YourSalaryLocation}.frequency.option_weekly`),
    option_fortnightly: t(`${YourSalaryLocation}.frequency.option_fortnightly`),
    option_monthly: t(`${YourSalaryLocation}.frequency.option_monthly`),
  },
  paymentDate: {
    dayInputLabel: t(`${YourSalaryLocation}.lastPaymentDate.dayInputLabel`),
    monthInputLabel: t(`${YourSalaryLocation}.lastPaymentDate.monthInputLabel`),
    yearInputLabel: t(`${YourSalaryLocation}.lastPaymentDate.yearInputLabel`),
  },
});

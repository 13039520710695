import { useTranslatedValues } from 'hooks/useTranslatedValues';

interface ProgressProps {
  progress: number;
  wasSubmitted: boolean;
  canSubmit: boolean;
  hasRemedialSections: boolean;
}

const translations = {
  progress: '/dashboard.progress',
  progress_remedial: '/dashboard.progress_remedial',
  progress_completed: '/dashboard.progress_completed',
  progress_submitted: '/dashboard.progress_submitted',
} as const;

const Progress = ({ progress, wasSubmitted, canSubmit, hasRemedialSections }: ProgressProps) => {
  const content = useTranslatedValues(translations);

  return (
    <div data-testid="progress" className="w-full">
      <div className="flex flex-row justify-between font-semibold text-sm">
        <p>
          {wasSubmitted
            ? content.progress_submitted
            : progress === 100 && canSubmit
            ? content.progress_completed
            : hasRemedialSections
            ? content.progress_remedial
            : content.progress}
        </p>
        <p>{progress}%</p>
      </div>
      <div className="mt-2 w-full h-0.5 bg-pink-2 bg-opacity-30">
        <div
          data-testid="progress-bar"
          className="bg-pink-2  h-full z-10 transition-width duration-1000"
          style={{ width: `${progress}%` }}
        />
      </div>
    </div>
  );
};

export default Progress;

import {
  FetchAccessToken,
  HandleError,
  HandleFetch,
  HandleResponse,
  IsTokenValidOrUndefined,
  TokenRefreshLink,
} from 'apollo-link-token-refresh';
import AuthenticationService from 'authentication/AuthenticationService';
import axios, { AxiosResponse } from 'axios';
import { parseUtc } from 'services/dateService';
import { getEnv } from 'services/environmentVariables';

export const ACCESS_TOKEN_FIELD = 'access';

export type RefreshData = {
  access: string;
  access_exp_time: string; // UTC Timestamp
};

const CleanAxiosInstance = axios.create({
  withCredentials: true,
});

export const fetchAccessToken: FetchAccessToken = () => {
  const tokenRefreshUri = getEnv('TOKEN_REFRESH_URI');

  if (tokenRefreshUri === undefined) {
    throw new Error('Token refresh URI environment variable is not present');
  }

  return CleanAxiosInstance.get(tokenRefreshUri);
};

export const handleFetch: HandleFetch<RefreshData> = ({ access, access_exp_time }) => {
  AuthenticationService.applyNewAccessToken({
    accessToken: access,
    expiresAt: parseUtc(access_exp_time),
  });
};

export const isTokenValidOrUndefined: IsTokenValidOrUndefined = () =>
  AuthenticationService.tokenIsValidOrUndefined();
export const handleResponse: HandleResponse = () => ({ data }: AxiosResponse<RefreshData>) => ({
  access: data,
});
export const handleError: HandleError = () => AuthenticationService.logout();

export const REFRESH_LINK_OPTIONS: TokenRefreshLink.Options<RefreshData> = {
  accessTokenField: ACCESS_TOKEN_FIELD,
  fetchAccessToken,
  isTokenValidOrUndefined,
  handleError,
  handleFetch,
  handleResponse,
};

const createRefreshLink = () => new TokenRefreshLink<RefreshData>(REFRESH_LINK_OPTIONS);

export default createRefreshLink;

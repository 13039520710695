import React from 'react';
import clsx from 'clsx';

interface Props
  extends React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {
  className?: string;
  large?: boolean;
}

const Textarea = React.forwardRef<HTMLTextAreaElement, Props>(
  ({ className, large, ...props }, ref) => {
    return (
      <textarea
        ref={ref}
        className={clsx(
          'border-grey-3 text-black disabled:border-grey-3',
          large ? 'w-full' : 'w-64',
          'block box-border bg-white text-base leading-4 placeholder-grey-2 p-4 disabled:opacity-50 border rounded',
          'hover:border-blue-4 hover:text-black',
          'focus:outline-none focus:ring-2 focus:ring-light-blue-3 focus:border-light-blue-3 focus:text-black',
          'bg-clip-padding spin-button-none',
          className,
        )}
        {...props}
      />
    );
  },
);

export default Textarea;

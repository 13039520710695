import { differenceInMonths } from 'date-fns';
import { ResidentialHistoryType } from 'generated/graphql';

export const ResidentialHistoryThreeMonthFilter = (v: ResidentialHistoryType) => {
  const threeMonthsAgo = new Date(new Date().setMonth(new Date().getMonth() - 3));
  const diff = differenceInMonths(new Date(v.end), threeMonthsAgo);

  return v.isCurrent || (diff >= 0 && diff <= 3);
};

export default ResidentialHistoryThreeMonthFilter;

import useInitialData from 'api/data/initial-data/useInitialData';
import { ProgressIndicator } from 'components';
import { WizardSectionType } from 'generated/graphql';
import { useTranslatedValues } from 'hooks/useTranslatedValues';

const translations = {
  [WizardSectionType.Identity]: '/questions.sections.identity',
  [WizardSectionType.Income]: '/questions.sections.income',
  [WizardSectionType.Residence]: '/questions.sections.residence',
  informationRequest: '/questions.sections.informationRequest',
} as const;

const SectionIndicator = ({
  selectedValue,
  isRemedialSection,
}: {
  selectedValue: string;
  isRemedialSection?: boolean;
}) => {
  const initialData = useInitialData();
  const content = useTranslatedValues(translations);

  let options = [];

  if (isRemedialSection) {
    options = [
      {
        value: selectedValue,
        label: content.informationRequest,
      },
    ];
  } else {
    const sections = initialData.me.latestValidation?.sections || [];

    options = sections.map(section => ({
      value: section.name as string,
      label: content[section.name as WizardSectionType],
    }));
  }

  return (
    <ProgressIndicator className="mt-2 md:mt-8" options={options} selectedValue={selectedValue} />
  );
};

export default SectionIndicator;

import { useTranslation, TFunction } from 'react-i18next';
import { CurrencyTypesEnum } from 'generated/graphql';

const cmsLocation = 'utility';

const useContent = (t: TFunction<'translation'>) =>
  ({
    [CurrencyTypesEnum.Eur]: t(`${cmsLocation}.EUR`),
    [CurrencyTypesEnum.Gbp]: t(`${cmsLocation}.GBP`),
    [CurrencyTypesEnum.Usd]: t(`${cmsLocation}.USD`),
  } as Record<CurrencyTypesEnum, string>);

const useCurrencyCodeOptions = () => {
  const { t } = useTranslation();
  const content = useContent(t);

  return Object.values(CurrencyTypesEnum).map(type => ({
    label: content[type],
    value: type,
  }));
};

export default useCurrencyCodeOptions;

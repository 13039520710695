import {
  NameType,
  ProfileType,
  SectionMetaDataType,
  UserType,
  WizardRemedialSectionType,
  WizardSectionType,
} from 'generated/graphql';
import { DocumentNode } from 'graphql';
import React from 'react';
import { DefaultIdentityValuesType, StepNamesDefaultIdentity } from './flows/defaultIdentity/types';
import {
  DefaultResidenceValuesType,
  StepNamesDefaultResidence,
} from './flows/defaultResidence/types';
import { DefaultIncomeValuesType, StepNamesDefaultIncome } from './flows/defaultIncome/types';
import { StepNamesRTRIdentity } from './flows/rightToRentIdentity/types';

export enum RemedialReasons {
  IRRELEVANT_DOCUMENT_UPLOADED = 'IRRELEVANT_DOCUMENT_UPLOADED',
  IRRELEVANT_DOCUMENT_UPLOADED_PDF = 'IRRELEVANT_DOCUMENT_UPLOADED_PDF',
  EXPIRED_DOCUMENT = 'EXPIRED_DOCUMENT',
  EXPIRED_DOCUMENT_PDF = 'EXPIRED_DOCUMENT_PDF',
  PARTIAL_CROPPED_BLURRY_DOCUMENT = 'PARTIAL_CROPPED_BLURRY_DOCUMENT',
  PARTIAL_CROPPED_BLURRY_DOCUMENT_PDF = 'PARTIAL_CROPPED_BLURRY_DOCUMENT_PDF',
  NO_ADVERSE_META_DATA = 'NO_ADVERSE_META_DATA',
  MISSING_ID = 'MISSING_ID',
  SHARECODE_REQUIRED = 'SHARECODE_REQUIRED',
  MISSING_RTR_DOCUMENT = 'MISSING_RTR_DOCUMENT',
  DOCUMENT_OUT_OF_DATE = 'DOCUMENT_OUT_OF_DATE',
  DOCUMENT_OUT_OF_DATE_PDF = 'DOCUMENT_OUT_OF_DATE_PDF',
  BLACK_AND_WHITE_DOCUMENT = 'BLACK_AND_WHITE_DOCUMENT',
  BLACK_AND_WHITE_DOCUMENT_PDF = 'BLACK_AND_WHITE_DOCUMENT_PDF',
  PROOF_OF_ADDRESS_NOT_CORRECT = 'PROOF_OF_ADDRESS_NOT_CORRECT',
  MISSING_PROOF_OF_ADDRESS = 'MISSING_PROOF_OF_ADDRESS',
  SOURCE_DOES_NOT_SHOW_INCOME = 'SOURCE_DOES_NOT_SHOW_INCOME',
  MISSING_PROOF_OF_INCOME = 'MISSING_PROOF_OF_INCOME',
  MISSING_TENANCY_AGREEMENT = 'MISSING_TENANCY_AGREEMENT',
  INCORRECT_INCOME_REFERENCE = 'INCORRECT_INCOME_REFERENCE',
  INCORRECT_RENTAL_REFERENCE = 'INCORRECT_RENTAL_REFERENCE',
  OTHER = 'OTHER',
}

export enum GeneralSteps {
  missingInformation = 'missingInformation',
  genericUpload = 'genericUpload',
}

export type StepNames =
  | StepNamesDefaultIdentity
  | StepNamesDefaultIncome
  | StepNamesRTRIdentity
  | StepNamesDefaultResidence
  | GeneralSteps;

export type Rule = (value: string, comparator?: any) => boolean;

export enum YesNoEnum {
  YES = 'yes',
  NO = 'no',
}

export interface ValidationRule {
  name: string;
  rule: Rule;
  error: string;
}

export interface QuestionQueryParams {
  uuid: string | null;
  deprecatedIncomeUuid: string | null;
  success: boolean | null;
  remedial_uuid: String | null;
}

export interface Flow<
  MetaDataType,
  FlowValuesType,
  StepNameValues extends string,
  QueryParamsType,
  MutationArgsType
> {
  getQueryParams: (value: string) => QueryParamsType;
  start: (values: UserType, params: any, data: SectionMetaDataType) => StepNameValues;
  uuidKey?: string;
  steps: Record<
    StepNameValues | GeneralSteps,
    Step<StepNameValues, FlowValuesType, MutationArgsType>
  >;
  queryFragment: DocumentNode;
  mutation: DocumentNode;
  handleMutationResponse: (data: any, values: FlowValuesType) => Partial<FlowValuesType>;
  getDefaultValues: (values: UserType, params: any) => FlowValuesType;
  getData: (data: SectionMetaDataType, values: UserType) => MetaDataType;
}

type ValidationLookup<StepNames extends string> = Record<StepNames, ValidationRule[]>;

export interface Step<StepNames extends string, FlowValuesType, MutationArgsType> {
  component: React.ElementType;
  getMutationValues?: (
    values: FlowValuesType,
    remedialSectionUuid?: string,
  ) => Partial<MutationArgsType>;
  validationRules: ValidationLookup<StepNames> | { [key: string]: ValidationRule[] };
  chooseSuccessor?: (params: {
    value: string | number | object | string[];
    values: FlowValuesType;
    data: SectionMetaDataType & { remedialSection?: WizardRemedialSectionType };
  }) => StepNames | undefined;
  setFlowCompleted?: boolean;
}

export interface SubmitOptions {
  preventProgressCompletion?: boolean;
}

export interface SubmitValue {
  key: string;
  value: ValueType;
}

export type OnSubmitType = ({ key, value }: SubmitValue, options?: SubmitOptions) => void;
export interface QuestionComponentProps<MetaDataType, FlowValuesType> {
  data: MetaDataType & { remedialSection: WizardRemedialSectionType };
  values: FlowValuesType;
  onSubmit: OnSubmitType;
  validationRules: { [key: string]: ValidationRule[] };
  index: number;
  dispatch: React.Dispatch<React.SetStateAction<FlowValuesType>>;
  onBack: () => void;
  sectionName: string;
}

export type ValueType = any; //string | number | { [key: string]: ValueType } | ValueType[];

export type ValuesType =
  | DefaultIdentityValuesType
  | DefaultIncomeValuesType
  | DefaultResidenceValuesType;

//----------------------------------------------------------------
// GQL Types
export interface GQLMeValues {
  name: NameType;
  profile: ProfileType;
}
export interface GQLMe extends GQLMeValues {
  uuid: string;
  consent?: {
    marketing: boolean;
  };
  latestValidation: {
    consent?: {
      termsAndConditions: boolean;
      backgroundChecks: boolean;
      dataAccuracy: boolean;
    };
    sections: WizardSectionType[];
  };
}

export interface GQLUserQuery {
  me: GQLMe;
  sectionMetaData: SectionMetaDataType;
}

import {
  DefaultResidenceQuestionComponentProps,
  StepNamesDefaultResidence,
} from 'questionFlow/flows/defaultResidence/types';
import { useTranslation, TFunction } from 'react-i18next';
import { AccountDocumentType } from 'generated/graphql';
import DocumentSignposting from 'questionFlow/genericQuestions/DocumentSignposting';
import { OnSubmitType } from 'questionFlow/types';

const cmsResidenceSignpostingLocation = '/questions/documentSignposting';

const useContent = (t: TFunction<'translation'>) => ({
  title: t(`${cmsResidenceSignpostingLocation}.OTHER.title`),
  subtitle: t(`${cmsResidenceSignpostingLocation}.OTHER.subtitle`),
});

const GenericResidenceDocSignposting = ({
  values,
  data,
  onSubmit,
  ...props
}: DefaultResidenceQuestionComponentProps) => {
  let selectAddress = values?.[StepNamesDefaultResidence.selectAddress];

  if (!selectAddress && data.remedialSection) {
    selectAddress = values[StepNamesDefaultResidence.residentialHistory]?.find(
      ({ uuid }) => uuid === data.remedialSection.relatedObjectUuid,
    );
  }

  const docType = AccountDocumentType.Other;
  const { t } = useTranslation();
  const content = useContent(t);

  const handleSubmit: OnSubmitType = () => {
    const oldDocType = selectAddress?.documents?.[0]?.type;

    onSubmit({
      key: StepNamesDefaultResidence.selectAddress,
      value: {
        ...selectAddress,
        documents:
          oldDocType === docType ? selectAddress?.documents : [{ type: docType, path: null }],
      },
    });
  };

  return (
    <DocumentSignposting
      {...props}
      data={data}
      values={{
        documents: docType,
        upload: selectAddress?.documents,
      }}
      onSubmit={handleSubmit}
      content={content}
      translationValues={{ address: selectAddress?.address?.formattedAddress || '' }}
    />
  );
};

export default GenericResidenceDocSignposting;

type EnvironmentVariableName =
  | 'LOCIZE_PROJECT_ID'
  | 'LOCIZE_API_KEY'
  | 'PUBLIC_VERSION'
  | 'GRAPHQL_URI'
  | 'OTL_REQUEST_URI'
  | 'TOKEN_REFRESH_URI'
  | 'SEGMENT_KEY'
  | 'VERIFY_DOCUMENT_URL_URI'
  | 'UPLOAD_PHOTO_URL_URI'
  | 'CHECK_DOCUMENT_URL_URI';

const getFallback = (name: string, value: string | undefined) => {
  if (process.env['NODE_ENV'] !== 'development' || value) {
    return value;
  }
  switch (name) {
    case 'GRAPHQL_URI':
      return 'http://localhost:8000/api/graphql/';
    case 'OTL_REQUEST_URI':
      return 'http://localhost:8000/api/otl-request/';
    case 'TOKEN_REFRESH_URI':
      return 'http://localhost:8000/api/token/refresh';
  }
};

export const getEnv = (name: EnvironmentVariableName) =>
  getFallback(name, process.env[`REACT_APP_${name}`]);

export const TERMS_AND_CONDITIONS_URL = 'https://www.homeppl.com/terms_and_conditions/';
export const PRIVACY_POLICY_URL = 'https://www.homeppl.com/privacy_policy/';

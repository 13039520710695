import CenteredCard from 'components/templates/CenteredCard';
import { Trans, useTranslation } from 'react-i18next';
import BaseOtlPage from '../BaseOtlPage';
import Mail from './mail.svg';

interface Props {
  email: string;
}

const SentMailPage = ({ email }: Props) => {
  const { t } = useTranslation();

  return (
    <BaseOtlPage>
      <CenteredCard.Body className="flex-auto flex flex-col justify-start items-center text-center">
        <img
          className="max-w-[280px] m-6 self-center"
          height={186}
          src={Mail}
          alt={t('sentmail.image_description') as string}
        />

        <p className="font-semibold text-2xl leading-8 md:text-[32px] md:leading-10">
          {t('sentmail.title')}
        </p>

        <p
          className="p-6 m-6 md:mb-2 max-w-[391px] text-base leading-6 bg-grey-4"
          data-testid="notification-with-email"
        >
          <Trans
            i18nKey="sentmail.description"
            values={{ email }}
            components={{ bold: <span className="font-semibold" /> }}
          />
        </p>

        <p className="mx-6 mb-6 text-base leading-6">
          <Trans
            i18nKey="sentmail.expires"
            components={{
              nowrap: <span className="whitespace-nowrap" />,
            }}
          />
        </p>

        <p className="font-semibold text-base leading-6 md:text-2xl md:leading-8">
          {t('sentmail.call_to_action')}
        </p>
      </CenteredCard.Body>
    </BaseOtlPage>
  );
};

export default SentMailPage;

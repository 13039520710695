import EmailValidator from 'email-validator';
import validationRules from './validationRules';
import { ValidationRule } from '../types';
import { isAfter, isBefore, isSameDay, differenceInYears } from 'date-fns';

export const NAME_LENGTH = Object.freeze<ValidationRule>({
  name: 'length',
  rule: v => v.length > 1 && v.length < 65,
  error: 'validation.name.length',
});

export const OPTIONAL_NAME_LENGTH = Object.freeze<ValidationRule>({
  name: 'length',
  rule: v => !v || (v.length > 1 && v.length < 65),
  error: 'validation.name.length',
});

export const NAME_PATTERN = Object.freeze<ValidationRule>({
  name: 'pattern',
  rule: v => validationRules.unicodePattern(v),
  error: 'validation.name.pattern',
});

export const EMAIL_FORMAT = Object.freeze<ValidationRule>({
  name: 'email-format',
  rule: EmailValidator.validate,
  error: 'validation.email.format',
});

export const OPTIONAL_NAME_PATTERN = Object.freeze<ValidationRule>({
  name: 'pattern',
  rule: v => !v || validationRules.unicodePattern(v),
  error: 'validation.name.pattern',
});

export const FIELD_REQUIRED = Object.freeze<ValidationRule>({
  name: 'required',
  rule: validationRules.required,
  error: 'validation.required',
});

export const DATE_REQUIRED = Object.freeze<ValidationRule>({
  ...FIELD_REQUIRED,
  rule: v => validationRules.required(v?.replace(/-/g, '')),
});

export const OPTION_REQUIRED = Object.freeze<ValidationRule>({
  ...FIELD_REQUIRED,
  error: 'validation.please_select_an_option',
});

export const DATE_FORMAT = Object.freeze<ValidationRule>({
  name: 'pattern',
  rule: v => /^[1-9]{1}[0-9]{3}-[0-9]{2}-[0-9]{2}$/.test(v),
  error: 'validation.birthdate.invalid',
});

export const DATE_VALID = Object.freeze<ValidationRule>({
  name: 'valid',
  rule: (_, valid) => !!valid,
  error: 'validation.birthdate.invalid',
});

export const OPTIONAL_DATE_FORMAT = Object.freeze<ValidationRule>({
  name: 'pattern',
  rule: v => !v || /^[1-9]{1}[0-9]{3}-[0-9]{2}-[0-9]{2}$/.test(v),
  error: 'validation.birthdate.invalid',
});

export const OPTIONAL_DATE_VALID = Object.freeze<ValidationRule>({
  name: 'valid',
  rule: (v, valid) => !v || !!valid,
  error: 'validation.birthdate.invalid',
});

export const PHONE_NUMBER_VALID = Object.freeze<ValidationRule>({
  name: 'valid',
  rule: v => /^\+{1}\d{9,15}$/.test(v),
  error: 'validation.phone_number',
});

export const UNDER_18_INVALID = Object.freeze<ValidationRule>({
  name: 'under18',
  rule: v => differenceInYears(new Date(), new Date(v)) >= 18,
  error: 'validation.birthdate.under18',
});

export const createNumberRangeValidationRule = (
  min: number,
  max: number,
  error: string,
): ValidationRule => ({
  error,
  name: 'number-in-range',
  rule: v => {
    const value = parseFloat(v);

    return !isNaN(value) && value > min && value < max;
  },
});

export const createNoFutureDateRule = (error: string): ValidationRule => ({
  error,
  name: 'not-future',
  rule: v => {
    const today = new Date();
    const asDate = new Date(v);

    return isBefore(asDate, today) || isSameDay(today, asDate);
  },
});

export const createNotBeforeDateRule = (
  minimumDate: Date,
  error: string,
  optional: boolean = false,
): ValidationRule => ({
  error,
  name: 'not-past',
  rule: v => {
    if (!v) {
      return optional;
    }

    const asDate = new Date(v);

    return isAfter(asDate, minimumDate) || isSameDay(minimumDate, asDate);
  },
});

export const createNotAfterDateRule = (maximumDate: Date, error: string): ValidationRule => ({
  error,
  name: 'not-after',
  rule: v => {
    const asDate = new Date(v);

    return isBefore(asDate, maximumDate) || isSameDay(maximumDate, asDate);
  },
});

export const COMPANY_NUMBER_LENGTH = Object.freeze<ValidationRule>({
  name: 'length',
  rule: v => validationRules.lte(15)(v.length),
  error: 'validation.company_number.invalid',
});

export const COMPANY_NUMBER_PATTERN = Object.freeze<ValidationRule>({
  name: 'pattern',
  rule: v => validationRules.alphaNumericPattern(v),
  error: 'validation.company_number.invalid',
});

export const COMPANY_NAME_PATTERN = Object.freeze<ValidationRule>({
  name: 'pattern',
  rule: v => /^[a-zA-Z0-9 &*()-+']*$/.test(v),
  error: 'validation.name.pattern',
});

export const baseAndLowerEmail = (address: string) => {
  return address.toLowerCase().replace(/\+.+@/, '@');
};

export const ALLOWED_OPTION = Object.freeze<ValidationRule>({
  name: 'allowed_option',
  rule: (value, options) => (options as string[]).includes(value),
  error: 'validation.required',
});

export const SHARE_CODE = Object.freeze<ValidationRule>({
  name: 'share_code',
  rule: v => /^[0-9A-z]{3}[\s-]?[0-9A-z]{3}[\s-]?[0-9A-z]{3}$/.test(v),
  error: '/questions/identity/shareCode.error',
});

const allowed = ['UK', 'GB', 'United Kingdom'];
export const POSTCODE = Object.freeze<ValidationRule>({
  name: 'postcode/zipcode',
  rule: (v, values) =>
    allowed.includes(values.country)
      ? validationRules.ukPostcode(v)
      : validationRules.genericPostZipCode(v),
  error: '/questions/residence/residentialHistory.validation.postcode',
});

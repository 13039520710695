import { onError } from '@apollo/client/link/error';
import * as Sentry from '@sentry/react';

const networkErrorsToIgnore = [401];

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(error => {
      Sentry.captureException(new Error(error.message), {
        tags: { category: 'graphql', gqlPath: error.path?.toString() },
        extra: { error },
      });
    });

  if (networkError && !networkErrorsToIgnore.includes(networkError?.statusCode)) {
    Sentry.captureException(networkError, { tags: { category: 'graphql' } });
  }
});

export default errorLink;

import { getClient } from 'api/apolloClient';
import { isBefore, subSeconds } from 'date-fns';
import axios from 'axios';
import { now, parseUtc } from 'services/dateService';
import { getEnv } from 'services/environmentVariables';

type NewAccessToken = {
  accessToken: string;
  expiresAt: Date;
};

// Will only ever move to one of these states
type StableAuthenticationState = 'logged-in' | 'logged-out';
// However the set of possible states includes pending as the initial value (will never be transitioned to)
export type AuthenticationState = 'pending' | StableAuthenticationState;

export type OnAuthenticationChanged = (authenticationState: StableAuthenticationState) => void;
type Unsubscribe = () => void;

// Consider this many seconds before expiry time to be an expired token to allow for variations in system times
export const EXPIRY_THRESHOLD_SECONDS = 45;

const AxiosInstance = axios.create({
  withCredentials: true,
});
export class AuthenticationServiceImpl {
  private _accessToken: string | null = null;
  private expiryTime: Date | null = null;

  private readonly authenticationCallbacks: Set<OnAuthenticationChanged> = new Set();
  private authenticationState: AuthenticationState = 'pending';

  get accessToken() {
    return this._accessToken;
  }

  getAuthenticationState() {
    return this.authenticationState;
  }

  private setAuthenticationState(newState: StableAuthenticationState) {
    if (newState !== this.authenticationState) {
      this.authenticationState = newState;

      this.authenticationCallbacks.forEach(callback => {
        callback(newState);
      });
    }
  }

  applyNewAccessToken({ accessToken, expiresAt }: NewAccessToken) {
    this._accessToken = accessToken;
    this.expiryTime = subSeconds(expiresAt, EXPIRY_THRESHOLD_SECONDS);
    this.setAuthenticationState('logged-in');
  }

  tokenIsValidOrUndefined() {
    const accessToken = this.accessToken;

    if (accessToken !== null) {
      const expiryTime = this.expiryTime;

      if (expiryTime !== null) {
        return isBefore(now(), expiryTime);
      }
    }

    return false;
  }

  async refreshToken() {
    if (this.getAuthenticationState() === 'logged-in' && !this.tokenIsValidOrUndefined()) {
      const tokenRefreshUri = getEnv('TOKEN_REFRESH_URI');

      if (tokenRefreshUri === undefined) {
        throw new Error('Token refresh URI environment variable is not present');
      }

      try {
        const { data } = await AxiosInstance.get(tokenRefreshUri);
        this.applyNewAccessToken({
          accessToken: data.access,
          expiresAt: parseUtc(data.access_exp_time),
        });
        return data.access;
      } catch (e) {
        this.logout();
      }
    }
  }

  logout() {
    this.setAuthenticationState('logged-out');
    this._accessToken = null;
    this.expiryTime = null;
    const client = getClient();
    client.stop();
    client.clearStore();
  }

  registerAuthenticationListener(callback: OnAuthenticationChanged): Unsubscribe {
    this.authenticationCallbacks.add(callback);

    return () => {
      this.authenticationCallbacks.delete(callback);
    };
  }
}

export default new AuthenticationServiceImpl();

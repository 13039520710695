import React from 'react';
import { Trans } from 'react-i18next';
import clsx from 'clsx';

export interface Props {
  className?: string;
  children?: React.ReactNode;
}

const OutlineNumberedBullet = ({
  number,
  contentKey,
  className,
}: {
  number: string;
  contentKey: string;
  className?: string;
}) => {
  return (
    <div
      data-testid="outline-numbered-bullet"
      className={clsx('flex flex-row space-x-4', className)}
    >
      <p
        className="text-3xl"
        style={{
          textShadow: '-1px 0 #092ff4, 0 1px #092ff4, 1px 0 #092ff4, 0 -1px #092ff4',
          color: 'white',
        }}
      >
        {number}
      </p>
      <div>
        <Trans i18nKey={contentKey as any} components={{ b: <span className="font-semibold" /> }} />
      </div>
    </div>
  );
};

export default OutlineNumberedBullet;

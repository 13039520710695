import QuestionOrganism from 'questionFlow/QuestionOrganism';
import { TFunction, useTranslation, Trans } from 'react-i18next';
import {
  DefaultIncomeQuestionComponentProps,
  StepNamesDefaultIncome,
} from 'questionFlow/flows/defaultIncome/types';
import { LinkButton, TextButton } from 'components';
import { QuestionRoutes } from 'routing/routes';
const cmsLocation = '/questions/income/additionalIncome';

const useContent = (t: TFunction<'translation'>) => ({
  title: t(`${cmsLocation}.title`),
  subtitle: t(`${cmsLocation}.subtitle`),
  buttonText: t(`${cmsLocation}.buttonText`),
  linkText: t(`${cmsLocation}.linkText`),
});

const stepName = StepNamesDefaultIncome.additionalIncome;

const subtitle = (
  <Trans
    i18nKey={`${cmsLocation}.subtitle`}
    components={{
      p: <p className="mt-4 mb-4" />,
    }}
  />
);

const AdditionalIncome = ({
  data,
  onSubmit,
  values: initialValues,
  validationRules: validationRulesGeneric,
  ...props
}: DefaultIncomeQuestionComponentProps) => {
  const { t } = useTranslation();
  const content = useContent(t);

  const handleSubmit = () => {
    onSubmit({ key: stepName, value: 'acknowledged' });
  };

  return (
    <QuestionOrganism
      id={stepName}
      data-testid={stepName}
      onSubmit={() => {}}
      title={content.title}
      subtitle={subtitle}
      isFooterHidden
      {...props}
    >
      <LinkButton href={`${QuestionRoutes.INCOME}/add`}>{content.buttonText}</LinkButton>
      <TextButton
        onClick={handleSubmit}
        className="underline text-blue-3 mt-10 block max-w-max text-base"
      >
        {content.linkText}
      </TextButton>
    </QuestionOrganism>
  );
};

export default AdditionalIncome;

import clsx from 'clsx';

interface Props {
  stepsCount: number;
  crtStep: number;
}

const OnboardingSteps: React.FC<Props> = ({ stepsCount, crtStep }) => {
  return (
    <nav className="flex items-center justify-center" aria-label="Progress">
      <ol className="flex items-center space-x-5">
        {Array.from(Array(stepsCount).keys()).map(step => (
          <li key={step}>
            <span className="relative flex items-center justify-center">
              {step === crtStep ? (
                <span className="absolute w-5 h-5 p-px flex" aria-hidden="true">
                  <span className="w-full min-h-full rounded-full bg-indigo-200"></span>
                </span>
              ) : null}
              <span
                className={clsx(
                  'relative block w-2 h-2 rounded-full',
                  step <= crtStep ? 'bg-blue-3' : 'bg-gray-200',
                )}
              >
                <span className="sr-only">Step {step + 1}</span>
              </span>
            </span>
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default OnboardingSteps;

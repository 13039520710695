import AuthenticationService from '../authentication/AuthenticationService';

const Logout = () => {
  AuthenticationService.logout();
  window.location.href = process.env.REACT_APP_TOKEN_LOGOUT as string;

  return null;
};

export default Logout;

import { hasDocumentSignposting } from 'questionFlow/genericQuestions/Upload/utils';
import mockDefinitions from '__mocks__/mock-document-definitions-map.json';

export const docHasMultipleSides = (docType?: string | null): boolean => {
  if (!docType || docType === 'none') {
    return false;
  }
  const definition = mockDefinitions.find(d => d.docType === docType);
  if (!definition) throw new Error(`No document definition for ${docType}`);

  return definition.sides > 1 && hasDocumentSignposting(docType);
};

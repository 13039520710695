interface Props {
  description: string;
  documents: {
    name: string;
    note?: string;
    extra?: string;
    Icon: any;
  }[];
}

const DocumentsList: React.FC<Props> = ({ documents, description }) => {
  return (
    <div className="flex flex-col space-y-4 self-start">
      <p className="text-center font-medium mb-2">{description}</p>

      {documents.map(({ name, note, extra, Icon }) => (
        <div className="flex space-x-4 items-center" key={name}>
          <div className="w-10 h-10 bg-blue-3 p-2">
            <Icon className={'text-white object-scale-down pointer-events-none'} />
          </div>
          <p className="text-sm">
            <span className="font-semibold">{name}</span>
            {note ? <span className="font-medium text-gray-500 ml-1">({note})</span> : null}
            {extra ? <span className="font-semibold ml-1">{extra}</span> : null}
          </p>
        </div>
      ))}
    </div>
  );
};

export default DocumentsList;

import { ValidationRule } from '../../types';
import { useTranslation } from 'react-i18next';
import QuestionOrganism from '../../QuestionOrganism';
import { useMemo } from 'react';
import { Field, Input, DropDown } from 'components';
import { useContent } from './content';
import { baseAndLowerEmail } from '../../validations/index';
import {
  DefaultResidenceQuestionComponentProps,
  StepNamesDefaultResidence,
} from 'questionFlow/flows/defaultResidence/types';
import { pick } from 'lodash';
import { useAnalyticsErrors, useAnalyticsValues } from 'monitoring/analyticsHooks';
import { useRentTypeOptions } from '../useRentTypeOptions';
import { checkIfTenancyAgreementStepRequired } from 'questionFlow/flows/defaultResidence/DefaultResidence';

export interface LandlordDetailsInterface {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  type: string;
}

const defaultState = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  type: '',
};
type LandlordDetailsFields = keyof typeof defaultState;

const cmsLocation = '/questions/residence/landlord-details';

const validate = (v: string, rules: ValidationRule[], valid?: boolean) => {
  return rules.find(({ rule }) => !rule(v, valid));
};

const name = StepNamesDefaultResidence.landlordDetails;

const YourLandlordDetailsQuestion = ({
  data,
  values: initialValues,
  onSubmit,
  validationRules,
  ...props
}: DefaultResidenceQuestionComponentProps) => {
  const { t } = useTranslation();
  const content = useContent(t);

  const typeOptions = useRentTypeOptions();

  let selectAddress = initialValues[StepNamesDefaultResidence.selectAddress];
  if (!selectAddress && data.remedialSection) {
    selectAddress = initialValues[StepNamesDefaultResidence.residentialHistory]?.find(
      ({ uuid }) => uuid === data.remedialSection.relatedObjectUuid,
    );
  }

  const [values, setValues] = useAnalyticsValues<LandlordDetailsInterface>({
    ...defaultState,
    ...pick(selectAddress?.reference || {}, ['firstName', 'lastName', 'email', 'phone', 'type']),
  });
  const [error, setError] = useAnalyticsErrors<Record<keyof LandlordDetailsInterface, string>>(
    defaultState,
  );

  const PERSONAL_EMAIL_RULE = useMemo(
    () =>
      Object.freeze<ValidationRule>({
        name: 'personal-email',
        rule: v => !v || baseAndLowerEmail(v) !== baseAndLowerEmail(data.email),
        error: `${cmsLocation}.validation.personalEmailMatch`,
      }),
    [data.email],
  );

  const getValidationResult = (name: LandlordDetailsFields, givenValue?: string) => {
    const stringValue = givenValue === undefined ? values[name] : givenValue;

    const result = validate(stringValue, validationRules[name]);
    if (!result && name === 'email') {
      // Run an additional check that the email doesn't match the user's email address
      return validate(stringValue, [PERSONAL_EMAIL_RULE]);
    }

    return result;
  };

  const handleSubmit = () => {
    const errors = (Object.keys(values) as LandlordDetailsFields[]).reduce((e, inputName) => {
      const result = getValidationResult(inputName);

      return { ...e, [inputName]: result ? t(result.error as any) : '' };
    }, error);

    const isTenancyAgreementRequired = checkIfTenancyAgreementStepRequired(initialValues);

    const documents = initialValues['select-address']?.documents || [];

    if (Object.values(errors).filter(e => e !== '').length === 0) {
      const rentalDetails = {
        ...selectAddress,
        reference: values,
        documents:
          isTenancyAgreementRequired && !documents.find(v => v?.type === 'TENANCY_AGREEMENT')
            ? [...documents, { type: 'TENANCY_AGREEMENT', path: null }]
            : documents,
      };

      onSubmit({ key: StepNamesDefaultResidence.selectAddress, value: rentalDetails });
    } else {
      setError(errors);
    }
  };

  const validateAndSetError = (name: LandlordDetailsFields, newValue: string) => {
    const result = getValidationResult(name, newValue);
    setError({ ...error, [name]: result ? t(result.error as any) : '' });
  };

  const onStringValueChange = (name: LandlordDetailsFields, value: string) => {
    if (error[name]) {
      validateAndSetError(name, value);
    }

    setValues({ ...values, [name]: value });
  };

  const handleInputOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onStringValueChange(e.target.name as LandlordDetailsFields, e.target.value.trim());
  };

  const handlenDropdownChange = (name: LandlordDetailsFields, optionValue: string) =>
    onStringValueChange(name, optionValue);

  const handleInputOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    validateAndSetError(e.target.name as LandlordDetailsFields, e.target.value.trim());
  };

  return (
    <QuestionOrganism
      id={name}
      data-testid={name}
      onSubmit={handleSubmit}
      title={content.title}
      subtitle={content.subtitle}
      {...props}
    >
      <div className="grid w-max md:grid-cols-2 gap-x-10 gap-y-4 pb-60">
        <Field
          data-testid={`${name}-firstName`}
          label={content.firstName.label}
          validationError={error.firstName}
        >
          <Input
            id={`${name}-firstName`}
            name={'firstName'}
            defaultValue={values.firstName}
            onChange={handleInputOnChange}
            aria-invalid={!!error.firstName}
            onBlur={handleInputOnBlur}
            placeholder={content.firstName.placeholder}
          />
        </Field>
        <Field
          data-testid={`${name}-lastName`}
          label={content.lastName.label}
          validationError={error.lastName}
        >
          <Input
            id={`${name}-lastName`}
            name={'lastName'}
            defaultValue={values.lastName}
            onChange={handleInputOnChange}
            aria-invalid={!!error.lastName}
            onBlur={handleInputOnBlur}
            placeholder={content.lastName.placeholder}
          />
        </Field>
        <Field
          data-testid={`${name}-email`}
          label={content.email.label}
          validationError={error.email}
        >
          <Input
            id={`${name}-email`}
            name={'email'}
            defaultValue={values.email}
            onChange={handleInputOnChange}
            aria-invalid={!!error.email}
            onBlur={handleInputOnBlur}
            placeholder={content.email.placeholder}
          />
        </Field>
        <Field
          data-testid={`${name}-phone`}
          label={content.phoneNumber.label}
          validationError={error.phone}
        >
          <Input
            id={`${name}-phone`}
            name={'phone'}
            defaultValue={values.phone}
            onChange={handleInputOnChange}
            aria-invalid={!!error.phone}
            onBlur={handleInputOnBlur}
            placeholder={content.phoneNumber.placeholder}
            type="tel"
          />
        </Field>
        <Field data-testid={`${name}-type`} label={content.type.label} validationError={error.type}>
          <DropDown
            data-testid={`${name}-type`}
            placeholder={content.type.placeholder}
            options={typeOptions}
            onSelectValue={value => handlenDropdownChange('type', value)}
            value={values.type}
            error={!!error.type}
            label={content.type.label}
          />
        </Field>

        <div className="hidden xl:block" />
      </div>
    </QuestionOrganism>
  );
};

export default YourLandlordDetailsQuestion;

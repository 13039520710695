import QuestionOrganism from 'questionFlow/QuestionOrganism';
import { TFunction, useTranslation, Trans } from 'react-i18next';
import {
  DefaultIncomeQuestionComponentProps,
  StepNamesDefaultIncome,
} from 'questionFlow/flows/defaultIncome/types';
import { ExclamationCircleIcon } from '@heroicons/react/solid';
import { RemedialReasons } from 'questionFlow/types';
import { WizardSectionType } from 'generated/graphql';
import React, { useCallback } from 'react';
import * as Sentry from '@sentry/react';

const cmsLocation = '/questions/missingInformation';

const useContent = (t: TFunction<'translation'>) => ({
  title: t(`${cmsLocation}.title`),
  title_reference: t(`${cmsLocation}.title_reference`),
  comments: t(`${cmsLocation}.comments`),
  [WizardSectionType.Identity]: t(`/questions.sections.identity`),
  [WizardSectionType.Income]: t(`/questions.sections.income`),
  [WizardSectionType.Residence]: t(`/questions.sections.residence`),
  subtitle: t(`${cmsLocation}.subtitle`),
  continue: t(`${cmsLocation}.continue`),
  [RemedialReasons.IRRELEVANT_DOCUMENT_UPLOADED]: {
    title: t(`${cmsLocation}.reasons.IRRELEVANT_DOCUMENT_UPLOADED.title`),
    description: t(`${cmsLocation}.reasons.IRRELEVANT_DOCUMENT_UPLOADED.description`),
  },
  [RemedialReasons.IRRELEVANT_DOCUMENT_UPLOADED_PDF]: {
    title: t(`${cmsLocation}.reasons.IRRELEVANT_DOCUMENT_UPLOADED_PDF.title`),
    description: t(`${cmsLocation}.reasons.IRRELEVANT_DOCUMENT_UPLOADED_PDF.description`),
  },
  [RemedialReasons.EXPIRED_DOCUMENT]: {
    title: t(`${cmsLocation}.reasons.EXPIRED_DOCUMENT.title`),
    description: t(`${cmsLocation}.reasons.EXPIRED_DOCUMENT.description`),
  },
  [RemedialReasons.EXPIRED_DOCUMENT_PDF]: {
    title: t(`${cmsLocation}.reasons.EXPIRED_DOCUMENT_PDF.title`),
    description: t(`${cmsLocation}.reasons.EXPIRED_DOCUMENT_PDF.description`),
  },
  [RemedialReasons.PARTIAL_CROPPED_BLURRY_DOCUMENT]: {
    title: t(`${cmsLocation}.reasons.PARTIAL_CROPPED_BLURRY_DOCUMENT.title`),
    description: t(`${cmsLocation}.reasons.PARTIAL_CROPPED_BLURRY_DOCUMENT.description`),
  },
  [RemedialReasons.PARTIAL_CROPPED_BLURRY_DOCUMENT_PDF]: {
    title: t(`${cmsLocation}.reasons.PARTIAL_CROPPED_BLURRY_DOCUMENT_PDF.title`),
    description: t(`${cmsLocation}.reasons.PARTIAL_CROPPED_BLURRY_DOCUMENT_PDF.description`),
  },
  [RemedialReasons.NO_ADVERSE_META_DATA]: {
    title: t(`${cmsLocation}.reasons.NO_ADVERSE_META_DATA.title`),
    description: t(`${cmsLocation}.reasons.NO_ADVERSE_META_DATA.description`),
  },
  [RemedialReasons.MISSING_ID]: {
    title: t(`${cmsLocation}.reasons.MISSING_ID.title`),
    description: t(`${cmsLocation}.reasons.MISSING_ID.description`),
  },
  [RemedialReasons.SHARECODE_REQUIRED]: {
    title: t(`${cmsLocation}.reasons.SHARECODE_REQUIRED.title`),
    description: t(`${cmsLocation}.reasons.SHARECODE_REQUIRED.description`),
  },
  [RemedialReasons.MISSING_RTR_DOCUMENT]: {
    title: t(`${cmsLocation}.reasons.MISSING_RTR_DOCUMENT.title`),
    description: t(`${cmsLocation}.reasons.MISSING_RTR_DOCUMENT.description`),
  },
  [RemedialReasons.DOCUMENT_OUT_OF_DATE]: {
    title: t(`${cmsLocation}.reasons.DOCUMENT_OUT_OF_DATE.title`),
    description: t(`${cmsLocation}.reasons.DOCUMENT_OUT_OF_DATE.description`),
  },
  [RemedialReasons.DOCUMENT_OUT_OF_DATE_PDF]: {
    title: t(`${cmsLocation}.reasons.DOCUMENT_OUT_OF_DATE_PDF.title`),
    description: t(`${cmsLocation}.reasons.DOCUMENT_OUT_OF_DATE_PDF.description`),
  },
  [RemedialReasons.BLACK_AND_WHITE_DOCUMENT]: {
    title: t(`${cmsLocation}.reasons.BLACK_AND_WHITE_DOCUMENT.title`),
    description: t(`${cmsLocation}.reasons.BLACK_AND_WHITE_DOCUMENT.description`),
  },
  [RemedialReasons.BLACK_AND_WHITE_DOCUMENT_PDF]: {
    title: t(`${cmsLocation}.reasons.BLACK_AND_WHITE_DOCUMENT_PDF.title`),
    description: t(`${cmsLocation}.reasons.BLACK_AND_WHITE_DOCUMENT_PDF.description`),
  },
  [RemedialReasons.PROOF_OF_ADDRESS_NOT_CORRECT]: {
    title: t(`${cmsLocation}.reasons.PROOF_OF_ADDRESS_NOT_CORRECT.title`),
    description: t(`${cmsLocation}.reasons.PROOF_OF_ADDRESS_NOT_CORRECT.description`),
  },
  [RemedialReasons.MISSING_PROOF_OF_ADDRESS]: {
    title: t(`${cmsLocation}.reasons.MISSING_PROOF_OF_ADDRESS.title`),
    description: t(`${cmsLocation}.reasons.MISSING_PROOF_OF_ADDRESS.description`),
  },
  [RemedialReasons.SOURCE_DOES_NOT_SHOW_INCOME]: {
    title: t(`${cmsLocation}.reasons.SOURCE_DOES_NOT_SHOW_INCOME.title`),
    description: t(`${cmsLocation}.reasons.SOURCE_DOES_NOT_SHOW_INCOME.description`),
  },
  [RemedialReasons.MISSING_PROOF_OF_INCOME]: {
    title: t(`${cmsLocation}.reasons.MISSING_PROOF_OF_INCOME.title`),
    description: t(`${cmsLocation}.reasons.MISSING_PROOF_OF_INCOME.description`),
  },
  [RemedialReasons.MISSING_TENANCY_AGREEMENT]: {
    title: t(`${cmsLocation}.reasons.MISSING_TENANCY_AGREEMENT.title`),
    description: t(`${cmsLocation}.reasons.MISSING_TENANCY_AGREEMENT.description`),
  },
  [RemedialReasons.INCORRECT_INCOME_REFERENCE]: {
    title: t(`${cmsLocation}.reasons.INCORRECT_INCOME_REFERENCE.title`),
    description: t(`${cmsLocation}.reasons.INCORRECT_INCOME_REFERENCE.description`),
  },
  [RemedialReasons.INCORRECT_RENTAL_REFERENCE]: {
    title: t(`${cmsLocation}.reasons.INCORRECT_RENTAL_REFERENCE.title`),
    description: t(`${cmsLocation}.reasons.INCORRECT_RENTAL_REFERENCE.description`),
  },
  [RemedialReasons.OTHER]: {
    title: t(`${cmsLocation}.reasons.OTHER.title`),
    description: t(`${cmsLocation}.reasons.OTHER.description`),
  },
});

const stepName = StepNamesDefaultIncome.additionalIncome;

const MissingInformation = ({
  data,
  sectionName,
  onSubmit,
  values: initialValues,
  validationRules: validationRulesGeneric,
  ...props
}: DefaultIncomeQuestionComponentProps) => {
  const { t } = useTranslation();
  const content = useContent(t);

  const handleSubmit = () => {
    onSubmit({ key: stepName, value: '' });
  };

  const renderReasons = useCallback(() => {
    return data.remedialSection?.reasons?.map((reason, index) => {
      const reasonData = content[reason];
      if (!reasonData) {
        console.error('No translation found for reason', reason);
        Sentry.captureException(`No translation found for reason`, { extra: { reason } });

        return null;
      }
      return (
        <React.Fragment key={index}>
          <hr className="mb-6" />
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="font-medium flex space-x-6">
              <ExclamationCircleIcon className="h-6 w-6 text-yellow-500 flex-shrink-0" />
              <p>{reasonData.title}</p>
            </div>
            <p className="text-gray-500">
              <span>{reasonData.description}</span>
            </p>
          </div>
        </React.Fragment>
      );
    });
  }, [data, content]);

  const referenceIsMissing = !!data.remedialSection?.reasons?.find(
    reason =>
      reason === RemedialReasons.INCORRECT_RENTAL_REFERENCE ||
      reason === RemedialReasons.INCORRECT_INCOME_REFERENCE,
  );

  return (
    <QuestionOrganism
      id={stepName}
      data-testid={stepName}
      onSubmit={handleSubmit}
      title={
        <Trans
          i18nKey={referenceIsMissing ? `${cmsLocation}.title_reference` : `${cmsLocation}.title`}
          values={{ step: content[sectionName as WizardSectionType] }}
        />
      }
      subtitle={
        <Trans
          i18nKey={
            referenceIsMissing ? `${cmsLocation}.subtitle_reference` : `${cmsLocation}.subtitle`
          }
          values={{ doc: 'document' }}
        />
      }
      sectionName={sectionName}
      {...props}
    >
      {renderReasons()}

      {data.remedialSection.comment ? (
        <div className="bg-light-blue-1 p-4 space-y-6 rounded-lg mt-12">
          <p className="font-medium">{content.comments}:</p>
          <p>{data.remedialSection.comment}</p>
        </div>
      ) : null}

      <p className="mt-12">{content.continue}</p>
    </QuestionOrganism>
  );
};

export default MissingInformation;

import { useCallback, useState } from 'react';

type OnInputChangeEvent = (e: React.ChangeEvent<HTMLInputElement>) => void;

type UseCheckboxPayload = {
  checked: boolean;
  onChange: OnInputChangeEvent;
};

export type UseCheckboxReturn = [UseCheckboxPayload, (c: boolean) => void];

const useCheckbox = (initiallyChecked: boolean = false): UseCheckboxReturn => {
  const [checked, setChecked] = useState(initiallyChecked);

  const onChange: OnInputChangeEvent = useCallback(e => {
    setChecked(e.target.checked);
  }, []);

  const payload: UseCheckboxPayload = {
    checked,
    onChange,
  };

  return [payload, setChecked];
};

export default useCheckbox;

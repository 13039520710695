import { useMemo } from 'react';
import {
  IdentityQuestionComponentProps,
  StepNamesDefaultIdentity,
} from 'questionFlow/flows/defaultIdentity/types';
import { useTranslation } from 'react-i18next';
import DropdownQuestion from 'questionFlow/genericQuestions/DropdownQuestion';
import AllCountryCodes from 'questionFlow/data/all_country_codes.json';
import { useTranslatedValues } from 'hooks/useTranslatedValues';

const cmsLocation = '/questions/identity/nationality';

const translations = {
  title: `${cmsLocation}.title`,
  subtitle: `${cmsLocation}.subtitle`,
  placeholder: `${cmsLocation}.placeholder`,
} as const;

const name = StepNamesDefaultIdentity.nationality;

const Nationality = ({ data, values, ...props }: IdentityQuestionComponentProps) => {
  const { i18n } = useTranslation();
  const content = useTranslatedValues(translations);

  const regionNames = useMemo(
    // @ts-expect-error Property 'DisplayNames' does not exist on type 'typeof Intl'
    () => new Intl.DisplayNames([i18n.language], { type: 'region' }),
    [i18n.language],
  );

  const options = useMemo(
    () =>
      AllCountryCodes.map(countryCode => {
        const localisedNationality = regionNames.of(countryCode);
        return { value: countryCode, label: localisedNationality };
      }).sort((a, b) => (a.label < b.label ? -1 : 1)),
    [regionNames],
  );

  return (
    <DropdownQuestion
      content={content}
      initialValue={values[name]}
      name={name}
      options={options}
      {...props}
    />
  );
};

export default Nationality;

import { ValuesType } from './types';

export enum ActionType {
  NEXT_STEP,
  NEXT_STAGE,
  BACK,
  UPDATE_VALUES,
}

interface NextStep {
  type: ActionType.NEXT_STEP;
  payload: {
    values: ValuesType;
    nextStep: string;
  };
}

interface NextStage {
  type: ActionType.NEXT_STAGE;
  payload: {
    values: ValuesType;
    nextStep: string;
  };
}

interface Back {
  type: ActionType.BACK;
  payload: {
    nextStageIndex: number;
    nextStepIndex: number;
  };
}

interface UpdateValues {
  type: ActionType.UPDATE_VALUES;
  payload: Partial<ValuesType>;
}

export type Actions = NextStep | NextStage | Back | UpdateValues;

export interface State {
  currentSectionFlowIndex: number;
  currentStepIndex: number;
  seenFlow: string[];
  values: ValuesType;
}

const reducer = (state: State, action: Actions) => {
  switch (action.type) {
    case ActionType.NEXT_STEP: {
      const nextIndex = state.currentStepIndex + 1;

      return {
        ...state,
        values: { ...state.values, ...action.payload.values },
        currentStepIndex: nextIndex,
        seenFlow: [...state.seenFlow, action.payload.nextStep],
      };
    }
    case ActionType.NEXT_STAGE: {
      const nextIndex = state.currentStepIndex + 1;

      return {
        ...state,
        values: { ...state.values, ...action.payload.values },
        currentSectionFlowIndex: state.currentSectionFlowIndex + 1,
        currentStepIndex: nextIndex,
        seenFlow: [...state.seenFlow, action.payload.nextStep],
      };
    }
    case ActionType.BACK: {
      return {
        ...state,
        currentSectionFlowIndex: action.payload.nextStageIndex,
        currentStepIndex: action.payload.nextStepIndex,
        seenFlow: state.seenFlow.slice(0, -1),
      };
    }
    case ActionType.UPDATE_VALUES: {
      return {
        ...state,
        values: {
          ...state.values,
          ...action.payload,
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;

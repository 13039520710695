import {
  DefaultResidenceQuestionComponentProps,
  StepNamesDefaultResidence,
} from 'questionFlow/flows/defaultResidence/types';
import QuestionOrganism from 'questionFlow/QuestionOrganism';
import { TFunction, useTranslation } from 'react-i18next';

const cmsLocation = '/questions/residence/thanks';

const useContent = (t: TFunction) => ({
  title: t(`${cmsLocation}.title`),
  subtitle: t(`${cmsLocation}.subtitle`),
});

const Thanks = ({ data, values, onSubmit, ...props }: DefaultResidenceQuestionComponentProps) => {
  const { t } = useTranslation();
  const content = useContent(t);

  const handleSubmit = () => {
    onSubmit({ key: StepNamesDefaultResidence.thanks, value: 'acknowledged' });
  };

  return (
    <QuestionOrganism
      data-testid="thanks"
      title={content.title}
      subtitle={content.subtitle}
      onSubmit={handleSubmit}
      {...props}
    >
      {null}
    </QuestionOrganism>
  );
};

export default Thanks;

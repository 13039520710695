import useDocumentTitle from '@tanem/use-document-title';
import clsx from 'clsx';
import { Trans, useTranslation } from 'react-i18next';

const Server500 = () => {
  const { t } = useTranslation();

  useDocumentTitle(t('error_page.title'));

  return (
    <div
      data-testid="error"
      className={clsx(
        'flex flex-col flex-no-wrap justify-center content-center items-center bg-white',
        'absolute inset-0 m-6 text-center',
      )}
    >
      <p className="font-semibold text-2xl">{t('error_page.heading')}</p>

      <p className="text-blue-3 font-black text-[100px] leading-[121px] md:text-[316px] md:leading-[382px] py-6 md:py-10">
        500
      </p>

      <p className="text-base md:text-2xl">
        <Trans
          i18nKey={'error_page.description'}
          components={{ bold: <span className="font-semibold" /> }}
        />
        <br />
        <br className="md:hidden" />
        {t('error_page.try_again')}
      </p>
    </div>
  );
};

export default Server500;

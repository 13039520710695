import Modal from 'react-modal';
import OnboardingCard from 'pages/onboarding/OnboardingCard';
import { Trans, useTranslation, TFunction } from 'react-i18next';
import { Checkbox, Field } from 'components';
import { useState } from 'react';
import { InformationCircleIcon } from '@heroicons/react/solid';
import { AccountDocumentType } from 'generated/graphql';
import { getNameForDocType } from './utils';

const cmsLocation = '/questions/upload';

const useContent = (t: TFunction<'translation'>, docType: AccountDocumentType) => {
  const getTranslationKey = (translateItem: String): string => {
    const doc = getNameForDocType(docType);

    return t(`${cmsLocation}.${doc}.${translateItem}` as any).includes(cmsLocation)
      ? `${cmsLocation}.${translateItem}`
      : `${cmsLocation}.${doc}.${translateItem}`;
  };

  return {
    items: getTranslationKey('items'),
    confirmation: t(`${cmsLocation}.confirmation`),
    confirmBtn: t(`${cmsLocation}.confirmBtn`),
    backBtn: t(`${cmsLocation}.backBtn`),
    validationError: t(`${cmsLocation}.validationError`),
  };
};

type Props = {
  showModal: boolean;
  closeModal: () => void;
  docType: string;
  docTypeName: string;
  transValues?: Record<string, string>;
};

export const UploadConfirmationModal = ({
  showModal,
  closeModal,
  docType,
  docTypeName,
  transValues,
}: Props) => {
  const { t } = useTranslation();
  const [checked, setChecked] = useState(false);
  const [error, setError] = useState('');

  const content = useContent(t, docTypeName as AccountDocumentType);

  const handleSubmit = (e: React.MouseEvent) => {
    if (!checked) {
      e.preventDefault();
      setError(content.validationError);
    } else {
      setError('');
    }
  };

  return (
    <Modal
      id="upload-modal"
      appElement={document.getElementById('root') || undefined}
      isOpen={showModal}
      contentLabel="Onboarding Modal"
      className="outline-none p-0 md:w-[480px] min-h-screen md:min-h-[600px] absolute md:static left-0 right-0 top-0 bottom-0 md:flex overflow-scroll md:overflow-hidden shadow-xl"
      overlayClassName="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-30 z-50"
    >
      <OnboardingCard
        className="h-full"
        title={content.confirmation}
        buttonProps={{
          text: content.confirmBtn,
          onClick: handleSubmit,
          attrs: {
            type: 'submit',
            form: 'form',
            'data-testid': 'upload-modal-submit',
          },
        }}
        backButtonProps={{
          text: content.backBtn,
          onClick: closeModal,
          attrs: {
            'data-testid': 'upload-modal-back',
          },
        }}
      >
        <div className="space-y-4">
          <p className="font-semibold text-sm">
            <Trans
              i18nKey={'/questions/upload.title'}
              values={{ doc: docType?.toLocaleLowerCase() }}
            />
          </p>

          <ul className="py-2 list-disc pl-6 space-y-3 text-sm">
            <Trans
              i18nKey={content.items as any}
              components={{
                li: <li />,
                b: <strong />,
              }}
              values={transValues}
            />
          </ul>

          <Field data-testid="upload-modal-checkbox-field" validationError={error} className="ml-9">
            <label htmlFor="upload-modal-checkbox" className="text-sm flex items-center">
              <Checkbox
                id="upload-modal-checkbox"
                data-testid="upload-modal-checkbox"
                className="-ml-9"
                checked={checked}
                onChange={e => setChecked(e.currentTarget.checked)}
                color="blue"
              />

              <span className="ml-4 text-sm">
                <Trans
                  i18nKey={'/questions/upload.agreeemnt'}
                  components={{ r: <span className="text-red-500" /> }}
                  values={{ doc: docType?.toLocaleLowerCase() }}
                />
              </span>
            </label>
          </Field>

          <div className="flex items-center">
            <InformationCircleIcon className="w-5 h-5 text-gray-400 flex-shrink-0 mr-4" />
            <p className="text-gray-400 text-sm">
              <Trans
                i18nKey={'/questions/upload.correctInfo'}
                components={{ b: <span className="font-semibold" /> }}
              />
            </p>
          </div>
        </div>
      </OnboardingCard>
    </Modal>
  );
};

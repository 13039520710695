import { useEffect, useState, createContext, ReactElement } from 'react';
import { Analytics, AnalyticsBrowser } from '@segment/analytics-next';
import { getEnv } from 'services/environmentVariables';
import { GetInitialDataQuery } from 'generated/graphql';

export const AnalyticsContext = createContext<
  Partial<{
    analytics: Analytics;
    setUserIdentity: (v: string) => void;
  }>
>({ analytics: undefined, setUserIdentity: undefined });

const AnalyticsProvider = ({
  children,
  pathname,
  data,
}: {
  children: ReactElement;
  pathname: string;
  data: GetInitialDataQuery | undefined;
}) => {
  const [analytics, setAnalytics] = useState<Analytics | undefined>(undefined);

  useEffect(() => {
    const loadAnalytics = async () => {
      const writeKey = getEnv('SEGMENT_KEY');
      if (writeKey) {
        const [response] = await AnalyticsBrowser.load({ writeKey });
        setAnalytics(response);
      }
    };
    loadAnalytics();
  }, []);

  useEffect(() => {
    if (analytics) analytics.page(pathname);
  }, [pathname, analytics]);

  useEffect(() => {
    if (data && data.me.email && analytics) {
      analytics.identify(data.me.email, {
        firstName: data.me.name.firstName,
        lastName: data.me.name.lastName,
        email: data.me.email,
      });
    }
  }, [data, analytics]);

  return <AnalyticsContext.Provider value={{ analytics }}>{children}</AnalyticsContext.Provider>;
};

export default AnalyticsProvider;

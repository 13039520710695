<svg width="28" height="33" viewBox="0 0 28 33" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M28 9.0127V28.0127C28 29.0736 27.5786 30.091 26.8284 30.8411C26.0783 31.5913 25.0609 32.0127 24 32.0127H22V30.0127H24C24.5304 30.0127 25.0391 29.802 25.4142 29.4269C25.7893 29.0518 26 28.5431 26 28.0127V9.0127H22C21.2044 9.0127 20.4413 8.69662 19.8787 8.13402C19.3161 7.57141 19 6.80834 19 6.0127V2.0127H8C7.46957 2.0127 6.96086 2.22341 6.58579 2.59848C6.21071 2.97355 6 3.48226 6 4.0127V22.0127H4V4.0127C4 2.95183 4.42143 1.93441 5.17157 1.18427C5.92172 0.434123 6.93913 0.0126953 8 0.0126953L19 0.0126953L28 9.0127ZM3.2 23.7127H0V31.7107H1.582V29.0267H3.188C3.762 29.0267 4.25 28.9127 4.652 28.6807C5.058 28.4467 5.368 28.1307 5.578 27.7327C5.79597 27.3151 5.90663 26.8497 5.9 26.3787C5.9 25.8787 5.794 25.4267 5.584 25.0247C5.37512 24.6251 5.05577 24.2939 4.664 24.0707C4.264 23.8307 3.778 23.7127 3.2 23.7127ZM4.29 26.3787C4.29721 26.6422 4.23879 26.9034 4.12 27.1387C4.01343 27.3436 3.84754 27.5116 3.644 27.6207C3.41118 27.7359 3.15365 27.7922 2.894 27.7847H1.576V24.9727H2.896C3.332 24.9727 3.674 25.0927 3.92 25.3347C4.166 25.5787 4.29 25.9267 4.29 26.3787ZM6.724 23.7127V31.7107H9.644C10.446 31.7107 11.112 31.5507 11.64 31.2367C12.1743 30.917 12.5915 30.4339 12.83 29.8587C13.09 29.2587 13.222 28.5347 13.222 27.6907C13.222 26.8507 13.092 26.1347 12.83 25.5407C12.5944 24.9721 12.1811 24.495 11.652 24.1807C11.124 23.8687 10.454 23.7127 9.642 23.7127H6.724ZM8.306 25.0027H9.432C9.928 25.0027 10.332 25.1027 10.65 25.3067C10.9801 25.5226 11.2291 25.8419 11.358 26.2147C11.516 26.6167 11.594 27.1187 11.594 27.7207C11.6002 28.1196 11.5545 28.5176 11.458 28.9047C11.3867 29.21 11.2533 29.4973 11.066 29.7487C10.892 29.973 10.6614 30.1469 10.398 30.2527C10.0893 30.3682 9.76149 30.4238 9.432 30.4167H8.306V25.0027ZM15.792 28.5287V31.7107H14.212V23.7127H19.308V25.0187H15.792V27.2527H19.004V28.5287H15.792Z"
    fill="#9CA3AF"
  />
</svg>;

interface Props {
  className?: string;
}

const PDFFileIcon = ({ className }: Props) => (
  <svg
    className={className}
    width="28"
    height="33"
    viewBox="0 0 28 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28 9.0127V28.0127C28 29.0736 27.5786 30.091 26.8284 30.8411C26.0783 31.5913 25.0609 32.0127 24 32.0127H22V30.0127H24C24.5304 30.0127 25.0391 29.802 25.4142 29.4269C25.7893 29.0518 26 28.5431 26 28.0127V9.0127H22C21.2044 9.0127 20.4413 8.69662 19.8787 8.13402C19.3161 7.57141 19 6.80834 19 6.0127V2.0127H8C7.46957 2.0127 6.96086 2.22341 6.58579 2.59848C6.21071 2.97355 6 3.48226 6 4.0127V22.0127H4V4.0127C4 2.95183 4.42143 1.93441 5.17157 1.18427C5.92172 0.434123 6.93913 0.0126953 8 0.0126953L19 0.0126953L28 9.0127ZM3.2 23.7127H0V31.7107H1.582V29.0267H3.188C3.762 29.0267 4.25 28.9127 4.652 28.6807C5.058 28.4467 5.368 28.1307 5.578 27.7327C5.79597 27.3151 5.90663 26.8497 5.9 26.3787C5.9 25.8787 5.794 25.4267 5.584 25.0247C5.37512 24.6251 5.05577 24.2939 4.664 24.0707C4.264 23.8307 3.778 23.7127 3.2 23.7127ZM4.29 26.3787C4.29721 26.6422 4.23879 26.9034 4.12 27.1387C4.01343 27.3436 3.84754 27.5116 3.644 27.6207C3.41118 27.7359 3.15365 27.7922 2.894 27.7847H1.576V24.9727H2.896C3.332 24.9727 3.674 25.0927 3.92 25.3347C4.166 25.5787 4.29 25.9267 4.29 26.3787ZM6.724 23.7127V31.7107H9.644C10.446 31.7107 11.112 31.5507 11.64 31.2367C12.1743 30.917 12.5915 30.4339 12.83 29.8587C13.09 29.2587 13.222 28.5347 13.222 27.6907C13.222 26.8507 13.092 26.1347 12.83 25.5407C12.5944 24.9721 12.1811 24.495 11.652 24.1807C11.124 23.8687 10.454 23.7127 9.642 23.7127H6.724ZM8.306 25.0027H9.432C9.928 25.0027 10.332 25.1027 10.65 25.3067C10.9801 25.5226 11.2291 25.8419 11.358 26.2147C11.516 26.6167 11.594 27.1187 11.594 27.7207C11.6002 28.1196 11.5545 28.5176 11.458 28.9047C11.3867 29.21 11.2533 29.4973 11.066 29.7487C10.892 29.973 10.6614 30.1469 10.398 30.2527C10.0893 30.3682 9.76149 30.4238 9.432 30.4167H8.306V25.0027ZM15.792 28.5287V31.7107H14.212V23.7127H19.308V25.0187H15.792V27.2527H19.004V28.5287H15.792Z"
      fill="#9CA3AF"
    />
  </svg>
);

export default PDFFileIcon;

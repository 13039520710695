import { useTranslation, TFunction } from 'react-i18next';
import { ResidenceTypeEnum } from 'generated/graphql';

const cmsLocation = '/questions/residence/residentialHistory.residenceType';

const useContent = (t: TFunction<'translation'>) =>
  ({
    [ResidenceTypeEnum.FamilyHome]: t(`${cmsLocation}.FAMILY_HOME`),
    [ResidenceTypeEnum.InformalArrangement]: t(`${cmsLocation}.INFORMAL_ARRANGEMENT`),
    [ResidenceTypeEnum.RentedFlat]: t(`${cmsLocation}.RENTED_FLAT`),
    [ResidenceTypeEnum.SelfOwnedHome]: t(`${cmsLocation}.SELF_OWNED_HOME`),
    [ResidenceTypeEnum.StudentAccommodation]: t(`${cmsLocation}.STUDENT_ACCOMMODATION`),
  } as Record<ResidenceTypeEnum, string>);

const sortOrder = [
  ResidenceTypeEnum.RentedFlat,
  ResidenceTypeEnum.SelfOwnedHome,
  ResidenceTypeEnum.FamilyHome,
  ResidenceTypeEnum.StudentAccommodation,
  ResidenceTypeEnum.InformalArrangement,
];

const useResidenceTypeOptions = () => {
  const { t } = useTranslation();
  const content = useContent(t);

  return Object.values(ResidenceTypeEnum)
    .sort((a, b) => {
      const aIndex = sortOrder.indexOf(a) === -1 ? 999 : sortOrder.indexOf(a);
      const bIndex = sortOrder.indexOf(b) === -1 ? 999 : sortOrder.indexOf(b);
      return aIndex - bIndex;
    })
    .map(type => ({
      label: content[type],
      value: type,
    }));
};

export default useResidenceTypeOptions;
